import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../service/auth.service';
import { TradeIdeaComponent } from '../dashboard/trade-idea/trade-idea.component';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { NoAccessComponent } from '../dashboard-header/no-access/no-access.component'; 
declare let $: any;

@Component({
  selector: 'app-signals-detail-view',
  templateUrl: './signals-detail-view.component.html',
  styleUrls: ['./signals-detail-view.component.scss'],
  providers: [ AppGlobals]

})
export class SignalsDetailViewComponent implements OnInit {


  //inside class define below variable
  public config: PerfectScrollbarConfigInterface = {};

  constructor(
  private router: Router,
  public dialog: MatDialog,
  private baseUrl: AppGlobals,
  private auth: AuthService,
  private route: ActivatedRoute) { }

  show = true;
  show1 = false;
  check_sess	='';
  trade_id 		=''
  token 		='';
  imgBaseUrl 	='';
  signaldetail : any;
  commentText:string = '';
  childCommentText:string = '';
  commentList:any = [];
  childCommentList:any = [];
  showDetail = false;
  f = false;
  currentDate = new Date();
  isApiCalled = false;
  profileBaseUrl = '';
  isAccessible=false;
  subscriptionStatus;
  userId;
  showcommentSection = 0;
  ngOnInit() {
     //this.container = document.getElementById("msgContainer");           
      //this.container.scrollTop = this.container.scrollHeight;
      this.userId = localStorage.getItem('for_userid'); 
      console.log()
      this.check_sess=localStorage.getItem('for_token');
        if(this.check_sess == null || this.check_sess ==""){
            this.router.navigate(['/sign-in']);
        }

    if(this.route.snapshot.paramMap.get('tradeid')!=undefined)this.trade_id = this.route.snapshot.paramMap.get('tradeid');
    else this.trade_id = localStorage.getItem('for_tradeid');
    console.log('this.trade_id',this.trade_id);
    this.getSubscriptionDetail();
  	this.getSignalsDetail();
    this.getComments();
    if(localStorage.getItem('for_tradeidfrm')){
      this.f = true;
      localStorage.setItem('for_tradeidfrm', '');
    }
    this.profileBaseUrl = this.baseUrl.baseAppUrl+'uploads/profile/';
    
  }

  getSubscriptionDetail() {
    this.auth.getSubscriptionDetail(this.baseUrl.baseAppUrl+'user/getSubscriptionDetail?userId='+this.userId,this.check_sess).subscribe((res: any) =>  {
        if(res){
          if(res.data.is_accessible==1)
          {
            this.isAccessible=true;
          }else{
            this.isAccessible=false;
          }
          console.log(this.isAccessible, 'www');
          //this.subscriptionStatus = res.data.subscription_status;
          this.subscriptionStatus = localStorage.getItem('subscription_status');
          //localStorage.setItem('subscription_status', this.subscriptionStatus);
        }
     });
  }

  putChiledComment(commentId){
    this.childCommentText = $('#focuc_textarea'+commentId).val();
    this.createComment(commentId);
  }

  createComment(id=0) {
    if(this.commentText!='' || this.childCommentText!=''){
      const data = {
      parent_id :id, 
      user_id : localStorage.getItem('for_userid'),
      text : id==0?this.commentText:this.childCommentText,
      trade_id: this.trade_id,
      trade_created_by: this.signaldetail.user_id
      };
      //console.log(data); return false;
      this.auth.tradeideaCommentSrvs(this.baseUrl.baseAppUrl+'trade/post_comment', data , this.token).subscribe((res: any) =>  {
              if(res.status==1){
                if(id==0){
                  this.commentText = '';
                  this.commentList.push(res.data);
                }else{
                  this.childCommentText = '';
                  $('#focuc_textarea'+id).val('');
                  //this.childCommentList.push(res.data);
                  for(let i in this.commentList){
                    if(this.commentList[i].id==id)(this.commentList[i].child_comment).push(res.data);
                  }
                }
                //console.log(this.signalList, 'res', this.imgBaseUrl);
              }
              else{
              
              }
            });
    }
    
  }

  chileCommentToggle(id){
    //console.log(this.showcommentSection,id);
    if(this.showcommentSection==id)this.showcommentSection = 0;
    else this.showcommentSection = id;
  }

  goToChat(username)
  {
    console.log(this.isAccessible, 'ad');
    if(this.isAccessible)
    {
      this.router.navigate(['/personal-chat'], { queryParams: {id: username}});
    }else{
      const dialogRef = this.dialog.open(NoAccessComponent, {
      });
      
      dialogRef.afterClosed().subscribe(result => {
        
      });
    }
    

  }

  onKeydown(event,type=''){
      //console.log('onKeydown',event.target.value);
      if(type=='chiled')this.childCommentText = event.target.value;
      event.preventDefault();
    }

  CreateTradeIdea(){
      const dialogRef = this.dialog.open(TradeIdeaComponent, {
        height: "100vh",
        maxHeight: "640px"
      });
      
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();
      });
    }

  getComments() {
  this.auth.getTradeComments(this.baseUrl.baseAppUrl+'trade/get_comments?trade_id='+this.trade_id , this.token).subscribe((res: any) =>  {
      if(res.status==1){
        this.commentList = res.data;
        //console.log(res, 'success');
        //console.log(this.signalList, 'res', this.imgBaseUrl);
       }
       else{
       
       }
    });
  }
  showmoreless(type){
     if(type == 'more'){
       this.show = false;
       this.show1 = true;
     }
     else{
      this.show = true;
      this.show1 = false;
     }
  }
  getSignalsDetail() {
    const data = {
      user_id : localStorage.getItem('for_userid'),
      signal_id : this.trade_id
      };
    this.token=localStorage.getItem('for_token');
        if(this.token == null || this.token ==""){
            this.router.navigate(['/sign-in']);
        }
    
    this.auth.tradeideaDetailSrvs(this.baseUrl.baseAppUrl+'trade/signal_detail', data, this.token).subscribe((res: any) =>  {
            if(res){
              this.isApiCalled = true;
              this.signaldetail = res.data;
              this.imgBaseUrl = this.baseUrl.baseAppUrl+'uploads/trade/';
              this.profileBaseUrl = this.baseUrl.baseAppUrl+'uploads/profile/';
              this.showDetail = true;
            //console.log(this.signalList, 'res', this.imgBaseUrl);
             }
            else{
             
             }
          });
  }

   tradelikeDislike(id){
     this.token=localStorage.getItem('for_token');
        if(this.token == null || this.token ==""){
            this.router.navigate(['/sign-in']);
        }
        
      const data = {
      user_id : localStorage.getItem('for_userid'),
      post_id : id,
      trade_created_by: this.signaldetail.user_id
      };
    this.auth.tradeideaLikeSrvs(this.baseUrl.baseAppUrl+'trade/post_like', data , this.token).subscribe((res: any) =>  {
            if(res.status==1){
              this.signaldetail.totallike = res.total;
              if(res.action =='like')
                this.signaldetail.userlike = 1;
              else
                this.signaldetail.userlike = 0;
              }
            else{
             
             }
          });
  }
  CreateStreamImage(img){
    localStorage.setItem('for_tradeImage', img);
      const dialogRef = this.dialog.open(CreateImageDialog, {
      });
      
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();
      });
    }

}

/**  Create confirm logout  **/
@Component({
  selector: 'dialog-create-image',
  templateUrl: './dialog-create-image.html',
  //styleUrls: ['.dashboard/dashboard.component.scss']
  })
  
  export class CreateImageDialog {
    trade_img = localStorage.getItem('for_tradeImage');
    set = localStorage.setItem('for_tradeImage', '');
  }