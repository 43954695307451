import { Component, OnInit,Optional ,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-contact-thank-popup',
  templateUrl: './contact-thank-popup.component.html',
  styleUrls: ['./contact-thank-popup.component.scss']
})
export class ContactThankPopupComponent implements OnInit {

  popup_msg = 'Thank you for sharing your trade idea with the community.';
  constructor(public dialogRef: MatDialogRef<ContactThankPopupComponent>,
   @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.popup_msg = data.popup_msg;
	}

  ngOnInit() {
  }

}
