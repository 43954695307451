// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //local
    socketApiPath: 'http://localhost:4005',
  //live
    //socketApiPath: 'https://dashboard.forexplatinumtrading.com/',
  //staging
    //socketApiPath: 'https://dashboard.staging.forexplatinumtrading.com/',    

  agora: {
    //appId: '8e075d56e11b4bb0aa277cd10061a9be',
    appId:'9d36855c09274ac8a0a58b8daabafc8c'
  },
  //zoomApiKey : 'SIboxFLHQkWeXCdGKD4OFQ'
  zoomApiKey : 'UxTsuMikQQmh-RfEjhMoQA'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
