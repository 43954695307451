import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class AdminService {
	httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'my-auth-token' }) };
  constructor(private http: HttpClient) { }

  addVideo(baseurl, data) {
	 //const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
  	//return this.http.post(baseurl, data, this.httpOptions);
  	return this.http.post(baseurl, data);
  }

  addChapter(baseurl, data) {
  	return this.http.post(baseurl, data);
  }
  chapterListing(baseurl, data) {
    return this.http.get(baseurl, data);
  }

  deletefromListing(baseurl, data) {
    return this.http.post(baseurl, data , this.httpOptions);
  }
  getEditdetail(baseurl, data) {
    return this.http.post(baseurl, data , this.httpOptions);
  }
  loginSrvs(baseurl, data) {
    return this.http.post(baseurl, data, this.httpOptions);
  }

  deleteChannelVideo(baseurl, data) {
    return this.http.post(baseurl, data , this.httpOptions);
  }
  getUsers(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.get(baseurl, options);
  }
  addPrize(baseurl, data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.post(baseurl, data, options);
  }
  prizeListing(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.get(baseurl, options);
  }
  getPrizedetail(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.get(baseurl, options);
  }
  deletePrize(baseurl, data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.post(baseurl, data , options);
  }
  userListing(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.get(baseurl, options);
  }
  getUserdetail(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.get(baseurl, options);
  }
  updateUser(baseurl, data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.post(baseurl, data , options);
  }
  updateUserStatus(baseurl, data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.post(baseurl, data , options);
  }
  deleteUser(baseurl, data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.post(baseurl, data , options);
  }
  addStream(baseurl, data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.post(baseurl, data , options);
  }
  streamListing(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.get(baseurl, options);
  }
  updateStream(baseurl, data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.post(baseurl, data , options);
  }
  deleteStream(baseurl, data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.post(baseurl, data , options);
  }
  agoraRecordingStartStop(baseurl, data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.post(baseurl, data , options);
  }


  testimonialsListing(baseurl, data) {
    return this.http.get(baseurl, data);
  }
  getTestimonialsDetail(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.get(baseurl, options);
  }
  addEditTestimonials(baseurl, data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.post(baseurl, data , options);
  }

  faqsListing(baseurl, data) {
    return this.http.get(baseurl, data);
  }
  getFaqsDetail(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.get(baseurl, options);
  }
  addEditFaqs(baseurl, data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.post(baseurl, data , options);
  }

  subscriptionplan(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.get(baseurl, options);
  }
  subscriptionplan_add(baseurl,data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.post(baseurl,data, options);
  }

  delete_plan(baseurl, data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.post(baseurl, data , options);
  }
  transactions(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.get(baseurl, options);
  }

  // Add 24/10/2023
  getRoledetail(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.get(baseurl, options);
  }
  roleListing(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.get(baseurl, options);
  }
  roleAction(baseurl,data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.http.post(baseurl,data, options);
  }


  resetpassword(baseurl, data) {
    return this.http.post(baseurl, data, this.httpOptions);
  }

}
