import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder,FormGroup, FormControl, Validators } from '@angular/forms';
import {AdminService} from "../services/admin.service";
import { AppGlobals } from '../../app.global';
import { tokendata } from '../../main/variable';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-add-subadmin',
  templateUrl: './add-subadmin.component.html',
  styleUrls: ['./add-subadmin.component.scss']
})
export class AddSubadminComponent implements OnInit {

  btn_title = 'Add Subadmin';
  addUserForm: FormGroup;
  isSubmitted  =  false;
  check_sess : any;
  hide1 : any;hide2 : any;
  email_msg = '';
  user_msg = '';

  user_id = '';
  userTypes=<any>[];
  rolelist=[];
  roleid=0;

  constructor(private adminService: AdminService,
    private baseUrl: AppGlobals,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private auth: AuthService,) { }

    emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

  ngOnInit() {
    this.addUserForm = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      username: ['', Validators.required],
      email: ['', [Validators.required , Validators.pattern(this.emailPattern)]],
      role_id: ['',Validators.required]
    });

    this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
      this.router.navigate(['/admin/login-dashboard']);
    }
    if(localStorage.getItem('user_type')!='admin'){
      this.router.navigate(['/admin/my-profile']);
    }

    this.adminService.roleListing(this.baseUrl.baseAppUrl+'admin/role_listing', this.check_sess).subscribe((res : any) => {
      this.rolelist = res.data;
    });

    if(this.route.snapshot.paramMap.get('id')!=null)this.user_id = this.route.snapshot.paramMap.get('id');

    if(this.user_id!=''){

        this.btn_title = 'Edit Subadmin';
        const data = {id: this.user_id};

        this.adminService.getUserdetail(this.baseUrl.baseAppUrl+'admin/getUserDetail?id='+this.user_id, this.check_sess).subscribe((res : any) => {
          if(res.status == '1'){
              console.log(' === ',res.data);
              this.addUserForm = this.formBuilder.group({
                first_name: [res.data.first_name, Validators.required],
                last_name: [res.data.last_name, Validators.required],
                username: [res.data.username, Validators.required],
                email: [res.data.email, [Validators.required , Validators.pattern(this.emailPattern)]],
                role_id: [res.data.role_id,Validators.required]
                //userType: [res.data.user_type]
              });
              this.roleid = res.data.role_id;
          }
      });

    }else{

      this.btn_title = 'Add Subadmin';
      this.addUserForm = this.formBuilder.group({
        first_name: ['', Validators.required],
        last_name: ['', Validators.required],
        username: ['', Validators.required],
        email: ['', [Validators.required , Validators.pattern(this.emailPattern)]],
        role_id: ['',Validators.required]
        //userType: ['subadmin']
      });

    }
  }

  get addUserFrm() { return this.addUserForm.controls; }

  onSubmit(){
    this.isSubmitted = true;
    console.log(this.addUserForm);
    if (this.addUserForm.invalid) {
      return;
    }

    //console.log(this.addUserForm.value);return false;
    let endpoint = this.baseUrl.baseAppUrl+'admin/add_user';
    if(this.user_id!=''){
      this.addUserForm.value.user_id = this.user_id;
      endpoint = this.baseUrl.baseAppUrl+'admin/update_user';
    }else{
      endpoint = this.baseUrl.baseAppUrl+'admin/add_user';
    }
    this.addUserForm.value.user_type = 'subadmin';
    this.addUserForm.value.role_id = this.roleid;
    this.adminService.updateUser(endpoint, this.addUserForm.value, this.check_sess).subscribe((data: any) => {
      console.log('Responce : ',data);
      if(data.status){

            if(this.user_id!=''){
              // var userID = ""+this.user_id+"";
              // var user = new CometChat.User(userID);
              // user.setRole(this.addUserForm.value.userType);
              // CometChat.updateUser(user, COMETCHAT_CONSTANTS.API_KEY).then(
              // user => {

              //   }, error => {
              //       console.log("error", error);
              //   }
              // )
              this.router.navigate(['admin/sub-admin']);
              localStorage.setItem('succ_user', data.message);
              //console.log("user updated", user);

            }else{
              this.router.navigate(['admin/sub-admin']);
              localStorage.setItem('succ_user', data.message);
              //console.log("user added", user);
            }
      }else{
        if(data.type=='email')this.email_msg = data.message;
        if(data.type=='user')this.user_msg = data.message;
      }
    });

  }

  removeError(type){
    if(type=='email')this.email_msg='';
    if(type=='user')this.user_msg='';
  }
  chooseroles(e){
    this.roleid = e.target.value;
  }

}
