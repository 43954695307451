import { Component, OnInit } from '@angular/core';
import { CometChat } from '@cometchat-pro/chat';
import { COMETCHAT_CONSTANTS } from './Commit-Constants';
import { AppGlobals } from './app.global';
import { ChatService } from './service/chat.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'forexplatinumtrading';
  onActivate(event) {
    window.scroll(0,0);
    }
    constructor(public globals: AppGlobals,chatService: ChatService) { }

ngOnInit() {
	console.log(this.globals.isLogin, 'this.globals.isLogin');
  }
}
