import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AdminService} from "../services/admin.service";
import { AppGlobals } from '../../app.global';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';

import {AdminSubscriptionDialogComponent} from '../admin-subscription-dialog/admin-subscription-dialog.component';
import {AdminSubscriptionStatusDialogComponent} from '../admin-subscription-status-dialog/admin-subscription-status-dialog.component';

export interface PeriodicElement {
  row_id: number;
  record_id: number;
  username: string;
  email: string;
  subs_status: string;
  accessibility_date: string;
  renew_date: string;
  payment_id: string;
  status: string;
  action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-sub-admin',
  templateUrl: './sub-admin.component.html',
  styleUrls: ['./sub-admin.component.scss']
})
export class SubAdminComponent implements OnInit {
  all_records;
	displayedColumns: string[] = ['record_id','fullname','username', 'email','rolename','status', 'action'];//'renew_date', 'payment_id','status'
  	dataSource : any;
  	check_sess;
  	user_id;
  	error_msg='';
  	success_msg='';
	@ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;
  constructor(private adminService: AdminService,
  	private baseUrl: AppGlobals,
  	private router: Router,private dialog: MatDialog) { }
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  ngOnInit() {
  	this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/admin/login-dashboard']);
    }
	if(localStorage.getItem('user_type')!='admin'){
		this.router.navigate(['/admin/my-profile']);
	  }

    this.success_msg=localStorage.getItem('succ_user');
  	 setTimeout (() => {
         this.success_msg="";
         localStorage.setItem('succ_user','');
        }, 2000);
    this.getUsers();
  }

  getUsers(){
  	this.all_records=[];
  	this.adminService.userListing(this.baseUrl.baseAppUrl+'admin/user_listing?type=subadmin', this.check_sess).subscribe((res: any) =>  {

      if(res.status == '1'){
        
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {

			    res.data[i].trader_accessibility_date = res.data[i].trader_end_date;

			    this.all_records.push({record_id: j, order_id: res.data[i].user_id,fullname:res.data[i].first_name+' '+res.data[i].last_name, username: res.data[i].username, email : res.data[i].email,rolename : res.data[i].name, status:res.data[i].status, action: ''},);
			    ['record_id','fullname','username', 'email','rolename','status', 'action'];
			    j++;
        }
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
		    this.dataSource.paginator = this.paginator;
		    
      }
      else{
        this.error_msg = res.message;
      }

    });
  }

  //delete user
	openDialog2(id) {

		const dialogRef = this.dialog.open(ConfirmDialogComponent,{
							data:{
								message: 'Are you sure want to delete the subadmin?',
								buttonText: { ok: 'Yes', cancel: 'No' }
							}
						  });

			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					//this.updateStatus(id);
					
					const data = { id: id };
          
						this.adminService.updateUserStatus(this.baseUrl.baseAppUrl+'admin/deleteUser', data, this.check_sess).subscribe((res : any) => {
							if(res.status == '1'){
								this.success_msg = 'Sub admin deleted successfully'; //res.message;
								this.getUsers();
								setTimeout (() => {
									this.success_msg="";
								}, 2000);
							}
							else{
								this.error_msg = res.message;
								setTimeout (() => {
									this.error_msg="";
								}, 2000);
							}
						});
				}
			});

	}

	addsubadmin(){
		this.router.navigate(['admin/add-subadmin/']);
	}
	editsubadmin(id){
		this.router.navigate(['admin/edit-subadmin/'+id]);
	}




}
