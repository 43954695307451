import { Component, OnInit, HostListener } from '@angular/core';
import { AuthService } from '../../service/auth.service';
import { AppGlobals } from '../../app.global';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-notification-page',
  templateUrl: './notification-page.component.html',
  styleUrls: ['./notification-page.component.scss']
})
export class NotificationPageComponent implements OnInit {
  check_sess ='';
  offset=0;
  limit=18;
  notificationList = [];
  notificationTotalCount=0;
  profileBaseUrl='';
  frontBaseUrl='';
  isNotificationCalled=false;
  isNotiApiCalled=false;
  constructor(private baseUrl: AppGlobals,
    private auth: AuthService,private router: Router,) { }
    @HostListener('window:scroll', ['$event'])
    onScroll(e) {
      console.log('scroll called');
       if(this.notificationTotalCount>this.offset && !this.isNotificationCalled)
       {
         this.onListScroll();
       }
      
    }

    onListScroll() {
      if(this.notificationTotalCount>this.offset && !this.isNotificationCalled)
      {
        this.getNotifications();
      }
    }
  ngOnInit() {
    this.frontBaseUrl = this.baseUrl.baseAppUrl;
    this.check_sess=localStorage.getItem('for_token');
    if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/sign-in']);
    }
    /**********/
    let loginType = localStorage.getItem('for_usertype');
    if(loginType=='expire'){ //'member'
      this.router.navigate(['/error404']);
    }

    let user_accessibility = localStorage.getItem('for_accessibility');        
    if(user_accessibility!=''){
      let accessibility = JSON.parse(user_accessibility);
        if(!accessibility.members)this.router.navigate(['/error404']);
    }
    /**********/
    this.getNotifications();
    this.mark_all_notifications_read();
  }

  getNotifications()
  {
    this.isNotificationCalled=true;
    this.auth.notificationList(this.baseUrl.baseAppUrl+'user/notification_listing?offset='+this.offset + '&limit='+this.limit  + '&user_id='+localStorage.getItem('for_userid'), this.check_sess).subscribe((res: any) =>  {
      if(res){
        this.isNotiApiCalled= true;
        if(res.status==1){
         this.offset = res.offset;
         this.profileBaseUrl = this.baseUrl.baseAppUrl+'uploads/profile/';
         console.log(res.total, 'total');
         this.notificationTotalCount = res.total;
         if(res.is_socket==0)
          {
            this.notificationList = this.notificationList.concat(res.data);
          }else{
            if(localStorage.getItem('for_userid')==res.data.trade_created_by)
            {
              this.notificationList.unshift(res.data);
            }
          }
          this.isNotificationCalled=false;
        }
      }
    });
  }

  readNotification(notidata, i,frm='')
  {
    const data = {
      id : notidata.id
      };
      this.auth.readNotification(this.baseUrl.baseAppUrl+'user/read_notification', data, this.check_sess).subscribe((res : any) => {
        if(res.status==1)
        {
          localStorage.setItem('for_tradeid', notidata.trade_id);
          localStorage.setItem('for_tradeidfrm', frm);
          this.router.navigate(['/signals-detail-view']);
        }
      })
    
  }

  mark_all_notifications_read()
  {
    const data = {
      user_id : localStorage.getItem('for_userid')
      };
      this.auth.readNotification(this.baseUrl.baseAppUrl+'user/mark_all_notification_read', data, this.check_sess).subscribe((res : any) => {
        if(res.status==1)
        {
          
        }
      })
    
  }

}
