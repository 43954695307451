import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../service/auth.service';
import { ConfirmedValidator } from '../confirmed.validator';
//import { CometChat } from '@cometchat-pro/chat';
//import { COMETCHAT_CONSTANTS } from '../../Commit-Constants';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';

export interface PeriodicElement {
  username    : string;
  password    : string;
  error_msg   : string;
}

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  providers: [ AppGlobals]
})
export class SigninComponent implements OnInit {
  username ='';
  password='';
  error_msg='';
  success_msg='';
  hide : any;
  hide2 : any;
  signinForm: FormGroup;
  isSubmitted  =  false;
  check_sess = '';
  showLoader = false;
  isDisabled = false;
  frontBaseUrl = '';
  login_type = 'member';
  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder, private http: HttpClient) { }

  ngOnInit() {
    /*localStorage.removeItem('203207a6d563a72:common_store/app_settings');
    localStorage.removeItem('203207a6d563a72:common_store/appId');
    localStorage.removeItem('203207a6d563a72:keys_store/deviceId');
    localStorage.removeItem('comet_authtoken');*/
      this.frontBaseUrl = this.baseUrl.baseFrontAppUrl;

      this.check_sess=localStorage.getItem('for_token');
      if(this.check_sess != null && this.check_sess !=""){
          this.router.navigate(['/dashboard']);
      }

      if(this.route.snapshot.paramMap.get('type')!=null)this.login_type = this.route.snapshot.paramMap.get('type');
      
      if(localStorage.getItem('s_signup') != null && localStorage.getItem('s_signup') !='')
      this.success_msg =  localStorage.getItem('s_signup');

      this.signinForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });

      if(localStorage.getItem('s_resetpass') != null && localStorage.getItem('s_resetpass') !='')
          this.success_msg =  localStorage.getItem('s_resetpass');
      
      localStorage.setItem('s_signup', '');
      localStorage.setItem('s_resetpass', '');
  }

  get signinFrm() { return this.signinForm.controls; }

  click_message(){
    this.success_msg='';
    this.error_msg='';
  }

  login(){
      localStorage.setItem('s_resetpass', '');
      this.success_msg="";
      this.isSubmitted = true;
      if (this.signinForm.invalid) {
        return;
      }
      const data = {
        username    : this.username,
        password    : this.password,
        login_type  : this.login_type
      };
      
      //console.log(data, 'da');
      this.isDisabled = true;
      this.auth.signupSrvs(this.baseUrl.baseAppUrl+'auth/login', data).subscribe((res : tokendata) => {
        
        this.isDisabled = false;
        if(res.status == '1'){
          this.showLoader = true;
          let profile_url = '';
          if(res.data.profile_image!='' && res.data.profile_image!=null)
          {
            profile_url = this.baseUrl.baseAppUrl+'uploads/profile/'+res.data.id+'/'+res.data.profile_image;
          }
          //this.cometLogin(res.data.id);
        
          localStorage.setItem('for_userid', res.data.id);
          localStorage.setItem('for_email', res.data.email);
          localStorage.setItem('for_username', res.data.username);
          localStorage.setItem('for_token', res.token);
          localStorage.setItem('role', res.data.role);
          localStorage.setItem('subscription_status', res.data.subs_status);
          localStorage.setItem('profile_image', profile_url);
          localStorage.setItem('sub_updated_at', res.data.subs_canceled_at);
          localStorage.setItem('member_end', res.data.end_date);
          localStorage.setItem('trader_end', res.data.trader_end_date);
          //localStorage.setItem('for_usertype', this.login_type);
          localStorage.setItem('for_accessibility', res.data.accessibility);

          var access = JSON.parse(res.data.accessibility);
          
          var current = new Date();
          const currentTime = current.getTime();
          var memberTime = new Date(res.data.end_date);
          var traderTime = new Date(res.data.trader_end_date);
          
          if(access.members && currentTime < memberTime.getTime()){
            localStorage.setItem('pagename', 'Members');
            localStorage.setItem('for_usertype', 'members');
            this.router.navigate(['/dashboard']);
          } 
          else if(access.trading_academy && currentTime < traderTime.getTime()){
            localStorage.setItem('pagename', 'Trading Academy');
            localStorage.setItem('for_usertype', 'trading_academy');
            this.router.navigate(['/trading-academy']);
          }
          else{
            localStorage.setItem('pagename', 'My Subscription');
            localStorage.setItem('for_usertype', 'expire');
            this.router.navigate(['/subscription-plans']);
          }
          //this.router.navigate(['/dashboard']);
          // if(this.login_type=='member')this.router.navigate(['/dashboard']);
          // if(this.login_type=='trading-academy')this.router.navigate(['/trading-academy']);//
        }
        else{
          this.showLoader = false;
          this.error_msg = res.message;
        }
    });
      
  }

  // cometLogin(UID) {
  //   //const appSetting = new CometChat.AppSettingsBuilder().setRegion(COMETCHAT_CONSTANTS.REGION).subscribePresenceForAllUsers().build();
  //     //console.log(CometChat.isInitialized(), 'is isInitialized');
  //     //CometChat.init(COMETCHAT_CONSTANTS.APP_ID, appSetting).then(() => {
  //       CometChat.getLoggedinUser().then(
  //       user => {
  //           if (!user) {
  //               let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'apikey': COMETCHAT_CONSTANTS.REST_API_KEY, 'appid': COMETCHAT_CONSTANTS.APP_ID, 'Accept': 'application/json' }) };
  //                 var data = {};
  //                 this.http.post<any>('https://api-us.cometchat.io/v3.0/users/'+UID+'/auth_tokens', data,httpOptions).subscribe(
  //                   (res:any) => {

  //                         CometChat.login(res.data.authToken).then(
  //                             (User:any) => {
  //                               //console.log('Login token: ', User);
  //                               //this.comet_token = user.getAuthToken();
  //                               localStorage.setItem('comet_authtoken', res.data.authToken);
  //                               //console.log("Login Successful:", { User });
  //                               this.showLoader = false;
  //                               if(this.login_type=='member')this.router.navigate(['/dashboard']);
  //                               if(this.login_type=='trading-academy')this.router.navigate(['/trading-academy']);//
  //                               // User loged in successfully.
  //                             },
  //                             error => {
  //                               //console.log("Login failed with exception:", { error });
  //                               // User login failed, check error and take appropriate action.
  //                             }
  //                         );

  //                         //console.log(res, 'auth_tokens');
  //                       })
  //                     }
  //             }
  //             )
      
        
          

  //     //});
    
  // }

  

}
