import { Component, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../app.global';
import { tokendata } from '../variable';
import { AdminService } from '../../admin/services/admin.service';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';
import { NoAccessComponent } from '../dashboard-header/no-access/no-access.component';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';


@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  public config: PerfectScrollbarConfigInterface = {};
    token='';
    imgBaseUrl='';
    faqList = [];
    isApiCalled = false;
    subscription_status='';
    isAccessible=false;
    userId;

   constructor(public dialog: MatDialog,private router: Router,
    private baseUrl: AppGlobals,
    private admin: AdminService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    /**********/
    let loginType = localStorage.getItem('for_usertype');
    if(loginType!=='members'){ //'member'
      this.router.navigate(['/error404']);
    }
    /**********/
    localStorage.setItem('activeTab', 'faq');
    this.userId = localStorage.getItem('for_userid');
    this.getFaqList();
  }

  getFaqList(){
        this.token=localStorage.getItem('for_token');
        if(this.token == null || this.token ==""){
            this.router.navigate(['/sign-in']);
        }
    
    this.admin.faqsListing(this.baseUrl.baseAppUrl+'admin/faqs_listing?type=dashboard&status=active','').subscribe((res: any) =>  {
          if(res){
            console.log(res.data);
              //this.isApiCalled = true;
              if(res.status==1){
                this.faqList = res.data;
              }
              //this.imgBaseUrl = this.baseUrl.baseAppUrl+'uploads/tool/';
          }
    });
  }

}
