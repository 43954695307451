import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AdminService} from "../services/admin.service";
import { AppGlobals } from '../../app.global';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

export interface PeriodicElement {
  row_id: number;
  record_id: number;
  name: string;
  email: string;
  country: string;
  contact_no: string;
  knew_frm_where: string;
  enquiry_details: string;
  created_at: string;
  action: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-contact-enquiry',
  templateUrl: './contact-enquiry.component.html',
  styleUrls: ['./contact-enquiry.component.scss']
})
export class ContactEnquiryComponent implements OnInit {
	check_sess;
	displayedColumns: string[] = ['record_id', 'name','email','country','contact_no','knew_frm_where','enquiry_details','created_at'];
  dataSource : any;
  show = true;
  show1 = false;
  error_msg='';
  success_msg='';
  capability='';
  userType='';
  returnfalse = 'return false';
  permission = false;
  @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;
  constructor(private adminService: AdminService,
  	private baseUrl: AppGlobals,
  	private dialog: MatDialog,private router: Router) {

    }
   all_records=[];
  ngOnInit() {
    this.capability=localStorage.getItem('capability');
    this.userType=localStorage.getItem('user_type');
    this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/admin/login-dashboard']);
    }
    
    if(this.userType!='admin' && this.capability.split(',').indexOf('contact_enq_read')<=-1){
      this.permission = false;
    }
    if(this.userType=='admin'){
      this.permission = true;
    }
    if(!this.permission){
      this.router.navigate(['/admin/my-profile']);
    }
  	localStorage.removeItem('for_tradeid');
    
    this.contact_enquiry_list();
  }

  showmoreless(type){
     if(type == 'more'){
       this.show = false;
       this.show1 = true;
     }
     else{
      this.show = true;
      this.show1 = false;
     }
  }

  contact_enquiry_list()
  {
  	this.all_records=[];
  this.adminService.getUsers(this.baseUrl.baseAppUrl+'admin/contact_listing', this.check_sess).subscribe((res: any) =>  {

      if(res.status == '1'){
        //console.log(res.data.length);
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
            //console.log(res.data[i].id);
            //alert(res.data[i].id);
            this.all_records.push({record_id: j, order_id: res.data[i].id, name: res.data[i].name, email : res.data[i].email, country : res.data[i].country,
            contact_no : res.data[i].contact_no, knew_frm_where : res.data[i].knew_frm_where, enquiry_details : res.data[i].enquiry_details, created_at : res.data[i].created_at},);
            ['record_id', 'name','email','country','contact_no','knew_frm_where','enquiry_details','created_at'];
			j++;
          }
          console.log(this.all_records);
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;

      }
      else{
       //this.error_msg = res.message;
      }

      });
  }

}
