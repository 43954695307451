import {Component, Inject, OnInit, ElementRef, AfterViewInit,ViewChild } from '@angular/core';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule} from '@angular/material/dialog';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import {NgIf} from '@angular/common';
import {MatButtonModule} from '@angular/material/button';
import {FormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { AppGlobals } from '../../app.global';
import { ActivatedRoute, Router } from '@angular/router';
import {AuthService} from "../../service/auth.service";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { set } from 'date-fns';

declare var $: any;
export interface DialogData {
  animal: string;
  name: string;
  id: BigInteger;
  cardelement: any;
  stripeLoad: any;
}

@Component({
  selector: 'app-my-subscriptions2',
  templateUrl: './my-subscriptions2.component.html',
  styleUrls: ['./my-subscriptions2.component.scss'],
  // standalone: true,
})
export class MySubscriptions2Component implements OnInit {

  // constructor(public dialog: MatDialog) { }

  all_records;
  check_sess;
  animal: string;
  name: string;
  cardelement: any;
  stripeLoad: any;
  planexists: any;
  checklist: any=[];

  constructor(public dialog: MatDialog,
    private baseUrl: AppGlobals,
  	private router: Router,
    private authservice: AuthService,) {}
  /*async ngAfterViewInit() {
    const stripe = loadStripe('pk_test_51HKq6ABna33KOagA6PiBZcks0m714qPZHZOCJH1at6zP4mVdf7QxGbxZkO3NzWw6tEOXb6lyTjbShCvWAYY0N9q300O7MS6dG3');
    //const stripe = stripe('pk_test_51HKq6ABna33KOagA6PiBZcks0m714qPZHZOCJH1at6zP4mVdf7QxGbxZkO3NzWw6tEOXb6lyTjbShCvWAYY0N9q300O7MS6dG3');
    console.log(' ----------------- ',stripe);
    //var stripe = stripe('pk_test_51HKq6ABna33KOagA6PiBZcks0m714qPZHZOCJH1at6zP4mVdf7QxGbxZkO3NzWw6tEOXb6lyTjbShCvWAYY0N9q300O7MS6dG3');
   // var elements = stripe.elements();
    //console.log('Elements -------- ',elements);
    //this.stripe = await loadStripe('YOUR_STRIPE_PUBLIC_KEY');
    const elements = (await stripe).elements();

    const style = {
      base: {
        color: '#fff',
        // fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        // fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#fff'
        }
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a'
      }
    };

    const cardElement = elements.create('card',{style: style});
    this.cardelement = cardElement;
    console.log('cardElement - ',cardElement);
    this.stripeLoad = stripe;
    //cardElement.mount('#card-element');
  }
  */

  ngOnInit() {
    this.check_sess=localStorage.getItem('for_token');
    if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/sign-in']);
    }
    var userId = localStorage.getItem('for_userid');
    this.planexists=[];
  	this.authservice.getExistPlan(this.baseUrl.baseAppUrl+'web/getExistPlan?id='+userId, this.check_sess).subscribe((res: any) =>  {
      if(res.status == '1'){
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
            var cd = new Date();
            var cmonth = (cd.getMonth()<10)?'0'+(cd.getMonth()+1):cd.getMonth()+1;
            var cday = (cd.getDate()<10)?'0'+cd.getDate():cd.getDate();
            var currDate = cd.getFullYear()+'-'+cmonth+'-'+cday;

            var d = new Date(res.data[i].end_date);
            var month = (d.getMonth()<10)?'0'+(d.getMonth()+1):d.getMonth()+1;
            var day = (d.getDate()<10)?'0'+d.getDate():d.getDate();
            var endDate = d.getFullYear()+'-'+month+'-'+day;

            var td = new Date(res.data[i].trader_end_date);
            var tmonth = (td.getMonth()<10)?'0'+(td.getMonth()+1):td.getMonth()+1;
            var tday = (td.getDate()<10)?'0'+td.getDate():td.getDate();
            var tendDate = td.getFullYear()+'-'+tmonth+'-'+tday;
            this.checklist.push({planId:res.data[i].plan_id,startDt:res.data[i].start_date,endDt:endDate,id:res.data[i].id,adminSubscribe:res.data[i].admin_subscription,tradstartDt:res.data[i].trader_start_date,tradendDt:tendDate,currDate:currDate});
          j++;
        }
      }
    });

    var __this = this;
    setTimeout(function(){
      __this.planlist();
    },2000);

  }

  planlist(){
  	this.all_records=[];
  	this.authservice.getPlan(this.baseUrl.baseAppUrl+'web/planlist', this.check_sess).subscribe((res: any) =>  {

      if(res.status == '1'){

        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
          var checkedPlan=0;
          var expiryPlan='';
          var traderexpiryPlan='';
          var adminsubscrib =0;
          var currDate='';
          if(this.checklist.length>0){ console.log('CheckList - ',this.checklist);
            for(var k=0;k<this.checklist.length;k++){
              if(this.checklist[k].planId==res.data[i].id && this.checklist[k].adminSubscribe==0){
                checkedPlan = res.data[i].id;
                expiryPlan = this.checklist[k].endDt;
                traderexpiryPlan = this.checklist[k].tradendDt;
                adminsubscrib = this.checklist[k].adminSubscribe;
                currDate = this.checklist[k].currDate;
              }
              else if(this.checklist[k].adminSubscribe==1){
                adminsubscrib = this.checklist[k].adminSubscribe;
                expiryPlan = this.checklist[k].endDt;
                traderexpiryPlan = this.checklist[k].tradendDt;
                currDate = this.checklist[k].currDate;
              }

            }
          }

          this.all_records.push({subscription_id: res.data[i].id, planname: res.data[i].plan_name, plan_price : res.data[i].plan_amount,plan_type : res.data[i].plan_type, plan_cycle: res.data[i].plan_cycle, status: res.data[i].status,academy_video: res.data[i].permission_academy_video,video_library: res.data[i].permission_video_library,trading_strategies: res.data[i].permission_trading_strategies,trading_academy:res.data[i].trading_academy,members:res.data[i].members,expiryDt:expiryPlan,plandesc:res.data[i].plan_description,adminsubscrib:adminsubscrib,traderexpiry:traderexpiryPlan,currDate:currDate });


          j++;
        }


      }
    });
  }

  openDialog(planid) {
    const dialogRef = this.dialog.open(PayDialog, {
      data: {name: this.name, animal: this.animal,id: planid, cardelement: this.cardelement,stripeLoad:this.stripeLoad },
    });

    dialogRef.afterClosed().subscribe(result => {

      this.animal = result;
    });
  }

  // payNow(){
  //   console.log('payNow');
  //   const dialogRef = this.dialog.open(PayDialog, {
  //       data: { type: 'payNow' }
  //   });
  // }

}

/**  pay  **/
// @Component({
//   selector: 'pay',
//   templateUrl: './pay.html',
//   styleUrls: ['./my-subscriptions2.component.scss'],
// })
// export class PayDialog implements OnInit {
//   constructor(public dialog: MatDialog,private baseUrl: AppGlobals,
//     private auth: AuthService
//   ) {}
//   ngOnInit() {
//   }
// }

@Component({
  selector: 'PayDialog',
  templateUrl: 'PayDialog.html',
  // standalone: true,
  // imports: [MatDialogModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule],
})
export class PayDialog {
  //private stripe: Stripe;
  @ViewChild('paymentForm',{ static: false }) paymentForm: ElementRef;
  public cardForm: FormGroup;
    cardName;
    cardNo;
    expDate;
    cvv;
    isSubmitted  =  false;
    card_error;
    cvv_error;
    token;
    stripe_id;
    isUpdateDisable = false;
    exp_error;
    stripeCard;
    stripeData:any=[];
    card_id;
    plan_id;
    cardElement;
    stripeLoad;
    msg='';
    //stripeLoad: any;

  constructor(
    public dialogRef: MatDialogRef<PayDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private baseUrl: AppGlobals,
      private auth: AuthService,  private formBuilder: FormBuilder,
  ) {
    dialogRef.disableClose = true;
      this.cardName = '';
      this.cardNo = '';
      this.stripeCard = '';
      this.cvv = '***';
      this.expDate = '';
      this.stripe_id = '';
      this.stripeData = '';
      this.card_id = '';
      this.plan_id = data.id;
      this.cardElement = data.cardelement;
      this.stripeLoad = data.stripeLoad;
  }



  ngAfterViewInit() {
    //this.stripeLoad = loadStripe('pk_test_51HKq6ABna33KOagA6PiBZcks0m714qPZHZOCJH1at6zP4mVdf7QxGbxZkO3NzWw6tEOXb6lyTjbShCvWAYY0N9q300O7MS6dG3');
    // var stripe = stripe('pk_test_51HKq6ABna33KOagA6PiBZcks0m714qPZHZOCJH1at6zP4mVdf7QxGbxZkO3NzWw6tEOXb6lyTjbShCvWAYY0N9q300O7MS6dG3');
    // var elements = stripe.elements();
    // console.log('Elements -------- ',elements);
    // //this.stripe = await loadStripe('YOUR_STRIPE_PUBLIC_KEY');
    // // const elements = this.stripe.elements();

    // const cardElement = elements.create('card');
    // console.log('cardElement - ',cardElement)
    this.cardElement.mount('#card-element');
  }

  ngOnInit() {

    this.token = localStorage.getItem('for_token');
    this.cardNo = this.cardNo.replace(/\s/g, "");
    //this.cardNo = '********'+this.cardNo.slice(this.cardNo.length - 4);
    this.cardNo = '********'+this.cardNo;
    this.cardForm = this.formBuilder.group({
        cardName: ['', Validators.required],
        cardNo: ['', Validators.required],
        cvv : ['', Validators.required],
        expDate: ['', Validators.required]
    });
  }
  get cardFrm() { return this.cardForm.controls; }

  onNoClick(): void {
    this.dialogRef.close();
  }
  getcard(evt){
    if(this.cardNo.length == 1){
     if (isNaN(parseInt(this.cardNo, 10)))
       this.cardNo ='';
     }
   }
  blurCardErr(evt)
  {
    this.card_error = '';
  }
  blurExpDate(evt)
  {
    this.exp_error = '';
  }
  blurCvv(evt)
  {
    this.cvv_error = ''
  }

  onSubmit() {

    // Create a token when the form is submitted.
    // var form = document.getElementById('payment-form');
    // form.addEventListener('submit',(event)=>{
    //   event.preventDefault();
    //   this.createToken();
    // });
    // this.createToken();

    // console.log(this.cardForm); return false;

    this.isSubmitted = true;
    //console.log(this.cardForm.invalid, 'this.cardForm.invalid');
    if (this.cardForm.invalid) {
        return;
    }

    var data = {};
    if(this.cardNo.indexOf('*') > -1)
    {
      data = {
        userId    : localStorage.getItem('for_userid'),
        cardName  : this.cardName,
        exp_date  : this.expDate,
        card_cvv  : this.cvv,
        stripe_id : this.stripe_id,
        card_id   : this.card_id,
        plan_id   : this.plan_id
      };

    }else{
      data = {
        userId    : localStorage.getItem('for_userid'),
        cardName     : this.cardName,
        exp_date : this.expDate,
        card_cvv : this.cvv,
        card_number : this.cardNo,
        stripe_id: this.stripe_id,
        plan_id   : this.plan_id
      };

    }

    this.isUpdateDisable = true;
    //this.cardForm.value.plan_id = this.plan_id;
    //this.cardForm.value.plan_id = this.plan_id;
    this.auth.planpurchase(this.baseUrl.baseAppUrl+'web/planpurchase', data , this.token).subscribe((res : any) => {

      if(res.status == '1'){
        this.card_error = '';
        this.cvv_error = '';
        this.exp_error = '';
        this.isUpdateDisable = false;
        this.dialogRef.close({data:{status:'success'}});
        localStorage.setItem('for_accessibility', res.accessibility);
        $('.ThankyouDialog').modal('show');
        setTimeout(function(){
          $('.ThankyouDialog').modal('hide');
          location.reload();
        },2000);
      }
      else{
        if(res.message=="Your card's expiration month is invalid." || res.message=="Your card's expiration year is invalid.")
        {
          this.exp_error = res.message;
          this.card_error = '';
          this.cvv_error = '';
        }else if(res.message=="Your card's security code is invalid."){
          this.cvv_error = res.message;
          this.exp_error = '';
          this.card_error = '';
        }else{
          this.card_error = res.message;
          this.exp_error = '';
          this.cvv_error = '';
        }
        this.isUpdateDisable = false;
        //this.card_error = res.message;
      }
    });
  }

  async createToken() {
    (await this.stripeLoad).createToken(this.cardElement).then((result)=>{
      if (result.error) {
        // Inform the user if there was an error
        var errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
      } else {

        // Send the token to your server
        //stripeTokenHandler(result.token);
        var data: any = {};
        if(this.cardNo.indexOf('*') > -1)
        {
          data = {
            userId    : localStorage.getItem('for_userid'),
            cardName  : this.cardName,
            exp_date  : this.expDate,
            card_cvv  : this.cvv,
            stripe_id : this.stripe_id,
            card_id   : this.card_id,
            plan_id   : this.plan_id
          };

        }else{
          data = {
            userId    : localStorage.getItem('for_userid'),
            cardName     : this.cardName,
            exp_date : this.expDate,
            card_cvv : this.cvv,
            card_number : this.cardNo,
            stripe_id: this.stripe_id,
            plan_id   : this.plan_id
          };

        }
        data.stoken =  result.token.id;

        this.isUpdateDisable = true;
        //this.cardForm.value.plan_id = this.plan_id;
        //this.cardForm.value.plan_id = this.plan_id;
        this.auth.planpurchase(this.baseUrl.baseAppUrl+'web/planpurchase', data , this.token).subscribe((res : any) => {

          if(res.status == '1' || res.status == 1){
            this.card_error = '';
            this.cvv_error = '';
            this.exp_error = '';
            this.isUpdateDisable = false;
            this.dialogRef.close({data:{status:'success'}});
          }
          else{
            if(res.message=="Your card's expiration month is invalid." || res.message=="Your card's expiration year is invalid.")
            {
              this.exp_error = res.message;
              this.card_error = '';
              this.cvv_error = '';
            }else if(res.message=="Your card's security code is invalid."){
              this.cvv_error = res.message;
              this.exp_error = '';
              this.card_error = '';
            }
            else if(res.message=="Error"){
              this.cvv_error = '';
              this.exp_error = '';
              this.card_error = 'Your are already subscribed to this plan.';
            }
            else{
              this.card_error = res.message;
              this.exp_error = '';
              this.cvv_error = '';
            }
            this.isUpdateDisable = false;
            this.msg = "Your subscription plan successfully updated.";
            this.dialogRef.close();
            //this.card_error = res.message;
          }
        });

      }
    });
  };


  closeUpdate(){
      this.dialogRef.close({data:'cross_icon'});
    }
}



