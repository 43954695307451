import { Component, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../service/auth.service';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-trading-academy',
  templateUrl: './trading-academy.component.html',
  styleUrls: ['./trading-academy.component.scss'],
  providers: [ AppGlobals]
})
export class TradingAcademyComponent implements OnInit {
public config: PerfectScrollbarConfigInterface = {};
  loginType = '';
  accessibility:any 
  constructor(
      private router: Router,
      private baseUrl: AppGlobals,
      private auth: AuthService,
      private route: ActivatedRoute) { }

  ngOnInit() {
     localStorage.setItem('activeTab', 'trading-academy');
     this.loginType = localStorage.getItem('for_usertype');
     if(this.loginType=='expire'){
      this.router.navigate(['/error404']);
     }

     let user_accessibility = localStorage.getItem('for_accessibility');
     if(user_accessibility!='')this.accessibility = JSON.parse(user_accessibility);
     
  }

  ClicktoForextool(){
  	this.router.navigate(['/trade-tools']);
  }

}
