import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder,FormGroup, FormControl, Validators } from '@angular/forms';
import {AdminService} from "../services/admin.service";
import { AppGlobals } from '../../app.global';
import { tokendata } from '../../main/variable';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-subscription-plan-add',
  templateUrl: './subscription-plan-add.component.html',
  styleUrls: ['./subscription-plan-add.component.scss']
})
export class SubscriptionPlanAddComponent implements OnInit {

  btn_title = 'Add Plan';
  addPlanForm: FormGroup;
  isSubmitted  =  false;
  check_sess : any;
  hide1 : any;hide2 : any;
  plan_id = '';
  members = false;
  trading_academy = false;
  academy_videos = false;
  video_library = false;
  strategies = false;
  planTypeSelect='';
  editpermission=false;
  capability='';
  userType='';
  addpermission=false;

  constructor(
    private adminService: AdminService,
    private baseUrl: AppGlobals,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
  ) { }

  numberOnly = '^[0-9]*$';
  ngOnInit() {
    this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
      this.router.navigate(['/admin/login-dashboard']);
    }

    this.capability=localStorage.getItem('capability');
    this.userType=localStorage.getItem('user_type');
    if(this.userType!='admin' && this.capability.split(',').indexOf('subscription_edit')>-1){
      this.editpermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('subscription_add')>-1){
      this.addpermission = true;
    }
    if(this.userType=='admin'){
      this.editpermission=true;
      this.addpermission = true;
    }
    if(!this.editpermission && !this.addpermission){
      this.router.navigate(['/admin/my-profile']);
    }

    if(this.route.snapshot.paramMap.get('id')!=null) this.plan_id = this.route.snapshot.paramMap.get('id');
    console.log('this.plan_id',this.plan_id);
    if(this.plan_id!=''){

        this.btn_title = 'Edit Plan';
        const data = {id: this.plan_id};
        this.adminService.subscriptionplan(this.baseUrl.baseAppUrl+'admin/subscriptionplan?id='+this.plan_id, this.check_sess).subscribe((res : any) => {
          if(res.status == '1'){
            console.log('res.data',res.data);
            res.data = res.data[0];
              // if(res.data.accessibility!=null){
              //   var decode_accessibility = JSON.parse(res.data.accessibility);
              //   this.members=decode_accessibility.members;
              //   this.trading_academy=decode_accessibility.trading_academy;
              //   this.academy_videos=decode_accessibility.academy_videos;
              //   this.video_library=decode_accessibility.video_library;
              //   this.strategies=decode_accessibility.strategies;
              // }
              this.academy_videos = res.data.permission_academy_video;
              this.video_library = res.data.permission_video_library;
              this.strategies = res.data.permission_trading_strategies;
              this.trading_academy = res.data.trading_academy;
              this.members = res.data.members;
              this.addPlanForm = this.formBuilder.group({
                plan_name: [res.data.plan_name, Validators.required],
                //last_name: [res.data.last_name, Validators.required],
                //mobile: [res.data.mobile, [ Validators.pattern(this.numberOnly)]],
                plan_price: [res.data.plan_amount, Validators.required],
                plan_cycle: [res.data.plan_cycle, [Validators.required]],
                plan_type: [res.data.plan_type, Validators.required],
                plan_description: [res.data.plan_description, [Validators.required]],
              });
              this.planTypeSelect = res.data.plan_type;

          }else{

          }
      });

    }else{

      this.btn_title = 'Add Plan';
      this.addPlanForm = this.formBuilder.group({
        plan_name: ['', Validators.required],
        plan_price: ['', [Validators.required, Validators.pattern(this.numberOnly)]],
        plan_cycle: ['', Validators.required],
        plan_type: ['', [Validators.required]],
        plan_description: ['', [Validators.required]],
      });

    }

  }

  get addPlanFrm() { return this.addPlanForm.controls; }

  changeAccessibility(name){
    if(name=="trading_academy"){
      if(this.trading_academy){
        this.trading_academy = this.academy_videos = this.video_library = this.strategies = false;
      }else{
        this.trading_academy = this.academy_videos = this.video_library = this.strategies = true;
      }
    }
    if(name=="academy_videos"){
      this.academy_videos = !this.academy_videos;
      if(this.academy_videos || this.video_library || this.strategies )this.trading_academy = true;
      if(!this.academy_videos && !this.video_library && !this.strategies )this.trading_academy = false;
    }
    if(name=="video_library"){
      this.video_library = !this.video_library;
      if(this.academy_videos || this.video_library || this.strategies )this.trading_academy = true;
      if(!this.academy_videos && !this.video_library && !this.strategies )this.trading_academy = false;
    }
    if(name=="strategies"){
      this.strategies = !this.strategies;
      if(this.academy_videos || this.video_library || this.strategies )this.trading_academy = true;
      if(!this.academy_videos && !this.video_library && !this.strategies )this.trading_academy = false;
    }
  }

  onSubmit(){
    this.isSubmitted = true;
    console.log(this.members, this.academy_videos, this.trading_academy, this.video_library, this.strategies);
    console.log(this.addPlanForm);//return false;
    if (this.addPlanForm.invalid) {

      return;
    }

    //var accessibility = {'academy_videos':this.academy_videos,'video_library':this.video_library,'strategies':this.strategies};


    let endpoint = this.baseUrl.baseAppUrl+'admin/add_user';
    if(this.plan_id!=''){
      this.addPlanForm.value.plan_id = this.plan_id;
      //endpoint = this.baseUrl.baseAppUrl+'admin/update_user';
    }
    endpoint = this.baseUrl.baseAppUrl+'admin/add_plan';
    this.addPlanForm.value.permission_academy_video = this.academy_videos;
    this.addPlanForm.value.permission_video_library = this.video_library;
    this.addPlanForm.value.permission_trading_strategies = this.strategies;
    this.addPlanForm.value.trading_academy = this.trading_academy;
    this.addPlanForm.value.members = this.members;

    this.adminService.subscriptionplan_add(endpoint, this.addPlanForm.value, this.check_sess).subscribe((data: any) => {
      console.log('Responce : ',data);
      if(data.status){

        this.router.navigate(['admin/subscription-plan']);
        localStorage.setItem('succ_user', data.message);

      }else{
        // if(data.type=='email')this.email_msg = data.message;
        // if(data.type=='user')this.user_msg = data.message;
      }
    });

  }

  onOptionsSelected(event){
    console.log(event.target.value);
    this.planTypeSelect = event.target.value;
  }


}
