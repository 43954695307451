import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {AdminService} from "../services/admin.service";
import { AppGlobals } from '../../app.global';
import { Select2OptionData,Select2TemplateFunction } from 'ng2-select2';

@Component({
  selector: 'app-prizes',
  templateUrl: './prizes.component.html',
  styleUrls: ['./prizes.component.scss'],
  providers: [ AppGlobals]
})
export class PrizesComponent implements OnInit {
	public prizeForm: FormGroup;
	btn_title='Add Prize';
	check_sess;
	users=<any>[];
	submitted = false;
  userError='';
  uID;
  prizeTypes;
  type;
  prize_id='';
  prizeMoney;
  userID;
  pid;
  status;
  statusdata=[];
  statusError='';
  public options: Select2Options;
  constructor(private adminService: AdminService, 
    private baseUrl: AppGlobals,
    private route: ActivatedRoute,
    private router: Router) { }
  
  ngOnInit() {
    this.prize_id = localStorage.getItem('for_prizeid');
    console.log(localStorage.getItem('for_prizeid'), 'for_prizeid');
    this.prizeTypes = [{"id":1,"text":"Trade Champion"}, {"id":2,"text":"Chat Champion"}];

    this.statusdata = [
      {
        id: 'active',
        text: 'Active'
      },
      {
        id: 'inactive',
        text: 'Inactive'
      }
      ];

  	this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/admin/login-dashboard']);
    }

    this.options = {
      multiple: false,
      closeOnSelect: true,
      placeholder: "Select Users",
    }

  	this.prizeForm = new FormGroup({
      
      prizeMoney : new FormControl('', [Validators.required]),
      type: new FormControl(''),
    });
    this.getUsers();
    if(this.prize_id!='')
    {
      this.getPrizeDetail();
    }
  }

  get prizeFrm() { return this.prizeForm.controls; }

  getPrizeDetail(){
    console.log('called');
    this.btn_title = 'Edit Prize';
    const data = {
      id: this.prize_id
      };
    this.adminService.getPrizedetail(this.baseUrl.baseAppUrl+'admin/prize_detail?id='+this.prize_id, this.check_sess).subscribe((res : any) => {
      if(res.status == '1'){
        this.userID = res.data.user_id;
        this.prizeMoney   = res.data.prize_rupees;
        this.status = res.data.status;
        //this.type = res.data.prize_type;
        if(res.data.prize_type=="1")
        {
          this.type='Trade Champion';
        }else{
          this.type='Chat Champion';
        }
        this.pid = res.data.id;
        }
      else{
        }
    });
    localStorage.setItem('for_prizeid','');
  }


  getUsers(){
  	 this.adminService.getUsers(this.baseUrl.baseAppUrl+'admin/getUsers', this.check_sess).subscribe((res: any) => {
  	 	if(res.status==1)
  	 	{
			this.users = res.data;
  	 	}
  	 });
  }

  setCode(e) {
    this.uID = e;
  }

  setStatus(e) {
    this.status = e;
  }

  setPrizeType(e){
    console.log(e, 'e');
    console.log(this.pid, 'pid');
    if(this.pid=='' || this.pid==undefined)
    {
      if(e==1)
      {
        this.prizeMoney = 250;
      }else{
        this.prizeMoney = 100;
      }
    }
    
    this.type = e;
  }

  onSubmit(){
  	this.submitted = true;
    console.log(this.prizeForm.invalid, 'this.prizeForm.invalid');
      if (this.prizeForm.invalid) {
          return;
      }
      if(this.uID=='')
      {
        this.userError = "User is required";
        return;
      }
      if(this.status=='')
      {
        this.statusError = "Please select status";
        return;
      }

      const prizeFormData = new FormData();
         
          prizeFormData.append('user_id', this.uID);
          prizeFormData.append('prize_rupees', this.prizeForm.value.prizeMoney);
          prizeFormData.append('id', this.pid);
          prizeFormData.append('status', this.status);
          console.log(this.uID, 'uid',this.type, 'type', this.prizeForm.value.prizeMoney );
          
          console.log(prizeFormData, 'prize');
          this.adminService.addPrize(this.baseUrl.baseAppUrl+'admin/add_prize', prizeFormData, this.check_sess).subscribe((data: any) => {
            console.log(data, 'test');
            if(data.status == '1'){
              this.router.navigate(['admin/prize-listing']);
              localStorage.setItem('succ_prize', data.message);
            }
            else{
              console.log('error');
            }
      
          });
  }



}
