import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {AdminService} from "../services/admin.service";
import { AppGlobals } from '../../app.global';

@Component({
  selector: 'app-video-library',
  templateUrl: './video-library.component.html',
  styleUrls: ['./video-library.component.scss'],
  providers: [ AppGlobals]
})
export class VideoLibraryComponent implements OnInit {
  public addVideoForm: FormGroup;
  submitted: boolean = false;
  resImageFile : File = null;
  resImageSrc: any ='';
  invalidresimage='';
  invalidfile = false;
  video_id='';
  success_msg='';
  error_msg='';
  title='';
  subtitle='';
  markup='';
  videoUrl='';
  vid='';
  btn_title='Add Video';
  duration='';
  viewCount;
  resimage : any ;
  check_sess;
  errorImage;
  invalidVideoUrl:boolean = false;
  capability='';
  userType='';
  addpermission=false;
  editpermission=false;
  
  constructor(private adminService: AdminService, 
    private baseUrl: AppGlobals,
    private route: ActivatedRoute,
    private router: Router) { 
    /*const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {video_id: string};
    console.log(state, 'state');
    this.video_id = state.video_id;*/
      }

  ngOnInit() {
    this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/admin/login-dashboard']);
    }

    this.capability=localStorage.getItem('capability');
    this.userType=localStorage.getItem('user_type');
    if(this.userType!='admin' && this.capability.split(',').indexOf('video_library_add')>-1){
      this.addpermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('video_library_edit')>-1){
      this.editpermission = true;
    }
    if(this.userType=='admin'){
      this.addpermission = true;
      this.editpermission = true;
    }
    if(!this.addpermission && !this.editpermission){
      this.router.navigate(['/admin/my-profile']);
    }

    this.video_id=localStorage.getItem('for_videoid');
    if(this.video_id !=''){
        
         const data = {
            id: this.video_id
            };
          this.adminService.getEditdetail(this.baseUrl.baseAppUrl+'admin/video_detail', data).subscribe((res : any) => {
            if(res.status == '1'){
              this.title   = res.data.title;
              this.subtitle= res.data.sub_title;
              this.markup  = res.data.markup;
              this.videoUrl= res.data.video_url;
              this.vid     = res.data.id;
              this.duration = res.data.duration;
              this.viewCount = res.data.viewCount;
              this.resImageSrc= this.baseUrl.baseAppUrl+'uploads/video_library/r'+res.data.image_name;
              this.invalidfile=true;
              this.btn_title='Update Video';
              }
            else{
              }
          });
        }
      localStorage.setItem('for_videoid','');
    this.addVideoForm = new FormGroup({
      title : new FormControl('', [Validators.required]),
      subtitle : new FormControl('', [Validators.required]),
      duration : new FormControl('', [Validators.required]),
      markup : new FormControl('', [Validators.required]),
      videoUrl :  new FormControl('', [Validators.required]),
      image :  new FormControl(''), 
    });

  }
  
  validVideoUrl(str, prefixes) {
    var cleaned = str.replace(/^(https?:)?\/\/(www\.)?/, '');
    for(var i = 0; i < prefixes.length; i++) {
      if (cleaned.indexOf(prefixes[i]) === 0)
        return cleaned.substr(prefixes[i].length)
    }
    return false;
  }

  videoImage(event){
    //console.l(event, 'e');
      const reader = new FileReader();
      const ext = event.target.files[0].name.split('.')[1];
      if (
        ext === 'jpg' ||
        ext === 'JPG' ||
        ext === 'jpeg' ||
        ext === 'JPEG' ||
        ext === 'png' ||
        ext === 'PNG' 
        /*ext === 'gif' ||
        ext === 'GIF'*/
      ) {


        //console.log(event.target.files[0].size/1024/1024 + ' MB');
        this.errorImage='';
        if (event.target.files[0].size/1024/1024 > 2) {
         this.invalidresimage = 'The maximum size limit of image is 2 MB.';
          this.invalidfile = false;
          this.resImageSrc = '';
          this.resimage=null;
        }
        else{
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
              this.resImageSrc = reader.result;
            };
          this.resImageFile = event.target.files[0];
              console.log(this.resImageFile, 'this.resImageSrc');
          this.invalidfile = true;
          this.invalidresimage = '';
          }
        } else {
        this.invalidresimage = 'Only jpg/jpeg/png format will be accepted';
        //this.resImageFile ='';
        this.invalidfile = false;
        this.resImageSrc = '';
        this.resimage=null;
        }
  }

  removeImage(){
    this.invalidfile = false;
    this.resImageSrc = '';
    this.resimage=null;
  }

  onSubmit() {
    /*this.submitted = true;
  	
  	console.log(this.addVideoForm.value, 'added data');
  	this.adminService.addVideo(this.baseUrl.baseAppUrl+'admin/add_video', this.addVideoForm.value).subscribe(data => {
  		console.log(data, 'test');
  	});*/
    this.submitted = true;
    console.log(this.addVideoForm.invalid, 'this.addVideoForm.invalid');
      if (this.addVideoForm.invalid && this.vid=='') {
          return;
      }

    if(this.vid=='' && this.resimage==null)
    {
      
      this.errorImage = 'Please upload image';
      this.invalidresimage='';
       return false;
    }else if(this.vid!='' && this.resImageSrc==''){
      
      this.errorImage = 'Please upload image';
      this.invalidresimage='';
        return false;
    }else{
        var isValidUrl = this.validVideoUrl(this.addVideoForm.value.videoUrl, [
          'player.vimeo.com/video/'
        ]);
        if(isValidUrl=='' || isValidUrl==undefined)
        {
          this.invalidVideoUrl=true;
          return false;
        }else{
          const videoFormData = new FormData();
         
          videoFormData.append('title', this.addVideoForm.value.title);
          videoFormData.append('subtitle', this.addVideoForm.value.subtitle);
          videoFormData.append('markup', this.addVideoForm.value.markup);
          videoFormData.append('videoUrl', this.addVideoForm.value.videoUrl);
          videoFormData.append('image', this.resImageFile);
          videoFormData.append('duration', this.duration);
          videoFormData.append('id', this.vid);
           console.log(this.addVideoForm.value.title, 'aa');
          this.adminService.addVideo(this.baseUrl.baseAppUrl+'admin/add_video', videoFormData).subscribe((data: any) => {
            console.log(data, 'test');
            if(data.status == '1'){
        
            localStorage.setItem('succ_video', data.message);
            this.router.navigate(['admin/video-listing']);   
            }
            else{

            }
      
          });
        }
        console.log(isValidUrl, 'isValidUrl');
        
    }
        
  }


}
