import { Component, OnInit, HostListener } from '@angular/core';
import { ZoomMtg } from '@zoomus/websdk';
import { NavigationStart, ActivatedRoute, Router } from '@angular/router';
import {AdminService} from "../../services/admin.service";
import { AppGlobals } from '../../../app.global';
import { environment } from 'src/environments/environment';
//ZoomMtg.setZoomJSLib('https://source.zoom.us/2.4.5/lib 1', '/av');
//ZoomMtg.setZoomJSLib('node_modules/@zoomus/websdk/dist/lib/', '/av');
ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();
@Component({
  selector: 'app-zoom',
  templateUrl: './zoom.component.html',
  styleUrls: ['./zoom.component.scss']
})
export class ZoomComponent implements OnInit {
  showCreateMeeting=true;
  showLaunch=false;
  check_sess='';
  stream_id;
  success_msg='';
  error_msg='';
  role = 1;
  leaveUrl = '/admin/zoom-call';
  meetingNumber;
  password;
  userName = 'FOREX PLATINUM TRADING'
  userEmail = ''
  @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler(event) {
      console.log('window load');
      //this.leavestream();
      const streamFormData = new FormData();
      streamFormData.append('id', this.stream_id);
      streamFormData.append('status', '3');
      streamFormData.append('isStatus','1');
      streamFormData.append('leavestream','1');
      this.adminService.updateStream(this.baseUrl.baseAppUrl+'admin/add_stream', streamFormData, this.check_sess).subscribe((data: any) => {
        if(data.status == '1'){
          this.router.navigate(['/admin/live-stream']);
        }else{
          console.log('error');
        }
  
      });
  }
  constructor(private route: ActivatedRoute,
    private router: Router,private adminService: AdminService,private baseUrl: AppGlobals) { }

  ngOnInit() {
    this.check_sess=localStorage.getItem('access_token');
    this.stream_id = localStorage.getItem('for_channelID');
    if(this.check_sess == null || this.check_sess ==""){
      this.router.navigate(['/admin/login-dashboard']);
    }
    if(this.stream_id==undefined || this.stream_id=='')
    {
      this.router.navigate(['/admin/live-stream']);
    }
  }

  createMeeting(){
  	const data = {
      stream_id : this.stream_id
      };
  	this.adminService.addStream(this.baseUrl.baseAppUrl+'admin/createZoomMeeting', data, this.check_sess).subscribe((data: any) => {
      if(data.status==1)
      {
        this.showLaunch=true;
        this.showCreateMeeting=false;
        this.success_msg=data.message;
        this.meetingNumber=data.data.id;
    	  this.password=data.data.encrypted_password;
        setTimeout (() => {
          this.success_msg="";
          //localStorage.setItem('for_channelID','');
        }, 2000);
      }else{
        this.error_msg=data.message;
      }
  		console.log(data, 'dtaa');
  	});
  }

  launchMeeting(){
    localStorage.setItem('launch_meeting_id', this.meetingNumber);
    localStorage.setItem('launch_meeting_password', this.password);
    console.log(this.meetingNumber, 'this.meetingNumber', this.password);
    this.router.navigate([]).then(result => {  window.open('/admin/launch-meeting', '_blank'); });
  }

  getSignature(){
    const data = {
      meetingNumber: this.meetingNumber,
	    role: this.role
      };
  	this.adminService.addStream(this.baseUrl.baseAppUrl+'admin/generateSignature', data, this.check_sess).subscribe((data: any) => {
      this.startMeeting(data.signature)
    });
  }

  startMeeting(signature) {
    console.log(signature, 'signature');
    document.getElementById('zmmtg-root').style.display = 'block'
    var d = document.getElementById("zmmtg-root");
    d.className += "custom-zoom-root-admin";
    ZoomMtg.init({
      leaveUrl: this.leaveUrl,
      isSupportAV: true,
      success: (success) => {
        console.log(success, 'this.meetingNumber', this.meetingNumber);
console.log(environment.zoomApiKey, 'zoom api key');
        ZoomMtg.join({
          signature: signature,
          meetingNumber: this.meetingNumber,
          userName: this.userName,
          apiKey: environment.zoomApiKey,
          userEmail: this.userEmail,
          passWord: this.password,
          success: (success) => {
            const streamFormData = new FormData();
            streamFormData.append('id', this.stream_id);
            streamFormData.append('status', '1');
            streamFormData.append('isStatus','1');
            this.adminService.updateStream(this.baseUrl.baseAppUrl+'admin/add_stream', streamFormData, this.check_sess).subscribe((data: any) => {
              if(data.status == '1'){
              }
            })
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }

}
