import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, PipeTransform, Pipe, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../service/auth.service';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';
import { DialogPopupComponent } from '../dialog-popup/dialog-popup.component';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser'


@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-trading-academy-video-series',
  templateUrl: './trading-academy-video-series.component.html',
  styleUrls: ['./trading-academy-video-series.component.scss'],
  providers: [ AppGlobals]
})
export class TradingAcademyVideoSeriesComponent implements OnInit {
 public config: PerfectScrollbarConfigInterface = {};
  chapter_id;
  token ='';
  imgBaseUrl = '';
  videoList = [];
  offset: string = "0";
  limit:string = "9";
  videoTotalCount;
  chapterName;
  chapterDescription;
  sanitiseUrl = false;
  isScrollCalled=false;

  sanitizeVideo = [];
  loginType='';

  constructor(private route: ActivatedRoute,private router: Router, private http: HttpClient, private baseUrl: AppGlobals, private auth: AuthService, public dialog: MatDialog,private sanitizer: DomSanitizer) { 
	const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {chapter_id: string, chapterName: string, description: string};
    if(state!=undefined)
    {
      this.chapter_id = state.chapter_id;
      this.chapterName = state.chapterName;
      this.chapterDescription = state.description;
    }
    
	}

  ngOnInit() {
     /**********/
     this.loginType = localStorage.getItem('for_usertype');
    //  if(this.loginType!=='trading-academy'){ //'member'
    //    this.router.navigate(['/error404']);
    //  }
     let user_accessibility = localStorage.getItem('for_accessibility');        
     if(user_accessibility!=''){
       let accessibility = JSON.parse(user_accessibility);
        if(!accessibility.academy_video || this.loginType!='trading_academy')this.router.navigate(['/error404']);
     }        
     /**********/

    this.getChapterDetails();
  	if(this.chapter_id==undefined)
  	{
  		this.router.navigate(['/trading-academy2']);
  	}
  }

  onListScroll() {
    if(this.videoTotalCount>this.offset && !this.isScrollCalled)
    {
      this.sanitiseUrl = false;
      this.getChapterDetails();
    }
  }

  getChapterDetails(){
    let params = new HttpParams()
                .set('offset', this.offset)
                .set('limit', this.limit);
    this.token=localStorage.getItem('for_token');
        if(this.token == null || this.token ==""){
            this.router.navigate(['/sign-in']);
        }
    
    if(this.offset!=undefined)
    {
      this.isScrollCalled=true;
    this.auth.videoLibrarylistSrvs(this.baseUrl.baseAppUrl+'trade/chapterDetail?offset='+this.offset + '&limit='+this.limit + '&chapterId='+this.chapter_id , this.token).subscribe((res: any) =>  {
          if(res){
              this.sanitizeVideo=[];
              if(res.status==1){
                this.imgBaseUrl = this.baseUrl.baseAppUrl+'uploads/trading-academy/';
                this.videoTotalCount = res.total;
                //this.offset = res.offset;
                let vData = res.data;
                var count = 0
                for (var i = 0; i <  vData.length; i++) {
                  let splitUrl = vData[i].video_url.split('/');
                  vData[i].video_url = "https://player.vimeo.com/video/"+splitUrl[splitUrl.length-1]+'?api=1&background=1&autoplay=0';
                  vData[i].video_popup_url = "https://player.vimeo.com/video/"+splitUrl[splitUrl.length-1];
                  
                  this.sanitizeVideo.push(vData[i]);
                  count++;
                  if(count==vData.length)
                  {
                    if(this.offset=="0")
                    {
                      console.log(this.sanitizeVideo, 'snai');
                      this.videoList = this.sanitizeVideo;
                    }
                    else{
                      console.log(this.videoList, 'aa');
                      this.videoList = this.videoList.concat(this.sanitizeVideo);
                    }
                    this.offset = res.offset;
                    this.isScrollCalled=false;
                   
                  }
                }
              	
                
               }
            }
            else{
             
             }
          });
    }
  }

  openVideo(videoData, i){
    var image = this.imgBaseUrl+videoData.imageName;
  	this.videoViewed(videoData.id, i);
    //console.log(videoData.video_popup_url);
	  const dialogRef = this.dialog.open(DialogPopupComponent, {
      data: { url: videoData.video_popup_url, image: image }
    });
      
      /*dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();
      });*/
    }

    videoViewed(id, index){
    	//console.log(id, 'id', index);
     this.token=localStorage.getItem('for_token');
        if(this.token == null || this.token ==""){
            this.router.navigate(['/sign-in']);
        }
        
      const data = {
	      user_id : localStorage.getItem('for_userid'),
	      video_id : id,
      };
    this.auth.chapterVideoView(this.baseUrl.baseAppUrl+'trade/chapterVideoView', data , this.token).subscribe((res: any) =>  {
            if(res.status==1){
            	this.videoList[index].viewCount = this.videoList[index].viewCount+1;
             }
            else{
             
             }
          });
  	}

}
