import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder,FormGroup, FormControl, Validators } from '@angular/forms';
import {AdminService} from "../services/admin.service";
import { AppGlobals } from '../../app.global';
//import { Select2TemplateFunction, Select2OptionData } from 'ng2-select2';
//import { tokendata } from '../../main/variable';
import { AuthService } from '../../service/auth.service';
//import { DateAdapter } from '@angular/material/core';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {

  minDate:any;
  btn_title = 'Add User';
  addUserForms: FormGroup;
  isSubmitted  =  false;

  check_sess : any;
  hide1 : any;hide2 : any;

  email_msg = '';
  user_msg = '';

  user_id = '';
  userTypes=<any>[];

  members = false;
  trading_academy = false;
  academy_videos = false;
  video_library = false;
  strategies = false;
  error_msg='';
  success_msg='';

  //public options: Select2Options;
  //public codes: Array<Select2OptionData>;

  constructor(
    private adminService: AdminService,
    private baseUrl: AppGlobals,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    //private dateAdapter: DateAdapter<Date>
  ) {
      //this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
  }
  emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

  ngOnInit() {
    this.addUserForms = this.formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      //mobile: [res.data.mobile, [ Validators.pattern(this.numberOnly)]],
      username: ['', Validators.required],
      email: ['', Validators.required]
      //userType: ['subadmin', Validators.required]
    });
    this.minDate = new Date();

    //this.user_id = localStorage.getItem('for_editUserID');
    this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
      this.router.navigate(['/admin/login-dashboard']);
    }
    this.user_id = localStorage.getItem('for_userid');
    this.btn_title = 'Update Profile';
        const data = {id: this.user_id};
        this.adminService.getUserdetail(this.baseUrl.baseAppUrl+'admin/getUserDetail?id='+this.user_id, this.check_sess).subscribe((res : any) => {
          if(res.status == '1'){
            console.log('res.data',res.data);

              this.addUserForms = this.formBuilder.group({
                first_name: [res.data.first_name, Validators.required],
                last_name: [res.data.last_name, Validators.required],
                //mobile: [res.data.mobile, [ Validators.pattern(this.numberOnly)]],
                username: [res.data.username, Validators.required],
                email: [res.data.email, Validators.required]
                //userType: ['subadmin', Validators.required]
              });
              console.log(this.addUserForms);
          }
      });

  }
  get addUserFrms() { return this.addUserForms.controls; }

  onSubmit(){
    this.isSubmitted = true;
    console.log(this.addUserForms);
    if (this.addUserForms.invalid) {
      return;
    }

    //console.log(this.addUserForm.value);return false;
    let endpoint = this.baseUrl.baseAppUrl+'admin/update_myprofile';
    if(this.user_id!=''){
      this.addUserForms.value.user_id = this.user_id;
      //endpoint = this.baseUrl.baseAppUrl+'admin/update_user';
    }else{
      //endpoint = this.baseUrl.baseAppUrl+'admin/add_user';
    }
    this.addUserForms.value.user_type = 'subadmin';
    this.adminService.updateUser(endpoint, this.addUserForms.value, this.check_sess).subscribe((data: any) => {
      console.log('Responce : ',data);
      if(data.status){
              //this.router.navigate(['admin/sub-admin']);
              //localStorage.setItem('success_msg', data.message);

              this.success_msg = data.message;
              setTimeout(function(){ this.success_msg=''; }, 3000);
      }else{
        // if(data.type=='email')this.email_msg = data.message;
        // if(data.type=='user')this.user_msg = data.message;
        this.error_msg = data.message;
        setTimeout(function(){ this.error_msg=''; }, 3000);
      }
    });

  }

  removeError(type){
    if(type=='email')this.email_msg='';
    if(type=='user')this.user_msg='';
  }

}
