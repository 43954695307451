import { Component, OnInit, ViewChild } from '@angular/core';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogLogoutComponent } from '../dashboard-header/dialog-logout/dialog-logout.component';
import { TradeIdeaComponent } from '../dashboard/trade-idea/trade-idea.component';
import 'rxjs/add/operator/filter';
import { ImagePopupComponent } from '../my-profile/image-popup/image-popup.component';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';
import { COMETCHAT_CONSTANTS } from '../../Commit-Constants';
import { AppGlobals } from '../../app.global';
import { AuthService } from '../../service/auth.service';
import { NoAccessComponent } from '../dashboard-header/no-access/no-access.component';

@Component({
  selector: 'app-dashboard-header',
  templateUrl: './dashboard-header.component.html',
  styleUrls: ['./dashboard-header.component.scss'],
  providers: [ AppGlobals]
})
export class DashboardHeaderComponent implements OnInit {
  active;
  userName;
  profile_img;
  userId;
  token;
  unReadCount = false;
  subscriptionStatus;
  disclaimanerUrl;
  helpUrl;
  unReadNotiCount:any=0;
  isDiffDetected=false;
  isAccessible=false;
  accessrole:any;
  MarketingStaff = 'Marketing Staff'; 
  frontBaseUrl = '';  
  loginType = '';
  accessibility:any;
  bothaccessibility: any;
  planid:any;
  pagename='';
  accesspage:any = [];
  accessroles: any;
  @ViewChild(ImagePopupComponent, {static: false}) child;
  constructor(public dialog: MatDialog, private router: Router, private http: HttpClient,private baseUrl: AppGlobals,private auth: AuthService,private route: ActivatedRoute) { }


  selectedTab(tab){
    
    localStorage.setItem('for_usertype', tab);
    var accessroles: any = JSON.parse(localStorage.getItem('for_accessibility'));   
    
    if(tab=='members'){
      this.pagename = 'Members';
      this.router.navigate(['/dashboard']);
      this.accessibility = 'members';   
      // if(accessroles.members){
      //   this.accessibility = 'members';      
      // } 
    }else{
      this.pagename = 'Trading Academy';
      this.router.navigate(['/trading-academy']);
      this.accessibility = 'trading_academy';
      // if(accessroles.trading_academy){
      //   this.accessibility = 'trading_academy';
      // }
    }
    localStorage.setItem('pagename', this.pagename);
    this.loginType = tab;
  }
  ngOnInit() {
    
    this.pagename = localStorage.getItem('pagename');
    this.frontBaseUrl = this.baseUrl.baseFrontAppUrl;
    this.subscriptionStatus = localStorage.getItem('subscription_status');
    this.loginType = localStorage.getItem('for_usertype');

    this.accessroles = JSON.parse(localStorage.getItem('for_accessibility'));
    var current = new Date();
    const currentTime = current.getTime();
    var memberEnd = localStorage.getItem('member_end');
    var traderEnd = localStorage.getItem('trader_end');
    
    if(this.accessroles.members && currentTime < Number(memberEnd)){
      this.accessibility = 'members';  
      //this.pagename = 'Members'; 
      this.accesspage.push({'page':'Members','accessibility':'members'});  
      //this.router.navigate(['/dashboard']); 
    } 
    if(this.accessroles.trading_academy && currentTime < Number(traderEnd)){
      this.accessibility = 'trading_academy';  
      //this.pagename = 'Trading Academy'; 
      this.accesspage.push({'page':'Trading Academy','accessibility':'trading_academy'});   
      //this.router.navigate(['/trading-academy']);
    } 
    if(this.loginType=='expire'){
      this.accesspage = [];
    }


    // if(this.loginType=='member'){
    //   //this.router.navigate(['/dashboard']);
      
    // }else{
    //   //this.router.navigate(['/trading-academy']);
    //   this.pagename = 'Trading Academy';
    //   if(accessroles.trading_academy){
    //     this.accessibility = 'trading_academy';
    //   }
    // }

    this.auth.getCometNoti().subscribe(info => {
      if(info.isMsgArrived==1)
      {
        this.unReadCount=true;
      }else
      {
        this.unReadCount=false;
      }
      
      //this.updateUserInfo = info;
    })

    this.disclaimanerUrl = this.frontBaseUrl+'terms-and-conditions';
    this.helpUrl = this.frontBaseUrl+'contact-us';
    //this.baseUrl.baseAppUrl+'uploads/site-docs/Website-Terms-and-Conditions.pdf';
    this.active = localStorage.getItem('activeTab');
    this.userName = localStorage.getItem('for_username');
    this.profile_img = localStorage.getItem('profile_image');
    this.userId = localStorage.getItem('for_userid');
    this.token = localStorage.getItem('for_token');
    this.accessrole = localStorage.getItem('role');
    //this.loginType = localStorage.getItem('role');
    //this.getUnreadMsgCount();
    //this.getSubscriptionDetail();
    this.getUnreadNotificationCounts();
  }

  getSubscriptionDetail() {
    this.auth.getSubscriptionDetail(this.baseUrl.baseAppUrl+'user/getSubscriptionDetail?userId='+this.userId,this.token).subscribe((res: any) =>  {
        if(res){
          
          if(res.data.is_accessible==1)
          {
            this.isAccessible=true;
          }else{
            this.isAccessible=false;
          }
          var accessroles = JSON.parse(res.data.accessibility);
          
          // if(accessroles.members){
          //   this.accessibility = 'members';
            
          // } 
          // if(accessroles.trading_academy){
          //   this.accessibility = 'trading_academy';
          // }
           
          if(localStorage.getItem('accessibility')=='' || localStorage.getItem('accessibility')==null){
            localStorage.setItem('accessibility', this.accessibility);
          }
          
          if(accessroles.trading_academy && accessroles.members) this.bothaccessibility = 'both';
          //this.subscriptionStatus = res.data.subscription_status;
          this.subscriptionStatus = localStorage.getItem('subscription_status');
          this.planid = localStorage.getItem('planid');
          //localStorage.setItem('subscription_status', this.subscriptionStatus);
        }
     });
  }

  getUnreadNotificationCounts() {
    this.auth.getUnreadNotificationCounts(this.baseUrl.baseAppUrl+'user/getUnreadNotificationCounts?userId='+this.userId+'',this.token).subscribe((res: any) =>  {
      if(res){
       //console.log(res, 'socket');
        if(res.is_socket==1)
        {
          if(res.data.trade_created_by==this.userId)
          {
          //console.log(this.unReadNotiCount, 'unReadCount');
            this.unReadNotiCount = ++this.unReadNotiCount;
            this.isDiffDetected=true;
          }
        }else{
          // this.unReadNotiCount = res.data.total;
          // localStorage.setItem('unReadNotiCount',res.data.total);
          //console.log(localStorage.getItem('unReadNotiCount'), 'localStorage.getIt');
          if(localStorage.getItem('unReadNotiCount')!=res.data.total)
          {
            //console.log(res.data.total, 'res.data.total');
            this.unReadNotiCount = res.data.total;
            localStorage.setItem('unReadNotiCount',res.data.total);
            //console.log('isDiffDetected');
            this.isDiffDetected=true;
          }else{
            this.unReadNotiCount = localStorage.getItem('unReadNotiCount');
          }
          
        }
        
      }
   });
  }

  navigateTabs(url, isRestrict){
    if(this.isAccessible)
    {
      this.router.navigate([url]);
    }else if(this.isAccessible && url=="/personal-chat"){
      this.router.navigate([url]);
    }else{
      const dialogRef = this.dialog.open(NoAccessComponent, {
      });
      
      dialogRef.afterClosed().subscribe(result => {
        
      });
    }
  }

  getUnreadMsgCount(){
    // let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'apikey': COMETCHAT_CONSTANTS.REST_API_KEY, 'appid': COMETCHAT_CONSTANTS.APP_ID, 'accept': 'application/json' }) };

      
    //   let userId = localStorage.getItem('for_userid');
    //   this.http.get<any>('https://api-us.cometchat.io/v3.0/users/'+userId+'/messages?unread=true&count=true&receiverType=user', httpOptions).subscribe(
    //     (res:any) => { 
    //       if(res.data.length>0)
    //       {
    //         //console.log(res.data, 'res data');
    //         for (var i = 0; i <  res.data.length; i++) {
    //           if(res.data[i].count>0)
    //           {
    //             this.unReadCount = true;
    //           }
    //         }
    //         //console.log(localStorage.getItem('newMsgArrived'),'newMsgArrived');
    //       }
    //       if(this.active=='personal-chat')
    //       {
    //         this.unReadCount = false;
    //       }
    //     })
  }
  
  

  openNav() {
    document.getElementById("mySidechat").style.cssText = "background-color: #000;display: block;";
  }

  CreateConfirmLogout(){
        const dialogRef = this.dialog.open(DialogLogoutComponent, {
      });
      
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();
      });

    }

    CreateTradeIdea(){

      // if(this.isAccessible)
      // {
        const dialogRef = this.dialog.open(TradeIdeaComponent, {
          height: "100vh",
          maxHeight: "640px"
        });
        
        dialogRef.afterClosed().subscribe(result => {
          //this.ngOnInit();
        });
      // }else{
      //   const dialogRef = this.dialog.open(NoAccessComponent, {
      //   });
        
      //   dialogRef.afterClosed().subscribe(result => {
          
      //   });
      // }
      
    }  

}
