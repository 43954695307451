import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder,FormGroup, FormControl, Validators } from '@angular/forms';
import {AdminService} from "../../services/admin.service";
import { AppGlobals } from '../../../app.global';
//import { CometChat } from '@cometchat-pro/chat';
//import { COMETCHAT_CONSTANTS } from '../../../Commit-Constants';
import { ConfirmedValidator } from '../../confirmed.validator';
import { Select2TemplateFunction, Select2OptionData } from 'ng2-select2';
import { tokendata } from '../../../main/variable';
import { AuthService } from '../../../service/auth.service';
import { DateAdapter } from '@angular/material/core';


@Component({
  selector: 'app-add-member',
  templateUrl: './add-member.component.html',
  styleUrls: ['./add-member.component.scss'],
  providers: [ AppGlobals]
})

export class AddMemberComponent implements OnInit {
  minDate:any;
  btn_title = 'Add User';
  addUserForm: FormGroup;
  isSubmitted  =  false;

  check_sess : any;
  hide1 : any;hide2 : any;

  email_msg = '';
  user_msg = '';
  country = '101';

  user_id = '';
  userTypes=<any>[];

  members = false;
  trading_academy = false;
  academy_videos = false;
  video_library = false;
  strategies = false;
  editpermission=false;
  addpermission=false;
  automationtool=false;
  capability='';
  userType='';
  currDate = '';
  endDate = '';
  tendDate = '';
  adminsubscrib = 0;
  setFreeSubscribe=false;

  public options: Select2Options;
  public codes: Array<Select2OptionData>;
  
  constructor(
    private adminService: AdminService,
    private baseUrl: AppGlobals,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private dateAdapter: DateAdapter<Date>
    ) { 
      this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy 
     }

    emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    numberOnly = '^[0-9]*$';

  ngOnInit() {

    this.minDate = new Date();

    //this.user_id = localStorage.getItem('for_editUserID');
    this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
      this.router.navigate(['/admin/login-dashboard']);
    }

    this.capability=localStorage.getItem('capability');
    this.userType=localStorage.getItem('user_type');
    if(this.userType!='admin' && this.capability.split(',').indexOf('members_edit')>-1){
      this.editpermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('members_add')>-1){
      this.addpermission = true;
    }
      if(this.userType=='admin'){
        this.editpermission=true;
        this.addpermission = true;
      }
    
    if(!this.editpermission && !this.addpermission){
      this.router.navigate(['/admin/my-profile']);
    }

    if(this.route.snapshot.paramMap.get('id')!=null)this.user_id = this.route.snapshot.paramMap.get('id');
    
    if(this.user_id!=''){

        this.btn_title = 'Edit User';
        const data = {id: this.user_id};
        this.adminService.getUserdetail(this.baseUrl.baseAppUrl+'admin/getUserDetail?id='+this.user_id, this.check_sess).subscribe((res : any) => {
          if(res.status == '1'){
            console.log('res.data',res.data);

            var cd = new Date();
            var cmonth = (cd.getMonth()<10)?'0'+(cd.getMonth()+1):cd.getMonth()+1;
            var cday = (cd.getDate()<10)?'0'+cd.getDate():cd.getDate();
            this.currDate = cd.getFullYear()+'-'+cmonth+'-'+cday;

            var d = new Date(res.data.end_date);
            var month = (d.getMonth()<10)?'0'+(d.getMonth()+1):d.getMonth()+1;
            var day = (d.getDate()<10)?'0'+d.getDate():d.getDate();
            this.endDate = d.getFullYear()+'-'+month+'-'+day;

            var td = new Date(res.data.trader_end_date);
            var tmonth = (td.getMonth()<10)?'0'+(td.getMonth()+1):td.getMonth()+1;
            var tday = (td.getDate()<10)?'0'+td.getDate():td.getDate();
            this.tendDate = td.getFullYear()+'-'+tmonth+'-'+tday;
            this.adminsubscrib = res.data.admin_subscription;

              if(res.data.accessibility!=null){
                var decode_accessibility = JSON.parse(res.data.accessibility);
                this.members=decode_accessibility.members;
                this.trading_academy=decode_accessibility.trading_academy;
                this.academy_videos=decode_accessibility.academy_videos;
                this.video_library=decode_accessibility.video_library;
                this.strategies=decode_accessibility.strategies;
                this.automationtool= (decode_accessibility.automationtool==undefined)?false:decode_accessibility.automationtool;
              }

              this.addUserForm = this.formBuilder.group({
                first_name: [res.data.first_name, Validators.required],
                //last_name: [res.data.last_name, Validators.required],
                //mobile: [res.data.mobile, [ Validators.pattern(this.numberOnly)]],
                username: [res.data.username, Validators.required],
                email: [res.data.email, [Validators.required , Validators.pattern(this.emailPattern)]],
                password: ['', Validators.minLength(8)],
                conf_password: [''],
                expiry_date: [(res.data.end_date!=='0000-00-00 00:00:00'?res.data.end_date:''), Validators.required],
                //trader_expiry_date: [(res.data.trader_end_date!=='0000-00-00 00:00:00'?res.data.trader_end_date:''), Validators.required],
                userType: [res.data.role_id, Validators.required],
                is_mentor: res.data.is_mentor
              },{
                validator: ConfirmedValidator('password', 'conf_password')
              });
            //if(res.data.end_date!=='0000-00-00 00:00:00')this.expiryDate = res.data.end_date;
          }else{
            
          }
      });

    }else{

      this.btn_title = 'Add User';
      this.addUserForm = this.formBuilder.group({
        first_name: ['', Validators.required],
        //last_name: ['', Validators.required],
        mobile: ['', [ Validators.pattern(this.numberOnly)]],
        username: ['', Validators.required],
        email: ['', [Validators.required , Validators.pattern(this.emailPattern)]],
        password: ['', [Validators.required, Validators.minLength(8)]],
        conf_password: ['', Validators.required],
        expiry_date: [''],
        //trader_expiry_date: ['', Validators.required],
        userType: [],
        is_mentor: 0
        //month: new FormControl(this.data.month, [Validators.required]),
      }, {
        validator: ConfirmedValidator('password', 'conf_password')
      });

    }

    

    this.userTypes = [{"id":3,"value":"Moderator"},{"id":4,"value":"Trading Mentor"},{"id":2,"value":"Member"}];

    this.auth.commonSrvs(this.baseUrl.baseAppUrl+'signup/getcountrylist').subscribe((res : tokendata) => {
      if(res.status == '1'){
        //this.codes = res.data;
        let finalData = <any>[];
        let children = <any>[];
        let newData = <any>[];
        res.data.forEach((code, index)=>{
          let c = '+'+code.text;
          let p = {"id":code.id, text:c,additional: {"image":code.country_code.toLowerCase( )+".png"}};
          newData.push(p);
        });
        this.codes = newData;
      }else{ }
    });
    
    this.options = {
      templateResult: this.templateResult,
      templateSelection: this.templateSelection
    }
  
  }

  public templateResult: Select2TemplateFunction = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }
    let image = '<span class="image"></span>';
    if (state.additional.image) {
      image = '<span class="image"><img src="' +'/assets/images/flags/16x16/'+ state.additional.image + '"</span>';
    }
    return jQuery('<span>' + image + ' '  + state.text + '</span>');
  }
  
  public templateSelection: Select2TemplateFunction = (states: Select2OptionData): JQuery | string => {
    if (!states.id) {
      return states.text;
    }
    let image = '<span class="image"></span>';
    if (states.additional.image) {
      image = '<span class="image"><img src="' +'/assets/images/flags/16x16/'+ states.additional.image + '"</span>';
    }
    return jQuery('<span>' + image + ' '  + states.text + '</span>');
  }

  setCode(e){
    this.country = e;
  }

  get addUserFrm() { return this.addUserForm.controls; }

  changeAccessibility(name){
    
    if(name=="trading_academy"){
      if(this.trading_academy){
        this.trading_academy = this.academy_videos = this.video_library = this.strategies = false;
      }else{
        this.trading_academy = this.academy_videos = this.video_library = this.strategies = true;
      }      
    }
    if(name=="academy_videos"){
      this.academy_videos = !this.academy_videos;
      if(this.academy_videos || this.video_library || this.strategies )this.trading_academy = true;
      if(!this.academy_videos && !this.video_library && !this.strategies )this.trading_academy = false;
    }
    if(name=="video_library"){
      this.video_library = !this.video_library;
      if(this.academy_videos || this.video_library || this.strategies )this.trading_academy = true;
      if(!this.academy_videos && !this.video_library && !this.strategies )this.trading_academy = false;
    }
    if(name=="strategies"){
      this.strategies = !this.strategies;
      if(this.academy_videos || this.video_library || this.strategies )this.trading_academy = true;
      if(!this.academy_videos && !this.video_library && !this.strategies )this.trading_academy = false;
    }
    

  }
  
  onSubmit(){
    this.isSubmitted = true;
    
    if (this.addUserForm.invalid) {
      return;
    }

    var accessibility = {'members':this.members,'trading_academy':this.trading_academy,'academy_videos':this.academy_videos,'video_library':this.video_library,'strategies':this.strategies,'automationtool':this.automationtool};
    this.addUserForm.value.accessibility = JSON.stringify(accessibility);

    let endpoint = this.baseUrl.baseAppUrl+'admin/add_user';
    if(this.user_id!=''){
      this.addUserForm.value.user_id = this.user_id;
      endpoint = this.baseUrl.baseAppUrl+'admin/update_user';
    }else{
      this.addUserForm.value.country = this.country;
    }
    this.adminService.updateUser(endpoint, this.addUserForm.value, this.check_sess).subscribe((data: any) => {
      
      if(data.status){
        this.router.navigate(['admin/members']);
        localStorage.setItem('succ_user', data.message);
            /*if(this.user_id!=''){
              // var userID = ""+this.user_id+"";
              // var user = new CometChat.User(userID);
              // user.setRole(this.addUserForm.value.userType);
              // CometChat.updateUser(user, COMETCHAT_CONSTANTS.API_KEY).then(
              // user => {
                  
              //   }, error => {
              //       console.log("error", error);
              //   }
              // )
              this.router.navigate(['admin/members']);
              localStorage.setItem('succ_user', data.message);
              //console.log("user updated", user);

            }else{
              this.router.navigate(['admin/members']);
              localStorage.setItem('succ_user', data.message);
              //console.log("user added", user);
            }*/
      }else{
        if(data.type=='email')this.email_msg = data.message;
        if(data.type=='user')this.user_msg = data.message;
      }
    });

  }

  removeError(type){
    if(type=='email')this.email_msg='';
    if(type=='user')this.user_msg='';
  }

  setFreeSubscribeFun(type: boolean){
    console.log('type - ',type);
    this.setFreeSubscribe = type;
  }

}
