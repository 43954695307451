import { Component, OnInit, ViewChild, Optional, Inject } from '@angular/core';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

import { AuthService } from '../../service/auth.service';
import { AppGlobals } from '../../app.global';

import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


export interface PeriodicElement {
  OrderID: string;
  PaymentDate: string;
  Subscription: string;
  Price: Number;
  //RenewsExpires: string;
  Invoice: string;
}
export interface DialogData {
  otpdata: any;
}


const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-my-subscriptions',
  templateUrl: './my-subscriptions.component.html',
  styleUrls: ['./my-subscriptions.component.scss'],
  providers: [ AppGlobals]
})
export class MySubscriptionsComponent implements OnInit {
  displayedColumns: string[] = ['OrderID', 'PaymentDate', 'Subscription', 'Price', 'Invoice'];
  //dataSource = ELEMENT_DATA;
  isApiCalled = false;
  userId;
  token;
  subscription_id;
  renew_date;
  subs_data:any={};
  dataSource : any;
  invoiceHistory: any=[];
  isInvoiceCalled = false;
  isUpdDetailsDisabled=false;
  sub_err_msg='';
  sub_success_msg='';
  adm_sub_msg = '';
 @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;

  public config: PerfectScrollbarConfigInterface = {};
  constructor(public dialog: MatDialog,private baseUrl: AppGlobals,
      private auth: AuthService) { }

  ngOnInit() {
    this.userId = localStorage.getItem('for_userid');
    this.token=localStorage.getItem('for_token');
    this.getSubscriptionDetail();
  }
  
  getSubscriptionDetail(){
    this.auth.getSubscriptionDetail(this.baseUrl.baseAppUrl+'user/getSubscriptionDetail?userId='+this.userId,this.token).subscribe((res: any) =>  {
        if(res){
          console.log(res);
          if(res.data.admin_subscription)this.adm_sub_msg='( Free access expire on '+res.data.adm_sub_end_date + ')';
          this.subs_data = res.data;
          this.subscription_id = res.data.subscription_id;
          var renew_date = new Date(res.data.trial_end*1000);
           this.getInvoiceDetails(this.subs_data.stripe_id);
           var year = renew_date.getFullYear();
           var month = renew_date.getMonth();
           var day = renew_date.getDate();
           this.renew_date = res.data.end_date;
          localStorage.setItem('sub_id', this.subscription_id);
        }
     });
    this.isApiCalled = true;
  }

  getInvoiceDetails(stripe_id) {
    this.invoiceHistory=[];
    this.auth.getInvoiceDetails(this.baseUrl.baseAppUrl+'user/getInvoiceDetails?cusID='+stripe_id,this.token).subscribe((res: any) =>  {
        if(res){
          this.isInvoiceCalled = true;
          let j = 1;
        for (let i = 0; i < res.data.length; i++) {
            
            this.invoiceHistory.push({OrderID: res.data[i].invoice_number,PaymentDate:res.data[i].invoice_created_at, Subscription: 'Monthly',  Price: 99, Invoice: 'View', invoice_pdf: res.data[i].invoice_pdf},);
            ['OrderID', 'PaymentDate', 'Subscription', 'Price', 'Invoice']
      j++;
          }
          console.log(this.invoiceHistory, 'invoiceHistory');
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.invoiceHistory);
          this.dataSource.paginator = this.paginator;
        }
      })
  }

  

  openInvoice(file) {
    console.log(file, 'file');
    window.open(file);
  }

  // Create Update Card modal
    
  CreateUpdateCardO(){
    const dialogRef = this.dialog.open(CreateUpdateCardDialog, {
        data:{cardName: this.subs_data.card_name, cardNo: this.subs_data.card_number, cvv: this.subs_data.card_cvv, expDate: this.subs_data.exp_date, stripe_id: this.subs_data.stripe_id}
    });
    
    dialogRef.afterClosed().subscribe(result => {
      this.isUpdDetailsDisabled=false;
      //this.ngOnInit();
    });
  }

  CreateUpdateCard(){
    this.isUpdDetailsDisabled=true;
    this.auth.getSubscriptionDetail(this.baseUrl.baseAppUrl+'user/getCardDetails?cust_id='+this.subs_data.stripe_id+'&card_id='+this.subs_data.card_id,this.token).subscribe((res: any) =>  {
        if(res.status==1){

          const dialogRef = this.dialog.open(CreateUpdateCardDialog, {
            data:{cardName: res.data.name, cardNo: res.data.last4, exp_month: res.data.exp_month,exp_year: res.data.exp_year, stripe_id: this.subs_data.stripe_id, card_id:this.subs_data.card_id}
          });
    
        dialogRef.afterClosed().subscribe(result => {
          //console.log(result, 'result');
          if(result.data=='cross_icon' || result.data.status=='success')
          {
            this.isUpdDetailsDisabled=false;
          }
          //this.ngOnInit();
        });
          //console.log(res, 'res');
        }else{
          this.isUpdDetailsDisabled=false;
        }

      });
  }

  // Create cancel Card modal
    
  CreatecancelCard(){
    const dialogRef = this.dialog.open(CreatecancelCardDialog, {
        data: { type: 'cancel' }
    });

    dialogRef.afterClosed().subscribe(result => {
      if(result.data.status=='success'){
        if(!result.data.is_accessible)
        {
          localStorage.removeItem('subscription_status');
          localStorage.setItem('subscription_status', 'cancel');
        }
        this.subs_data.subscription_status = 'cancel';
        //this.renew_date = result.data.renew_date;
        this.renew_date='';
        this.sub_success_msg = "Your subscription is cancelled successfully.";
        setTimeout(() => {
          this.sub_success_msg = '';
        }, 4000);

      }else{
        if(result.subscription_status=='cancel')
        {
          this.subs_data.subscription_status = 'cancel';
          this.subs_data.end_date_time = result.end_date;
          localStorage.setItem('subscription_status', 'cancel');
          this.sub_err_msg = "Your subscription is already cancelled";
          setTimeout(() => {
            this.sub_err_msg = '';
          }, 4000);
        }else{
          this.sub_err_msg = "Error is cancelling your subscription";
          setTimeout(() => {
            this.sub_err_msg = '';
          }, 4000);
        }
        
      }
    });
  } 

  startSubscription(){
    const dialogRef = this.dialog.open(CreatecancelCardDialog, {
        data: { type: 'start', plan_id: this.subs_data.plan_id, user_id: this.userId, customer_id: this.subs_data.stripe_id, subs_end_date:this.subs_data.end_date_time }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result, 'result start');
      if(result.data && result.data.status=='success'){
        if(result.data.subscription_status=='active')
        {
          localStorage.removeItem('subscription_status');
          localStorage.setItem('subscription_status', 'active');
  
          this.renew_date = result.data.renew_date;
          this.subs_data.subscription_status = 'active';
          if(result.data.is_billing_later)
          {
            this.sub_success_msg = 'Thank you for upgrading the plan. Since you already have an active plan your next invoice date would be ' + this.renew_date; 
            setTimeout(() => {
              this.sub_success_msg = '';
            }, 5000);
          }else{
            this.sub_success_msg = 'Thank you for upgrading your plan. You now have full access to all features of the members area. Your subscription will automatically renew every month.';
            setTimeout(() => {
              this.sub_success_msg = '';
            }, 5000);
          }
          
        }else{
          this.sub_err_msg="Something went wrong with payments, try again";
          setTimeout(() => {
            this.sub_err_msg = '';
          }, 4000);
        }
        
      }else{
        this.sub_err_msg=result.message;
        setTimeout(() => {
          this.sub_err_msg = '';
        }, 4000);
      }
     
    });
  } 

  click_message(){
    this.sub_err_msg='';
  }

  payNow(){
    //console.log('payNow');
    const dialogRef = this.dialog.open(CreatecancelCardDialog, {
        data: { type: 'payNow', plan_id: this.subs_data.plan_id, user_id: this.userId, customer_id: this.subs_data.stripe_id, sub_id: this.subs_data.subscription_id }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(result, 'result start');
      if(result.data.status=='success'){
        if(result.data.subscription_status=='active')
        {
          this.renew_date = result.data.renew_date;
          localStorage.setItem('subscription_status', 'active');
          this.subs_data.subscription_status = 'active';
          this.sub_success_msg = 'Thank you for upgrading your plan. You now have full access to all features of the members area. Your subscription will automatically renew every month.'; 
          setTimeout(() => {
            this.sub_success_msg = '';
          }, 5000);
        }else{
          this.sub_err_msg="Something went wrong with payments, try again";
          this.subs_data.subscription_status = 'cancel';
          setTimeout(() => {
            this.sub_err_msg = '';
          }, 4000);
        }
        
      }
     
    });
  }


} 

export class displayedColumns {}



/**  Create confirm logout  **/
@Component({
  selector: 'dialog-create-update-card',
  templateUrl: './dialog-create-update-card.html',
  styleUrls: ['./my-subscriptions.component.scss'],
  providers: [ AppGlobals]
  })
  export class CreateUpdateCardDialog implements OnInit {
    public cardForm: FormGroup;
    cardName;
    cardNo;
    expDate;
    cvv;
    isSubmitted  =  false;
    card_error;
    cvv_error;
    token;
    stripe_id;
    isUpdateDisable = false;
    exp_error;
    stripeCard;
    stripeData:any=[];
    card_id;

    constructor(private baseUrl: AppGlobals,
      private auth: AuthService, public dialogRef: MatDialogRef<CreateUpdateCardDialog>,  @Optional() @Inject(MAT_DIALOG_DATA) public data: any,  private formBuilder: FormBuilder) { 
        dialogRef.disableClose = true;
      this.cardName = data.cardName;
      this.cardNo = data.cardNo;
      this.stripeCard = data.cardNo;
      this.cvv = '***';
      //console.log(data.exp_year.toString().substr(-2), 'qwqwqwqw');
      this.expDate = data.exp_month+'/'+data.exp_year.toString().substr(-2);
      this.stripe_id = data.stripe_id;
      this.stripeData = data;
      this.card_id = data.card_id;
        //console.log(data, 'data');
    }
    ngOnInit() {
      this.token = localStorage.getItem('for_token');
      this.cardNo = this.cardNo.replace(/\s/g, "");
      //this.cardNo = '********'+this.cardNo.slice(this.cardNo.length - 4);
      this.cardNo = '********'+this.cardNo;
      this.cardForm = this.formBuilder.group({
          cardName: ['', Validators.required],
          cardNo: ['', Validators.required],
          cvv : ['', Validators.required],
          expDate: ['', Validators.required]
      });
    }
    get cardFrm() { return this.cardForm.controls; }
    
    getcard(evt){
       if(this.cardNo.length == 1){
        if (isNaN(parseInt(this.cardNo, 10)))
          this.cardNo ='';
        }
      }

      blurCardErr(evt)
      {
        this.card_error = '';
      }
      blurExpDate(evt)
      {
        this.exp_error = '';
      }
      blurCvv(evt)
      {
        this.cvv_error = ''
      }

    onSubmit() {
      //console.log(this.stripeData,'stripeData', this.cardNo.indexOf('*'));
      this.isSubmitted = true;
      //console.log(this.cardForm.invalid, 'this.cardForm.invalid');
      if (this.cardForm.invalid) {
          return;
      }
      //console.log(this.cardNo, 'card', '********'+this.stripeCard);
      /*if(this.cardNo=='********'+this.stripeCard)
      {
        this.dialogRef.close({data:'cross_icon'});
        return;
      }*/
      var data = {};
      if(this.cardNo.indexOf('*') > -1)
      {
        data = {
          userId    : localStorage.getItem('for_userid'),
          cardName  : this.cardName,
          exp_date  : this.expDate,
          card_cvv  : this.cvv,
          stripe_id : this.stripe_id,
          card_id   : this.card_id
        };

      }else{
         data = {
          userId    : localStorage.getItem('for_userid'),
          cardName     : this.cardName,
          exp_date : this.expDate,
          card_cvv : this.cvv,
          card_number : this.cardNo,
          stripe_id: this.stripe_id
        };

      }
      
      this.isUpdateDisable = true;
      this.auth.updateCardDetails(this.baseUrl.baseAppUrl+'user/updateCardDetails', data , this.token).subscribe((res : any) => {
      if(res.status == '1'){
        this.card_error = '';
        this.cvv_error = '';
        this.exp_error = '';
        this.isUpdateDisable = false;
        this.dialogRef.close({data:{status:'success'}});
       }
      else{
        if(res.message=="Your card's expiration month is invalid." || res.message=="Your card's expiration year is invalid.")
        {
          this.exp_error = res.message;
          this.card_error = '';
          this.cvv_error = '';
        }else if(res.message=="Your card's security code is invalid."){
          this.cvv_error = res.message;
          this.exp_error = '';
          this.card_error = '';
        }else{
          this.card_error = res.message;
          this.exp_error = '';
          this.cvv_error = '';
        }
        this.isUpdateDisable = false;
        //this.card_error = res.message;
      }
    });
    }

    closeUpdate(){
        this.dialogRef.close({data:'cross_icon'});
      }
  }

/**  Create confirm logout  **/
@Component({
  selector: 'dialog-create-cancel-card',
  templateUrl: './dialog-create-cancel-card.html',
  styleUrls: ['./my-subscriptions.component.scss'],
  providers: [ AppGlobals]
  })
  export class CreatecancelCardDialog implements OnInit {
    token;
    popupType;
    customer_id;
    userId;
    sub_id;
    plan_id;
    subs_end_date;
    isStartDisable = false;
    isPayNowDisable=false;
    constructor(private baseUrl: AppGlobals,
      private auth: AuthService, public dialogRef: MatDialogRef<CreatecancelCardDialog>,  @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
      //console.log(data, 'dtaa');
        this.popupType = data.type;
        dialogRef.disableClose = true;
        if(data.type=='start' || data.type=='payNow')
        {
          this.userId = data.user_id;
          this.plan_id = data.plan_id;
          this.customer_id = data.customer_id;
          this.sub_id = data.sub_id;
        }
        if(data.type=='start')
        {
          this.subs_end_date=data.subs_end_date;
        }
      }
     ngOnInit() { 
       this.token=localStorage.getItem('for_token');
     }
      cancelSubscription(){
        var sub_id = localStorage.getItem('sub_id');
        //console.log(localStorage.getItem('sub_id'), 'sub id');
        this.auth.cancelSubscription(this.baseUrl.baseAppUrl+'user/cancelSubscription?subId='+sub_id,this.token).subscribe((res: any) =>  {
          if(res.status==1)
          {
            this.dialogRef.close({data:{status:'success', id:0, renew_date:res.data.current_period_end, is_accessible: res.data.is_accessible}});
          }else{
            this.dialogRef.close({data:'error','subscription_status':res.data.subscription_status,'end_date':res.data.end_date});
          }
        })
      }

      startSubscription(){
        this.isStartDisable=true;
        this.auth.reactivateSubscription(this.baseUrl.baseAppUrl+'user/startSubscription?customer_id='+this.customer_id+'&plan_id='+this.plan_id+'&user_id='+this.userId+'&subs_end_date='+this.subs_end_date,this.token).subscribe((res: any) =>  {
            if(res){
              if(res.status==1)
              {
                this.isStartDisable=false;
                localStorage.setItem('sub_id',res.data.id);
                this.dialogRef.close({data:{status:'success', renew_date:res.data.current_period_end, subscription_status: res.data.status, is_billing_later:res.data.is_billing_later}});
              }else{
                this.dialogRef.close({data:'error', message:res.message});
                
              }
              //console.log(res, 'reactivateSubscription');
            }
          })
      }

      paySubscriptionNow(){
        this.isPayNowDisable=true;
        this.auth.paySubscription(this.baseUrl.baseAppUrl+'user/paySubscription?sub_id='+this.sub_id+'&plan_id='+this.plan_id+'&user_id='+this.userId,this.token).subscribe((res: any) =>  {
            if(res){
              if(res.status==1)
              {
                console.log(res.data, 'data');
                this.isPayNowDisable=false;
                this.dialogRef.close({data:{status:'success', renew_date:res.data.current_period_end, subscription_status: res.data.status}});
              }else{
                this.dialogRef.close({data:'error'});
              }
              //console.log(res, 'reactivateSubscription');
            }
          })
      }


  }  