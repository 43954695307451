import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {BrowserModule, DomSanitizer} from '@angular/platform-browser'
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-dialog-popup',
  templateUrl: './dialog-popup.component.html',
  styleUrls: ['./dialog-popup.component.scss']
})
export class DialogPopupComponent implements OnInit {
	videoUrl;
	imageUrl: string;
	vimeoUrl;
  constructor(public dialogRef: MatDialogRef<DialogPopupComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any, private sanitizer: DomSanitizer,private activatedRoute: ActivatedRoute) {
		//this.videoUrl = data.url;
		let splitUrl = data.url.split('/');
		//console.log(splitUrl[splitUrl.length-1], 'data.url');
		//this.vimeoUrl = "https://player.vimeo.com/video/"+splitUrl[splitUrl.length-1]+'?api=1&background=1&mute=0&loop=0';
		this.vimeoUrl = "https://player.vimeo.com/video/"+splitUrl[splitUrl.length-1];
		this.videoUrl = sanitizer.bypassSecurityTrustResourceUrl(this.vimeoUrl);
		this.imageUrl = data.image;
		console.log(this.videoUrl, 'video url', this.imageUrl);
     }

  ngOnInit() {
  }

}
