import { Component, OnInit } from '@angular/core';
import { AppGlobals } from '../../app.global';
import { AuthService } from '../../service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-globel-news-foot-slider',
  templateUrl: './globel-news-foot-slider.component.html',
  styleUrls: ['./globel-news-foot-slider.component.scss']
})
export class GlobelNewsFootSliderComponent implements OnInit {
  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    autoplay:true,
    navSpeed: 400,
    navText: ['<span class="carousel-control-prev-icon" aria-hidden="true"></span>', '<span class="carousel-control-next-icon" aria-hidden="true"></span>'],
    responsive: {
      0: {
        items: 1 
      }
    },
    nav: true
  }





	search_metadata_bloomberg='';
  	search_metadata_reuters='';
  	sliderNews = [];
  	token ='';
  constructor(private baseUrl: AppGlobals, private auth: AuthService,private router: Router) { }

  ngOnInit() {
    this.token=localStorage.getItem('for_token');
        if(this.token == null || this.token ==""){
            this.router.navigate(['/sign-in']);
        }
  	this.getGlobalNews();
  }

  getGlobalNews(){
  	this.auth.getGlobalNews(this.baseUrl.baseAppUrl+'user/getTwitterFeeds?next_bloomberg='+this.search_metadata_bloomberg+'&next_reuters='+this.search_metadata_reuters, this.token).subscribe((res: any) =>  {
    		if(res){
              if(res.status==1){
              	for (var i = 0; i <  res.data.statuses.length; i++) {
              		var text = res.data.statuses[i].text.split("https://")
              		res.data.statuses[i].text = text[0];
              	}
              	this.sliderNews = res.data.statuses;
              }
          	}
      })
  
	}
}
