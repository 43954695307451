import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {AdminService} from "../../services/admin.service";
import { AppGlobals } from '../../../app.global';

@Component({
  selector: 'app-add-testimonials',
  templateUrl: './add-testimonials.component.html',
  styleUrls: ['./add-testimonials.component.scss'],
  providers: [ AppGlobals]
})

export class AddTestimonialsComponent implements OnInit {

  btn_title = 'Add Testimonials';
  addForm: FormGroup;
  isSubmitted  =  false;

  check_sess : any;
  hide1 : any;hide2 : any;
  statusdata:any=[];

  public options: Select2Options;
  pastStatus='inactive';
  success_msg='';
  error_msg='';
  id = '';
  invalidVideoUrl:boolean = false;
  editpermission=false;
  capability='';
  userType='';
  addpermission=false;

  constructor(
    private adminService: AdminService,
    private baseUrl: AppGlobals,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
    ) {  }

  ngOnInit() {

    this.id = localStorage.getItem('for_testimonialsid');
    this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
      this.router.navigate(['/admin/login-dashboard']);
    }

    this.capability=localStorage.getItem('capability');
    this.userType=localStorage.getItem('user_type');
    if(this.userType!='admin' && this.capability.split(',').indexOf('testimonial_edit')>-1){
      this.editpermission = true;
    }    
    if(this.userType!='admin' && this.capability.split(',').indexOf('testimonial_add')>-1){
      this.addpermission = true;
    }
   
    if(this.userType=='admin'){
      this.editpermission=true;
      this.addpermission = true;
    }
    if(!this.editpermission && !this.addpermission){
      this.router.navigate(['/admin/my-profile']);
    }

    if(this.route.snapshot.paramMap.get('id')!=null)this.id = this.route.snapshot.paramMap.get('id');
    
    if(this.id!=''){
        this.btn_title = 'Edit';
        const data = {id: this.id};
        this.adminService.getTestimonialsDetail(this.baseUrl.baseAppUrl+'admin/getTestimonialsDetail?id='+this.id, this.check_sess).subscribe((res : any) => {
          if(res.status == '1'){              
              this.pastStatus = res.data.status;
              this.addForm = this.formBuilder.group({
                  video_url: [res.data.video_url, Validators.required],        
                  name: [res.data.name, Validators.required],
                  biography: [res.data.biography, Validators.required]
              });
          }else{
            
          }
      });

    }else{

      this.btn_title = 'Add';
      this.addForm = this.formBuilder.group({
        video_url: ['', Validators.required],        
        name: ['', Validators.required],
        biography: ['', Validators.required]
      });

    }

    this.statusdata = [{ id: 'active', text: 'Active' },{ id: 'inactive', text: 'Inactive' }];

  }

  setStatus(e) {
    this.pastStatus = e;
  }

  get addFrm() { return this.addForm.controls; }

  onSubmit(){
    this.isSubmitted = true;
    if (this.addForm.invalid) {
      return;
    }

    var isValidUrl = this.validVideoUrl(this.addForm.value.video_url, [
      'player.vimeo.com/video/'
    ]);
    if(isValidUrl=='' || isValidUrl==undefined)
    {
      this.invalidVideoUrl=true;
      return false;
    }

    let endpoint = this.baseUrl.baseAppUrl+'admin/add_edit_testimonials';
    if(this.id!='')this.addForm.value.id = this.id;
    else this.addForm.value.id = 0;

    this.addForm.value.status = this.pastStatus;

    this.adminService.addEditTestimonials(endpoint, this.addForm.value, this.check_sess).subscribe((data: any) => {
      if(data.status){
            if(this.id!='')localStorage.setItem('succ_testimonials', data.message);              
            else localStorage.setItem('succ_user', data.message);              
            this.router.navigate(['admin/testimonials']);
      }else{        
      }
    });

  }

  validVideoUrl(str, prefixes) {
    var cleaned = str.replace(/^(https?:)?\/\/(www\.)?/, '');
    for(var i = 0; i < prefixes.length; i++) {
      if (cleaned.indexOf(prefixes[i]) === 0)
        return cleaned.substr(prefixes[i].length)
    }
    return false;
  }

  removeError(){
    this.invalidVideoUrl = false;
  }
  
}



