import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SigninComponent } from './main/signin/signin.component';
import { DashboardComponent } from './main/dashboard/dashboard.component';


/*const routes: Routes = [
  
  { path: 'login', component: SigninComponent },
  
];*/

const routes: Routes = [
//{ path: '', loadChildren: () => import(`./main/main.module`).then(m => m.MainModule) },
{ path: "", loadChildren: "./main/main.module#MainModule" }

];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes)
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
