import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AdminService} from "../../services/admin.service";
import { AppGlobals } from '../../../app.global';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';


export interface PeriodicElement {
  row_id: number;
  record_id: number;
  username: string;
  prize_type: string;
  prize_money: string;
  action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];


@Component({
  selector: 'app-prize-listing',
  templateUrl: './prize-listing.component.html',
  styleUrls: ['./prize-listing.component.scss'],
  providers: [ AppGlobals]
})
export class PrizeListingComponent implements OnInit {
	all_records;
	displayedColumns: string[] = ['record_id','username', 'prize_type','prize_money', 'action'];
  	dataSource : any;
  	check_sess;
  	prize_id;
  	error_msg='';
  	success_msg='';
  	@ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;
constructor(private adminService: AdminService, 
  	private baseUrl: AppGlobals,
  	private router: Router,private dialog: MatDialog) { }

  ngOnInit() {
  	this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/admin/login-dashboard']);
    }
    this.success_msg=localStorage.getItem('succ_prize');
  	 setTimeout (() => {
         this.success_msg="";
         localStorage.setItem('succ_prize','');
        }, 2000);
  	this.prize_listing();
  }

  prize_listing(){
  	this.all_records=[];
  	this.adminService.prizeListing(this.baseUrl.baseAppUrl+'admin/prize_listing', this.check_sess).subscribe((res: any) =>  {
      
      if(res.status == '1'){
        //console.log(res.data.length);
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
            if(res.data[i].prize_type==1)
            {
            	res.data[i].prize_type = 'Trade Champion';
            }else{
            	res.data[i].prize_type = 'Chat Champion';
            }
            this.all_records.push({record_id: j, order_id: res.data[i].id, username: res.data[i].username, prize_type : res.data[i].prize_type,prize_money : res.data[i].prize_rupees , action: ''},);
            ['record_id','username', 'prize_type','prize_money', 'action'];
			j++;
          }
          console.log(this.all_records);
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;
   
      }
      else{
       this.error_msg = res.message;
      }

      });
  }

  editVideo(id){
  	
  	localStorage.setItem('for_prizeid', id);
	this.router.navigate(['admin/prizes']);   
	}

	openDialog(id) {
    this.prize_id=id;
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
    //const snack = this.snackBar.open('Snack bar open before dialog');

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
           const data = {
            id: this.prize_id
            };
            console.log(data,'data');
          this.adminService.deletePrize(this.baseUrl.baseAppUrl+'admin/prize_delete', data, this.check_sess).subscribe((res : any) => {
            if(res.status == '1'){
              this.success_msg = res.message;
              this.prize_listing();
              setTimeout (() => {
               this.success_msg="";
              }, 2000);
             }
            else{
               this.error_msg = res.message;
               setTimeout (() => {
               this.error_msg="";
              }, 2000);
             }
          });

      }
    });
  }

}
