import { Component, OnInit, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../../app.global';
import { AuthService } from '../../../service/auth.service';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';
import { ZoomMtg } from '@zoomus/websdk';
import { environment } from '../../../../environments/environment';
ZoomMtg.preLoadWasm();
ZoomMtg.prepareJssdk();
@Component({
  selector: 'app-join-meeting',
  templateUrl: './join-meeting.component.html',
  styleUrls: ['./join-meeting.component.scss']
})
export class JoinMeetingComponent implements OnInit {
  meeting_id;
  meeting_password;
  role=2;
  userId;
  check_sess='';
  leaveUrl = '/dashboard';
  userName;
  @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler(event) {
      //console.log('window load');
      ZoomMtg.leaveMeeting({});
  }
  constructor(private router: Router, private http: HttpClient, private baseUrl: AppGlobals, private auth: AuthService) {
    // const navigation = this.router.getCurrentNavigation();
    // const state = navigation.extras.state as {meetingNumber: number, passWord: string};
    // if(state!=undefined)
    // {
    //   this.meeting_id = state.meetingNumber;
    //   this.meeting_password = state.passWord;
    // }
    // console.log(this.meeting_id, 'meeting id', this.meeting_password);
   }

  ngOnInit() {
    this.userId=localStorage.getItem('for_userid');
    this.check_sess=localStorage.getItem('for_token');
    this.userName = localStorage.getItem('for_username');
    if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/sign-in']);
    }
    this.meeting_id=localStorage.getItem('m_id');
    this.meeting_password=localStorage.getItem('m_pwd');
    if(this.meeting_id=='' || this.meeting_password=='')
    {
      this.router.navigate(['/dashboard']);
    }
    this.joinMeeting();
  }
  
  joinMeeting()
  {
  const data = {
      meetingNumber: this.meeting_id,
	    role: this.role
      };
  	this.auth.tradeideaSrvs(this.baseUrl.baseAppUrl+'admin/generateSignature', data, this.check_sess).subscribe((data: any) => {
      this.startMeeting(data.signature)
    });
  }

  startMeeting(signature) {
    //console.log(signature, 'signature');
    document.getElementById('zmmtg-root').style.display = 'block';
    var d = document.getElementById("zmmtg-root");
    d.className += "custom-zoom-root";

    ZoomMtg.init({
      leaveUrl: this.leaveUrl,
      isSupportAV: true,
      meetingInfo:<any>[ 'topic', 'FOREX PLATINUM TRADING', ],
      disablePreview:true,
      disableJoinAudio:true,
      success: (success) => {
        ZoomMtg.join({
          signature: signature,
          meetingNumber: this.meeting_id,
          userName: this.userName,
          apiKey: environment.zoomApiKey,
          userEmail: '',
          passWord: this.meeting_password,          
          success: (success) => {
            localStorage.setItem('m_id', '');
            localStorage.setItem('m_pwd', '');
            //console.log(success)
          },
          error: (error) => {
            //console.log(error)
          }
        })

      },
      error: (error) => {
        //console.log(error)
      }
    })
  }

}
