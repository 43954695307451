import { Component, OnInit } from '@angular/core';
import { PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../service/auth.service';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';
import { NoAccessComponent } from '../dashboard-header/no-access/no-access.component';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
@Component({
  selector: 'app-trade-tools',
  templateUrl: './trade-tools.component.html',
  styleUrls: ['./trade-tools.component.scss'],
  providers: [ AppGlobals]
})
export class TradeToolsComponent implements OnInit {
  public config: PerfectScrollbarConfigInterface = {};
  token='';
  imgBaseUrl='';
 	toolList = [];
   isApiCalled = false;
   subscription_status='';
   isAccessible=false;
   userId;
  constructor(public dialog: MatDialog,private router: Router,
      private baseUrl: AppGlobals,
      private auth: AuthService,
      private route: ActivatedRoute) { }

  ngOnInit() {
    /**********/
    let loginType = localStorage.getItem('for_usertype');
    if(loginType!=='members'){ //'member'
      this.router.navigate(['/error404']);
    }
    /**********/
    this.userId = localStorage.getItem('for_userid');
    this.getToolslist();
    this.getSubscriptionDetail();
    //this.subscription_status = localStorage.getItem('subscription_status');
    
  }

  getSubscriptionDetail() {
    this.auth.getSubscriptionDetail(this.baseUrl.baseAppUrl+'user/getSubscriptionDetail?userId='+this.userId,this.token).subscribe((res: any) =>  {
        if(res){
          this.subscription_status=res.data.subscription_status;
          if(res.data.tradeAccess=='active')
          {
            this.subscription_status='active';
          }
          console.log(this.subscription_status, 'insode');
          if(res.data.is_accessible==1)
          {
            this.isAccessible=true;
          }else{
            this.isAccessible=false;
          }
        }
     });
  }

  getToolslist(){
    this.token=localStorage.getItem('for_token');
        if(this.token == null || this.token ==""){
            this.router.navigate(['/sign-in']);
        }
    
    this.auth.tradeideaListSrvs(this.baseUrl.baseAppUrl+'trade/tool_listing','',this.token).subscribe((res: any) =>  {
          if(res){
            this.isApiCalled = true;
              if(res.status==1){
                this.toolList = res.data;
              }
                this.imgBaseUrl = this.baseUrl.baseAppUrl+'uploads/tool/';
               }
        });
  }

  downloadFile(tool, type) {
    console.log(this.subscription_status, 'subs status')
    if(this.subscription_status!='active')
    {
      const dialogRef = this.dialog.open(NoAccessComponent, {
      });
      
      dialogRef.afterClosed().subscribe(result => {
        
      });

    }else{
      if(type=='ex4' || type=='ex5')
      {
        var url = this.imgBaseUrl+tool.tool_name+'.'+type;
      }else{
        var url = this.imgBaseUrl+tool.tool_name+'.'+tool.type;
      }
      console.log(url, 'download url');
      //var url = this.imgBaseUrl+tool.tool_name
      window.open(url);
    }
  }


}
