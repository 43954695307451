import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
//import { CometChat } from '@cometchat-pro/chat';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';
import { COMETCHAT_CONSTANTS } from '../../../Commit-Constants';
import { AuthService } from '../../../service/auth.service';
import { AppGlobals } from '../../../app.global';

@Component({
  selector: 'app-dialog-logout',
  templateUrl: './dialog-logout.component.html',
  styleUrls: ['./dialog-logout.component.scss']
})
export class DialogLogoutComponent implements OnInit {
  token;
  constructor(private router: Router, private route: ActivatedRoute, private http: HttpClient, private auth: AuthService, private baseUrl: AppGlobals) { }
showLoader = false;
  ngOnInit() {
    this.token = localStorage.getItem('for_token');
  }

  weblogout(){
    this.showLoader = true;
    let userId = localStorage.getItem('for_userid');
    let cometToken = localStorage.getItem('comet_authtoken');
    
    //CometChat.removeUserListener(userId);
    //CometChat.removeMessageListener(userId);
    this.auth.logout(this.baseUrl.baseAppUrl+'auth/logout',this.token).subscribe((res: any) =>  {
      //console.log(res, 'res of logout');
      let login_type = localStorage.getItem('for_usertype');              
              localStorage.removeItem('for_userid');
              localStorage.removeItem('comet_authtoken');
              localStorage.removeItem('for_token');
              localStorage.removeItem('for_email');
              localStorage.removeItem('for_username');
              localStorage.removeItem('for_userid');
              localStorage.removeItem('activeTab');
              localStorage.removeItem('profile_image');
              localStorage.removeItem('for_tradeid');
              localStorage.removeItem('for_token_profileimg');
              localStorage.removeItem('for_tradeidfrm');
              localStorage.removeItem('sub_id');
              localStorage.removeItem('subscription_status');
              localStorage.removeItem('f_confpassword');
              localStorage.removeItem('f_user');
              localStorage.removeItem('f_password');
              localStorage.removeItem('f_email');
              localStorage.removeItem('sub_updated_at');
              localStorage.removeItem('newMsgArrived');
              localStorage.removeItem('for_usertype');
              //localStorage.clear();
              this.router.navigate(['/sign-in/'+login_type]);
    })
    // CometChat.logout().then( (res: any) => {
    //   if(res.success==true)
    //   {
    //     let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'apikey': COMETCHAT_CONSTANTS.REST_API_KEY, 'appid': COMETCHAT_CONSTANTS.APP_ID, 'Accept': 'application/json' }) };
        
    //     /*let userId = localStorage.getItem('for_userid');
    //     this.http.delete<any>('https://api-us.cometchat.io/v2.0/users/'+userId+'/auth_tokens/'+cometToken , httpOptions).subscribe(
    //       resDel => { */

    //         let login_type = localStorage.getItem('for_usertype');
              
    //           localStorage.removeItem('for_userid');
    //           localStorage.removeItem('comet_authtoken');
    //           localStorage.removeItem('for_token');
    //           localStorage.removeItem('for_email');
    //           localStorage.removeItem('for_username');
    //           localStorage.removeItem('for_userid');
    //           localStorage.removeItem('activeTab');
    //           localStorage.removeItem('profile_image');
    //           localStorage.removeItem('for_tradeid');
    //           localStorage.removeItem('for_token_profileimg');
    //           localStorage.removeItem('for_tradeidfrm');
    //           localStorage.removeItem('sub_id');
    //           localStorage.removeItem('subscription_status');
    //           localStorage.removeItem('f_confpassword');
    //           localStorage.removeItem('f_user');
    //           localStorage.removeItem('f_password');
    //           localStorage.removeItem('f_email');
    //           localStorage.removeItem('sub_updated_at');
    //           localStorage.removeItem('newMsgArrived');
    //           localStorage.removeItem('for_usertype');
    //           //localStorage.clear();
    //           this.router.navigate(['/sign-in/'+login_type]);
    //           this.showLoader = false;
    //       //});
        
    //   }
    //   //console.log(res, 'logout res');
    // //Logout completed successfully
    // },error=>{
    //   this.showLoader = false;
    //     localStorage.removeItem('for_userid');
    //           localStorage.removeItem('comet_authtoken');
    //           localStorage.removeItem('for_token');
    //           localStorage.removeItem('for_email');
    //           localStorage.removeItem('for_username');
    //           localStorage.removeItem('for_userid');
    //           localStorage.removeItem('activeTab');
    //           localStorage.removeItem('profile_image');
    //           localStorage.removeItem('for_tradeid');
    //           localStorage.removeItem('for_token_profileimg');
    //           localStorage.removeItem('for_tradeidfrm');
    //           localStorage.removeItem('sub_id');
    //           localStorage.removeItem('subscription_status');
    //           localStorage.removeItem('f_confpassword');
    //           localStorage.removeItem('f_user');
    //           localStorage.removeItem('f_password');
    //           localStorage.removeItem('f_email');
    //           localStorage.removeItem('sub_updated_at');
    //     this.router.navigate(['/sign-in']);
    //   //Logout failed with exception
    //   //console.log("Logout failed with exception:",{error});
    // })
  }

}
