import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-member-page',
  templateUrl: './member-page.component.html',
  styleUrls: ['./member-page.component.scss']
})
export class MemberPageComponent implements OnInit {
	token='';
  constructor(private router: Router) { }

  ngOnInit() {
  	this.token = localStorage.getItem('access_token');
  }

  moveTo()
  {
  	if(this.token=='')
  	{
  		this.router.navigate(['/sign-up']);
  	}else{
  		this.router.navigate(['/dashboard']);
  	}
  }

}
