import { Component, OnInit, Inject, Optional } from '@angular/core';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../../variable';
import { AuthService } from '../../../service/auth.service';
import { TradeSuccessThankComponent } from './trade-success-thank/trade-success-thank.component';
import { Select2TemplateFunction, Select2OptionData } from 'ng2-select2';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-trade-idea',
  templateUrl: './trade-idea.component.html',
  styleUrls: ['./trade-idea.component.scss'],
  providers: [ AppGlobals]
})
export class TradeIdeaComponent implements OnInit {

  selected = 'option2';
    selected1 = 'option';
    Allpair = [];  
    token ='';
    isSubmitted=false;
    invalidresimage='';
    invalidboth='';
    resImageFile : File = null;
    resImageSrc: any ='';
    invalidfile = false;
    pair_nm='';
    buy_sell='';
    entry_price='';
    sl_price='';
    tp_price='';
    description='';
    trade_image_url ='';
    resimage : any ;
    tradeForm: FormGroup;
    formDisable = false;
    editTradeID:any=0;
    constructor(
      public dialogRef: MatDialogRef<TradeIdeaComponent>,
      public dialog: MatDialog,
      private router: Router,
      private baseUrl: AppGlobals,
      private auth: AuthService,
      private route: ActivatedRoute,
      private formBuilder: FormBuilder,@Optional() @Inject(MAT_DIALOG_DATA) public data: any
    )
    {
      dialogRef.disableClose = true;
      if(data)
      {
        this.editTradeID = data.trade_id;
      }
      
      //console.log(this.editTradeID, 'edit trade id')
    }

    numberOnly = '^[0-9]+(.[0-9]{0,6})?$';
    ngOnInit() {

      this.token=localStorage.getItem('for_token');
        if(this.token == null || this.token ==""){
            this.router.navigate(['/sign-in']);
        }

      this.tradeForm = this.formBuilder.group({
            pair_nm: [''],
            buy_sell: ['', Validators.required],
            entry_price: ['', [Validators.required, Validators.pattern(this.numberOnly)]],
            sl_price: ['', [Validators.required,Validators.pattern(this.numberOnly)]],
            tp_price: ['', [Validators.required,Validators.pattern(this.numberOnly)]],
            description: ['', Validators.required]
        });

      this.auth.tradeideaSrvs(this.baseUrl.baseAppUrl+'trade/pair_listing', [],this.token).subscribe((res : tokendata) => {
      //alert();
      if(res.status == '1'){
        this.Allpair = res.data;
            /*for (let i = 0; i < res.data.length; i++) {
            //alert(res.data[i].category);
            this.Allpair.push({id : res.data[i].id, name : res.data[i].name},);
           }*/
        }
      });

      if(this.editTradeID!=0)
      {
        this.getTradeDetails();
      }

    }

    getTradeDetails()
    {
      const data = {
        user_id : localStorage.getItem('for_userid'),
        signal_id : this.editTradeID
        };
      this.token=localStorage.getItem('for_token');
          if(this.token == null || this.token ==""){
              this.router.navigate(['/sign-in']);
          }
      
      this.auth.tradeideaDetailSrvs(this.baseUrl.baseAppUrl+'trade/signal_detail', data, this.token).subscribe((res: any) =>  {
              if(res){
                this.entry_price=res.data.entry;
                this.sl_price=res.data.sl_value;
                this.tp_price=res.data.tp_value;
                this.buy_sell= res.data.buy_sell;
                this.description=res.data.description;
                this.selected=res.data.pair_id;
                //this.resimage=res.data.image_name;
                if(res.data.image_name!='' && res.data.image_name!=null)
                {
                  this.resImageSrc=this.baseUrl.baseAppUrl+'uploads/trade/'+res.data.user_id+'/r'+res.data.image_name;
                  this.invalidfile=true;
                }else if(res.data.image_url!='' && res.data.image_url!=null)
                {
                  this.trade_image_url=res.data.image_url;
                }
                
               
                //console.log(this.resImageSrc, 'img src');
               }
              else{
               
               }
            });
    }

    setpair(e)
    {
      this.pair_nm = e;
      //console.log(this.pair_nm);
    }

    get tradeFrm() { return this.tradeForm.controls; }

  tradeImage(event){
      const reader = new FileReader();
      const ext = event.target.files[0].name.split('.')[1];
      if (
        ext === 'jpg' ||
        ext === 'JPG' ||
        ext === 'jpeg' ||
        ext === 'JPEG' ||
        ext === 'png' ||
        ext === 'PNG' ||
        ext === 'gif' ||
        ext === 'GIF'
      ) {


        //console.log(event.target.files[0].size/1024/1024 + ' MB');

        if (event.target.files[0].size/1024/1024 > 2) {
         this.invalidresimage = 'The maximum size limit of image is 2 MB.';
          this.invalidfile = false;
          this.resImageSrc = '';
        }
        else{
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
              this.resImageSrc = reader.result;
            };
          this.resImageFile = event.target.files[0];
          this.invalidfile = true;
          this.invalidresimage = '';
          this.invalidboth ='';
          }
        } else {
        this.invalidresimage = 'Invalid file type.';
        //this.resImageFile ='';
        this.invalidfile = false;
        this.resImageSrc = '';
        }
  }

  removeImage(){
    this.invalidfile = false;
    this.resImageSrc = '';
    //this.selectedFile.splice(index, 1);
    this.resimage=null;

  }

    tradeidea_add(){
    	//this.formDisable = true; 
      this.isSubmitted = true;
        if (this.tradeForm.invalid) {
                return;
            }
        if (this.invalidfile==false && this.trade_image_url =="") {
          this.invalidboth = 'Please upload image or enter image URL.';
          return false;
        }
        else if(this.invalidfile==true && this.trade_image_url !=""){
          this.invalidboth = 'You can either upload image or enter image URL.';
          return false;
        }
        else
          this.invalidboth = '';

          this.formDisable = true;
          const fd = new FormData();
          fd.append('user_id', localStorage.getItem('for_userid'));
          fd.append('pair', this.pair_nm);
          fd.append('buy_sell', this.buy_sell);
          fd.append('entry', this.entry_price);
          fd.append('sl_value', this.sl_price);
          fd.append('tp_value', this.tp_price);
          fd.append('description', this.description);
          fd.append('image_url', this.trade_image_url);
          fd.append('trade_id', this.editTradeID);
          if(this.invalidfile)
            fd.append('image', this.resImageFile);


          
          this.auth.tradeideagetSrvs(this.baseUrl.baseAppUrl+'trade/create_trade_idea', fd , this.token).subscribe((res : tokendata) => {
            if(res.status == '1'){
              this.formDisable = false;
              var popup_msg='';
              var isAdd=0;
              if(this.editTradeID==0)
              {
                popup_msg='Thank you for sharing your trade idea with the community. Your trade idea can be edited if required via My Signals in your profile. The edit feature has a three hour time limit before it expires.';
                isAdd=1;
              }else{
                popup_msg='Your trade idea has been successfully updated.';
                isAdd=0;
              }
              document.getElementById("close_dialog_box").click();
               const dialogRef = this.dialog.open(TradeSuccessThankComponent,{ 
                 data:{ popup_msg : popup_msg, isAdd: isAdd}
               });
               
               this.dialogRef.close({data:res.data});
               dialogRef.afterClosed().subscribe(result => {
                 
                this.ngOnInit();
               });
            
             }
            else{
              //this.error_msg = res.message;
             }
          });


      }

}
