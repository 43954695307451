import { Injectable } from '@angular/core';
import { CanActivate, CanLoad, Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { AppGlobals } from '../../app.global';
@Injectable({
  providedIn: 'root'
})
export class RandomGuard implements CanActivate, CanLoad {

  constructor(private authService: AuthService, private router: Router,private baseUrl: AppGlobals) {
    console.log('Random gaurd load');
   }

  canActivate() {
    return this.canLoad();
  }

  canLoad() {
    //console.log('randam gaurd can load',this.authService.isLoggedIn());
    if (!this.authService.isLoggedIn()) {
      this.router.navigate(['/sign-in']);
    }else{

      /************************************************/
      // var loginType = localStorage.getItem('for_usertype');
      // var accessibility = localStorage.getItem('for_accessibility');
      // var path = this.router.url;
      // var trading_academy_arr = ['/sign-in/trading-academy','/trading-academy','/trading-academy2','/strategies','/tc-video-library','/tc-video-series'];

      // console.log('else ',trading_academy_arr.includes(path),path, loginType, accessibility, trading_academy_arr);

      // if(loginType=='trading-academy' && !trading_academy_arr.includes(path)){
      //   this.router.navigate(['/error404']);
      // }
      /************************************************/
      
      
        this.authService.verifytoken(this.baseUrl.baseAppUrl+'user/verifytokenfromgard',localStorage.getItem('for_token')).subscribe((res: any) =>  {
            if(res && res.status==5){
              //console.log('resasssssssssss',res);
              if(res.status==5){
                this.authService.weblogout();                
              }
            }else{
              //console.log(res);
              localStorage.setItem('for_accessibility', res.data.accessibility);
             
              let type = localStorage.getItem('for_usertype');
              if(res.data.accessibility!=''){
                let accessibility = JSON.parse(res.data.accessibility);
                // if( type=='trading-academy' && !accessibility.trading_academy )this.authService.weblogout();
                // if( type=='members' && !accessibility.members )this.authService.weblogout(); 

                var current = new Date();
                const currentTime = current.getTime();
                var memberTime = new Date(res.data.end_date);
                var traderTime = new Date(res.data.trader_end_date);
                localStorage.setItem('member_end',memberTime.getTime().toString());
                localStorage.setItem('trader_end', traderTime.getTime().toString());
                if((currentTime > memberTime.getTime())){
                  localStorage.setItem('pagename', 'My Subscription');
                  localStorage.setItem('for_usertype', 'expire');
                  this.router.navigate(['/subscription-plans']);
                } 
                // else if((accessibility.members && currentTime > memberTime.getTime()) && (accessibility.trading_academy && currentTime < traderTime.getTime())){
                //   localStorage.setItem('pagename', 'Trading Academy');
                //   localStorage.setItem('for_usertype', 'trading_academy');
                // }
                // else if((accessibility.members && currentTime < memberTime.getTime()) && (accessibility.trading_academy && currentTime > traderTime.getTime())){
                //   localStorage.setItem('pagename', 'Members');
                //   localStorage.setItem('for_usertype', 'members');
                // } 

              } 
              
              return true;
            }
         });

      return true;
    }

    
  }
}
