import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import * as io from "socket.io-client";
import { environment } from '../../environments/environment';
import { Observable } from "rxjs/Observable";
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { Tokens } from '../auth/models/tokens';
import { ActivatedRoute, Router } from '@angular/router';
//import { CometChat } from '@cometchat-pro/chat';
//import { COMETCHAT_CONSTANTS } from '../Commit-Constants';
import { AppGlobals } from '../app.global';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

    //private readonly ACCESS_TOKEN = 'ACCESS_TOKEN';
    //private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';
    private loggedUser: string;
    showLoader : any;

    private socket = io(environment.socketApiPath);
    private newMsg = new BehaviorSubject<any>({
      isMsgArrived: 0
    });
    private isStreamSubscribed = new BehaviorSubject<any>({
      streamSubscribed: 0
    });

  //httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', Authorization: 'my-auth-token' }) };
  accessToken = localStorage.getItem('ACCESS_TOKEN');
  httpOptions = {
    headers: new HttpHeaders(
      //{Authorization:this.accessToken}
      { 'Content-Type': 'application/json', Authorization: this.accessToken ? this.accessToken : 'my-auth-token' }
      )
  }; 

  constructor(private httpAuth: HttpClient,private router: Router,private baseUrl: AppGlobals) { }

  /*************************************************/
  // loginSrv(user: { username: string, password: string }): Observable<boolean> {
  //   return this.httpAuth.post<any>(`${environment.API_URL}admin-login`, user)
  //     .pipe(
  //       tap(tokens => this.doLoginUser(user.username, tokens)),
  //       mapTo(true),
  //       catchError(error => {
  //         // console.log('error',error.error.message);
  //         return of(false);
  //       }));
  // }
  isLoggedIn() {
    return !!this.getJwtToken();
  }

  refreshToken() {
    // return this.httpAuth.post<any>(`${environment.API_URL}/refresh`, {
    //   'refreshToken': this.getRefreshToken()
    // }).pipe(tap((tokens: Tokens) => {
    //   this.storeJwtToken(tokens.accessToken);
    // }));
  }
  

  getJwtToken() {
    return localStorage.getItem('for_token');
  }

  private doLoginUser(username: string, tokens: Tokens) {
    //console.log('doLoginUser',username,tokens);
    
    this.loggedUser = username;
    this.storeTokens(tokens);
  }

  private getRefreshToken() {
    return localStorage.getItem('for_token');
  }

  private storeJwtToken(jwt: string) {
    localStorage.setItem('for_token', jwt);
  }

  private storeTokens(tokens: Tokens) {
    localStorage.setItem('for_token', tokens.accessToken);
    //localStorage.setItem(this.REFRESH_TOKEN, tokens.refreshToken);
  }

  verifytoken(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.get(baseurl, options);
  }

  weblogout(){
    this.showLoader = true;
    let userId = localStorage.getItem('for_userid');
    let cometToken = localStorage.getItem('comet_authtoken');
    var loginType = localStorage.getItem('for_usertype');
    
    //CometChat.removeUserListener(userId);
    //CometChat.removeMessageListener(userId);
    this.logout(this.baseUrl.baseAppUrl+'auth/logout',localStorage.getItem('for_token')).subscribe((res: any) =>  {
      //console.log(res, 'res of logout');
      localStorage.removeItem('for_userid');
              localStorage.removeItem('comet_authtoken');
              localStorage.removeItem('for_token');
              localStorage.removeItem('for_email');
              localStorage.removeItem('for_username');
              localStorage.removeItem('for_userid');
              localStorage.removeItem('activeTab');
              localStorage.removeItem('profile_image');
              localStorage.removeItem('for_tradeid');
              localStorage.removeItem('for_token_profileimg');
              localStorage.removeItem('for_tradeidfrm');
              localStorage.removeItem('sub_id');
              localStorage.removeItem('subscription_status');
              localStorage.removeItem('f_confpassword');
              localStorage.removeItem('f_user');
              localStorage.removeItem('f_password');
              localStorage.removeItem('f_email');
              localStorage.removeItem('sub_updated_at');
              localStorage.removeItem('newMsgArrived');
              //localStorage.clear();
              this.router.navigate(['/sign-in/'+loginType]);
              this.showLoader = false;
    })

    // CometChat.logout().then( (res: any) => {
    //   if(res.success==true)
    //   {
    //     let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'apikey': COMETCHAT_CONSTANTS.REST_API_KEY, 'appid': COMETCHAT_CONSTANTS.APP_ID, 'Accept': 'application/json' }) };
        
    //     /*let userId = localStorage.getItem('for_userid');
    //     this.http.delete<any>('https://api-us.cometchat.io/v2.0/users/'+userId+'/auth_tokens/'+cometToken , httpOptions).subscribe(
    //       resDel => { */

              
    //           localStorage.removeItem('for_userid');
    //           localStorage.removeItem('comet_authtoken');
    //           localStorage.removeItem('for_token');
    //           localStorage.removeItem('for_email');
    //           localStorage.removeItem('for_username');
    //           localStorage.removeItem('for_userid');
    //           localStorage.removeItem('activeTab');
    //           localStorage.removeItem('profile_image');
    //           localStorage.removeItem('for_tradeid');
    //           localStorage.removeItem('for_token_profileimg');
    //           localStorage.removeItem('for_tradeidfrm');
    //           localStorage.removeItem('sub_id');
    //           localStorage.removeItem('subscription_status');
    //           localStorage.removeItem('f_confpassword');
    //           localStorage.removeItem('f_user');
    //           localStorage.removeItem('f_password');
    //           localStorage.removeItem('f_email');
    //           localStorage.removeItem('sub_updated_at');
    //           localStorage.removeItem('newMsgArrived');
    //           //localStorage.clear();
    //           this.router.navigate(['/sign-in/'+loginType]);
    //           this.showLoader = false;
    //       //});
        
    //   }
    //   console.log(res, 'logout res');
    // //Logout completed successfully
    // },error=>{
    //   this.showLoader = false;
    //     localStorage.removeItem('for_userid');
    //           localStorage.removeItem('comet_authtoken');
    //           localStorage.removeItem('for_token');
    //           localStorage.removeItem('for_email');
    //           localStorage.removeItem('for_username');
    //           localStorage.removeItem('for_userid');
    //           localStorage.removeItem('activeTab');
    //           localStorage.removeItem('profile_image');
    //           localStorage.removeItem('for_tradeid');
    //           localStorage.removeItem('for_token_profileimg');
    //           localStorage.removeItem('for_tradeidfrm');
    //           localStorage.removeItem('sub_id');
    //           localStorage.removeItem('subscription_status');
    //           localStorage.removeItem('f_confpassword');
    //           localStorage.removeItem('f_user');
    //           localStorage.removeItem('f_password');
    //           localStorage.removeItem('f_email');
    //           localStorage.removeItem('sub_updated_at');
    //     this.router.navigate(['/sign-in/'+loginType]);
    //   //Logout failed with exception
    //   console.log("Logout failed with exception:",{error});
    // })
  }
  

  /************************************************/

  setCometNoti(data: any) {
    this.newMsg.next(data);
  }

  setStreamSubscribed(data: any) {
    this.isStreamSubscribed.next(data);
  }

  getStreamSubscribed() {
    return this.isStreamSubscribed.asObservable();
  }

  getCometNoti() {
    return this.newMsg.asObservable();
  }
  
  loginSrvs(baseurl, data) {
  	return this.httpAuth.post(baseurl, data, this.httpOptions);
  }

  signupSrvs(baseurl, data) {
    //console.log('dddddddd',this.httpOptions);
  	return this.httpAuth.post(baseurl, data, this.httpOptions);
  }

  tradeideaSrvs_old(baseurl, data) {
  	return this.httpAuth.post(baseurl, data, this.httpOptions);
  }

  tradeideaSrvs(baseurl, data, jwtToken) {
	 const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
  	return this.httpAuth.post(baseurl, data, options);
  }

  tradeideaListSrvs(baseurl,isSocket, jwtToken) {
    //console.log(baseurl, 'baseurl of listing');
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    let observable = new Observable<any>(observer=>{
      this.httpAuth.get<any>(baseurl, options).subscribe(res => {
        observer.next(res);
        if(isSocket==0)
        {
          this.socket.on('new_signal', (data) => {
            console.log(data, 'data 123');
            observer.next(data);
          });
        }
      });
      if(isSocket==0) {
        return () => this.socket.disconnect();
      }
    });
    return observable;
  }

  

  
  tradeideagetSrvs(baseurl, data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    let observable = new Observable<any>(observer=>{
      this.httpAuth.post<any>(baseurl, data, options).subscribe(res => {
        observer.next(res);
        /*this.socket.on('new_signal', (data) => {
          console.log(data, 'data 123');
            observer.next(data);
          });*/

      });
    });
    return observable;
   /*const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.post(baseurl, data, options);*/
  }

  tradeideaLikeSrvs(baseurl, data, jwtToken) {
   const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.post(baseurl, data, options);
  }  

  tradeideaDetailSrvs(baseurl, data, jwtToken) {
   const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.post(baseurl, data, options);
  }

  tradeideaCommentSrvs(baseurl, data, jwtToken) {
   const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.post(baseurl, data, options);
  }

  getTradeComments(baseurl, jwtToken) {
   const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.get<any>(baseurl, options);
  }

  tradeAcademylistSrvs(baseurl, jwtToken) {
   const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.get(baseurl, options);
  }

  videoLibrarylistSrvs(baseurl, jwtToken) {
   const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.get(baseurl, options);
  }

  videoView(baseurl, data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.post(baseurl, data, options);
  }

  chapterDetail(baseurl, jwtToken) {
   const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.get(baseurl, options);
  }

  chapterVideoView(baseurl, data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.post(baseurl, data, options);
  }

  checkOldPassExist(baseurl, jwtToken) {
   const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.get(baseurl, options);
  }

  changePassword(baseurl, data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.post(baseurl, data, options);
  }


  userDetail(baseurl, jwtToken) {
   const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.get(baseurl, options);
  }

  updateDetail(baseurl, data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.post(baseurl, data, options);
  }

  listingSrvs(baseurl, jwtToken) {
   const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.get(baseurl, options);
  }

  getdataSrvs(baseurl, data) {
    return this.httpAuth.post(baseurl, data, this.httpOptions);
  }

  commonSrvs(baseurl) {
    return this.httpAuth.get(baseurl, this.httpOptions);
  }
  getSubscriptionDetail(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.get(baseurl, options);
  }
  cancelSubscription(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.get(baseurl, options);
  }
  getInvoiceDetails(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.get(baseurl, options);
  }
  reactivateSubscription(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.get(baseurl, options);
  }
  updateCardDetails(baseurl, data, jwtToken) {
   const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.post(baseurl, data, options);
  }
  paySubscription(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.get(baseurl, options);
  }
  getpriceListing(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.get(baseurl, options);
  }
  getLiveStreams(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    //return this.httpAuth.get(baseurl, options);
    let observable = new Observable<any>(observer=>{
      this.httpAuth.get<any>(baseurl, options).subscribe(res => {
        observer.next(res);
          this.socket.on('active_stream', (data) => {
          //console.log(data, ' active stream data ');
            observer.next(data);
          });
        
      });
      
    });
    return observable;
  }
  getGlobalNews(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.get(baseurl, options);
  }
  addContactEnquiry(baseurl, data) {
   return this.httpAuth.post(baseurl, data);
  }
  getRecordedStreams(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.get(baseurl, options);
  }
  getUnreadNotificationCounts(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    let observable = new Observable<any>(observer=>{
      this.httpAuth.get<any>(baseurl, options).subscribe(res => {
        observer.next(res);
          this.socket.on('new_notification', (data) => {
            observer.next(data);
          });
      });
    });
    return observable;
  }
  notificationList(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    let observable = new Observable<any>(observer=>{
      this.httpAuth.get<any>(baseurl, options).subscribe(res => {
        observer.next(res);
          this.socket.on('new_notification', (data) => {
            observer.next(data);
          });
      });
    });
    return observable;
  }
  readNotification(baseurl, data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
     return this.httpAuth.post(baseurl, data, options);
   }

   logout(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'access_token': jwtToken }) };
     return this.httpAuth.get(baseurl, options);
   }


   //common
   post(baseurl, data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.post(baseurl, data, options);
   }
   get(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.get(baseurl, options);
  }

  getPlan(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.get(baseurl, options);
  }
  planpurchase(baseurl,data, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.post(baseurl,data, options);
  }
  
  getExistPlan(baseurl, jwtToken) {
    const options = { headers: new HttpHeaders({ 'authentication': 'Bearer ' + jwtToken }) };
    return this.httpAuth.get(baseurl, options);
  }



}
