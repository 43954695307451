import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router , ParamMap} from '@angular/router';
import { AppGlobals } from '../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-thankyou',
  templateUrl: './thankyou.component.html',
  styleUrls: ['./thankyou.component.scss'],
  providers: [ AppGlobals]
})
export class ThankyouComponent implements OnInit {

  success_message="";
  userid="";
  action="";
  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute) { }

  ngOnInit() {
  	if(localStorage.getItem('for_token')=="")
  		this.action="login";
  	else
  		this.action="dashboard";
  	this.userid = this.route.snapshot.paramMap.get('userid');
  	const data = {
      user_id : this.userid
      };
    this.auth.getdataSrvs(this.baseUrl.baseAppUrl+'user/verifyemail', data).subscribe((res: any) =>  {
            this.success_message=res.message;
        });
  }

  gotoPage(path){
  	if(path =='login')
  	 	this.router.navigate(['/sign-in']);
  	else
  		this.router.navigate(['/dashboard']);
  }

}
