import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AdminService} from "../services/admin.service";
import { AppGlobals } from '../../app.global';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';

export interface PeriodicElement {
  row_id: number;
  subscription_id: number;
  transaction_date: string;
  planname: string;
  plan_price: string;
  member_name: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];
@Component({
  selector: 'app-transaction-history',
  templateUrl: './transaction-history.component.html',
  styleUrls: ['./transaction-history.component.scss']
})
export class TransactionHistoryComponent implements OnInit {
  all_records;
	displayedColumns: string[] = ['record_id','transaction_date', 'planname','plan_price','member_name'];
  dataSource : any;
  check_sess;
  user_id;
  error_msg='';
  success_msg='';
  capability='';
  userType='';
  readpermission=false;
  @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;

  constructor(
    private baseUrl: AppGlobals,
  	private router: Router,
    private dialog: MatDialog,
    private adminService: AdminService,
  ) { }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  ngOnInit() {
    this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/admin/login-dashboard']);
    }
    this.capability=localStorage.getItem('capability');
    this.userType=localStorage.getItem('user_type');
    if(this.userType!='admin' && this.capability.split(',').indexOf('tranction_read')>-1){
      this.readpermission = true;
    }
    if(this.userType=='admin'){
      this.readpermission = true;
    }
    if(this.readpermission==false){
      this.router.navigate(['/admin/my-profile']);
    }

    this.success_msg=localStorage.getItem('succ_user');
  	 setTimeout (() => {
         this.success_msg="";
         localStorage.setItem('succ_user','');
        }, 2000);
    this.transactionlist();
  }

  transactionlist(){ 
  	this.all_records=[];
  	this.adminService.transactions(this.baseUrl.baseAppUrl+'admin/transactions', this.check_sess).subscribe((res: any) =>  {
      console.log(res);
      if(res.status == '1'){
        
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
                  
          this.all_records.push({record_id: j, transaction_id: res.data[i].id, planname: res.data[i].plan_name, plan_price : res.data[i].amount,transaction_date : res.data[i].created_at, member_name: res.data[i].first_name+' '+res.data[i].last_name},);
          ['record_id','planname', 'plan_price','transaction_date', 'member_name'];
          j++;
        }
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
        this.dataSource.paginator = this.paginator;
        console.log(this.all_records);
      }
      else{
        this.error_msg = res.message;
      }

    });
  }

}