import { Component, OnInit, HostListener, Input, ChangeDetectorRef, OnChanges, OnDestroy, Output, EventEmitter, SimpleChanges, ElementRef, AfterViewInit, AfterViewChecked, ViewChild } from '@angular/core';


import { PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../app.global';
import { COMETCHAT_CONSTANTS } from '../../Commit-Constants';
import { MessageListManager } from './cometchat-msg-manager';
import { CometChat } from '@cometchat-pro/chat';
import { MESSAGES_COMPOSER_ACTIONS } from '../string_constants';
import { Location, LocationStrategy, PathLocationStrategy, PopStateEvent } from '@angular/common';
import { CONVERSATION_LIST_ACTIONS, CONVERSATIONS_SCREEN_ACTIONS, USER_LIST_ACTIONS } from '../string_constants';
import { Helper } from '../helpers/helper';
import { CometChatManager } from '../cometchat-manager';
import { ConversationListManager } from './cometchat-manager';
import {
  MEDIA_MESSAGES_COMPOSER_ACTIONS,
  
  CONVERSATION_SCREEN_HEADER_ACTIONS,
  
} from '../string_constants';

import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-personal-chat',
  templateUrl: './personal-chat.component.html',
  styleUrls: ['./personal-chat.component.scss'],
  providers: [ AppGlobals]
})

export class PersonalChatComponent implements OnChanges, AfterViewInit, AfterViewChecked, OnDestroy {

	ngOnDestroy(): void {
	    /*this.callback = null;
	    if (this.conversationManager) {
	      this.conversationManager.removeListeners();
	      this.messagesManager = null;
	    }*/
  	}
  	
  public config: PerfectScrollbarConfigInterface = {};
  offset: string = "0";
  limit:string = "5";
  token;
  comet_authtoken;
  comet_conversation = [];
  messages = [];
  senderName;
  senderAvatar:string = '';
  typingStarted: any;
  userId;
  msgText:string = '';
  emptyMsgError:string;
  msgOffset = 0;
  msgLimit = 10;
  @Input() selectedConversation?: CometChat.Conversation | any;
	messagesManager: MessageListManager;
  @Input() selectedUser?;
  @Input() selectedGroup?;
  @Input() refreshMessageList?;
  updatedMessagesList: any = '';
  scrollTrigger = false;
  currentScrollPossition = 0;

  selConvers: any;
  isConversation=false;
searchKey;
showEmojiKeyboard = false;
isChatDisable = false;
activeId;

  /**
   * * Input  of conversations list component
   * * an updated list of conversation can be provided as an input. component can verify the provided list and update the dom if needed.
   */
  @Input() updatedConversations?: CometChat.Conversation[] | any;

  /**
   * * List of conversations either provided as an attribute (input) or can be set using fetching the list.
   */
  @Input() conversations?: CometChat.Conversation[] | any = [];


  @Input() actionRequired: { action: string, payload?: object | any };
  /**
   * * Event emitter function can triggered on ckick of the conversation list item( conversation ).
   */
  @Output() actionPerformed = new EventEmitter<{ action: string, payload?: object }>();

 @Input() user?;
 @Input() group?;
  

 JSONParser = JSON; // * JSON object to be used in `HTML`.

  conversationManager: ConversationListManager; // * contains cometchat related

@ViewChild('thisRed', { read: ElementRef, static: true }) tref: ElementRef;
@ViewChild('focus', {static: true}) focus;


  constructor(private router: Router,
  private baseUrl: AppGlobals, private route: ActivatedRoute, private http: HttpClient,private cdRef: ChangeDetectorRef,public location: Location, private auth: AuthService) { }

 


  ngOnInit() {
    this.focus.nativeElement.focus(); 
    //console.log(this.location.path(), 'path');
    localStorage.setItem('activeTab', 'personal-chat');
  	//console.log(this.selectedConversation, 'this.selected');
  	this.token=localStorage.getItem('for_token');
  	this.comet_authtoken=localStorage.getItem('comet_authtoken');
  	this.userId = localStorage.getItem('for_userid');
    if(this.token == null || this.token ==""){
        this.router.navigate(['/sign-in']);
    }
    /**********/
    let loginType = localStorage.getItem('for_usertype');
    if(loginType!=='members'){ //'member'
      this.router.navigate(['/error404']);
    }
    /**********/

    if(this.comet_authtoken==null){
      setTimeout(() => {this.ngOnInit();}, 2000);
    }
    console.log('this.comet_authtoken',this.comet_authtoken);

    /*setTimeout(() => {
        this.getCometUsers();
  }, 5000);*/
  	this.getCometUsers();
  	this.conversationManager = new ConversationListManager();
	  this.init();

  }

  ngOnChanges(changes: SimpleChanges) {
  	//console.log('called on changes');
  }

  private scrollToBottom = () => {
    this.cdRef.detectChanges();
    this.tref.nativeElement.scrollTop = this.tref.nativeElement.scrollHeight + this.tref.nativeElement.offsetHeight;
  }

   /**
   * Determines white option is triggered.
   * @param event default click event triggered.
   * @param action which option is clicked.
   */
  onActionGenerated(event) {
  	this.showEmojiKeyboard = !this.showEmojiKeyboard;
  }

  clickOnEmoji(event) {
  	//console.log(event.emoji.native, 'event');
  	this.msgText += event.emoji.native;
    document.getElementById('cometchat-message-composer').focus();
    document.getElementById('cometchat-message-composer').innerText = document.getElementById('cometchat-message-composer').innerText + event.emoji.native
    let tag = document.getElementById('cometchat-message-composer');

    var setpos = document.createRange();

    // Creates object for selection 
    var set = window.getSelection();

    // Set start position of range 
    setpos.setStart(tag.childNodes[0], document.getElementById('cometchat-message-composer').innerText.length);

    // Collapse range within its boundary points 
    // Returns boolean 
    setpos.collapse(true);

    // Remove all ranges set 
    set.removeAllRanges();

    // Add range with respect to range object. 
    set.addRange(setpos);
    document.getElementById('cometchat-message-composer').focus();
  }

  

  init() {
    this.conversationManager.isLoggedIn(this.isChatReady);
    this.conversationManager.attachListener((message, isReceipt) => this.callback(message, isReceipt));
    this.conversationManager.attachUserListener((event) => this.userListernerCallback(event));
	}

  /**
   * Callback  of conversations list component
   */
  callback(message, isReceipt, makeFirst = true, isClick = false) {
    //console.log(message.sender.uid,'callback called', isReceipt, 'makeFirst', message);
    //console.log(this.activeId, 'active id');
    //console.log(this.comet_conversation,'comet_conversation');
    if(!isClick)
    {
      for (var i = 0; i <  this.comet_conversation.length; i++) {
        if(this.comet_conversation[i].conversationWith.uid==message.sender.uid && message.category=="message")
        {
          //console.log(this.comet_conversation[i].unreadMessageCount, 'this.comet_conversation[i].unreadMessageCount');
          this.comet_conversation[i].unreadMessageCount = this.comet_conversation[i].unreadMessageCount+1;
          this.comet_conversation[i].conversationWith.status = message.sender.status; 
          this.comet_conversation[i].lastMessage.data.text = message.text;
        }
      }
    }
    
    
    if (!isReceipt) {
      CometChat.CometChatHelper.getConversationFromMessage(message).then((updatedConversation: CometChat.Conversation) => {
      	
        //updatedConversation = this.setProfileImage(updatedConversation);
        if (this.conversations.length > 0) {
          this.conversations.map((conversation: CometChat.Conversation, i: number) => {
          	
            if (conversation.getConversationId() === updatedConversation.getConversationId()) {
              updatedConversation.setConversationWith(conversation.getConversationWith());

              
              /*if (this.selectedConversation && this.selectedConversation.getConversationId() === updatedConversation.getConversationId()) {
                updatedConversation.setUnreadMessageCount(0);

              } else {
                // tslint:disable-next-line: radix
                updatedConversation.setUnreadMessageCount(parseInt(conversation.getUnreadMessageCount()) + 1);
              }*/
              if (makeFirst) {
                this.conversations.splice(i, 1);
                this.conversations = [updatedConversation, ...this.conversations];
                this.comet_conversation.splice(i, 1);
                this.comet_conversation = [updatedConversation, ...this.comet_conversation];
                this.messages.push(updatedConversation.getLastMessage());
                //console.log(this.location.path(), 'path1');
                //console.log('markAsRead1')
                if(this.location.path()=='/personal-chat')
                {
                  CometChat.markAsRead(this.messages[this.messages.length-1].id, message.sender.uid, 'user');
                }
              	//console.log(updatedConversation, 'updatedConversation1')
              } else {
                //console.log(updatedConversation,'updatedConversation2');
                this.conversations[i] = updatedConversation;
                this.messages[i] = updatedConversation.getLastMessage();
                
              }
              
            }
          });
        } else {
          //console.log('updtaed conversation callback called');
          this.conversations = [updatedConversation];
          //this.comet_conversation = [updatedConversation, ...this.comet_conversation];
        }
        this.cdRef.detectChanges();

      });
    } else {
//console.log('else callback called');
    }
  }
  userListernerCallback(event) {
  		switch (event.action) {
	      case USER_LIST_ACTIONS.USER_STATUS_CHANGED.ONLINE:

	        this.conversations.map((conversation, i) => {

	          if (conversation.conversationType === 'user') {

	            let user = conversation.conversationWith;
	            if (user.uid.toString() === event.payload.onlineUser.uid.toString()) {
	              conversation.setConversationWith(event.payload.onlineUser);
	              //conversation = this.setProfileImage(conversation);
	              this.conversations[i] = conversation;
	            }
	          }
	        });
	        break;
	      case USER_LIST_ACTIONS.USER_STATUS_CHANGED.OFFLINE:

	        this.conversations.map((conversation, i) => {

	          if (conversation.conversationType === 'user') {
	            let user = conversation.conversationWith;
	            if (user.uid.toString() === event.payload.offlineUser.uid.toString()) {
	              conversation.setConversationWith(event.payload.offlineUser);
	              //conversation = this.setProfileImage(conversation);
	              this.conversations[i] = conversation;
	            }
	          }
	        });
	        break;
	    }
	
    this.cdRef.detectChanges();
  }


  /**
   * Hosts listener
   * @param elem HTML elemet on which scroll action is perfomed.
   * Function will determine if onScroll event scroll top is reached to bottom
   * ,and if yes will try to load next set of conversations if available.
   */
  @HostListener('scroll', ['$event.target'])
  onScroll(elem) {
  	//console.log(elem, 'elem scroll');
    if ((elem.target.offsetHeight + elem.target.scrollTop) >= elem.target.scrollHeight) {
      this.conversationManager.fetchNext().then((conversations: CometChat.Conversation[]) => {
        conversations.map((conversation, i) => {
          //conversation = this.setProfileImage(conversation);
          conversations[i] = conversation;
          if (conversation.getConversationType() === 'user') {
            if (this.selectedUser)
              if ((conversation.getConversationWith() as CometChat.User).getUid() === (this.selectedUser as CometChat.User).getUid()) {
                this.selectedConversation = conversation;
              }
          }
          if (conversation.getConversationType() === 'group') {
            if (this.selectedGroup)
              if ((conversation.getConversationWith() as CometChat.Group).getGuid() === (this.selectedGroup as CometChat.Group).getGuid()) {
                this.selectedConversation = conversation;
              }
          }
        });
        this.conversations = [...this.conversations, ...conversations];
      }, error => {
        // TODO error in fetching contact list
      });
    }
  }

  ngAfterViewInit() {
    this.tref.nativeElement.scrollTop = 0;
    this.tref.nativeElement.onChange = () => {
    };
  }
  ngAfterViewChecked() {
    if (!this.scrollTrigger) {
      this.tref.nativeElement.scrollTop = this.tref.nativeElement.scrollHeight - this.currentScrollPossition;
    }
  }


getCometUsers(){
  let conversationRequest = new CometChat.ConversationsRequestBuilder()
  .setConversationType("user")
  .setLimit(50)
  .build();
  conversationRequest.fetchNext().then((conversationList:any) => {
  
        if(conversationList.length>0)
        {
          this.comet_conversation = conversationList;
          this.selConvers = conversationList[0];
          //console.log(this.selConvers, 'se');
          this.messagesManager = new MessageListManager(this.selConvers.conversationWith.uid, 'user');
          this.getMessages(this.selConvers,0);
          this.cdRef.detectChanges();
          //console.log(this.selConvers, 'selConvers');
        }else{
          var users = new CometChat.UsersRequestBuilder().setLimit(30).build();
            users.fetchNext().then(
              (userList:any) => { 
                if(userList.length>0)
                {
                  let searchUsers = userList;
                  let searchData = <any>{};
                  let searchResult = [];
                  let count = 0;
                  for (var i = 0; i <  userList.length; i++) {
                    //searchData[i].conversationWith = searchUsers[i];
                    searchUsers[i].conversationWith = {
                      "name": searchUsers[i].name,
                      "uid": searchUsers[i].uid,
                      "status": searchUsers[i].status,
                      "avatar" : searchUsers[i].avatar
                    }
                    searchUsers[i].conversationType = "user";
                    searchResult.push(searchUsers[i]);
                    count++;
                    //console.log(searchData, 'cwewe', searchUsers.length);
                    if(count==searchUsers.length)
                    {
                      this.comet_conversation = searchResult;
                      //console.log(this.comet_conversation, 'v');
                    }

                  }
                  this.selConvers = this.comet_conversation[0];
                  this.messagesManager = new MessageListManager(this.selConvers.uid, 'user');
                  this.getMessages(this.selConvers,0);
                  this.cdRef.detectChanges();
                }
                //console.log(userList, 'userList');
          })
        }
        //console.log("Conversations list received:", conversationList);
      },
      error => {
        //console.log("Conversations list fetching failed with error:", error);
      }
    );
}
  

  getCometUsersO(){
  	  let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'apikey': COMETCHAT_CONSTANTS.REST_API_KEY, 'appid': COMETCHAT_CONSTANTS.APP_ID, 'authtoken': this.comet_authtoken }) };

	    let params = new HttpParams()
	                .set('offset', this.offset)
	                .set('limit', this.limit);
	    let userId = localStorage.getItem('for_userid');
      //https://apiclient-us.cometchat.io/v2.0/users?per_page=30&page=1
	   this.http.get<any>("https://apiclient-us.cometchat.io/v3.0/conversations?per_page=30&page=1&conversationType=user&", httpOptions).subscribe(
        res => {
          if(res.data.length>0)
          {
          	this.isConversation = true;
          	this.comet_conversation = res.data;
            //console.log(this.comet_conversation, 'cometchat');
          	this.selConvers = res.data[0];
          	//console.log(this.selConvers, 'se');
          	this.messagesManager = new MessageListManager(this.selConvers.conversationWith.uid, 'user');
          	this.getMessages(this.selConvers,0);
          	this.cdRef.detectChanges();
          }
          else
          {
            this.http.get<any>('https://apiclient-us.cometchat.io/v3.0/users?per_page=30&page=1&', httpOptions).subscribe(
	        res => {
	        	if(res.data.length>0)
	        	{
	        		let searchUsers = res.data;
	          	let searchData = <any>{};
	          	let searchResult = [];
	          	let count = 0;
	          	for (var i = 0; i <  searchUsers.length; i++) {
	          		//searchData[i].conversationWith = searchUsers[i];
	          		searchUsers[i].conversationWith = {
	          			"name": searchUsers[i].name,
	          			"uid": searchUsers[i].uid,
	          			"status": searchUsers[i].status
	          		}
	          		searchUsers[i].conversationType = "user";
	          		searchResult.push(searchUsers[i]);
	          		count++;
	          		//console.log(searchData, 'cwewe', searchUsers.length);
	          		if(count==searchUsers.length)
	          		{
	          			this.comet_conversation = searchResult;
	          			//console.log(this.comet_conversation, 'v');
	          		}

	          	}

	        		
	        		this.selConvers = this.comet_conversation[0];
	        		this.messagesManager = new MessageListManager(this.selConvers.uid, 'user');
		          	this.getMessages(this.selConvers,0);
		          	this.cdRef.detectChanges();
	        	}
	         })
          }
        }, 
        err => {
          
        }
      )
	}

	searchUsersO($event){
		let searchkey = $event.target.value;
		let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'apikey': COMETCHAT_CONSTANTS.REST_API_KEY, 'appid': COMETCHAT_CONSTANTS.APP_ID, 'authtoken': this.comet_authtoken }) };

	    let params = new HttpParams()
	                .set('offset', this.offset)
	                .set('limit', this.limit);
	    let userId = localStorage.getItem('for_userid');
		if(this.searchKey.length>=3)
		{
			

		    //https://apiclient-us.cometchat.io/v2.0/users?per_page=30&page=1
		   this.http.get<any>('https://apiclient-us.cometchat.io/v3.0/users?per_page=30&searchKey='+searchkey+'&page=1&', httpOptions).subscribe(
	        res => {
	          	//console.log(res, 'search res');
	          if(res.data.length>0)
	          {
	          	let searchUsers = res.data;
	          	let searchData = <any>{};
	          	let searchResult = [];
	          	let count = 0;
	          	for (var i = 0; i <  searchUsers.length; i++) {
	          		//searchData[i].conversationWith = searchUsers[i];
	          		searchUsers[i].conversationWith = {
	          			"name": searchUsers[i].name,
	          			"uid": searchUsers[i].uid,
	          			"status": searchUsers[i].status,
                  "avatar":searchUsers[i].avatar
	          		}
	          		searchUsers[i].conversationType = "user";
	          		searchResult.push(searchUsers[i]);
	          		count++;
	          		
	          		if(count==searchUsers.length)
	          		{
	          			this.comet_conversation = searchResult;
	          		}

	          	}

	        		
	        		this.selConvers = this.comet_conversation[0];
	        		this.messagesManager = new MessageListManager(this.selConvers.uid, 'user');
		          	this.getMessages(this.selConvers,0);
		          	this.cdRef.detectChanges();
	          }
	          else
	          {
	            this.comet_conversation = [];
	          }
	        }, 
	        err => {
	          
	        }
	      )
		}else{
      if(this.searchKey.length==0)
      {
        this.getCometUsers();
      }
		}
	}

  searchUsers($event){
    let searchkey = $event.target.value;
    let userId = localStorage.getItem('for_userid');
    if(this.searchKey.length>=3)
    {
      var users = new CometChat.UsersRequestBuilder().setLimit(30).setSearchKeyword(this.searchKey.trim()).build();
        users.fetchNext().then(
          (userList:any) => { 
            if(userList.length>0)
            {
              let searchUsers = userList;
              let searchData = <any>{};
              let searchResult = [];
              let count = 0;
              for (var i = 0; i <  searchUsers.length; i++) {
                //searchData[i].conversationWith = searchUsers[i];
                searchUsers[i].conversationWith = {
                  "name": searchUsers[i].name,
                  "uid": searchUsers[i].uid,
                  "status": searchUsers[i].status,
                  "avatar":searchUsers[i].avatar
                }
                searchUsers[i].conversationType = "user";
                searchResult.push(searchUsers[i]);
                count++;
                
                if(count==searchUsers.length)
                {
                  this.comet_conversation = searchResult;
                }

              }

              
              this.selConvers = this.comet_conversation[0];
              this.messagesManager = new MessageListManager(this.selConvers.uid, 'user');
                this.getMessages(this.selConvers,0);
                this.cdRef.detectChanges();
            }
            else
            {
              this.comet_conversation = [];
            }
          })
    }else{
      if(this.searchKey.length==0)
      {
        this.getCometUsers();
      }
    }
  }

	getMessages(conversation, index){
		//console.log(conversation, 'co');
    conversation.unreadMessageCount = 0;
    this.activeId = conversation.conversationWith.uid;

		//this.messagesManager = new MessageListManager(conversation.conversationWith.uid, 'user');
		this.selectedConversation = conversation; // setting the selected conversation.
	    if (conversation.lastMessage) {
	      this.callback(conversation.lastMessage, false, false, true);
	    }
	    if (conversation.conversationType === 'group') {

	      this.actionPerformed.emit(
	        {
	          action: CONVERSATION_LIST_ACTIONS.CONVERSATION_ITEM_SELECTED,
	          payload: {
	            group: conversation.conversationWith
	          }
	        });
	    }

	    if (conversation.conversationType === 'user') {

        
	      this.actionPerformed.emit(
	        {
	          action: CONVERSATION_LIST_ACTIONS.CONVERSATION_ITEM_SELECTED,
	          payload: {
	            user: conversation.conversationWith
	          }
	        });
	    }
		
		this.senderName = conversation.conversationWith.name;
		if(conversation.conversationWith.avatar)
		{
			this.senderAvatar = conversation.conversationWith.avatar;
		}else{
			this.senderAvatar = '';
		}
		this.conversations = conversation;
		this.user = conversation.conversationWith;
		let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'apikey': COMETCHAT_CONSTANTS.REST_API_KEY, 'appid': COMETCHAT_CONSTANTS.APP_ID, 'authtoken': this.comet_authtoken }) };

	    let params = new HttpParams()
	                .set('offset', this.offset)
	                .set('limit', this.limit);
	    let userId = localStorage.getItem('for_userid');
		this.http.get<any>('https://apiclient-us.cometchat.io/v3.0/users/'+conversation.conversationWith.uid+'/messages?per_page='+this.msgLimit+'&uid='+conversation.conversationWith.uid+'&affix=prepend&', httpOptions).subscribe(
        res => {
          if(res.data)
          {
          	this.messages = res.data;
            this.scrollToBottom();
          	//this.msgOffset = 
            //console.log(this.messages[this.messages.length-1].id, 'messages', conversation.conversationWith.uid);
            console.log('markAsRead2',this.messages[this.messages.length-1].id, conversation.conversationWith.uid, 'user')
            this.auth.setCometNoti({
              isMsgArrived: 2 
            });
            //CometChat.markAsRead(this.messages[this.messages.length-1].id, conversation.conversationWith.uid, 'user');
          }
          else
          {
            
          }
        }, 
        err => {
          
        }
      )
	}

  getMessagesN(conversation, index){
    conversation.unreadMessageCount = 0;
    
    //console.log(conversation, 'co');

    //this.messagesManager = new MessageListManager(conversation.conversationWith.uid, 'user');
    this.selectedConversation = conversation; // setting the selected conversation.
      if (conversation.lastMessage) {
        this.callback(conversation.lastMessage, false, false);
      }
      if (conversation.conversationType === 'group') {

        this.actionPerformed.emit(
          {
            action: CONVERSATION_LIST_ACTIONS.CONVERSATION_ITEM_SELECTED,
            payload: {
              group: conversation.conversationWith
            }
          });
      }

      if (conversation.conversationType === 'user') {

        
        this.actionPerformed.emit(
          {
            action: CONVERSATION_LIST_ACTIONS.CONVERSATION_ITEM_SELECTED,
            payload: {
              user: conversation.conversationWith
            }
          });
      }
    
    this.senderName = conversation.conversationWith.name;
    if(conversation.conversationWith.avatar)
    {
      this.senderAvatar = conversation.conversationWith.avatar;
    }else{
      this.senderAvatar = '';
    }
    this.conversations = conversation;
    this.user = conversation.conversationWith;
    let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'apikey': COMETCHAT_CONSTANTS.REST_API_KEY, 'appid': COMETCHAT_CONSTANTS.APP_ID, 'authtoken': this.comet_authtoken }) };

      let params = new HttpParams()
                  .set('offset', this.offset)
                  .set('limit', this.limit);
      let userId = localStorage.getItem('for_userid');
      /*var messagesRequest = new CometChat.MessagesRequestBuilder()
      .setLimit(10)
      .setGUID(COMETCHAT_CONSTANTS.GUID)
      .build();*/

    this.messagesManager.fetchPrevious().then(
      (messages:any) => {
        this.messages = messages;
        this.scrollToBottom();
            //this.msgOffset = 
            //console.log(this.messages[this.messages.length-1].id, 'messages', conversation.conversationWith.uid);
            console.log('markAsRead3')
            this.auth.setCometNoti({
              isMsgArrived: 2 
            });
            CometChat.markAsRead(this.messages[this.messages.length-1].id, conversation.conversationWith.uid, 'user');
            conversation.setUnreadMessageCount(0);
            this.cdRef.detectChanges();
      })
    
  }

	onScrollMsg(elem) {
    this.scrollTrigger = true;
    if (elem.target.scrollTop === 0 && this.messages.length > 0 && !this.scrollTrigger) {
      this.currentScrollPossition = this.tref.nativeElement.scrollHeight;
		  //console.log('called', 'as');
      this.messagesManager.fetchPrevious().then((messages: CometChat.BaseMessage[]) => {
        this.messages = [...messages, ...this.messages];
        this.scrollTrigger = false;
      });
      /*this.http.get<any>('https://apiclient-us.cometchat.io/v2.0/users/'+conversation.conversationWith.uid+'/messages?per_page='+this.msgLimit+'&uid='+conversation.conversationWith.uid+'&affix=prepend&', httpOptions).subscribe(
        res => {

        })*/
    }
  }

	/**
   * Determines whether message sent tirggerd by media message composer.
   * @param event;
   */
  onMediaMessageSent(event) {
    //console.log(event, 'event');
  	switch (event.action) {
      case MEDIA_MESSAGES_COMPOSER_ACTIONS.MEDIA_MESSAGE_SENT: {

        const message = event.payload;
        this.updatedMessagesList = JSON.stringify([message]);
        //this.showOptionMenu = false;
        this.cdRef.detectChanges();

        this.actionPerformed.emit(
          {
            action: CONVERSATIONS_SCREEN_ACTIONS.MEDIA_MESSAGES_COMPOSER_ACTIONS.MEDIA_MESSAGE_SENT,
            payload: { message: event.payload }
          });
        break;
      }
      case MEDIA_MESSAGES_COMPOSER_ACTIONS.ERROR_IN_MESSAGE_SENDING: {
        // TODO show error in if the message sending failes
        break;
      }
      case MEDIA_MESSAGES_COMPOSER_ACTIONS.WHITEBOARD_MESSAGE_SENT: {
        const message = event.payload;
        this.updatedMessagesList = JSON.stringify([message]);
        this.cdRef.detectChanges();
        this.actionPerformed.emit(
          {
            action: CONVERSATIONS_SCREEN_ACTIONS.MEDIA_MESSAGES_COMPOSER_ACTIONS.MEDIA_MESSAGE_SENT,
            payload: { message: event.payload }
          });

        break;
      }
      case MEDIA_MESSAGES_COMPOSER_ACTIONS.WRITEBOARD_MESSAGE_SENT: {
        const message = event.payload;
        this.updatedMessagesList = JSON.stringify([message]);
        this.cdRef.detectChanges();
        this.actionPerformed.emit(
          {
            action: CONVERSATIONS_SCREEN_ACTIONS.MEDIA_MESSAGES_COMPOSER_ACTIONS.MEDIA_MESSAGE_SENT,
            payload: { message: event.payload }
          });

        break;
      }
      case MEDIA_MESSAGES_COMPOSER_ACTIONS.BROADCAST_MESSAGE_SENT: {
        const message = event.payload;
        this.updatedMessagesList = JSON.stringify([message]);
        this.cdRef.detectChanges();
        this.actionPerformed.emit(
          {
            action: CONVERSATIONS_SCREEN_ACTIONS.MEDIA_MESSAGES_COMPOSER_ACTIONS.MEDIA_MESSAGE_SENT,
            payload: { message: event.payload }
          });

        break;
      }
      case MEDIA_MESSAGES_COMPOSER_ACTIONS.BROADCAST_DEMO_MESSAGE_SENT: {
        const message = event.payload;
        this.updatedMessagesList = JSON.stringify([message]);
        this.cdRef.detectChanges();
        this.actionPerformed.emit(
          {
            action: CONVERSATIONS_SCREEN_ACTIONS.MEDIA_MESSAGES_COMPOSER_ACTIONS.MEDIA_MESSAGE_SENT,
            payload: { message: event.payload }
          });

        break;
      }
      case MEDIA_MESSAGES_COMPOSER_ACTIONS.MESSAGE_SENT: {
        const message = event.payload;
        this.updatedMessagesList = JSON.stringify([message]);
        this.cdRef.detectChanges();
        this.actionPerformed.emit(
          {
            action: CONVERSATIONS_SCREEN_ACTIONS.MEDIA_MESSAGES_COMPOSER_ACTIONS.MESSAGE_SENT,
            payload: { message: event.payload }
          });

        break;
      }

    }
  }

	onTypingStarted($event: any) {
    
    if (this.typingStarted) {

    } else {
      let receiverID;
      let receiverType;
      if (this.user) {
        receiverID = this.user.uid;
        receiverType = CometChat.RECEIVER_TYPE.USER;
      } else {
        receiverID = this.group.guid;
        receiverType = CometChat.RECEIVER_TYPE.GROUP;
      }

      let typingNotification = new CometChat.TypingIndicator(
        receiverID,
        receiverType
      );
      CometChat.startTyping(typingNotification);

      this.typingStarted = setTimeout(() => {
        clearTimeout(this.typingStarted);
        this.typingStarted = undefined;


      }, 5000);
    }

  }

	/**
   * Onchange detect of message composer component
   * * will detecte the any change in the CometChat massage composer input box and take acction accordigly.
   */
  onchangeDetect = (event, isBtnClick) => {
  	if ((event.keyCode === 13 && !event.shiftKey && (this.msgText!=undefined)) || (isBtnClick)) {
      event.preventDefault();
      this.showEmojiKeyboard = false;
      event.target.disabled = true;
      let receiverID;
      let receiverType;
      
      //this.user = this.conversations.getConversationWith()
      if (this.user) {
        receiverID = this.user.uid;
        receiverType = CometChat.RECEIVER_TYPE.USER;
      } else {
        receiverID = this.group.guid;
        receiverType = CometChat.RECEIVER_TYPE.GROUP;
      }
      let typingNotification = new CometChat.TypingIndicator(
        receiverID,
        receiverType
      );
      CometChat.endTyping(typingNotification);
      this.isChatDisable = true;
      /*this.typingStarted = setTimeout(() => {
        clearTimeout(this.typingStarted);
        this.typingStarted = undefined;


      }, 5000);*/
      const messageText = this.msgText.trim();
      const textMessage = new CometChat.TextMessage(
        receiverID,
        messageText,
        receiverType,
      );
      event.srcElement.innerText = '';
      this.cdRef.detectChanges();
      CometChat.sendMessage(textMessage).then(
        (message: CometChat.BaseMessage) => {
          this.actionPerformed.emit({ action: MESSAGES_COMPOSER_ACTIONS.MESSAGE_SENT, payload: message });
          this.msgText = '';
          this.isChatDisable = false;

          //console.log(message, 'msg');
          this.messages.push(message);
          for (var i = 0; i <  this.comet_conversation.length; i++) {
            if(this.comet_conversation[i].conversationWith.uid==this.user.uid)
            {
              this.comet_conversation[i].lastMessage.data.text = messageText;
              //console.log(this.comet_conversation[i],'conversationWith');
            }
          }
          const currentscrollHeight = this.tref.nativeElement.scrollHeight;
	      /*if (this.tref.nativeElement.scrollTop + this.tref.nativeElement.offsetHeight + 10 >= currentscrollHeight) {
	      }*/
	        this.scrollToBottom();
          setTimeout(()=>{
            this.focus.nativeElement.focus();
          },0);
        //this.isChatDisable = false;
	      event.target.disabled = false;
          // this.fileOptionsExpanded = !this.fileOptionsExpanded;
        },
        (error) => {
          this.actionPerformed.emit({ action: MESSAGES_COMPOSER_ACTIONS.ERROR_IN_MESSAGE_SENDING, payload: error });
          // this.fileOptionsExpanded = !this.fileOptionsExpanded;
        }
      );
      return false;
    } else {
      this.onTypingStarted(event);
      this.isChatDisable = false;
    }

  }	

  isChatReady = (user?: CometChat.User, error?) => {
    if (user) {

      this.conversationManager.fetchNext().then((conversations: CometChat.Conversation[]) => {

        if (conversations.length === 0) {
          //this.decoratorMessage = "Please initiate conversation with a user or group"
        } else {

        }
        conversations.map((conversation, i) => {
          //conversation = this.setProfileImage(conversation);
          conversations[i] = conversation;
          if (conversation.getConversationType() === 'user') {
            if (this.selectedUser)
              if ((conversation.getConversationWith() as CometChat.User).getUid() === (this.selectedUser as CometChat.User).getUid()) {
                this.selectedConversation = conversation;
              }
          }
          if (conversation.getConversationType() === 'group') {
            if (this.selectedGroup)
              if ((conversation.getConversationWith() as CometChat.Group).getGuid() === (this.selectedGroup as CometChat.Group).getGuid()) {
                this.selectedConversation = conversation;
              }
          }
        });

        this.conversations = conversations;

      }, (err) => {
        // TODO handle is chatusr logedin Failes.
      });
    } else {
      // TODO handle is chatusr logedin Failes.
    }
  }
}
