import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import {AdminService} from "../../services/admin.service";
import { AppGlobals } from '../../../app.global';
import {formatDate } from '@angular/common';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-create-stream',
  templateUrl: './create-stream.component.html',
  styleUrls: ['./create-stream.component.scss']
})
export class CreateStreamComponent implements OnInit {
	btn_title = 'Create Stream';
	check_sess;
	submitted = false;
	public streamForm: FormGroup;
	stream_id='';
	title='';
	description='';
  meeting_id='';
  meeting_join_url='';
  meeting_password='';
	channel_id='';
  date= new Date();
  resImageFile : File = null;
  resImageSrc: any ='';
  invalidresimage='';
  invalidfile = false;
  errorImage;
  resimage : any ;
  minDate;
  time:any;
  timeError;
  isDisabled = false;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  showEditImage=false;
  addpermission = false;
  capability='';
  userType='';
  loader: boolean = false;
  constructor(private adminService: AdminService, 
    private baseUrl: AppGlobals,
    private route: ActivatedRoute,
    private router: Router) { }

  ngOnInit() {
    this.minDate = this.date;
    console.log(formatDate(this.date, 'dd-MM-yyyy hh:mm:ss a', 'en-US', '+0530'), 'format');
    this.check_sess=localStorage.getItem('access_token');
  	this.stream_id = localStorage.getItem('for_streamid');
    if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/admin/login-dashboard']);
    }
    this.capability=localStorage.getItem('capability');
    this.userType=localStorage.getItem('user_type');

    if(this.userType!='admin' && this.capability.split(',').indexOf('stream_add')>-1){
      this.addpermission = true;
    }
    if(this.userType=='admin'){
      this.addpermission=true;
    }
    if(!this.addpermission){
      this.router.navigate(['/admin/my-profile']);
    }

    this.streamForm = new FormGroup({
      title: new FormControl('',[Validators.required]),
      description: new FormControl('', [Validators.required]),
      date: new FormControl('', [Validators.required]),
      time: new FormControl('', [Validators.required]),
      image :  new FormControl(''),
      meeting_join_url : new FormControl('',[Validators.required]),
      meeting_password :new FormControl('',[Validators.required])
    });

    if(this.stream_id!='' && this.stream_id!=null)
    {
      console.log(this.stream_id,'this.stream_id');
      this.getStreamDetail();
    }
    if(this.stream_id==null)
    {
      this.stream_id='';
    }
  }

  fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
        console.log(this.croppedImage, 'this.croppedImage');
    }
  
  timeChangeHandler(e)
  {
    console.log(e, 'e');
  }

  get streamFrm() { return this.streamForm.controls; }

  videoImage(event){
    //console.l(event, 'e');
    this.showEditImage=false;
      const reader = new FileReader();
      const ext = event.target.files[0].name.split('.')[1];
      if (
        ext === 'jpg' ||
        ext === 'JPG' ||
        ext === 'jpeg' ||
        ext === 'JPEG' ||
        ext === 'png' ||
        ext === 'PNG' 
        /*ext === 'gif' ||
        ext === 'GIF'*/
      ) {


        //console.log(event.target.files[0].size/1024/1024 + ' MB');
        this.errorImage='';
        if (event.target.files[0].size/1024/1024 > 2) {
         this.invalidresimage = 'The maximum size limit of image is 2 MB.';
          this.invalidfile = false;
          this.resImageSrc = '';
          this.resimage=null;
        }
        else{
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
              this.resImageSrc = reader.result;
            };
          this.resImageFile = event.target.files[0];
              
          this.invalidfile = true;
          this.invalidresimage = '';
          this.imageChangedEvent = event;
          
          }
        } else {
        this.invalidresimage = 'Only jpg/jpeg/png format will be accepted';
        //this.resImageFile ='';
        this.invalidfile = false;
        this.resImageSrc = '';
        this.resimage=null;
        }
        //this.fileChangeEvent(event)
  }

  removeImage(){
    this.invalidfile = false;
    this.resImageSrc = '';
    this.resimage=null;
  }

  getStreamDetail(){
    this.btn_title = 'Edit Stream';
    const data = {
      id: this.stream_id
      };
    this.adminService.getPrizedetail(this.baseUrl.baseAppUrl+'admin/stream_detail?id='+this.stream_id, this.check_sess).subscribe((res : any) => {
      if(res.status == '1'){
        this.invalidfile=true;
        this.showEditImage=true;
        this.title = res.data.title;
        this.description   = res.data.description;
        this.channel_id = res.data.channel_id;

        this.meeting_join_url = res.data.meeting_join_url;
        this.meeting_password = res.data.meeting_password;
        
        //this.date = new Date(res.data.live_date);
        this.date = res.data.live_date;
        this.time = res.data.live_time;
        this.resImageSrc= this.baseUrl.baseAppUrl+'uploads/agora_stream/'+res.data.image_name;
        this.croppedImage = this.baseUrl.baseAppUrl+'uploads/agora_stream/'+res.data.image_name;
        }
      else{
        }
    });
    localStorage.setItem('for_streamid','');
  }

  onSubmit(){
    var currentTime = formatDate(new Date(), 'hh:mm', 'en');

    this.submitted = true;
	  if (this.streamForm.invalid) {
	    return;
	  }
    if(this.stream_id=='' && this.resimage==null)
    {
      
      this.errorImage = 'Please upload image';
      this.invalidresimage='';
       return false;
    }else if(this.stream_id!='' && this.resImageSrc==''){
      
      this.errorImage = 'Please upload image';
      this.invalidresimage='';
        return false;
    }else if(this.streamForm.value.time<currentTime && formatDate(this.streamForm.value.date, 'dd-MM-yyyy','en')== formatDate(new Date(), 'dd-MM-yyyy','en')){
      this.timeError = 'Please select future time';
      return false;
    }else{
      this.isDisabled = true;
      const streamFormData = new FormData();
      if(this.stream_id=='')
      {
          var channel_id = ""+Math.floor(Math.random() * 100)+"";
          streamFormData.append('channel_id', channel_id);
      }else{
        streamFormData.append('id', this.stream_id);
        streamFormData.append('channel_id', this.channel_id);
      }
      
        streamFormData.append('title', this.streamForm.value.title);
        streamFormData.append('description',this.streamForm.value.description);
        streamFormData.append('date',this.streamForm.value.date);
        streamFormData.append('time',this.streamForm.value.time);
        streamFormData.append('status','0');
        streamFormData.append('isStatus','0');
        streamFormData.append('meeting_join_url', this.streamForm.value.meeting_join_url);
        streamFormData.append('meeting_password', this.streamForm.value.meeting_password);
        //streamFormData.append('image', this.resImageFile);
        if(this.imageChangedEvent!='')
        {
          streamFormData.append('image', this.croppedImage);
        }
        
        //console.log(this.date, 'streamFormData', this.time, this.streamForm.value.date, 'time', this.streamForm.value.time );
        //return false;
        this.loader = true;
        this.adminService.updateStream(this.baseUrl.baseAppUrl+'admin/add_stream', streamFormData, this.check_sess).subscribe((data: any) => {
          this.isDisabled = false;
          if(data.status == '1'){
            localStorage.setItem('succ_stream', data.message);
            this.router.navigate(['/admin/live-stream']);
          }else{
            console.log('error');
          }
          this.loader = false;
    
        });
    }
	  
	}
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

}
