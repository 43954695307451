import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  location: Location;

  constructor(location: Location,private authService: AuthService, private router: Router) {
    this.location = location;
    //console.log('Auth gaurd load');
   }

  canActivate() {
    //console.log('canActivate',this.authService.isLoggedIn());
    if (this.authService.isLoggedIn()) {

      //console.log('this.authService.isLoggedIn');
      
      var titlee = this.location.prepareExternalUrl(this.location.path());
      //console.log('titlee',titlee);
      if(titlee.charAt(0) === '/'){
          titlee = titlee.slice( 1 );
      }
      //console.log('titlee',titlee);
      if(titlee=='sign-up' || titlee=='sign-up-started' || titlee=='sign-in' || titlee=='forgot' || titlee=='verify' || titlee=='sign-in/trading-academy' || titlee=='sign-in/member')this.router.navigate(['/dashboard']);
      
    }
    return !this.authService.isLoggedIn();
  }
}
