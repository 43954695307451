import { Component, OnInit, HostListener } from '@angular/core';
import {AdminService} from "../../services/admin.service";
import { AppGlobals } from '../../../app.global';
import { environment } from 'src/environments/environment';
import { ZoomMtg } from '@zoomus/websdk';
import { NavigationStart, ActivatedRoute, Router } from '@angular/router';
ZoomMtg.setZoomJSLib('https://source.zoom.us/2.4.5/lib', '/av');
ZoomMtg.setZoomJSLib('node_modules/@zoomus/websdk/dist/lib/', '/av');
ZoomMtg.preLoadWasm();
//ZoomMtg.prepareJssdk();
ZoomMtg.prepareWebSDK()
@Component({
  selector: 'app-launch-meeting',
  templateUrl: './launch-meeting.component.html',
  styleUrls: ['./launch-meeting.component.scss']
})
export class LaunchMeetingComponent implements OnInit {
  
  stream_id;
  success_msg='';
  error_msg='';
  role = 1;
  leaveUrl = '/admin/zoom-call';
  meetingNumber;
  password;
  userName = 'FOREX PLATINUM TRADING'
  userEmail = ''
  userId;
  check_sess;
  @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler(event) {
      console.log('window load');
      //this.leavestream();
      const streamFormData = new FormData();
      streamFormData.append('id', this.stream_id);
      streamFormData.append('status', '3');
      streamFormData.append('isStatus','1');
      streamFormData.append('leavestream','1');
      this.adminService.updateStream(this.baseUrl.baseAppUrl+'admin/add_stream', streamFormData, this.check_sess).subscribe((data: any) => {
        if(data.status == '1'){
          this.router.navigate(['/admin/live-stream']);
        }else{
          console.log('error');
        }
  
      });
  }
constructor(private route: ActivatedRoute,
  private router: Router,private adminService: AdminService,private baseUrl: AppGlobals) { }
  

  ngOnInit() {
    console.log('called init');
    //this.userId=localStorage.getItem('for_userid');
    this.check_sess=localStorage.getItem('access_token');
    this.stream_id = localStorage.getItem('for_channelID');
    if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/sign-in']);
    }
    this.meetingNumber=localStorage.getItem('launch_meeting_id');
    this.password=localStorage.getItem('launch_meeting_password');
    // if(this.meetingNumber=='')
    // {
    //   this.router.navigate(['/live-stream']);
    // }
    this.getSignature();
  }

  getSignature(){
    console.log('called getSignatire');
    const data = {
      meetingNumber: this.meetingNumber,
	    role: this.role
      };
  	this.adminService.addStream(this.baseUrl.baseAppUrl+'admin/generateSignature', data, this.check_sess).subscribe((data: any) => {
      console.log(data.signature, 'signature');
      this.startMeeting(data.signature)
    });
  }

  startMeeting(signature) {
    console.log(signature, 'signature');
    document.getElementById('zmmtg-root').style.display = 'block'
    var d = document.getElementById("zmmtg-root");
    d.className += "custom-zoom-root-admin";
    ZoomMtg.init({
      leaveUrl: this.leaveUrl,
      isSupportAV: true,
      success: (success) => {
        console.log(success, 'this.meetingNumber', this.meetingNumber);
          console.log(environment.zoomApiKey, 'zoom api key');
        ZoomMtg.join({
          signature: signature,
          meetingNumber: this.meetingNumber,
          userName: this.userName,
          apiKey: environment.zoomApiKey,
          userEmail: this.userEmail,
          passWord: this.password,
          success: (success) => {
            const streamFormData = new FormData();
            streamFormData.append('id', this.stream_id);
            streamFormData.append('status', '1');
            streamFormData.append('isStatus','1');
            this.adminService.updateStream(this.baseUrl.baseAppUrl+'admin/add_stream', streamFormData, this.check_sess).subscribe((data: any) => {
              if(data.status == '1'){
                localStorage.setItem('for_channelID','');
              }

            })
            console.log(success)
          },
          error: (error) => {
            console.log(error)
          }
        })

      },
      error: (error) => {
        console.log(error)
      }
    })
  }

}
