import { Component, OnInit, HostListener } from '@angular/core';
import { AgoraClient, ClientEvent, NgxAgoraService, Stream, StreamEvent } from 'ngx-agora';
import { NavigationStart, ActivatedRoute, Router } from '@angular/router';
import {AdminService} from "../../services/admin.service";
import { AppGlobals } from '../../../app.global';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';

export let browserRefresh = false;
@Component({
  selector: 'app-start-call',
  templateUrl: './start-call.component.html',
  styleUrls: ['./start-call.component.scss']
})
export class StartCallComponent implements OnInit {
title = 'angular-video';
  localCallId = 'agora_local';
  localScreenID = 'agora_screen';
  remoteCalls: string[] = [];
  subscription: Subscription;
  private client: AgoraClient;
  private localStream: Stream;
  private screenStream: Stream;
  private screenClient: AgoraClient;
  private uid: number;
  check_sess;
  channel_id='';
  btn_title='Start Streaming';
  sid;
  startBtnDisable=false;
  showScreenBtn=false;
  isScreenShareDisable=false;
  isStopShare=false;
  isCallAlreadyStart=false;
  isScreeAlreadyShared=false;
  @HostListener('window:beforeunload', ['$event'])
    beforeunloadHandler(event) {
      console.log('window load');
      //this.leavestream();
      const streamFormData = new FormData();
      streamFormData.append('id', this.channel_id);
      streamFormData.append('status', '3');
      streamFormData.append('isStatus','1');
      streamFormData.append('leavestream','1');
      this.adminService.updateStream(this.baseUrl.baseAppUrl+'admin/add_stream', streamFormData, this.check_sess).subscribe((data: any) => {
        this.ngxAgoraService.client.leave(() => { 
          this.localStream.stop();
          this.stopRecording();
        });
        if(data.status == '1'){
          this.router.navigate(['/admin/live-stream']);
        }else{
          console.log('error');
        }
  
      });
  }
  constructor(private ngxAgoraService: NgxAgoraService,private route: ActivatedRoute,
    private router: Router,private adminService: AdminService,private baseUrl: AppGlobals) {
        this.subscription = router.events.subscribe((event) => {
          if (event instanceof NavigationStart) {
            browserRefresh = !router.navigated;
            console.log(browserRefresh, 'refresh');
          }
        });
     }

  ngOnInit() {
  	this.check_sess=localStorage.getItem('access_token');
  	if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/admin/login-dashboard']);
    }
    this.channel_id = localStorage.getItem('for_channelID');
  	this.client = this.ngxAgoraService.createClient({ mode: 'live', codec: 'h264' });

    this.screenClient = this.ngxAgoraService.createClient({mode: 'rtc', codec: 'vp8'});

    this.assignClientHandlers();
    this.assignScreenClientHandlers();

    /*this.client.init(environment.agora.appId, function () {
      console.log("init success");
      }, (err) => {
      console.error(err);
    });

    this.client.disableDualStream(function() {
      console.log("Disable dual stream success!")
    });*/

    this.screenClient.init(environment.agora.appId, function () {
      console.log("init success");
      }, (err) => {
      console.error(err);
    });

    this.screenClient.disableDualStream(function() {
      console.log("Disable dual stream success!")
    });
  }

  startCall() {
    this.startBtnDisable=true;
    this.showScreenBtn=true;
  	console.log(this.channel_id, 'uid');
    /*this.localStream = this.ngxAgoraService.createStream({ streamID: this.channel_id, audio: true, video: true, screen: false });
    this.assignLocalStreamHandlers();
    // Join and publish methods added in this step
    this.initLocalStream(() => this.join(uid => this.publish(), error => console.error(error)));
    localStorage.setItem('for_channelID','');*/
    this.updateStreamStatus();
  }

  updateStreamStatus()
  {
    this.isCallAlreadyStart=true;
    const streamFormData = new FormData();
      streamFormData.append('id', this.channel_id);
      streamFormData.append('status', '1');
      streamFormData.append('isStatus','1');
      this.adminService.updateStream(this.baseUrl.baseAppUrl+'admin/add_stream', streamFormData, this.check_sess).subscribe((data: any) => {
        if(data.status == '1'){
          this.client.init(environment.agora.appId, function () {
            console.log("init success");
            }, (err) => {
            console.error(err);
          });

          this.client.disableDualStream(function() {
            console.log("Disable dual stream success!")
          });
          this.localStream = this.ngxAgoraService.createStream({ streamID: this.channel_id, audio: true, video: true, screen: false  });
            this.assignLocalStreamHandlers();

            console.log(this.localStream, 'localStream');
            // Join and publish methods added in this step
            this.initLocalStream(() => this.join(uid => this.publish(), 
              error => console.error(error)));

            localStorage.setItem('for_channelID','');
        }else{
          console.log('error');
        }
  
      });
  }

  shareScreen() {
    this.isScreeAlreadyShared=true;
    this.startBtnDisable=false;
    this.client.unpublish(this.localStream, err => console.log('Publish local stream error: ' + err));
    /*if (this.localStream) {
      this.uid = this.localStream.getId();
      this.localStream.stop();
      this.client.unpublish(this.localStream, (err) => {
        if(err)
        {
          console.error(err, 'client unpublish error')
        }
      })
    }*/
    this.client.unpublish(this.localStream, err => console.log('Publish local stream error: ' + err));
    this.isScreenShareDisable = true;
    
    const streamFormData = new FormData();
      streamFormData.append('id', this.channel_id);
      streamFormData.append('status', '1');
      streamFormData.append('isScreenShare', '1');
      streamFormData.append('isStatus','1');
      this.adminService.updateStream(this.baseUrl.baseAppUrl+'admin/add_stream', streamFormData, this.check_sess).subscribe((data: any) => {
        if(data.status == '1'){
          this.screenStream = this.ngxAgoraService.createStream({ streamID: this.channel_id, audio: true, video: false, screen: true  });

          
          
          this.initLocalScreenStream(() => this.joinScreen(uid => this.publishScreen(), 
            error => console.error(error)));

          localStorage.setItem('for_channelID','');
        }

    })
    
    
  }

  shareScreenAgain() {
    this.startBtnDisable=false;
    this.isScreenShareDisable = true;
    this.client.unpublish(this.localStream, err => console.log('Publish local stream error: ' + err));
    const streamFormData = new FormData();
      streamFormData.append('id', this.channel_id);
      streamFormData.append('status', '1');
      streamFormData.append('isScreenShare', '1');
      streamFormData.append('isStatus','1');
      this.adminService.updateStream(this.baseUrl.baseAppUrl+'admin/add_stream', streamFormData, this.check_sess).subscribe((data: any) => {
        if(data.status == '1'){
          this.client.init(environment.agora.appId, function () {
            console.log("init success");
            }, (err) => {
            console.error(err);
          });

          this.client.disableDualStream(function() {
            console.log("Disable dual stream success!")
          });

          this.screenStream = this.ngxAgoraService.createStream({ streamID: this.channel_id, audio: true, video: false, screen: true  });

          /*this.initLocalStreamAgain(() => this.join(uid => this.publish(), 
            error => console.error(error)));*/
            this.initLocalScreenStream(() => this.publishScreen());

          localStorage.setItem('for_channelID','');
        }

    })
  }

  shareScreenO() {
    this.localStream = this.ngxAgoraService.createStream({ streamID: this.channel_id, audio: true, video: false, screen: true  });
          console.log(this.localStream, 'localStream screen sharing');
          this.initLocalScreenStream(() => this.joinScreen(uid => this.publishScreen(), 
            error => console.error(error)));

          localStorage.setItem('for_channelID','');
    
    
  }

  /**
   * Attempts to connect to an online chat room where users can host and receive A/V streams.
   */
  join(onSuccess?: (uid: number | string) => void, onFailure?: (error: Error) => void): void {
    //this.client.join(null, '1000', this.uid, onSuccess, onFailure);
    this.client.join(null, this.localCallId, this.uid, onSuccess, onFailure);
    
  }

  joinScreen(onSuccess?: (uid: number | string) => void, onFailure?: (error: Error) => void): void {
    //this.client.join(null, '1000', this.uid, onSuccess, onFailure);
    this.screenClient.join(null, 'agora_local', this.uid, onSuccess, onFailure);
    
  }


  startRecording(localCallId){
    console.log(localCallId,'localCallId');
    var data = {"appid": environment.agora.appId, "channel": localCallId, "id":this.channel_id};
    this.adminService.agoraRecordingStartStop(this.baseUrl.baseAppUrl+'recorder/v1/start', data, this.check_sess).subscribe((res: any) => {
        if(res.status == '1'){
          this.sid = res.data.sid;
          console.log(data, 'startRecording');
        }else{
          console.log('error');
        }
  
      });
  }

  stopScreenNOne()
  {
    this.screenClient.unpublish(this.screenStream, err => console.log('Publish local stream error: ' + err));
  }

  stopCallOne(){
    this.client.unpublish(this.localStream, err => console.log('Publish local stream error: ' + err));
  }

  startCallAgain(){
    this.isScreenShareDisable=false;
    this.startBtnDisable=true;
    this.screenClient.unpublish(this.screenStream, err => console.log('Publish local stream error: ' + err));


    this.screenClient.unpublish(this.screenStream, err => console.log('Publish local stream error: ' + err));
    const streamFormData = new FormData();
      streamFormData.append('id', this.channel_id);
      streamFormData.append('status', '1');
      streamFormData.append('isScreenShare', '1');
      streamFormData.append('isStatus','1');
      this.adminService.updateStream(this.baseUrl.baseAppUrl+'admin/add_stream', streamFormData, this.check_sess).subscribe((data: any) => {
        if(data.status == '1'){
          this.client.init(environment.agora.appId, function () {
            console.log("init success");
            }, (err) => {
            console.error(err);
          });

          this.client.disableDualStream(function() {
            console.log("Disable dual stream success!")
          });

          this.localStream = this.ngxAgoraService.createStream({ streamID: this.channel_id, audio: true, video: true, screen: false  });

           
          console.log(this.localStream, 'localStream screen sharing');
          
          /*this.initLocalStreamAgain(() => this.join(uid => this.publish(), 
            error => console.error(error)));*/
            this.initLocalStreamAgain(() => this.publish());

          localStorage.setItem('for_channelID','');
        }

    })

  }

  stopRecording(){
    var data = {"appid": environment.agora.appId, "sid": this.sid};
    this.adminService.agoraRecordingStartStop(this.baseUrl.baseAppUrl+'recorder/v1/stop', data, this.check_sess).subscribe((data: any) => {
        if(data.status == '1'){
          console.log(data, 'stopRecording');
        }else{
          console.log('error');
        }
  
      });
  }

  stopSharing(){
    this.startBtnDisable=false;
    this.isStopShare=true;
    this.isScreenShareDisable = false;
    console.log(this.localStream, 'localStream');
    this.client.unpublish(this.screenStream, err => console.log('Publish local stream error: ' + err));
    //this.screenClient.unpublish(this.localStream, err => console.log('Publish local stream error: ' + err));
    const streamFormData = new FormData();
      streamFormData.append('id', this.channel_id);
      streamFormData.append('status', '1');
      streamFormData.append('isScreenShare', '1');
      streamFormData.append('isStatus','1');
      this.adminService.updateStream(this.baseUrl.baseAppUrl+'admin/add_stream', streamFormData, this.check_sess).subscribe((data: any) => {
        if(data.status == '1'){
          this.client.init(environment.agora.appId, function () {
            console.log("init success");
            }, (err) => {
            console.error(err);
          });

          this.client.disableDualStream(function() {
            console.log("Disable dual stream success!")
          });

          this.localStream = this.ngxAgoraService.createStream({ streamID: this.channel_id, audio: true, video: true, screen: false  });

           
          console.log(this.localStream, 'localStream screen sharing');
          
          /*this.initLocalStreamAgain(() => this.join(uid => this.publish(), 
            error => console.error(error)));*/
            this.initLocalStreamAgain(() => this.publish());

          localStorage.setItem('for_channelID','');
        }

    })

  }
  publishStopScreen(){
    /*this.client.publish(this.localStream, err => console.log('Publish local stream error: ' + err));*/
    this.screenClient.publish(this.localStream, err => console.log('Publish local stream error: ' + err));

  }
  

  leavestream() {
    this.localCallId = '';
    this.ngxAgoraService.client.leave(() => { 
      this.localStream.stop();
      this.stopRecording();
      const streamFormData = new FormData();
      streamFormData.append('id', this.channel_id);
      streamFormData.append('status', '3');
      streamFormData.append('isStatus','1');
      streamFormData.append('leavestream','1');
      this.adminService.updateStream(this.baseUrl.baseAppUrl+'admin/add_stream', streamFormData, this.check_sess).subscribe((data: any) => {
        if(data.status == '1'){
          this.router.navigate(['/admin/live-stream']);
        }else{
          console.log('error');
        }
  
      });
    });
  }



  /**
   * Attempts to upload the created local A/V stream to a joined chat room.
   */
  publish(): void {
    this.client.publish(this.localStream, err => console.log('Publish local stream error: ' + err));
  }

  publishScreen(): void {
    this.screenClient.publish(this.screenStream, err => console.log('Publish local stream error: ' + err));
  }

  private assignClientHandlers(): void {
    this.client.on(ClientEvent.LocalStreamPublished, evt => {
      console.log(`${evt.uid} local stream from this channel`);
      console.log('Publish local stream successfully');
    });

    this.client.on(ClientEvent.Error, error => {
      console.log('Got error msg:', error.reason);
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        this.client.renewChannelKey(
          '',
          () => console.log('Renewed the channel key successfully.'),
          renewError => console.error('Renew channel key failed: ', renewError)
        );
      }
    });

    this.client.on(ClientEvent.RemoteStreamAdded, evt => {
      const stream = evt.stream as Stream;
      console.log(stream, 'stream added');
      this.client.subscribe(stream, { audio: true, video: true }, err => {
        console.log('Subscribe stream failed', err);
      });
    });

    this.client.on(ClientEvent.RemoteStreamSubscribed, evt => {
      const stream = evt.stream as Stream;
      console.log(stream, 'as');
      const id = this.getRemoteId(stream);
      if (!this.remoteCalls.length) {
        this.remoteCalls.push(id);
        setTimeout(() => stream.play(id), 1000);
      }
    });

    

    

    this.client.on(ClientEvent.RemoteStreamRemoved, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = [];
        console.log(`Remote stream is removed ${stream.getId()}`);
      }
    });

    this.client.on(ClientEvent.PeerLeave, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = this.remoteCalls.filter(call => call !== `${this.getRemoteId(stream)}`);
        console.log(`${evt.uid} left from this channel`);
      }
    });
  }


  private assignScreenClientHandlers(): void {
    this.screenClient.on(ClientEvent.LocalStreamPublished, evt => {
      console.log(`${evt.uid} local stream from this channel`);
      console.log('Publish local stream successfully');
    });

    this.screenClient.on(ClientEvent.Error, error => {
      console.log('Got error msg:', error.reason);
      if (error.reason === 'DYNAMIC_KEY_TIMEOUT') {
        this.screenClient.renewChannelKey(
          '',
          () => console.log('Renewed the channel key successfully.'),
          renewError => console.error('Renew channel key failed: ', renewError)
        );
      }
    });

    this.screenClient.on(ClientEvent.RemoteStreamAdded, evt => {
      const stream = evt.stream as Stream;
      console.log(stream, 'stream added');
      this.screenClient.subscribe(stream, { audio: true, video: true }, err => {
        console.log('Subscribe stream failed', err);
      });
    });

    this.screenClient.on(ClientEvent.RemoteStreamSubscribed, evt => {
      const stream = evt.stream as Stream;
      console.log(stream, 'as');
      const id = this.getRemoteId(stream);
      if (!this.remoteCalls.length) {
        this.remoteCalls.push(id);
        setTimeout(() => stream.play(id), 1000);
      }
    });

    

    

    this.screenClient.on(ClientEvent.RemoteStreamRemoved, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = [];
        console.log(`Remote stream is removed ${stream.getId()}`);
      }
    });

    this.screenClient.on(ClientEvent.PeerLeave, evt => {
      const stream = evt.stream as Stream;
      if (stream) {
        stream.stop();
        this.remoteCalls = this.remoteCalls.filter(call => call !== `${this.getRemoteId(stream)}`);
        console.log(`${evt.uid} left from this channel`);
      }
    });
  }

  joinstream() {
    this.client.setClientRole('audience', function() {
        console.log('Client role set to audience');
      });
  
      this.client.join(null, '1000', 0, function(uid) {
          console.log('User ' + uid + ' join channel successfully');
      }, function(err) {
          console.log('[ERROR] : join channel failed', err);
      });
   
  }

  private assignLocalStreamHandlers(): void {
    this.localStream.on(StreamEvent.MediaAccessAllowed, () => {
      console.log('accessAllowed');
    });

    // The user has denied access to the camera and mic.
    this.localStream.on(StreamEvent.MediaAccessDenied, () => {
      console.log('accessDenied');
    });
    this.localStream.on(StreamEvent.ScreenSharingStopped, () => {
      console.log('ScreenSharingStopped');
    });
    
  }

  private initLocalStream(onSuccess?: () => any): void {
    this.localStream.init(
      () => {
        // The user has granted access to the camera and mic.
        this.localStream.play(this.localCallId);
        setTimeout(() =>  this.startRecording(this.localCallId), 2000);
        //this.startRecording(this.localCallId);
        if (onSuccess) {
          onSuccess();
        }
      },
      err => console.error('getUserMedia failed', err)
    );
  }

  private initLocalStreamAgain(onSuccess?: () => any): void {
    this.localStream.init(
      () => {
        // The user has granted access to the camera and mic.
        this.localStream.play(this.localCallId);
        //setTimeout(() =>  this.startRecording(this.localCallId), 2000);
        //this.startRecording(this.localCallId);
        if (onSuccess) {
          onSuccess();
        }
      },
      err => console.error('getUserMedia failed', err)
    );
  }

  private initLocalScreenStream(onSuccess?: () => any): void {
    
    this.screenStream.init(
      () => {
        this.screenStream.play(this.localCallId);

        if (onSuccess) {
          onSuccess();
        }
      })
    
    //this.publish();
  }

  private getRemoteId(stream: Stream): string {
    return `agora_remote-${stream.getId()}`;
  }

}
