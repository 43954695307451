import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit {
	check_sess ='';
  constructor(private router: Router) { }

  ngOnInit() {
  	this.check_sess=localStorage.getItem('access_token');
        if(this.check_sess == null || this.check_sess ==""){
            this.router.navigate(['/admin/login-dashboard']);
        }
        this.router.navigate(['/admin/my-profile']);
  }

}
