import { Component, OnInit, HostListener } from '@angular/core';
import { AppGlobals } from '../../app.global';
import { AuthService } from '../../service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';
import { PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
@Component({
  selector: 'app-globel-news',
  templateUrl: './globel-news.component.html',
  styleUrls: ['./globel-news.component.scss']
})
export class GlobelNewsComponent implements OnInit {
  news = [];
  token ='';
  offset: string = "0";
  limit:string = "20";
  isApiCalled = false;
  search_metadata_bloomberg='';
  search_metadata_reuters='';
  isScrollCalled = true;
  public config: PerfectScrollbarConfigInterface = {};
  constructor(private router: Router,private baseUrl: AppGlobals, private auth: AuthService) { }

  ngOnInit() {
  	localStorage.setItem('activeTab', 'globel-news');
  	this.token=localStorage.getItem('for_token');
        if(this.token == null || this.token ==""){
            this.router.navigate(['/sign-in']);
        }

    /**********/
    let loginType = localStorage.getItem('for_usertype');
    if(loginType!=='members'){ //'member'
      this.router.navigate(['/error404']);
    }
    /**********/
  	this.getGlobalNews();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(e) {
  	if((this.search_metadata_bloomberg!=null || this.search_metadata_reuters!=null) && this.isScrollCalled)
  	{
		this.onListScroll();
	}
  }

  onListScroll() {
   	this.getGlobalNews();
  }

  getGlobalNews(){
  	this.isScrollCalled = false;
  	this.auth.getGlobalNews(this.baseUrl.baseAppUrl+'user/getTwitterFeeds?next_bloomberg='+this.search_metadata_bloomberg + '&next_reuters='+this.search_metadata_reuters , this.token).subscribe((res: any) =>  {
    	this.isApiCalled=true;
          if(res){
              if(res.status==1){
              	let resData = [];

              	for (var i = 0; i <  res.data.statuses.length; i++) {
              		var text = res.data.statuses[i].text.split("https://")
              		res.data.statuses[i].text = text[0];
                  resData.push(res.data.statuses[i])
              	}
                if(this.search_metadata_bloomberg=='' && this.search_metadata_reuters=='')
              	{
              		this.news = resData;
        				}else{

                      		this.news = this.news.concat(resData);
        				}
				//let max_id = res.data.search_metadata[0].next_results.split("&q");
              	//max_id = max_id[0].split("=");
              	this.search_metadata_bloomberg = res.data.search_metadata[0].max_id;
              	this.search_metadata_reuters = res.data.search_metadata[1].max_id;
              	this.isScrollCalled = true;
              }
          }
      })
  
	}

}
