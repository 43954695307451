import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';

import { PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';

import { ChangePasswordComponent } from '../my-profile/change-password/change-password.component';
import { SubscribeTradeNotificationComponent } from '../my-profile/subscribe-trade-notification/subscribe-trade-notification.component';
import { ImagePopupComponent } from '../my-profile/image-popup/image-popup.component';

import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../app.global';
import { AuthService } from '../../service/auth.service';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';
import { TradeSuccessThankComponent } from '../dashboard/trade-idea/trade-success-thank/trade-success-thank.component';
import { ImageCroppedEvent  } from 'ngx-image-cropper';
import { UsernameValidator } from '../directive/space.validator';
import * as bcrypt from 'bcryptjs';
//import { CometChat } from '@cometchat-pro/chat';
//import { COMETCHAT_CONSTANTS } from '../../Commit-Constants';
import { TradeIdeaComponent } from '../dashboard/trade-idea/trade-idea.component';

export interface DialogData {
  otpdata: any;
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ordinalDate'
})
export class OrdinalDatePipe implements PipeTransform {
  transform(value: Date): string {
    if (!value) {
      return '';
    }
   let months= ["Jan","Feb","Mar","Apr","May","June","July",
           "Aug","Sep","Oct","Nov","Dec"]
    return `${value.getDate()}${this.nth(value.getDate)} ${months[value.getMonth()]} ${value.getFullYear()}`;
  }

 nth(d) {
  if (d > 3 && d < 21) return 'th'; 
  switch (d % 10) {
    case 1:  return "st";
    case 2:  return "nd";
    case 3:  return "rd";
    default: return "th";
  }
}
}

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
  providers: [ AppGlobals]
})
export class MyProfileComponent implements OnInit {
public config: PerfectScrollbarConfigInterface = {};
  token       = '';
  isSubmitted = false;
  profileForm : FormGroup;
  user_detail:any={};
  imgBaseUrl  = 'assets/images/structure/dnmb-menu-drop-default-icon.png';
  user_profile= 'assets/images/structure/upload-image-default.png';
  username:string    = '';
  email       = '';
  currentDate = new Date();
  userForm: FormGroup;
  userId      = '';
  email_error = '';
  user_error  = '';
  signalList = [];
  offset: string = "0";
  limit:string = "10";
  signalTotalCount;
  showSignalList = false;
  verify_text = 'Verify';
  verify_status = 0;
  profile_error='';
  pimg_status = false;
  old_image = '';
  imageChangedEvent: any = '';
  croppedImage: any = '';
  inputFile:any;
  isApiCalled = false;
  isSignalCalled = false;
  isScrollCalled=false;
  success_msg='';
  error_msg='';
  profileBaseUrl = '';
  subscribeTradeNoti = false;
  subscribeMentorNoti = false;

  accessrole:any;
  MarketingStaff = 'Marketing Staff';
  
  @ViewChild('clickOnSignal', {'static': false}) clickOnSignal: ElementRef;
  constructor(public dialog: MatDialog, private formBuilder: FormBuilder, private router: Router,
      private baseUrl: AppGlobals,
      private auth: AuthService,
      private route: ActivatedRoute, private http: HttpClient) {
        const navigation = this.router.getCurrentNavigation();
        const state = navigation.extras.state as {is_profile: boolean};
        if(state!=undefined)
        {
          if(state.is_profile)
          {
            setTimeout (() => {
              this.clickOnSignal.nativeElement.click();
            },100);
          }
        }
       }
    emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';

  ngOnInit() {
    this.token=localStorage.getItem('for_token');
      if(this.token == null || this.token ==""){
        this.router.navigate(['/sign-in']);
    }
    /**********/
    //let loginType = localStorage.getItem('for_usertype');
    // if(loginType!=='member'){ //'member'
    //   this.router.navigate(['/error404']);
    // }
    
    //let user_accessibility = localStorage.getItem('for_accessibility');        
    // if(user_accessibility!=''){
    //   let accessibility = JSON.parse(user_accessibility);
    //    //if(!accessibility.members)this.router.navigate(['/error404']);
    // }
    /**********/
    this.accessrole = localStorage.getItem('role');
    // setTimeout (() => {
    // this.clickOnSignal.nativeElement.click();
    // },1000);
    this.userForm = this.formBuilder.group({
            username: ['', [Validators.required, UsernameValidator.cannotContainSpace]],
            email: ['', [Validators.required , Validators.pattern(this.emailPattern)]],
        });
    this.profileForm = this.formBuilder.group({
            user_name: ['', Validators.required],
            email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$')]]
    });

    this.profileBaseUrl = this.baseUrl.baseAppUrl+'uploads/profile/';

    this.getSignals();
    this.userId = localStorage.getItem('for_userid');
    this.auth.userDetail(this.baseUrl.baseAppUrl+'user/getUserDetail?userId='+this.userId,this.token).subscribe((res: any) =>  {
          if(res){
            this.isApiCalled = true;
              if(res.status==1){
                console.log('sss',res.data);
                this.user_detail = res.data;
                this.username = res.data.username;
                this.email    = res.data.email;
                this.verify_status = res.data.email_verify;
                this.subscribeTradeNoti = res.data.email_notification;
                this.subscribeMentorNoti = res.data.trade_email_subscribe;
                if(res.data.email_verify)
                  this.verify_text = 'Verified';
                //console.log(res.data.profile_image, 'profile_image');
                if(res.data.profile_image === "" || res.data.profile_image==null )
                {
                  this.pimg_status = false;
                  this.user_detail = '';
                }else{
                  //console.log('inner');
                  this.user_profile = this.baseUrl.baseAppUrl+'uploads/profile/'+res.data.id+'/'+res.data.profile_image; 
                  if(this.user_profile!='assets/images/structure/upload-image-default.png')
                  {
                      this.pimg_status = true;
                  }
                  this.old_image = 'uploads/profile/'+res.data.id+'/'+res.data.profile_image;
                  //this.imgBaseUrl = this.baseUrl.baseAppUrl+'uploads/tool/';
                }
                  //console.log(this.pimg_status, 'pimag status');
                }
              }
        });

   
 }



 @HostListener('window:scroll', ['$event'])
  onScroll(e) {
    this.onListScroll();
  }

  onListScroll() {
    if(this.signalTotalCount>this.offset && !this.isScrollCalled)
    {
      //console.log(this.offset);
      this.getSignals();
    }
  }

  click_message(){
    this.success_msg='';
    this.error_msg='';
  }

 removePimage(img){
 if(img!=""){ 
  const data = {
      user_id    : this.userId,
      image      : img,
      };
   this.auth.updateDetail(this.baseUrl.baseAppUrl+'user/removeImage', data, this.token).subscribe((res : any) => {
      if(res.status == '1'){
        this.inputFile = null;
        this.user_profile= 'assets/images/structure/upload-image-default.png';
        //localStorage.setItem('profile_image','assets/images/structure/upload-image-default.png');
        localStorage.removeItem('for_token_profileimg');
        document.getElementById("imgProfilechg").setAttribute( 'src', this.user_profile);

        localStorage.setItem('profile_image','assets/images/structure/upload-image-default.png');
        //document.getElementById("imgProfilechg").setAttribute( 'src', this.baseUrl.baseAppUrl+localStorage.getItem('for_token_profileimg'));

        //this.cometUpdateuser('', 'blank');
        this.pimg_status = false;
        this.old_image = '';
      }
      else{}
    });
   }
  }

 getSignals() {
    let params = new HttpParams()
                .set('offset', this.offset)
                .set('limit', this.limit);
    this.token=localStorage.getItem('for_token');
        if(this.token == null || this.token ==""){
            this.router.navigate(['/sign-in']);
        }
    
    if(this.offset!=undefined)
    {
      this.isScrollCalled=true;
      this.auth.listingSrvs(this.baseUrl.baseAppUrl+'user/mytrade_listing?offset='+this.offset + '&limit='+this.limit  + '&user_id='+localStorage.getItem('for_userid'), this.token).subscribe((res: any) =>  {
          if(res){
            this.isSignalCalled = true;
              if(res.status==1){
                if(this.offset=="0")
                {
                  this.signalList = res.data;
                }
                else{
                  this.signalList = this.signalList.concat(res.data);
                  //this.academyList.unshift(res.data[0]);
                }
                this.imgBaseUrl = this.baseUrl.baseAppUrl+'uploads/trade/';
                this.signalTotalCount = res.total;
                this.offset = res.offset;
                this.showSignalList = true;
                this.isScrollCalled=false;
               }
            }
            else{
             
             }
          });
    }
  }

 get userFrm() { return this.userForm.controls; }

  // update user detail 
  updateUser(){
    this.isSubmitted = true;
    if (this.userForm.invalid) {
            return;
        }
    const data = {
      user_id    : this.userId,
      username  : this.username,
      email     : this.email,
      };
      
      this.auth.updateDetail(this.baseUrl.baseAppUrl+'user/detailUpdate', data, this.token).subscribe((res : any) => {
      if(res.status == 1){
        //this.cometUpdateuser(this.username, '');
        
        localStorage.setItem('for_username',this.username);
        this.username = this.titleCase(this.username);
        document.getElementById("userNamechg").innerHTML="Hi, "+this.username;
        this.user_detail.username=this.username;
        if(res.data){
          this.verify_text = 'Verified';
          this.verify_status = 1;
        }
        else{
          this.verify_text = 'Verify';
          this.verify_status = 0; 
        }
        console.log('Popup before');
        const dialogRef = this.dialog.open(TradeSuccessThankComponent, {
      data: { popup_msg: 'Your profile has been updated successfully.' }
      //ngOnInit()
    });
      }
      else{
          if(res.type =='email')
           this.email_error = res.message;
          else if(res.type =='user')
            this.user_error = res.message;
      }
    });
  }

  // cometUpdateuser(name, avatar) {
  //   var user = new CometChat.User(this.userId);
  //   if(name)
  //   {
  //     user.setName(name);
  //   }
  //   if(avatar)
  //   {
  //     if(avatar!='blank')
  //     {
  //         user.setAvatar(avatar);
  //     }else{
  //       user.setAvatar('');
  //     }
  //   }

  //   CometChat.updateUser(user, COMETCHAT_CONSTANTS.API_KEY).then(
  //       user => {
  //         let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'apikey': COMETCHAT_CONSTANTS.REST_API_KEY, 'appid': COMETCHAT_CONSTANTS.APP_ID, 'Accept': 'application/json' }) };
  //           let userId = localStorage.getItem('for_userid');
  //               //console.log("user updated", user);
  //             }, error => {
  //                 //console.log("error", error);
  //             }
  //         )
  // }

  // cometUpdateToken(UID, token) {
  //   //console.log(token, 'previos auth_tokens');

  //   let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'apikey': COMETCHAT_CONSTANTS.REST_API_KEY, 'appid': COMETCHAT_CONSTANTS.APP_ID, 'Accept': 'application/json' }) };

  //   let userId = localStorage.getItem('for_userid');
  //   this.http.delete<any>('https://api-us.cometchat.io/v3.0/users/'+UID+'/auth_tokens/'+token , httpOptions).subscribe(
  //     resDel => { 

  //       var data = {};
  //       this.http.post<any>('https://api-us.cometchat.io/v3.0/users/'+UID+'/auth_tokens', data,httpOptions).subscribe(
  //         (res:any) => { 
            

  //            CometChat.login(res.data.authToken).then(
  //               (User:any) => {
  //                 //console.log('Login token: ', User);
  //                 //this.comet_token = user.getAuthToken();
  //                 localStorage.setItem('comet_authtoken', res.data.authToken);
  //                 //console.log("Login Successful:", { User });
  //                //this.router.navigate(['/dashboard']);
  //                 // User loged in successfully.
  //               },
  //               error => {
  //                 //console.log("Login failed with exception:", { error });
  //                 // User login failed, check error and take appropriate action.
  //               }
  //           );

  //               //console.log(res, 'auth_tokens');
  //             })
        
  //     })
  // }

  titleCase(str)
  {
    return str.replace(
            /\w\S*/g,
            function(txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
  }

  getdata(evt){
    //if(this.email != "")
      this.email_error='';
    //if(this.username != "")
      this.user_error='';
  }
  // Create Update Card modal
  CreateChangePassword(){
    const dialogRef = this.dialog.open(ChangePasswordComponent, {
    });
    
    dialogRef.afterClosed().subscribe(result => {
      //this.ngOnInit();
    });
  }

  
  SubscribeTradeNotification(type: any){
    const dialogRef = this.dialog.open(SubscribeTradeNotificationComponent, {
       data: { type: type }
    });
    
    dialogRef.afterClosed().subscribe(result => {
      console.log(result);   
      if(result && type==='email_notification'){
        this.subscribeTradeNoti=true;
        this.subscribeMentorNoti=false;
      }
      if(result && type==='trade_email_subscribe'){
        this.subscribeMentorNoti=true;
        this.subscribeTradeNoti=false;
      }
      this.ngOnInit();
    });
  }

  unsubscribeTradeNotification(){
    var datas = {
      user_id: this.userId,
      email_notification: 0,
      trade_email_subscribe: 0
    };
    this.auth.signupSrvs(this.baseUrl.baseAppUrl + 'auth/unsubscribe', datas).subscribe((res: any) => {
      
      if (res.status == '1') {
        this.subscribeTradeNoti=false;
        this.subscribeMentorNoti=false;
      }
      else {

      }
    });
  }

  changePassword() {
    this.isSubmitted = true;
    if (this.profileForm.invalid) {
        return;
    }
    /*const salt = bcrypt.genSaltSync(10);

    let pass = bcrypt.hashSync(this.changePasswordForm.value.new_password, salt);
    console.log(pass)
    const data = {
            user_id: localStorage.getItem('for_userid'),
            password: pass
            };
    
    
  this.auth.changePassword(this.baseUrl.baseAppUrl+'user/changePassword',data, this.token).subscribe((res:any)=>{
      if(res.status=='1'){
        document.getElementById("close_dialog_box_pass").click();
      }
    });*/
  }

  tradeDetailpage(id,frm=''){
    localStorage.setItem('for_tradeid', id);
    localStorage.setItem('for_tradeidfrm', frm);
    this.router.navigate(['/signals-detail-view']);
  }

  tradelikeDislike(id,ind){
    //console.log(ind, 'index');
      //alert(ind);
     this.token=localStorage.getItem('for_token');
        if(this.token == null || this.token ==""){
            this.router.navigate(['/sign-in']);
        }
        
      const data = {
      user_id : localStorage.getItem('for_userid'),
      post_id : id,
      };
    this.auth.tradeideaLikeSrvs(this.baseUrl.baseAppUrl+'trade/post_like', data , this.token).subscribe((res: any) =>  {
            if(res.status==1){
              this.signalList[ind].totallike = res.total;
              if(res.action =='like')
                this.signalList[ind].userlike = 1;
              else
                this.signalList[ind].userlike = 0;
              //this.signalList[ind].totallike+1;
              //console.log(this.signalList, 'res', this.imgBaseUrl);
             }
            else{
             
             }
          });

  }

  verify_emailid(id){
    if(id==0){
        const data = {
        user_id : this.userId,
        base_url_server: this.baseUrl.baseAppUrl
        };
        this.auth.updateDetail(this.baseUrl.baseAppUrl+'user/send_emailverify', data, this.token).subscribe((res : any) => {
      if(res.status == '1'){
          const dialogRef = this.dialog.open(TradeSuccessThankComponent, {
          data: { popup_msg: res.message }
          });
        }
      else{
        const dialogRef = this.dialog.open(TradeSuccessThankComponent, {
          data: { popup_msg: res.message }
          });
        }
      });  
          
      }
  }

   fileChangeEvent(event: any): void {
        this.imageChangedEvent = event;
        const reader = new FileReader();
      const ext = event.target.files[0].name.split('.')[1];
      if (
        ext === 'jpg' ||
        ext === 'JPG' ||
        ext === 'png' ||
        ext === 'PNG' ||
        ext === 'jpeg' ||
        ext === 'JPEG'
      ) {

        /*if (event.target.files[0].size/1024/1024 > 5) {
         this.profile_error = 'The maximum size limit of image is 5 MB.';
        }
        else{*/
             const dialogRef = this.dialog.open(ImagePopupComponent,{ 
                 data:{ image : this.imageChangedEvent}
               });
        //console.log(this.imageChangedEvent, 'imgProfilechg');
            dialogRef.afterClosed().subscribe(result => {
              console.log(result, 'result');
            //this.ngOnInit();
                //this.inputFile = null;
              if(result.data!='cancel')
              {
                this.success_msg=result.message;
                setTimeout(() => {
                  this.success_msg = '';
                }, 4000);
                this.profile_error='';
                if(localStorage.getItem('for_token_profileimg')!="" && localStorage.getItem('for_token_profileimg')!=null){
                //this.cometUpdateuser('', this.baseUrl.baseAppUrl+localStorage.getItem('for_token_profileimg'));
                this.user_profile = this.baseUrl.baseAppUrl+localStorage.getItem('for_token_profileimg'); 
                this.pimg_status = true;
                this.old_image = localStorage.getItem('for_token_profileimg');
                    
                localStorage.setItem('profile_image', this.baseUrl.baseAppUrl+localStorage.getItem('for_token_profileimg'));
                document.getElementById("imgProfilechg").setAttribute( 'src', this.baseUrl.baseAppUrl+localStorage.getItem('for_token_profileimg'));
                }
              }
              this.inputFile = null;
              

            
            
            });    
          //}
        } else {
        this.profile_error = 'Please upload only JPG/JPEG/PNG format.';
        }
        
    
    }
    removeProfilePicture() {
      
    }
    imageCropped(event: ImageCroppedEvent) {
        this.croppedImage = event.base64;
    }
    imageLoaded() {
        // show cropper
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
    }

    EditTradeIdea(id,i){

      const dialogRef = this.dialog.open(TradeIdeaComponent, {
          height: "100vh",
          maxHeight: "640px",
          data: { trade_id: id }
        });
        
        dialogRef.afterClosed().subscribe(result => {
          //this.offset='0';
          this.signalList[i]=result.data[0];
          //this.getSignals();
          console.log(result, 'result');
        });
      
      
    }

}