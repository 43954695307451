import { Component, OnInit } from '@angular/core';
import { AppGlobals } from '../../app.global';
import { AuthService } from '../../service/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
  
@Component({
  selector: 'app-prizes',
  templateUrl: './prizes.component.html',
  styleUrls: ['./prizes.component.scss'],
  providers: [ AppGlobals]
})
export class PrizesComponent implements OnInit {
	prizes= [];
	profileBaseUrl='';
	isAPICalled = false;
  constructor(private router: Router,
  private baseUrl: AppGlobals,
  private auth: AuthService,) { }

  ngOnInit() {
  	localStorage.setItem('activeTab', 'prizes');
  	this.priceListing();
  }

  priceListing()
  {
  	this.auth.getpriceListing(this.baseUrl.baseAppUrl+'user/prizes' , localStorage.getItem('for_token')).subscribe((res: any) =>  {
  		this.isAPICalled=true;
            if(res.status==1){
            	this.profileBaseUrl = this.baseUrl.baseAppUrl+'uploads/profile/';
              	this.prizes = res.data;
             }
          });
 }

}
