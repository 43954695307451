import { Component, OnInit, ChangeDetectorRef, ViewChild, ElementRef, AfterViewInit, AfterViewChecked } from '@angular/core';
import { PerfectScrollbarConfigInterface,PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';
import { ChatService } from '../../service/chat.service';
import { AppGlobals } from '../../app.global';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import * as io from "socket.io-client";
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  providers: [ AppGlobals]
})

export class ChatComponent implements AfterViewInit, AfterViewChecked {
  public config: PerfectScrollbarConfigInterface = {suppressScrollX: true};
  groupConversations = [];
  offset=0;
  limit=5;
  profileImageUrl='';
  scrollTrigger = true;
  socket = io(environment.socketApiPath);

  token;
  userId;
  traders = [];
  tradersCount=0;
  searchKey = '';
  
  msgText:string = '';
  total_pages;
  cursorID;
  groupConversationoffset = 0;
  conversationCount;
  
  currentScrollPossition = 0;

  //tradersScrollTrigger = false;
  currentTradersScrollPosition = 0;
 
  showEmojiKeyboard = false;
  isSubmitDisable = false;
  messagesRequest;
  inProgress = false;
  isInputDisabled=false;
  isAccessible=false;
  //@ViewChild('perfectScroll', { static: false }) perfectScroll: PerfectScrollbarComponent;
  
  @ViewChild(PerfectScrollbarComponent, { static: false }) componentRef?: PerfectScrollbarComponent;
  @ViewChild(PerfectScrollbarDirective, { static: false }) directiveRef?: PerfectScrollbarDirective;
  @ViewChild('thisRed', { read: ElementRef, static: true }) tref: ElementRef;
  @ViewChild('thisTraders', { read: ElementRef, static: true }) tradersScroll: ElementRef;
  @ViewChild('chatAreaFocus', {static: true}) chatAreaFocus;
  
  constructor(
              private http: HttpClient, 
              private router: Router, 
              private auth: AuthService, 
              private chat: ChatService,
              private baseUrl: AppGlobals, 
              private cdRef: ChangeDetectorRef) { }

  ngAfterViewInit() {
    this.tref.nativeElement.scrollTop = 0;
    this.tref.nativeElement.onChange = () => {};
    
    this.tradersScroll.nativeElement.scrollTop = 0;
    this.tradersScroll.nativeElement.onChange = () => {};
    //this.scrollToggle();
    //console.log('aaaaaaaaaaaaaaaaaaaa');
  }
  
  ngAfterViewChecked() {
    
    if (!this.scrollTrigger) {
      this.tref.nativeElement.scrollToBottom = 0;      
    }

    // if (!this.tradersScrollTrigger) {
    //   this.tradersScroll.nativeElement.scrollTop = this.tradersScroll.nativeElement.scrollHeight - this.currentTradersScrollPosition;
    // }
  }
  
  private scrollToBottom = () => {
    this.cdRef.detectChanges();
    this.tref.nativeElement.scrollTop = this.tref.nativeElement.scrollHeight + this.tref.nativeElement.offsetHeight;
  }
  
  ngOnInit() {
    this.chatAreaFocus.nativeElement.focus();
    this.userId = localStorage.getItem('for_userid');
    this.token=localStorage.getItem('for_token');

    this.profileImageUrl = this.baseUrl.baseAppUrl+'uploads/profile/';
    if(localStorage.getItem('subscription_status')=='cancel')
    {
      //this.isInputDisabled= false;//true;
    }
    if(this.token == null || this.token ==""){
      this.router.navigate(['/sign-in']);
    }
    this.getSubscriptionDetail();
    this.getGroupConversations();

    this.chat
    .getChatMessages()
    .subscribe((data) => {
      //this.messages.push(message);
      this.groupConversations.push(data);
      this.scrollToggle();
      console.log('socket msg come => ',data);
    });

  }

  
  sendMessage(event,click=false) {
    console.log(event.keyCode);
    if ( ((event.keyCode === 13 && !event.shiftKey) || click==true ) && (this.msgText).trim()!=undefined && (this.msgText).trim()!='' ) {
      event.preventDefault();
      this.isSubmitDisable = true;
      
      //console.log(messageText, 'messageText');
      if(this.msgText){
        const data = {
          user_id: localStorage.getItem('for_userid'),
          message: this.msgText
          };
          this.msgText = '';
        this.auth.post(this.baseUrl.baseAppUrl+'chat/create',data, this.token).subscribe((res:any)=>{
          if(res.status=='1'){
            //document.getElementById("close_dialog_box_pass").click();
              this.isSubmitDisable = false;
              //event.target.disabled = true;
              //this.groupConversations.unshift(res.chat);
              this.socket.emit('new_chatmessage', res);              
              this.scrollToggle();
          }
        });

      }
    }

  }

  getGroupConversations(scrolleTop=false) {
    this.auth.get(this.baseUrl.baseAppUrl+'chat/get?offset='+this.offset + '&limit='+this.limit,this.token).subscribe((res: any) =>  {
        if(res.status){
          //console.log(res.data);
          this.offset = res.offset;
          var chats = [...(res.data)].reverse();
          this.groupConversations = chats.concat(this.groupConversations);
          if(this.scrollTrigger==true){
            this.scrollToggle();
            this.scrollTrigger=false;
          }
          if(scrolleTop==true)this.scrollToggle1();
          //this.groupConversations.push(chats);
          //console.log('this.groupConversations',this.groupConversations);
        }
     });
  }

  onListScroll(elem) {
    this.scrollTrigger = false;
    //console.log(elem.target.scrollTop, 'position');
      if (elem.target.scrollTop === 0 && this.groupConversations.length > 0) {
        if (!this.inProgress) {
          this.getGroupConversations(true); 
        }
      }
  }

  getSubscriptionDetail() {
    this.auth.getSubscriptionDetail(this.baseUrl.baseAppUrl+'user/getSubscriptionDetail?userId='+this.userId,this.token).subscribe((res: any) =>  {
        if(res){
          if(res.data.is_accessible==1)
          {
            this.isAccessible=true;
          }else{
            this.isAccessible=false;
          }
        }
     });
  }

  closeNav() {
    document.getElementById("mySidechat").style.cssText = "display: none;";
  }

  scrollToggle() {
    this.cdRef.detectChanges();
    this.tref.nativeElement.scrollTop = this.tref.nativeElement.scrollHeight + this.tref.nativeElement.offsetHeight;
  }

  scrollToggle1() {
    this.cdRef.detectChanges();
    setTimeout(()=>{
      this.tref.nativeElement.scrollTop = 100;
    }, 500);
    
  }

  onActionGenerated(event) {
    this.showEmojiKeyboard = !this.showEmojiKeyboard;
  }

  



}
