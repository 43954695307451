import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AdminService} from "../services/admin.service";
import { AppGlobals } from '../../app.global';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
import { FormBuilder, FormGroup, Validators,FormControl } from '@angular/forms';
import { Select2OptionData,Select2TemplateFunction } from 'ng2-select2';
export interface PeriodicElement {
  row_id: number;
  record_id: number;
  title: string;
  channel_id: string;
  status: string;
  action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-live-stream',
  templateUrl: './live-stream.component.html',
  styleUrls: ['./live-stream.component.scss']
})
export class LiveStreamComponent implements OnInit {
	all_records;
	displayedColumns: string[] = ['record_id','title', 'channel_id','status', 'action'];
  	dataSource : any;
  	check_sess;
	error_msg='';
  	success_msg='';
    stream_id;
    recordedForm: FormGroup;
    isSubmitted=false;
    statusdata:any=[];
    public options: Select2Options;
    recorded_id;
    duration;
    pastStatus='inactive';
    editpermission=false;
    deletepermission = false;
    completepermission = false;
    readpermission = false;
    capability='';
    userType='';
    @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;
    @ViewChild('openRecordedPopup', {'static': false}) openRecordedPopup: ElementRef;
    @ViewChild('closeRecordedPopup', {'static': false}) closeRecordedPopup: ElementRef;
  constructor(private adminService: AdminService,
    private baseUrl: AppGlobals,
    private route: ActivatedRoute,
    private router: Router,private dialog: MatDialog, private formBuilder: FormBuilder) { }

  ngOnInit() {
  	this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/admin/login-dashboard']);
    }

    this.capability=localStorage.getItem('capability');
    this.userType=localStorage.getItem('user_type');

    if(this.userType!='admin' && this.capability.split(',').indexOf('stream_edit')>-1){
      this.editpermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('stream_delete')>-1){

      this.deletepermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('stream_complete')>-1){
      this.completepermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('stream_read')>-1){
      this.readpermission = true;
    }
    if(this.userType=='admin'){
      this.editpermission=true;
      this.deletepermission = true;
      this.completepermission = true;
      this.readpermission = true;
    }
    if(!this.editpermission && !this.deletepermission && !this.completepermission && !this.readpermission){
      this.router.navigate(['/admin/my-profile']);
    }

    this.success_msg=localStorage.getItem('succ_stream');
  	 setTimeout (() => {
         this.success_msg="";
         localStorage.setItem('succ_stream','');
        }, 2000);
        this.recordedForm = this.formBuilder.group({
          recorded_id   : ['', Validators.required],
          duration   : ['', Validators.required],
          status   : ['']
        });
        this.statusdata = [
          {
            id: 'active',
            text: 'Active'
          },
          {
            id: 'inactive',
            text: 'Inactive'
          }
        ];
  	this.stream_listing();
  }



  get recordedFrm() { return this.recordedForm.controls; }

  updateRecordedID(){
    this.isSubmitted = true;
    if(this.recordedForm.invalid) {
           return;
       }
       console.log(this.recordedForm.value, 'value');
       const recordFormData = new FormData();
       recordFormData.append('id', this.stream_id);
       recordFormData.append('past_status', this.pastStatus);
       recordFormData.append('recorded_id',this.recorded_id);
       recordFormData.append('duration',this.duration);
       this.adminService.updateStream(this.baseUrl.baseAppUrl+'admin/updateRecordedInfo', recordFormData, this.check_sess).subscribe((res: any) => {
         if(res.status == '1'){
          this.closeRecordedPopup.nativeElement.click();
          this.stream_listing();
          this.success_msg = res.message;
          setTimeout (() => {
           this.success_msg="";
          }, 2000);
         }else{
           console.log('error');
         }

       });
  }
  setStatus(e) {
    this.pastStatus = e;
  }

  createCall(id) {
  	localStorage.setItem('for_channelID', id);
  	//this.router.navigate(['/admin/start-call']);
    this.router.navigate(['/admin/zoom-call']);
  }

  openRecordedPopupForm(stream){
    console.log(stream, 'adasd stream');
    this.stream_id=stream.order_id;
    this.recorded_id=stream.recorded_stream_id;
    this.pastStatus=stream.past_stream_status;
    this.duration=stream.duration;
    this.openRecordedPopup.nativeElement.click();
  }

  stream_listing() {
  	this.all_records=[];
  	this.adminService.streamListing(this.baseUrl.baseAppUrl+'admin/stream_listing', this.check_sess).subscribe((res: any) =>  {

      if(res.status == '1'){
        //console.log(res.data.length);
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
            if(res.data[i].status==1)
            {
              res.data[i].displayStatus= 'Active';
            }else if(res.data[i].status==0)
            {
              res.data[i].displayStatus= 'Upcoming';
            }else if(res.data[i].status==3)
            {
              res.data[i].displayStatus= 'Completed';
            }
            this.all_records.push({record_id: j, order_id: res.data[i].id, title: res.data[i].title, description : res.data[i].description, channel_id : res.data[i].channel_id, status : res.data[i].status, displayStatus: res.data[i].displayStatus,recorded_stream_id: res.data[i].recorded_stream_id, duration: res.data[i].duration, past_stream_status: res.data[i].past_stream_status, action: ''},);
            ['record_id','title', 'description','channel_id', 'status', 'displayStatus', 'action'];
			j++;
          }
          console.log(this.all_records);
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;

      }
      else{
       this.error_msg = res.message;
      }

      });
  }

  editStream(id){
    localStorage.setItem('for_streamid', id);
    this.router.navigate(['admin/create-stream']);
  }

  openDialog(id) {
    this.stream_id=id;
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
           const data = {
            id: this.stream_id
            };
            console.log(data,'data');
          this.adminService.deleteStream(this.baseUrl.baseAppUrl+'admin/stream_delete', data, this.check_sess).subscribe((res : any) => {
            if(res.status == '1'){
              this.success_msg = res.message;
              this.stream_listing();
              setTimeout (() => {
               this.success_msg="";
              }, 2000);
             }
            else{
               this.error_msg = res.message;
               setTimeout (() => {
               this.error_msg="";
              }, 2000);
             }
          });

      }
    });
  }


  markComplete(id) {
    this.stream_id=id;
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      data:{
        message: 'Are you sure want to complete the stream?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        const streamFormData = new FormData();
        streamFormData.append('id', this.stream_id);
        streamFormData.append('status', '3');
        streamFormData.append('isStatus','1');
        streamFormData.append('leavestream','1');
        this.adminService.updateStream(this.baseUrl.baseAppUrl+'admin/add_stream', streamFormData, this.check_sess).subscribe((data: any) => {
          if(data.status == '1'){
            this.success_msg = "Stream completed successfully";
            this.stream_listing();
            setTimeout (() => {
             this.success_msg="";
            }, 2000);
           }
          else{
             this.error_msg = data.message;
             setTimeout (() => {
             this.error_msg="";
            }, 2000);
           }

        });

      }
    });
  }



}
