import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AdminService} from "../services/admin.service";
import { AppGlobals } from '../../app.global';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';

import * as $ from 'jquery';

export interface PeriodicElement {
  row_id: number;
  record_id: number;
  title: string;
  sub_title: string;
  view_count: string;
  mark_up: string;
  action: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];


@Component({
  selector: 'app-video-list',
  templateUrl: './video-list.component.html',
  styleUrls: ['./video-list.component.scss'],
  providers: [ AppGlobals]
})
export class VideoListComponent implements OnInit {

  displayedColumns: string[] = ['record_id', 'title','sub_title','view_count','mark_up', 'action'];
  dataSource : any;
  @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;
  all_records=[];
  error_msg='';
  success_msg='';
  check_sess;
  videoId='';
  editpermission=false;
  deletepermission = false;
  capability='';
  userType='';
  readpermission=false;

  constructor(
  	private adminService: AdminService,
  	private baseUrl: AppGlobals,
  	private router: Router,private dialog: MatDialog) { }

  ngOnInit() {
    this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/admin/login-dashboard']);
    }

    this.capability=localStorage.getItem('capability');
    this.userType=localStorage.getItem('user_type');
    if(this.userType!='admin' && this.capability.split(',').indexOf('video_library_edit')>-1){
      this.editpermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('video_library_delete')>-1){
      this.deletepermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('video_library_read')>-1){
      this.readpermission = true;
    }
    if(this.userType=='admin'){
      this.editpermission=true;
      this.deletepermission = true;
      this.readpermission = true;
    }
    if(!this.editpermission && !this.deletepermission && !this.readpermission){
      this.router.navigate(['/admin/my-profile']);
    }

  	 this.success_msg=localStorage.getItem('succ_video');
  	 setTimeout (() => {
         this.success_msg="";
         localStorage.setItem('succ_video','');
        }, 2000);
  	this.video_listing();
  }

  video_listing(){
  	this.all_records=[];
  this.adminService.chapterListing(this.baseUrl.baseAppUrl+'admin/video_listing', []).subscribe((res: any) =>  {

      if(res.status == '1'){
        //console.log(res.data.length);
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
            //console.log(res.data[i].id);
            //alert(res.data[i].id);
            this.all_records.push({record_id: j, order_id: res.data[i].id, title: res.data[i].title, sub_title : res.data[i].sub_title,view_count : res.data[i].viewCount, mark_up : res.data[i].markup , action: ''},);
            ['record_id', 'title','sub_title','view_count', 'mark_up', 'action'];
			j++;
          }
          console.log(this.all_records);
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;

      }
      else{
       //this.error_msg = res.message;
      }

      });
	}

	deleteVideo(id){
	 const data = {
      id: id
      };
    this.adminService.deletefromListing(this.baseUrl.baseAppUrl+'admin/video_listing_delete', data).subscribe((res : any) => {
      if(res.status == '1'){
        this.success_msg = res.message;
        this.video_listing();
        setTimeout (() => {
         this.success_msg="";
        }, 2000);
       }
      else{
       	this.error_msg = res.message;
       	setTimeout (() => {
         this.error_msg="";
        }, 2000);
       }
    });
  }

  editVideo(id){
  	//console.log(id, 'adad');
  	localStorage.setItem('for_videoid', id);
    this.router.navigate(['admin/video-library']);
  	//this.router.navigate(['admin/video-library'],{ state: { video_id: id } });
	}


  openDialog(id) {
    this.videoId=id;
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
    //const snack = this.snackBar.open('Snack bar open before dialog');

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
           const data = {
            id: this.videoId
            };
          this.adminService.deletefromListing(this.baseUrl.baseAppUrl+'admin/video_listing_delete', data).subscribe((res : any) => {
            if(res.status == '1'){
              this.success_msg = res.message;
              this.video_listing();
              setTimeout (() => {
               this.success_msg="";
              }, 2000);
             }
            else{
               this.error_msg = res.message;
               setTimeout (() => {
               this.error_msg="";
              }, 2000);
             }
          });

      }
    });
  }


}
