import { Component, OnInit,ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AdminService} from "../services/admin.service";
import { AppGlobals } from '../../app.global';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';

import {AdminSubscriptionDialogComponent} from '../admin-subscription-dialog/admin-subscription-dialog.component';
import {AdminSubscriptionStatusDialogComponent} from '../admin-subscription-status-dialog/admin-subscription-status-dialog.component';


export interface PeriodicElement {
  row_id: number;
  roleName: string;
  status: string;
  action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];


@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss']
})
export class RolesComponent implements OnInit {
  all_records;
	displayedColumns: string[] = ['record_id','roleName', 'action'];
  	dataSource : any;
  	check_sess;
  	user_id;
  	error_msg='';
  	success_msg='';
	@ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;
  constructor(private adminService: AdminService, 
  	private baseUrl: AppGlobals,
  	private router: Router,private dialog: MatDialog) { }
    public doFilter = (value: string) => { console.log('value --- ', value);
      this.dataSource.filter = value.trim().toLocaleLowerCase();
    }

  ngOnInit() {
    this.check_sess=localStorage.getItem('access_token');
    
    if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/admin/login-dashboard']);
    }
    if(localStorage.getItem('user_type')!='admin'){
      this.router.navigate(['/admin/my-profile']);
    }

    this.success_msg=localStorage.getItem('succ_user');
  	setTimeout (() => {
        this.success_msg="";
        localStorage.setItem('succ_user','');
    }, 2000);
    this.getUsers();

  }

  getUsers(){
  	this.all_records=[];
  	this.adminService.roleListing(this.baseUrl.baseAppUrl+'admin/role_listing', this.check_sess).subscribe((res: any) =>  {
      
      if(res.status == '1'){
        console.log(res.data);
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
          
          this.all_records.push({record_id: j, order_id: res.data[i].id, roleName: res.data[i].name, action: ''},);
          ['record_id','roleName','action'];
          j++;
        }
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
		    this.dataSource.paginator = this.paginator;
        console.log('this.all_records', this.dataSource.filteredData);
      }
      else{
       this.error_msg = res.message;
      }

    });
  }

  editRole(id){
  		//localStorage.setItem('for_editUserID', id);
		this.router.navigate(['admin/edit-role/'+id]);   
	}

	addRole(){
		this.router.navigate(['admin/add-roles']);
	}

  openDialog3(id,status){

  }
  
  openDialog2(id) {

		const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      data:{
        message: 'Are you sure want to delete the role?',
        buttonText: { ok: 'Yes', cancel: 'No' }
      }
    });

			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					//this.updateStatus(id);
					const data = { id: id };
          this.adminService.deleteUser(this.baseUrl.baseAppUrl+'admin/deleterole', data, this.check_sess).subscribe((res : any) => {
							if(res.status == '1'){
								this.success_msg = res.message;
								this.getUsers();
								setTimeout (() => {
									this.success_msg="";
								}, 2000);
							}
							else{
								this.error_msg = res.message;
								setTimeout (() => {
									this.error_msg="";
								}, 2000);
							}
					});
				}
			});

	}


}
