import { Component, OnInit, Optional ,Inject, Input, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../../app.global';
import { tokendata } from '../../variable';
import { AuthService } from '../../../service/auth.service';
import { ImageCroppedEvent  } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-popup',
  templateUrl: './image-popup.component.html',
  styleUrls: ['./image-popup.component.scss'],
  providers: [ AppGlobals]
})
export class ImagePopupComponent implements OnInit {

	imageChangedEvent: any = '';
	croppedImage: any = '';
  token='';
  showLoader=false;
  @Input() profile_image: string;
  @Output() imgEvent = new EventEmitter<string>();
  constructor(public dialogRef: MatDialogRef<ImagePopupComponent>,
  	private router: Router,
      private baseUrl: AppGlobals,
      private auth: AuthService,
      private route: ActivatedRoute,
   @Optional() @Inject(MAT_DIALOG_DATA) public data: any) {
    this.imageChangedEvent = data.image;
	}

  ngOnInit() {
  	 this.token=localStorage.getItem('for_token');
        if(this.token == null || this.token ==""){
            this.router.navigate(['/sign-in']);
        }
  }

  close()
  {
     this.dialogRef.close({data:'cancel'});
  }

  update_profile(){
    	//this.formDisable = true; 
      //this.isSubmitted = true;
      //console.log(this.croppedImage);
      this.showLoader=true;
      const fd = new FormData();
          fd.append('user_id', localStorage.getItem('for_userid'));
          fd.append('image', this.croppedImage);
          this.auth.tradeideagetSrvs(this.baseUrl.baseAppUrl+'user/UpdateProfileimage', fd , this.token).subscribe((res : tokendata) => {
            this.showLoader=false;
            if(res.status == '1'){
              this.profile_image = res.data;
            	localStorage.setItem('for_token_profileimg',res.data);
              //localStorage.setItem('profile_image',res.data);
              this.imgEvent.emit(this.profile_image);
              this.dialogRef.close({data:'success', message:res.message});
            	//document.getElementById("close_dialog_box_pass").click();
    	     }
            else{
              //this.error_msg = res.message;
             }
          });


      }

  imageCropped(event: ImageCroppedEvent) {
  		this.croppedImage = event.base64;
  		//console.log(event);
  		//console.log(this.croppedImage);
    }
    imageLoaded() {
        // show cropper
    }
    cropperReady() {
        // cropper ready
    }
    loadImageFailed() {
        // show message
    }

}
