import { Component, OnInit } from '@angular/core';
import {AdminService} from "../../services/admin.service";
import { AppGlobals } from '../../../app.global';
import { AuthService } from '../../../service/auth.service';

declare const $: any;
declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    permission: any;
}
export const ROUTES: RouteInfo[] = [

    // { path: '/admin/dashboard', title: 'Dashboard',  icon: 'dashboard', class: '' },
    { path: '/admin/trading-academy', title: 'Trading Academy',  icon: 'library_books', class: '',permission: ['trading_academy_add'] },
    { path: '/admin/trading-list', title: 'Trading Listing',  icon: 'library_books', class: '',permission: ['trading_academy_read'] },
    { path: '/admin/video-library', title: 'Video Library',  icon: 'library_books', class: '',permission: ['video_library_add'] },
    { path: '/admin/video-listing', title: 'Video Listing',  icon: 'library_books', class: '',permission: ['video_library_read'] },

    { path: '/admin/trading-tools-tutorials', title: 'Trading Tools Tutorials',  icon: 'library_books', class: '',permission: ['trade_tool_read','trade_tool_add','trade_tool_edit'] },
    { path: '/admin/strategies', title: 'Strategies',  icon: 'library_books', class: '',permission: ['strategi_read','strategi_edit','strategi_add'] },
    //{ path: '/admin/prizes', title: 'Prizes',  icon: 'library_books', class: '' },
    //{ path: '/admin/prize-listing', title: 'Prize Listing',  icon: 'library_books', class: '' },
    { path: '/admin/create-stream', title: 'Create Stream',  icon: 'library_books', class: '',permission: ['stream_add'] },
    { path: '/admin/live-stream', title: 'Live Stream',  icon: 'library_books', class: '',permission: ['stream_read'] },
    { path: '/admin/members', title: 'Members',  icon: 'library_books', class: '',permission: ['members_read'] },
    { path: '/admin/contact-enquiry', title: 'Contact Enquiry',  icon: 'library_books', class: '',permission: ['contact_enq_read'] },
    { path: '/admin/testimonials', title: 'Testimonials',  icon: 'library_books', class: '',permission: ['testimonial_read','testimonial_add','testimonial_edit','testimonial_delete'] },
    { path: '/admin/faqs', title: 'Website Faqs',  icon: 'library_books', class: '',permission: ['faq_website_read'] },
    { path: '/admin/dashboard-faqs', title: 'Dashboard Faqs',  icon: 'library_books', class: '',permission: ['faq_dashboard_read'] },
    { path: '/admin/subscription-plan', title: 'Subscription Plan',  icon: 'library_books', class: '',permission: ['subscription_add','subscription_edit','subscription_read','subscription_delete'] },
    //{ path: '/admin/subscription-plan-add', title: 'Subscription Plan Add',  icon: 'library_books', class: '' },
    { path: '/admin/transaction-history', title: 'Transaction History',  icon: 'library_books', class: '',permission: ['tranction_read'] },
    { path: '/admin/roles', title: 'Roles',  icon: 'library_books', class: '',permission: ['admin'] },
    { path: '/admin/sub-admin', title: 'Sub Admin',  icon: 'library_books', class: '',permission: ['admin'] },
    //{ path: '/admin/add-subadmin', title: 'Add Sub Admin',  icon: 'library_books', class: '' },
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  user_id='';
  check_sess : any;
  constructor(private adminService: AdminService,private baseUrl: AppGlobals,) { }

  roles = {'Trading Academy':['trading_academy_add','trading_academy_read','trading_academy_edit','trading_academy_delete',''],
      'Video Library':['video_library_add','video_library_read','video_library_edit','video_library_delete',''],
      'Trading Tools':['trade_tool_add','trade_tool_read','trade_tool_edit','',''],
      'Strategies':['strategi_add','strategi_read','strategi_edit','',''],
      'Stream':['stream_add','stream_read','stream_edit','stream_delete','stream_complete'],
      'Members':['members_add','members_read','members_edit','members_delete','members_manage_subscribe'],
      'Contact Enquiry':['','contact_enq_read','','',''],
      'Testimonials':['testimonial_add','testimonial_read','testimonial_edit','testimonial_delete',''],
      'FAQ Website':['faq_website_add','faq_website_read','faq_website_edit','faq_website_delete',''],
      'FAQ Dashboard':['faq_dashboard_add','faq_dashboard_read','faq_dashboard_edit','faq_dashboard_delete',''],
      'Subscription':['subscription_add','subscription_read','subscription_edit','subscription_delete',''],
      'Transaction History':['','tranction_read','','','']
    }

  ngOnInit() {

    //this.menuItems = ROUTES.filter(menuItem => menuItem);
    // this.menuItems = ROUTES.filter(menuItem => { console.log('Menu Item ----- ',menuItem.permission); return menuItem;

    // });
    this.check_sess=localStorage.getItem('access_token');

    this.user_id = localStorage.getItem('for_userid');

        const data = {id: this.user_id};
        this.adminService.getUserdetail(this.baseUrl.baseAppUrl+'admin/getUserDetail?id='+this.user_id, this.check_sess).subscribe((res : any) => {
          if(res.status == '1'){
            console.log('res.data',res.data);
            localStorage.setItem('capability', res.data.capability);
            let newMenuItems: any = [];
            this.menuItems = ROUTES.filter(menuItem => {
              // if(res.data.capability.split(',',).indexOf(menuItem.permission)>-1 && res.data.user_type!='admin' && menuItem.title!=newMenuItems.title){
              var array1 = res.data.capability.split(',',);
              
              const someExist = menuItem.permission.some((value: any) => array1.includes(value));
              if(someExist && res.data.user_type!='admin' && menuItem.title!=newMenuItems.title){
                newMenuItems.push(menuItem);
                return menuItem;
              }
              else if(res.data.user_type=='admin' && menuItem.title!=newMenuItems.title){
                newMenuItems.push(menuItem);
                return menuItem;
              }
              //console.log('Menu Item ----- ',menuItem.permission);
              //return menuItem;
            });

            // var prop: any;
            // // Step 1. Get all the object keys.
            // let evilResponseProps = Object.keys(this.roles);
            // console.log(evilResponseProps);
            // // Step 2. Create an empty array.
            // let goodResponse = [];
            // // Step 3. Iterate throw all keys.
            // for (prop of evilResponseProps) {
            //     goodResponse.push(evilResponseProps[prop]);
            // }
            // console.log(' ======> ',goodResponse);

          }
      });

  }
  isMobileMenu() {
      if ($(window).width() > 991) {
          return false;
      }
      return true;
  };
}
