import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup,FormGroupDirective, Validators } from '@angular/forms';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AuthService } from '../../service/auth.service';
import { AppGlobals } from '../../app.global';
//import { TradeSuccessThankComponent } from '../../main/dashboard/trade-idea/trade-success-thank/trade-success-thank.component';
import { ContactThankPopupComponent } from '../contact-thank-popup/contact-thank-popup.component';
///import { ReCaptcha2Component } from 'ngx-captcha';



@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
	public contactForm: FormGroup;
	emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
	isSubmitted=false;
  termsAndConditionUrl;
  privacyUrl;
  isSubmitDisable = false;
  ///@ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;
  @ViewChild('langInput', { static: false }) langInput: ElementRef;

  public captchaIsLoaded = false;
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public theme: 'light' | 'dark' = 'light';
  public size: 'compact' | 'normal' = 'normal';
  public lang = 'en';
  public type: 'image' | 'audio';

  constructor(private formBuilder: FormBuilder,private auth: AuthService, private baseUrl: AppGlobals,
      public dialog: MatDialog) { }

  ngOnInit() {
    this.termsAndConditionUrl = this.baseUrl.baseAppUrl+'uploads/site-docs/Website-Terms-and-Conditions.pdf';
    this.privacyUrl = this.baseUrl.baseAppUrl+'uploads/site-docs/Privacy-Policy.pdf';
  	this.contactForm = this.formBuilder.group({
  			name: ['', [Validators.required]],
            email: ['', [Validators.required , Validators.pattern(this.emailPattern)]],
            contact_no: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
            country: ['', [Validators.required]],
            knew_frm_where: ['', [Validators.required]],
            enquiry: ['', [Validators.required]],
            recaptcha: ['', Validators.required]
        });
  }

  handleSuccess(data) {
    console.log(data, 'handleSuccess');
  }

  get contactFrm() { return this.contactForm.controls; }

  submit(){
    	
      this.isSubmitted = true;
    	if (this.contactForm.invalid) {
            return;
        }
        const fd = new FormData();
          fd.append('name', this.contactForm.value.name);
          fd.append('email', this.contactForm.value.email);
          fd.append('contact_no', this.contactForm.value.contact_no);
          fd.append('country', this.contactForm.value.country);
          fd.append('knew_frm_where', this.contactForm.value.knew_frm_where);
          fd.append('enquiry_details', this.contactForm.value.enquiry);
          this.isSubmitDisable = true;
         this.auth.addContactEnquiry(this.baseUrl.baseAppUrl+'user/add_contact_enquiry', fd).subscribe((res : any) => {
            if(res.status == '1'){
              this.isSubmitDisable = false;
            	//this.contactForm.reset(this.contactForm.value);
              ///this.captchaElem.reloadCaptcha();
            	this.contactForm.reset();
              this.isSubmitted = false;
            	const dialogRef = this.dialog.open(ContactThankPopupComponent,{ 
                 data:{ popup_msg : 'Your enquiry has been submitted. A member of our team will get in touch with you shortly.'}
               });
            	dialogRef.afterClosed().subscribe(result => {
                
               });
            	
            }
        })
   }

}
