import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../service/auth.service';
import { ConfirmedValidator } from '../confirmed.validator';

export interface PeriodicElement {
  username 		: string;
  email			: string;
  fullname		: string;
  country		: string;
  password		: string;
  card_number	: string;
  exp_date		: string;
  card_cvv		: string;
  card_name		: string;
  email_msg		: string;
  user_msg		: string;
  success_msg	: string;
}

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
  providers: [ AppGlobals]
})
export class SignupComponent implements OnInit {
  username ='';
	email ='';
	password='';
	conf_password='';
	email_msg ='';
	user_msg ='';
	success_msg ='';
  hide : any;
  hide2 : any;
	signForm: FormGroup;
 	isSubmitted  =  false;
  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) { }
   emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
  
  ngOnInit() {
  	if(localStorage.getItem('s_signup') != null && localStorage.getItem('s_signup') !='')
    	this.success_msg =  localStorage.getItem('s_signup');

  	if(localStorage.getItem('f_user') != null && localStorage.getItem('f_user') !='')
    	this.username =  localStorage.getItem('f_user');
    if(localStorage.getItem('f_email') != null && localStorage.getItem('f_email') !='')
    	this.email =  localStorage.getItem('f_email');
    if(localStorage.getItem('f_password') != null && localStorage.getItem('f_password') !='')
    	this.password =  localStorage.getItem('f_password');
    if(localStorage.getItem('f_confpassword') != null && localStorage.getItem('f_confpassword') !='')
    	this.conf_password =  localStorage.getItem('f_confpassword');
    
    

  	//localStorage.setItem('f_user', this.username);
  	localStorage.setItem('s_signup', '');
  	localStorage.setItem('f_user', '');
    localStorage.setItem('f_email', '');
    localStorage.setItem('f_password', '');
    localStorage.setItem('f_confpassword', '');
    this.signForm = this.formBuilder.group({
            username: ['', Validators.required],
            email: ['', [Validators.required , Validators.pattern(this.emailPattern)]],
            password: ['', [Validators.required, Validators.minLength(8)]],
            conf_password: ['', Validators.required]

        }, {
            validator: ConfirmedValidator('password', 'conf_password')
        });

  }

  get signFrm() { return this.signForm.controls; }


  getusername(evt){
      this.user_msg='';
  }
  getemail(evt){
    if(this.email != "")
      this.email_msg='';
  }

  signup_one(){
   this.isSubmitted = true;
    if (this.signForm.invalid) {
            return;
        }
      const data = {
      username		: this.username,
      email 		: this.email,
      };

      this.auth.signupSrvs(this.baseUrl.baseAppUrl+'signup/user_check', data).subscribe((res : tokendata) => {
      if(res.status == '1'){
        this.email_msg = '';
        this.user_msg = '';
        localStorage.setItem('f_user', this.username);
      	localStorage.setItem('f_email', this.email);
      	localStorage.setItem('f_password', this.password);
      	localStorage.setItem('f_confpassword', this.conf_password);
      	this.router.navigate(['/sign-up-started']);
      }
      else{
      	 if(res.type =='email')
       		this.email_msg = res.message;
       	 else if(res.type =='user')
       	 	this.user_msg = res.message;
       	 //else
       	 	//this.error_msg = res.message;
       }
    });
      /*localStorage.setItem('user', JSON.stringify(data));*/
      
  }

}
