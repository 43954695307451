import { Component, OnInit } from '@angular/core';
import { DialogLogoutComponent } from '../../main/dashboard-header/dialog-logout/dialog-logout.component';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { AppGlobals } from '../../app.global';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  token='';  
	userName;
	profile_img;
	isLogin=false;
  disclaimanerUrl;
  accessrole='';
  MarketingStaff = 'Marketing Staff';  
  constructor(public dialog: MatDialog,private baseUrl: AppGlobals) { }

  ngOnInit() {
    this.disclaimanerUrl = this.baseUrl.baseAppUrl+'uploads/site-docs/Website-Terms-and-Conditions.pdf';
  	this.token = localStorage.getItem('for_token');
  	if(this.token == null || this.token ==""){
  		this.isLogin=false;
  	}else{
  		this.isLogin=true;
  	}
	  this.userName = localStorage.getItem('for_username');
    this.profile_img = localStorage.getItem('profile_image');
    this.accessrole = localStorage.getItem('role');
  }

  CreateConfirmLogout(){
        const dialogRef = this.dialog.open(DialogLogoutComponent, {
      });
      
      dialogRef.afterClosed().subscribe(result => {
        this.ngOnInit();
      });

    }

}
