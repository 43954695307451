import { Component, OnInit, Inject, Optional, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../../app.global';
import { AuthService } from '../../../service/auth.service';
import * as bcrypt from 'bcryptjs';
import { ConfirmedValidator } from '../../confirmed.validator';
import { tokendata } from '../../variable';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-subscribe-trade-notification',
  templateUrl: './subscribe-trade-notification.component.html',
  styleUrls: ['./subscribe-trade-notification.component.scss'],
  providers: [AppGlobals]
})
export class SubscribeTradeNotificationComponent implements OnInit {
  token = '';
  hide = true;
  hide1 = false;
  @Input() emailType: string;

  // constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
  //   @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel) {
  //   // Update view with given values
  //   this.title = data.title;
  //   this.message = data.message;
  // }

  constructor(private formBuilder: FormBuilder, private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    public dialogRef: MatDialogRef<SubscribeTradeNotificationComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    console.log('Received data:', data.type);
    this.emailType = data.type;
  }

  ngOnInit() {
    console.log('Received data: 1', this.emailType);
    this.token = localStorage.getItem('for_token');
    if (this.token == null || this.token == "") {
      this.router.navigate(['/sign-in']);
    }

    //this.getUserDetail();
  }

  selectedOption(type: any){
    this.emailType = type;
  }

  subscribe() {
    //const emailtype = this.emailType;
    let userID = localStorage.getItem('for_userid');
    let datas = {};
    if(this.emailType==='trade_email_subscribe'){
      datas = {
        user_id: userID,
        email_notification: 0,
        trade_email_subscribe: 1
      };
    }
    else if(this.emailType==='unsubscribe'){
      datas = {
        user_id: userID,
        email_notification: 0,
        trade_email_subscribe: 0
      };
    }
    else{
      datas = {
        user_id: userID,
        email_notification: 1,
        trade_email_subscribe: 0
      };
    }
    
    this.auth.signupSrvs(this.baseUrl.baseAppUrl + 'auth/unsubscribe', datas).subscribe((res: tokendata) => {
      if (res.status == '1') {
        this.hide = false;
        this.hide1 = true;
        setTimeout(() => {
          this.dialogRef.close(true);
        }, 1200);
      }
      else {

      }
    });


  }


  // this.auth.changePassword(this.baseUrl.baseAppUrl+'user/changePassword',data, this.token).subscribe((res:any)=>{
  //   if(res.status=='1'){
  //     document.getElementById("close_dialog_box_pass").click();
  //   }
  // });



}
