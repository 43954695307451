import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators,ValidatorFn,FormControl } from '@angular/forms';
import {AdminService} from "../../services/admin.service";
import { AppGlobals } from '../../../app.global';

const greaterThenValidator: ValidatorFn = (control: FormControl) => {  
  console.log(control.value);
  if (parseInt(control.value) <= 0) {
    return {
      'greater': { value: 'control has greater the 0' }
    };
  }
  return null;
};

@Component({
  selector: 'app-add-faqs',
  templateUrl: './add-faqs.component.html',
  styleUrls: ['./add-faqs.component.scss'],
  providers: [ AppGlobals]
})

export class AddFaqsComponent implements OnInit {

  btn_title = 'Add Faqs';
  addForm: FormGroup;
  isSubmitted  =  false;

  check_sess : any;
  hide1 : any;hide2 : any;
  statusdata:any=[];

  public options: Select2Options;
  pastStatus='active';
  success_msg='';
  error_msg='';
  id = '';
  faqtype = 'website';//dashboard
  invalidVideoUrl:boolean = false;
  editpermission = false;
  capability='';
  userType='';
  addpermission=false;
  constructor(
    private adminService: AdminService,
    private baseUrl: AppGlobals,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder
    ) {  }

  ngOnInit() {

    this.id = localStorage.getItem('for_faqsid');
    this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
      this.router.navigate(['/admin/login-dashboard']);
    }

    if(this.userType!='admin' && this.capability.split(',').indexOf('faq_website_add')>-1){
      this.addpermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('faq_website_edit')>-1){
      this.editpermission = true;
    }
    if(this.userType=='admin'){
      this.editpermission=true;
      this.addpermission=true;
    }
    if(!this.editpermission && !this.addpermission){
      this.router.navigate(['/admin/my-profile']);
    }

    if(this.route.snapshot.paramMap.get('id')!=null)this.id = this.route.snapshot.paramMap.get('id');
    
    if(this.id!=''){
        this.btn_title = 'Update';
        const data = {id: this.id};
        this.adminService.getFaqsDetail(this.baseUrl.baseAppUrl+'admin/getFaqsDetail?id='+this.id, this.check_sess).subscribe((res : any) => {
          if(res.status == '1'){              
              this.pastStatus = res.data.status;
              this.addForm = this.formBuilder.group({
                  question: [res.data.question, Validators.required],        
                  answer: [res.data.answer, Validators.required],
                  order_no: [res.data.order_no, [Validators.required,greaterThenValidator]]
              });
          }else{
            
          }
      });

    }else{

      this.btn_title = 'Add';
      this.addForm = this.formBuilder.group({
        question: ['', Validators.required],        
        answer: ['', Validators.required],
        order_no: ['', [Validators.required,greaterThenValidator]]
      });

    }

    this.statusdata = [{ id: 'active', text: 'Active' },{ id: 'inactive', text: 'Inactive' }];

  }

  setStatus(e) {
    this.pastStatus = e;
  }

  get addFrm() { return this.addForm.controls; }

  onSubmit(){
    this.isSubmitted = true;
    if (this.addForm.invalid) {
      return;
    }

    let endpoint = this.baseUrl.baseAppUrl+'admin/add_edit_faqs';
    if(this.id!='')this.addForm.value.id = this.id;
    else this.addForm.value.id = 0;

    this.addForm.value.status = this.pastStatus;
    this.addForm.value.type = this.faqtype;

    this.adminService.addEditFaqs(endpoint, this.addForm.value, this.check_sess).subscribe((data: any) => {
      if(data.status){
            if(this.id!='')localStorage.setItem('succ_faqs', data.message);              
            else localStorage.setItem('succ_faqs', data.message);              
            this.router.navigate(['admin/faqs']);
      }else{        
      }
    });
  }
  
}



