import { NgModule } from '@angular/core';
import { NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material';

import { ComponentsModule } from './components/components.module';
import { AdminLayoutModule } from './admin-layout/admin-layout.module';

import { AdminLayoutComponent } from './admin-layout/admin-layout.component';

import { MatButtonModule} from '@angular/material/button';
import { MatInputModule} from '@angular/material/input';
import { MatRippleModule} from '@angular/material/core';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatTooltipModule} from '@angular/material/tooltip';
import { MatSelectModule} from '@angular/material/select';
import { TradingAcademyComponent } from './trading-academy/trading-academy.component';
import { VideoListComponent } from './video-list/video-list.component';
import { VideoLibraryComponent } from './video-library/video-library.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { LoginDashboardComponent } from './login-dashboard/login-dashboard.component';
import { BlockCopyPasteDirective } from './trading-academy/block-copy-paste.directive';
import { TradingListComponent } from './trading-list/trading-list.component';
import { MatIconModule, MatDialogModule } from '@angular/material';
import { MatButtonToggleModule} from '@angular/material/button-toggle';


import { TruncateadminPipe } from '../pipes/admin-char-limit.pipe';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { AdminSubscriptionDialogComponent } from './admin-subscription-dialog/admin-subscription-dialog.component';
import {AdminSubscriptionStatusDialogComponent} from './admin-subscription-status-dialog/admin-subscription-status-dialog.component';
//const routes: Routes = [{ path: 'admin/dashboard', component: AdminDashboardComponent },];
import { NumberDirective } from './numbers-only.directive';
import { PrizesComponent } from './prizes/prizes.component';
import { Select2Module } from 'ng2-select2';
import { PrizeListingComponent } from './prizes/prize-listing/prize-listing.component';
import { LiveStreamComponent } from './live-stream/live-stream.component';
import { TrackMembersComponent } from './track-members/track-members.component';
import { AddMemberComponent } from './track-members/add-member/add-member.component';
//import { EditMemberComponent } from './track-members/edit-member/edit-member.component';
import { NgxAgoraModule } from 'ngx-agora';
import { environment } from 'src/environments/environment';
import { CreateStreamComponent } from './live-stream/create-stream/create-stream.component';
import { StartCallComponent } from './live-stream/start-call/start-call.component';
import { ContactEnquiryComponent } from './contact-enquiry/contact-enquiry.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule} from '@angular/material/core';
import { InvoiceListComponent } from './track-members/invoice-list/invoice-list.component';
import { ZoomComponent } from './live-stream/zoom/zoom.component';
import { LaunchMeetingComponent } from './live-stream/launch-meeting/launch-meeting.component';
import { TradingToolsTutorialsComponent } from './trading-tools-tutorials/trading-tools-tutorials.component';
import { StrategiesComponent } from './strategies/strategies.component';
import { TestimonialsComponent } from './testimonials/testimonials.component';
import { AddTestimonialsComponent } from './testimonials/add-testimonials/add-testimonials.component';
import { FaqsComponent } from './faqs/faqs.component';
import { AddFaqsComponent } from './faqs/add-faqs/add-faqs.component';
import { DashboardFaqsComponent } from './dashboard-faqs/dashboard-faqs.component';
import { AddDashboardFaqsComponent } from './dashboard-faqs/add-dashboard-faqs/add-dashboard-faqs.component';
import {  MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { SubscriptionPlanComponent } from './subscription-plan/subscription-plan.component';
import { SubscriptionPlanAddComponent } from './subscription-plan-add/subscription-plan-add.component';
import { TransactionHistoryComponent } from './transaction-history/transaction-history.component';
import { AddRolesComponent } from './add-roles/add-roles.component';
import { RolesComponent } from './roles/roles.component';
import { SubAdminComponent } from './sub-admin/sub-admin.component';
import { AddSubadminComponent } from './add-subadmin/add-subadmin.component';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { CreatePasswordComponent } from './create-password/create-password.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

const routes: Routes =[
  {
    path: 'admin/dashboard',
    component: AdminLayoutComponent,
    children: [{
      path: 'admin/dashboard',
      loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  {
    path: 'admin/trading-academy',
    component: AdminLayoutComponent,
    children: [{
      path: 'admin/trading-academy',
      loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  {
    path: 'admin/video-listing',
    component: AdminLayoutComponent,
    children: [{
      path: 'admin/video-listing',
      loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
  	}]
  },
  {
    path: 'admin/video-library',
    component: AdminLayoutComponent,
    children: [{
      path: 'admin/video-library',
      loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  {
    path: 'admin/trading-tools-tutorials',
    component: AdminLayoutComponent,
    children: [{
      path: 'admin/trading-tools-tutorials',
      loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  {
    path: 'admin/strategies',
    component: AdminLayoutComponent,
    children: [{
      path: 'admin/strategies',
      loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  {
    path: 'admin/login-dashboard',
      component: LoginDashboardComponent,
      children: [{
        path: 'admin/login-dashboard',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/trading-list',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/trading-list',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  // {
  //   path: 'admin/prizes',
  //   component: AdminLayoutComponent,
  //   children: [{
  //       path: 'admin/prizes',
  //       loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
  //     }]
  // },
  // {
  //   path: 'admin/prize-listing',
  //   component: AdminLayoutComponent,
  //   children: [{
  //       path: 'admin/prize-listing',
  //       loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
  //     }]
  // },
  {
    path: 'admin/live-stream',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/live-stream',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/members',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/members',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/add-member',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/add-member',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/edit-member/:id',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/edit-member/:id',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  // {
  //   path: 'admin/edit-member',
  //   component: AdminLayoutComponent,
  //   children: [{
  //       path: 'admin/edit-member',
  //       loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
  //     }]
  // },
  {
    path: 'admin/admin-subscription',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/edit-member',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/start-call',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/start-call',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  {
    path: 'admin/zoom-call',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/zoom-call',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  {
    path: 'admin/create-stream',
      component: AdminLayoutComponent,
      children: [{
        path: 'admin/create-stream',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/contact-enquiry',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/contact-enquiry',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  {
    path: 'admin/invoice-detail',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/invoice-detail',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/launch-meeting',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/launch-meeting',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/testimonials',
    component: AdminLayoutComponent,
    children: [{
      path: 'admin/testimonials',
      loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  {
    path: 'admin/add-testimonials',
    component: AdminLayoutComponent,
    children: [{
      path: 'admin/add-testimonials',
      loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  {
    path: 'admin/faqs',
    component: AdminLayoutComponent,
    children: [{
      path: 'admin/faqs',
      loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  {
    path: 'admin/add-faqs',
    component: AdminLayoutComponent,
    children: [{
      path: 'admin/add-faqs',
      loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  {
    path: 'admin/dashboard-faqs',
    component: AdminLayoutComponent,
    children: [{
      path: 'admin/dashboard-faqs',
      loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  {
    path: 'admin/add-dashboard-faqs',
    component: AdminLayoutComponent,
    children: [{
      path: 'admin/add-dashboard-faqs',
      loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
    }]
  },
  {
    path: 'admin/subscription-plan',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/subscription-plan',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/subscription-plan-add',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/subscription-plan-add',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/edit-plan/:id',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/edit-plan/:id',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/transaction-history',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/transaction-history',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/roles',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/roles',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/add-roles',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/add-roles',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/edit-role/:id',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/edit-role/:id',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/sub-admin',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/sub-admin',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/my-profile',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/my-profile',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/change-password',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/change-password',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/add-subadmin',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/change-password',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/edit-subadmin/:id',
    component: AdminLayoutComponent,
    children: [{
        path: 'admin/edit-subadmin/:id',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },
  {
    path: 'admin/create-password',
      component: CreatePasswordComponent,
      children: [{
        path: 'admin/create-password',
        loadChildren: './admin-layout/admin-layout.module#AdminLayoutModule'
      }]
  },


];

export const MY_FORMATS = {
  parse: {
      dateInput: 'LL'
  },
  display: {
      dateInput: 'YYYY-MM-DD',
      monthYearLabel: 'YYYY',
      dateA11yLabel: 'LL',
      monthYearA11yLabel: 'YYYY'
  }
};

@NgModule({

  declarations: [AdminDashboardComponent, AdminLayoutComponent, TradingAcademyComponent, VideoListComponent, VideoLibraryComponent, LoginDashboardComponent, BlockCopyPasteDirective, TradingListComponent , TruncateadminPipe, ConfirmDialogComponent,AdminSubscriptionDialogComponent,AdminSubscriptionStatusDialogComponent, NumberDirective, PrizesComponent, PrizeListingComponent, LiveStreamComponent, TrackMembersComponent, AddMemberComponent, CreateStreamComponent, StartCallComponent, ContactEnquiryComponent, InvoiceListComponent, ZoomComponent, LaunchMeetingComponent,TradingToolsTutorialsComponent,StrategiesComponent,TestimonialsComponent,AddTestimonialsComponent,FaqsComponent,AddFaqsComponent,DashboardFaqsComponent,AddDashboardFaqsComponent, SubscriptionPlanComponent, SubscriptionPlanAddComponent, TransactionHistoryComponent, AddRolesComponent, RolesComponent, SubAdminComponent, AddSubadminComponent, MyProfileComponent, ChangePasswordComponent, CreatePasswordComponent],

	imports: [
  	BrowserModule,
  	FormsModule,
    ReactiveFormsModule,
    CommonModule,
    ComponentsModule,
    AdminLayoutModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatTableModule,
    MatPaginatorModule,
    MatIconModule,
    MatDialogModule,
    Select2Module,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxMaterialTimepickerModule,
    ImageCropperModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    RouterModule.forRoot(routes),
    NgxAgoraModule.forRoot({ AppID: environment.agora.appId })
  ],
  entryComponents: [
    ConfirmDialogComponent,
    AdminSubscriptionDialogComponent,
    AdminSubscriptionStatusDialogComponent
  ],
  exports: [RouterModule],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ]
})
export class AdminModule { }
