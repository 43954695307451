import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AdminService} from "../services/admin.service";
import { AppGlobals } from '../../app.global';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';

import {AdminSubscriptionDialogComponent} from '../admin-subscription-dialog/admin-subscription-dialog.component';
import {AdminSubscriptionStatusDialogComponent} from '../admin-subscription-status-dialog/admin-subscription-status-dialog.component';


export interface PeriodicElement {
  row_id: number;
  record_id: number;
  username: string;
  email: string;
  subs_status: string;
  accessibility_date: string;
  renew_date: string;
  payment_id: string;
  status: string;
  action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-track-members',
  templateUrl: './track-members.component.html',
  styleUrls: ['./track-members.component.scss']
})
export class TrackMembersComponent implements OnInit {
	all_records;
	displayedColumns: string[] = ['record_id','username', 'email','subs_status','plan_name','accessibility_date', 'action'];//'renew_date', 'payment_id','status'
  	dataSource : any;
  	check_sess;
  	user_id;
  	error_msg='';
  	success_msg='';
    editpermission=false;
    addpermission=false;
    deletepermission = false;
    subscribepermission = false;
	readpermission = false;
    capability='';
    userType='';

	@ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;
  constructor(private adminService: AdminService,
  	private baseUrl: AppGlobals,
  	private router: Router,private dialog: MatDialog) { }
public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  ngOnInit() {
  	this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/admin/login-dashboard']);
    }

    this.capability=localStorage.getItem('capability');
    this.userType=localStorage.getItem('user_type');
    if(this.userType!='admin' && this.capability.split(',').indexOf('members_edit')>-1){
      this.editpermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('members_delete')>-1){
      this.deletepermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('members_manage_subscribe')>-1){
      this.subscribepermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('members_add')>-1){
      this.addpermission = true;
    }
	if(this.userType!='admin' && this.capability.split(',').indexOf('members_read')>-1){
		this.readpermission = true;
	}
	
    if(this.userType=='admin'){
      this.editpermission=true;
      this.deletepermission = true;
      this.subscribepermission = true;
      this.addpermission = true;
	  this.readpermission = true;
    }
	
	if(!this.editpermission && !this.deletepermission && !this.subscribepermission && !this.addpermission && !this.readpermission){
		this.router.navigate(['/admin/my-profile']);
	}

    this.success_msg=localStorage.getItem('succ_user');
  	 setTimeout (() => {
         this.success_msg="";
         localStorage.setItem('succ_user','');
        }, 2000);
    this.getUsers();
  }

  getUsers(){
  	this.all_records=[];
  	this.adminService.userListing(this.baseUrl.baseAppUrl+'admin/user_listing', this.check_sess).subscribe((res: any) =>  {

      if(res.status == '1'){
        //console.log(res.data.length);
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
			//console.log(new Date(res.data[i].trial_end*1000), 'trial end');
			if(res.data[i].subscription_status==null)res.data[i].subscription_status = 'cancel';

			if(res.data[i].subscription_status=='cancel' && res.data[i].renew_date<res.data[i].ut)
			{
				res.data[i].accessibility_date = res.data[i].ut;
			}else{
				res.data[i].accessibility_date = res.data[i].renew_date;
			}


			if(res.data[i].subscription_status=='cancel')
			{
				res.data[i].renew_date = '';
			}

			// if(res.data[i].status=='active')
			// {
			// 	res.data[i].status='live';
			// }

			// if(res.data[i].admin_subscription)
			// {
				res.data[i].accessibility_date = res.data[i].end_date;
				//res.data[i].subscription_status = res.data[i].subscription_status;// + ' (Free)';
				var d = new Date();
				var ed = new Date(res.data[i].end_date);
				if(ed > d){
					//res.data[i].status = "active";//'live';
					res.data[i].subscription_status = "active";
				}else{
					res.data[i].subscription_status = "expire";
					//res.data[i].subscription_status = "cancel";
				}
			//}

			res.data[i].trader_accessibility_date = res.data[i].trader_end_date;

			this.all_records.push({record_id: j, order_id: res.data[i].id, username: res.data[i].username, email : res.data[i].email,subs_status : res.data[i].subscription_status,plan_name:res.data[i].plan_name, accessibility_date: res.data[i].accessibility_date,trader_accessibility_date: res.data[i].trader_accessibility_date, renew_date: res.data[i].renew_date, payment_id:res.data[i].payment_id, status:res.data[i].status, action: '', stripe_id:res.data[i].stripe_id,admin_subscription:res.data[i].admin_subscription},);
			['record_id','username', 'email','subs_status', 'plan_name','accessibility_date', 'payment_id', 'status', 'action'];
			j++;
        }
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
		this.dataSource.paginator = this.paginator;
		console.log(this.dataSource);
      }
      else{
       this.error_msg = res.message;
      }

      });
  }

  editUser(id){
  		//localStorage.setItem('for_editUserID', id);
		this.router.navigate(['admin/edit-member/'+id]);
	}

	addUser(){
		this.router.navigate(['admin/add-member']);
	}

	adminSubscription(id){
		localStorage.setItem('for_editUserID', id);
		this.router.navigate(['admin/admin-subscription']);
	}

  getPaymentDetails(stripe_id)
  {
    //console.log(stripe_id, 'id');
    localStorage.setItem("customer_id", stripe_id);
    this.router.navigateByUrl('admin/invoice-detail', {state: {uid: stripe_id}});
	}

	// openSubscriptionpopup(){
	// 	//$('#subscriptionModal').modal('show');AdminSubscriptionDialogComponent
	// }

	updateStatus(id){
		const data = { id: id };
		this.adminService.updateUserStatus(this.baseUrl.baseAppUrl+'admin/updateUserStatus', data, this.check_sess).subscribe((res : any) => {
			if(res.status == '1'){
				this.success_msg = res.message;
				this.getUsers();
				setTimeout (() => {
					this.success_msg="";
				}, 2000);
			}
			else{
				this.error_msg = res.message;
				setTimeout (() => {
					this.error_msg="";
				}, 2000);
			}
		});
	}

	openDialog(id,status) {
	    //this.user_id=id;
		if(status=='active'){
			const dialogRef = this.dialog.open(ConfirmDialogComponent,{
						data:{
							message: 'Are you sure want to active the user?',
							buttonText: { ok: 'Yes', cancel: 'No'
						}
					}
				});
			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.updateStatus(id);
				}
			});
		}else{
			const dialogRef = this.dialog.open(ConfirmDialogComponent,{
						data:{
							message: 'Are you sure want to inactive the user?',
							buttonText: { ok: 'Yes', cancel: 'No'
						}
					}
				});
			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.updateStatus(id);
				}
			});
		}
	}

	openDialog1(id) {
		this.user_id=id;
		const data = { id: id };
		this.adminService.getUserdetail(this.baseUrl.baseAppUrl+'admin/getUserDetail?id='+this.user_id, this.check_sess).subscribe((res : any) => {
			if(res.status == '1'){
				//console.log(res);
				var months = 0;
				//console.log(res.data.start_date);
				if(res.data.start_date != '0000-00-00 00:00:00'){
					var sd = new Date(Date.parse(res.data.start_date));
					var ed = new Date(Date.parse(res.data.end_date));
					months = (ed.getFullYear() - sd.getFullYear()) * 12;
					months -= sd.getMonth();
					months += ed.getMonth();
				}
				//console.log(months <= 0 ? 0 : months);
				//console.log('months',months);
				const dialogRef = this.dialog.open(AdminSubscriptionDialogComponent,{
					data:{
						admin_subscription:res.data.admin_subscription,
						month:months,
						id:res.data.id
					}
				});
				dialogRef.afterClosed().subscribe((submitdata: any) => {
					//console.log('submitdata',submitdata);
					if (submitdata) {
						//console.log('submitdata123',submitdata);
						const userFormData = new FormData();
						userFormData.append('admin_subscription', submitdata.admin_subscription);
						userFormData.append('month', submitdata.month);
						userFormData.append('uid', submitdata.uid);
						//console.log('userFormData',userFormData);

						this.adminService.updateUser(this.baseUrl.baseAppUrl+'admin/update_user_subscription', userFormData, this.check_sess).subscribe((data: any) => {
							if(res.status == '1'){
								this.success_msg = res.message;
								this.getUsers();
								setTimeout (() => {
									this.success_msg="";
								}, 2000);
							}
							else{
								this.error_msg = res.message;
								setTimeout (() => {
									this.error_msg="";
								}, 2000);
							}
						});
					}
				});
			}else{

			}
		});

		//const snack = this.snackBar.open('Snack bar open before dialog');

	}

	//delete user
	openDialog2(id) {

		const dialogRef = this.dialog.open(ConfirmDialogComponent,{
							data:{
								message: 'Are you sure want to delete the user?',
								buttonText: { ok: 'Yes', cancel: 'No' }
							}
						  });

			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					//this.updateStatus(id);
					console.log('user deleted');
					const data = { id: id };
						this.adminService.updateUserStatus(this.baseUrl.baseAppUrl+'admin/deleteUser', data, this.check_sess).subscribe((res : any) => {
							if(res.status == '1'){
								this.success_msg = res.message;
								this.getUsers();
								setTimeout (() => {
									this.success_msg="";
								}, 2000);
							}
							else{
								this.error_msg = res.message;
								setTimeout (() => {
									this.error_msg="";
								}, 2000);
							}
						});
				}
			});

	}

	openDialog3(id,status) {
	    //this.user_id=id;
		if(status=='cancel'){
			const dialogRef = this.dialog.open(ConfirmDialogComponent,{
						data:{
							message: 'Are you sure want to cancle the user subscription?',
							buttonText: { ok: 'Yes', cancel: 'No'
						}
					}
				});
			dialogRef.afterClosed().subscribe((confirmed: boolean) => {
				if (confirmed) {
					this.updateSubscriptionStatus(id,'cancel','');
					// const userFormData = new FormData();
					// //userFormData.append('admin_subscription', submitdata.admin_subscription);
					// userFormData.append('status', 'cancel');
					// // userFormData.append('month', submitdata.month);
					// userFormData.append('uid', id);
					// //console.log('userFormData',userFormData);

					// this.adminService.updateUser(this.baseUrl.baseAppUrl+'admin/update_user_subscription_status', userFormData, this.check_sess).subscribe((res: any) => {
					// 	if(res.status == '1'){
					// 		this.success_msg = res.message;
					// 		this.getUsers();
					// 		setTimeout (() => {
					// 			this.success_msg="";
					// 		}, 2000);
					// 	}
					// 	else{
					// 		this.error_msg = res.message;
					// 		setTimeout (() => {
					// 			this.error_msg="";
					// 		}, 2000);
					// 	}
					// });
				}
			});
		}else{
			var months = 0;
			const dialogRef = this.dialog.open(AdminSubscriptionStatusDialogComponent,{
				data:{
					month:months,
					id:id
				}
			});
			dialogRef.afterClosed().subscribe((submitdata: any) => {
				//console.log('submitdata',submitdata);
				if (submitdata) {
					this.updateSubscriptionStatus(id,'active',submitdata.month);
					// const userFormData = new FormData();
					// userFormData.append('status', 'active');
					// userFormData.append('month', submitdata.month);
					// userFormData.append('uid', id);
					// this.adminService.updateUser(this.baseUrl.baseAppUrl+'admin/update_user_subscription_status', userFormData, this.check_sess).subscribe((res: any) => {
					// 	if(res.status == '1'){
					// 		this.success_msg = res.message;
					// 		this.getUsers();
					// 		setTimeout (() => {
					// 			this.success_msg="";
					// 		}, 2000);
					// 	}
					// 	else{
					// 		this.error_msg = res.message;
					// 		setTimeout (() => {
					// 			this.error_msg="";
					// 		}, 2000);
					// 	}
					// });
				}
			});
		}
	}

	updateSubscriptionStatus(id,status,month){
		const userFormData = new FormData();
		userFormData.append('status', status);
		userFormData.append('month', month);
		userFormData.append('uid', id);
		this.adminService.updateUser(this.baseUrl.baseAppUrl+'admin/update_user_subscription_status', userFormData, this.check_sess).subscribe((res: any) => {
			if(res.status == '1'){
				this.success_msg = res.message;
				this.getUsers();
				setTimeout (() => {
					this.success_msg="";
				}, 2000);
			}
			else{
				this.error_msg = res.message;
				setTimeout (() => {
					this.error_msg="";
				}, 2000);
			}
		});

	}



}
