import { Component, OnInit } from '@angular/core';
import { AuthService } from "src/app/service/auth.service";
import { AppGlobals } from "src/app/app.global";
import { Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
declare var Stripe;

@Component({
  selector: 'app-pay-page',
  templateUrl: './pay-page.component.html',
  styleUrls: ['./pay-page.component.scss']
})
export class PayPageComponent implements OnInit {
  selectedValue: string = '';
  checked: boolean = false;
  indeterminate: boolean = false;

  all_records: { subscription_id: any; planname: any; plan_price: any; plan_type: any; plan_cycle: any; status: any; academy_video: any; video_library: any; trading_strategies: any; trading_academy: any; members: any; expiryDt: string; plandesc: any; adminsubscrib: number; traderexpiry: string; currDate: string; automation_tool: any; is_recommend: any; }[];
  check_sess: string;
  animal: string;
  name: string;
  cardelement: any;
  stripeLoad: any;
  planexists: any;
  checklist: any = [];
  public cardForm: FormGroup;
  cardName: any;
  cardNo: any;
  expDate: any;
  cvv: any;
  isSubmitted = false;
  card_error: any;
  cvv_error: any;
  token: any;
  stripe_id: any;
  isUpdateDisable = false;
  exp_error: any;
  stripeCard: any;
  stripeData: any = [];
  card_id: any;
  plan_id: any;
  cardElement: any;
  msg = '';
  RetraceAutomation = false;
  PPAAutomation = false;
  ScalperAutomation = false;
  currentPlan: any;
  customer_id: "";

  stripe: any;
  card: any;
  cardErrors: string;
  errormultiOption: string;
  alertMsg: string;

  constructor(private baseUrl: AppGlobals,
    private router: Router,
    private auth: AuthService,private formBuilder: FormBuilder) {
      this.cardName = '';
      this.cardNo = '';
      this.stripeCard = '';
      this.cvv = '***';
      this.expDate = '';
      this.stripe_id = '';
      this.stripeData = '';
      this.card_id = '';
      this.plan_id = '';
    }



  ngOnInit() {
    this.check_sess = localStorage.getItem('for_token');
    if (this.check_sess == null || this.check_sess == "") {
      this.router.navigate(['/sign-in']);
    }

    this.stripe = Stripe('pk_test_PcSbmFgKecKw1j3QqFx46tjZ00REDbisJy'); // Replace with your Stripe publishable key
    const elements = this.stripe.elements();

    // Create an instance of the card Element
    this.card = elements.create('card', {
      style: {
          base: {
              color: '#ffffff', // Set text color to white
              fontWeight: '500',
              fontFamily: 'Arial, sans-serif',
              fontSize: '16px',
              '::placeholder': {
                  color: '#cccccc' // Placeholder color to a light gray
              }
          },
          invalid: {
              color: '#ff4d4d', // Change invalid field text color
          },
      },
  });
    this.card.mount('#card-element');

    this.token = localStorage.getItem('for_token');
    this.cardNo = this.cardNo.replace(/\s/g, "");
    //this.cardNo = '********'+this.cardNo.slice(this.cardNo.length - 4);
    this.cardNo = '********'+this.cardNo;
    this.cardForm = this.formBuilder.group({
        cardName: ['', Validators.required],
        cardNo: ['', Validators.required],
        cvv : ['', Validators.required],
        expDate: ['', Validators.required],
        RetraceAutomation: [''],
        PPAAutomation: [''],
        ScalperAutomation: [''],
        selectedValue: [''],

    });

    var userId = localStorage.getItem('for_userid');
    const planSelect = localStorage.getItem('selectedPlan');
    this.currentPlan = (planSelect!=undefined)? JSON.parse(planSelect):'';
    this.plan_id = this.currentPlan.subscription_id
    this.planlist();
  }

  onTabChanged(event: any) {
    const selectedTabIndex = event.index;
    const selectedTabItem = this.all_records[selectedTabIndex];
    this.chooseOption(selectedTabItem);
    this.errormultiOption = "";
    console.log('Tab change - ');
  }

  chooseOption(item: any) {
    this.errormultiOption = '';
    localStorage.setItem('selectedPlan',JSON.stringify(item));
    this.currentPlan = item;
    this.plan_id = item.subscription_id
    this.errormultiOption = "";
  }

  planlist() {
    this.all_records = [];
    this.auth.getPlan(this.baseUrl.baseAppUrl + 'web/planlist', this.check_sess).subscribe((res: any) => {

      if (res.status == '1') {
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
          var checkedPlan = 0;
          var expiryPlan = '';
          var traderexpiryPlan = '';
          var adminsubscrib = 0;
          var currDate = '';
          if (this.checklist.length > 0) {
            for (var k = 0; k < this.checklist.length; k++) {
              if (this.checklist[k].planId == res.data[i].id && this.checklist[k].adminSubscribe == 0) {
                checkedPlan = res.data[i].id;
                expiryPlan = this.checklist[k].endDt;
                traderexpiryPlan = this.checklist[k].tradendDt;
                adminsubscrib = this.checklist[k].adminSubscribe;
                currDate = this.checklist[k].currDate;
              }
              else if (this.checklist[k].adminSubscribe == 1) {
                adminsubscrib = this.checklist[k].adminSubscribe;
                expiryPlan = this.checklist[k].endDt;
                traderexpiryPlan = this.checklist[k].tradendDt;
                currDate = this.checklist[k].currDate;
              }
            }
          }
          console.log(this.currentPlan.plan_cycle+' ----- '+res.data[i].plan_cycle);
          //if(this.currentPlan.plan_cycle===res.data[i].plan_cycle){
            this.all_records.push({ subscription_id: res.data[i].id, planname: res.data[i].plan_name, plan_price: res.data[i].plan_amount, plan_type: res.data[i].plan_type, plan_cycle: res.data[i].plan_cycle, status: res.data[i].status, academy_video: res.data[i].permission_academy_video, video_library: res.data[i].permission_video_library, trading_strategies: res.data[i].permission_trading_strategies, trading_academy: res.data[i].trading_academy, members: res.data[i].members, expiryDt: expiryPlan, plandesc: res.data[i].plan_description, adminsubscrib: adminsubscrib, traderexpiry: traderexpiryPlan, currDate: currDate, automation_tool: res.data[i].automation_tool, is_recommend: res.data[i].is_recommend });
            j++;
          //}
          this.plan_id = res.data[i].id;
          
        }
        console.log(this.all_records);
      }
    });
  }

  get cardFrm() { return this.cardForm.controls; }


  getcard(evt){
    if(this.cardNo.length == 1){
     if (isNaN(parseInt(this.cardNo, 10)))
       this.cardNo ='';
     }
   }
  blurCardErr(evt)
  {
    this.card_error = '';
  }
  blurExpDate(evt)
  {
    this.exp_error = '';
  }
  blurCvv(evt)
  {
    this.cvv_error = ''
  }

  onSubmit() {

    this.isSubmitted = true;

    if (this.cardForm.invalid) {
        return;
    }

    var data = {};
    if(this.cardNo.indexOf('*') > -1)
    {
      data = {
        userId    : localStorage.getItem('for_userid'),
        email    : localStorage.getItem('for_email'),
        cardName  : this.cardName,
        exp_date  : this.expDate,
        card_cvv  : this.cvv,
        stripe_id : this.stripe_id,
        card_id   : this.card_id,
        plan_id   : this.plan_id
      };

    }else{
      data = {
        userId    : localStorage.getItem('for_userid'),
        email    : localStorage.getItem('for_email'),
        cardName     : this.cardName,
        exp_date : this.expDate,
        card_cvv : this.cvv,
        card_number : this.cardNo,
        stripe_id: this.stripe_id,
        plan_id   : this.plan_id
      };

    }

    // var expiry = this.expDate.split("/");
    // const cardDetails = {
    //   number: this.cardName,
    //   exp_month: expiry[0],
    //   exp_year: expiry[1],
    //   cvc: this.cvv
    // };

    //console.log(data, ' - this.cardForm.invalid ---  ',cardDetails); return false;
    this.isUpdateDisable = true;
    //const stripe = Stripe('your-publishable-key');
    console.log(data,' - data'); return false;
    //this.cardForm.value.plan_id = this.plan_id;
    //this.cardForm.value.plan_id = this.plan_id;
    this.auth.planpurchase(this.baseUrl.baseAppUrl+'web/planpurchase', data , this.token).subscribe((res : any) => {

      
      if(res.status == '1'){
        this.card_error = '';
        this.cvv_error = '';
        this.exp_error = '';
        this.isUpdateDisable = false;

        localStorage.setItem('for_accessibility', res.accessibility);

        setTimeout(function(){
          location.reload();
        },2000);
      }
      else{
        if(res.message=="Your card's expiration month is invalid." || res.message=="Your card's expiration year is invalid.")
        {
          this.exp_error = res.message;
          this.card_error = '';
          this.cvv_error = '';
        }else if(res.message=="Your card's security code is invalid."){
          this.cvv_error = res.message;
          this.exp_error = '';
          this.card_error = '';
        }else{
          this.card_error = res.message;
          this.exp_error = '';
          this.cvv_error = '';
        }
        this.isUpdateDisable = false;
        //this.card_error = res.message;
      }
    });
  }

  async createToken() {
    
    this.errormultiOption = '';
    // var arrcheck = [this.ScalperAutomation,this.PPAAutomation,this.RetraceAutomation];
    // // Count true
    // let trueCount = arrcheck.filter(val => val === true).length;
    // if(this.selectedValue==="" && this.currentPlan.automation_tool===1) this.errormultiOption = "Please choose any one option";
    
    // if((trueCount===3 || trueCount<=1) && this.currentPlan.automation_tool===2){
    //   this.errormultiOption = "Please choose any two option";
      
    // }
    // if(this.errormultiOption!==''){
    //   this.isUpdateDisable = false; return false;
    // }
    
    // if(this.currentPlan.automation_tool===0){
    //   this.ScalperAutomation = false;
    //   this.PPAAutomation = false;
    //   this.RetraceAutomation = false;
    // }
    // if(this.currentPlan.automation_tool===3){
    //   this.ScalperAutomation = true;
    //   this.PPAAutomation = true;
    //   this.RetraceAutomation = true;
    // }

    this.isUpdateDisable = true;
    //console.log(this.ScalperAutomation, this.PPAAutomation, this.RetraceAutomation, this.selectedValue); return false;
    const { token, error } = await this.stripe.createToken(this.card);
    
    if (error) {
      var errorElement = document.getElementById('card-errors');
      errorElement.textContent = error.message;
      this.isUpdateDisable = false;
    }
    else{
      
      const data = {
        userId    : localStorage.getItem('for_userid'),
        email    : localStorage.getItem('for_email'),
        customer_id : this.customer_id,
        card_id   : token.card.id,
        plan_id   : this.plan_id,
        selectedTool: this.selectedValue,
        scalper: (this.selectedValue==='Scalper Automation' || this.ScalperAutomation)? true: false,
        ppa: (this.selectedValue==='PPA Automation' || this.PPAAutomation)? true: false,
        retrace: (this.selectedValue==='Retrace Automation' || this.RetraceAutomation)? true: false,
        stoken: token,
        last4:  token.card.last4
      }
      
      this.auth.planpurchase(this.baseUrl.baseAppUrl+'web/planpurchase', data , this.token).subscribe((res : any) => {
        console.log('res.status -- ',res.status);
        if(res.status == '1' || res.status==1){
          this.card_error = '';
          this.cvv_error = '';
          this.exp_error = '';
          this.isUpdateDisable = false;
          
          localStorage.setItem('for_accessibility', res.accessibility);
          localStorage.setItem('selectedPlan','');
          localStorage.setItem('for_usertype', 'members');
          //setTimeout(function(){
            //location.reload();
            this.router.navigate(['/thankyou']);
          //},2000);
          
        }
        else{
          console.log('res.message -- ',res.message);
          if(res.message=="Your card's expiration month is invalid." || res.message=="Your card's expiration year is invalid.")
          {
            this.exp_error = res.message;
            this.card_error = '';
            this.cvv_error = '';
          }
          else if(res.message=="Error"){
            this.alertMsg = "You have already purchased this plan.";
          }
          else if(res.message=="Your card's security code is invalid."){
            this.cvv_error = res.message;
            this.exp_error = '';
            this.card_error = '';
          }else{
            this.card_error = res.message;
            this.exp_error = '';
            this.cvv_error = '';
          }
          this.isUpdateDisable = false;
          this.msg = "Your subscription plan successfully updated.";

          //this.card_error = res.message;
        }
      });

    }



    return false;

    (await this.stripe).createToken(this.cardElement).then((result)=>{
      if (result.error) {
        // Inform the user if there was an error
        var errorElement = document.getElementById('card-errors');
        errorElement.textContent = result.error.message;
      } else {
        console.log('result -- ',result);
        // Send the token to your server
        //stripeTokenHandler(result.token);
        var data: any = {};
        if(this.cardNo.indexOf('*') > -1)
        {
          data = {
            userId    : localStorage.getItem('for_userid'),
            cardName  : this.cardName,
            exp_date  : this.expDate,
            card_cvv  : this.cvv,
            stripe_id : this.stripe_id,
            card_id   : this.card_id,
            plan_id   : this.plan_id
          };

        }else{
          data = {
            userId    : localStorage.getItem('for_userid'),
            cardName     : this.cardName,
            exp_date : this.expDate,
            card_cvv : this.cvv,
            card_number : this.cardNo,
            stripe_id: this.stripe_id,
            plan_id   : this.plan_id
          };

        }
        data.stoken =  result.token.id;

        this.isUpdateDisable = true;
        //this.cardForm.value.plan_id = this.plan_id;
        //this.cardForm.value.plan_id = this.plan_id;
        this.auth.planpurchase(this.baseUrl.baseAppUrl+'web/planpurchase', data , this.token).subscribe((res : any) => {

          if(res.status == '1'){
            this.card_error = '';
            this.cvv_error = '';
            this.exp_error = '';
            this.isUpdateDisable = false;

          }
          else{
            if(res.message=="Your card's expiration month is invalid." || res.message=="Your card's expiration year is invalid.")
            {
              this.exp_error = res.message;
              this.card_error = '';
              this.cvv_error = '';
            }else if(res.message=="Your card's security code is invalid."){
              this.cvv_error = res.message;
              this.exp_error = '';
              this.card_error = '';
            }else{
              this.card_error = res.message;
              this.exp_error = '';
              this.cvv_error = '';
            }
            this.isUpdateDisable = false;
            this.msg = "Your subscription plan successfully updated.";

            //this.card_error = res.message;
          }
        });

      }
    });
  };

  async cancelSubscription() {
    var sub_id = localStorage.getItem('sub_id');
    //console.log(localStorage.getItem('sub_id'), 'sub id');
    this.auth.cancelSubscription(this.baseUrl.baseAppUrl+'user/cancelSubscription?subId='+sub_id,this.token).subscribe((res: any) =>  {
     
    })
  }

  closeAlert()
  {
    this.alertMsg='';
  }



}
