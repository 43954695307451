import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AdminService} from "../services/admin.service";
import { AppGlobals } from '../../app.global';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';

import * as $ from 'jquery';


export interface PeriodicElement {
  row_id: number;
  record_id: number;
  title: string;
  description: string;
  action: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-trading-list',
  templateUrl: './trading-list.component.html',
  styleUrls: ['./trading-list.component.scss'],
  providers: [ AppGlobals]
})
export class TradingListComponent implements OnInit {

	displayedColumns: string[] = ['record_id', 'title','description', 'action'];
  dataSource : any;
  @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;
  all_records=[];
  success_msg='';
  error_msg='';
  check_sess;
  tradeId='';
  capability='';
  userType='';
  editpermission=false;
  deletepermission = false;
  readpermission = false;
  constructor(
  	private adminService: AdminService,
  	private baseUrl: AppGlobals,
  	private router: Router,private dialog: MatDialog) { }

  ngOnInit() {
    localStorage.removeItem('for_tradeid');
    this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/admin/login-dashboard']);
    }

    this.capability=localStorage.getItem('capability');
    this.userType=localStorage.getItem('user_type');
    if(this.userType!='admin' && this.capability.split(',').indexOf('trading_academy_edit')>-1){
      this.editpermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('trading_academy_delete')>-1){
      this.deletepermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('trading_academy_read')>-1){
      this.readpermission = true;
    }
    
    if(this.userType=='admin'){
      this.editpermission=true;
      this.deletepermission = true;
      this.readpermission = true;
    }
    if(!this.editpermission && !this.deletepermission && !this.readpermission){
      this.router.navigate(['/admin/my-profile']);
    }

  	this.success_msg=localStorage.getItem('succ_trade');
  	 setTimeout (() => {
         this.success_msg="";
         localStorage.setItem('succ_trade','');
        }, 2000);

  	this.trading_list();
  }

  trading_list(){
  	this.all_records=[];
  this.adminService.chapterListing(this.baseUrl.baseAppUrl+'admin/channel_listing', []).subscribe((res: any) =>  {

      if(res.status == '1'){
        //console.log(res.data.length);
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
            //console.log(res.data[i].id);
            //alert(res.data[i].id);
            this.all_records.push({record_id: j, order_id: res.data[i].id, title: res.data[i].title, description : res.data[i].description,  action: ''},);
            ['record_id', 'title','description', 'action'];
			j++;
          }
          console.log(this.all_records);
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;

      }
      else{
       //this.error_msg = res.message;
      }

      });
	}

	deleteTrading(id){
	 const data = {
      id: id
      };
    this.adminService.deletefromListing(this.baseUrl.baseAppUrl+'admin/trade_listing_delete', data).subscribe((res : any) => {
      if(res.status == '1'){
        this.success_msg = res.message;
        this.trading_list();
        setTimeout (() => {
         this.success_msg="";
        }, 2000);
       }
      else{
       this.error_msg = res.message;
       	setTimeout (() => {
         this.error_msg="";
        }, 2000);
       }
    });
  }

  editTrading(id){
  	localStorage.setItem('for_tradeid', id);
    this.router.navigate(['admin/trading-academy']);
  	//this.router.navigate(['admin/trading-academy'],{ state: { trade_id: id } });
	}


  openDialog(id) {
    this.tradeId=id;
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
    //const snack = this.snackBar.open('Snack bar open before dialog');

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
           const data = {
            id: this.tradeId
            };
          this.adminService.deletefromListing(this.baseUrl.baseAppUrl+'admin/trade_listing_delete', data).subscribe((res : any) => {
            if(res.status == '1'){
              this.success_msg = res.message;
              this.trading_list();
              setTimeout (() => {
               this.success_msg="";
              }, 2000);
             }
            else{
             this.error_msg = res.message;
               setTimeout (() => {
               this.error_msg="";
              }, 2000);
             }
          });

      }
    });
  }




}
