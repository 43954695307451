import { Component, OnInit,Optional ,Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
//import {BrowserModule, DomSanitizer} from '@angular/platform-browser';1
import { ActivatedRoute, Router } from '@angular/router';
@Component({
  selector: 'app-trade-success-thank',
  templateUrl: './trade-success-thank.component.html',
  styleUrls: ['./trade-success-thank.component.scss']
})
export class TradeSuccessThankComponent implements OnInit {

  popup_msg = 'Thank you for sharing your trade idea with the community.';
  isAdd=0;
  @ViewChild('closeModal', {'static': false}) closeModal: ElementRef;
  constructor(public dialogRef: MatDialogRef<TradeSuccessThankComponent>,
   @Optional() @Inject(MAT_DIALOG_DATA) public data: any,private router: Router) {
     //console.log(data, 'data 123456');
    this.popup_msg = data.popup_msg;
    this.isAdd = data.isAdd;

	}

  ngOnInit() {
  }

  redirectTo()
  {
    this.closeModal.nativeElement.click();
    this.router.navigate(['/my-profile'], { state: { is_profile: true } });
  }

}
