import { Component, OnInit, HostListener } from '@angular/core';
import { PerfectScrollbarConfigInterface,
  PerfectScrollbarComponent, PerfectScrollbarDirective } from 'ngx-perfect-scrollbar';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../service/auth.service';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';
import { TradingAcademyVideoSeriesComponent } from '../trading-academy-video-series/trading-academy-video-series.component';
import { NoAccessComponent } from '../dashboard-header/no-access/no-access.component';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';

@Component({
  selector: 'app-trading-academy2',
  templateUrl: './trading-academy2.component.html',
  styleUrls: ['./trading-academy2.component.scss'],
  providers: [ AppGlobals]
})
export class TradingAcademy2Component implements OnInit {
 public config: PerfectScrollbarConfigInterface = {};
  
  constructor(public dialog: MatDialog,private http: HttpClient,
  private router: Router,
  private baseUrl: AppGlobals,
  private auth: AuthService,
  private route: ActivatedRoute,) { }

  showmore = true;
  showless = false;
  token ='';
  imgBaseUrl = '';
  academyList = [];
  offset: string = "0";
  limit:string = "5";
  academyTotalCount;
  check_sess;
  isApiCalled = false;
  scrollTrigger = false;
  isScrollCalled=false;
  userId;
  isAccessible=false;
  subscription_status='';
  loginType='';
  ngOnInit() {
     this.check_sess=localStorage.getItem('for_token');
        if(this.check_sess == null || this.check_sess ==""){
            this.router.navigate(['/sign-in']);
        }

        /**********/
        this.loginType = localStorage.getItem('for_usertype');
        // if(this.loginType!=='trading-academy'){ //'member'
        //   this.router.navigate(['/error404']);
        // }
        let user_accessibility = localStorage.getItem('for_accessibility');        
        if(user_accessibility!=''){
          let accessibility = JSON.parse(user_accessibility);
          console.log(accessibility);
           if(!accessibility.academy_video || this.loginType=='trading_academy')this.router.navigate(['/error404']);
        }        
        /**********/

    this.userId = localStorage.getItem('for_userid');
    this.getAcademy();
    this.getSubscriptionDetail();
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(e) {
    if(this.academyTotalCount>this.offset && !this.isScrollCalled)
    {
      this.onListScroll();
    }
  }

  onListScroll() {
    
    if(this.academyTotalCount>this.offset && !this.isScrollCalled)
    {
      this.getAcademy();
    }
  }

  getSubscriptionDetail() {
    this.auth.getSubscriptionDetail(this.baseUrl.baseAppUrl+'user/getSubscriptionDetail?userId='+this.userId,this.token).subscribe((res: any) =>  {
        if(res){
          
          this.subscription_status=res.data[0].subscription_status;
          // if(res.data.tradeAccess=='active')
          // {
          //   this.subscription_status='active';
          // }
          // if(res.data.is_accessible==1)
          // {
          //   this.isAccessible=true;
          // }else{
          //   this.isAccessible=false;
          // }
        }
     });
  }

  showmoreless(type){
     if(type == 'more'){
       this.showmore = false;
       this.showless = true;
     }
     else{
      this.showmore = true;
      this.showless = false;
     }
  }

  getAcademy(){
    let params = new HttpParams()
                .set('offset', this.offset)
                .set('limit', this.limit);
    let userId = localStorage.getItem('for_userid');
    this.token=localStorage.getItem('for_token');
        if(this.token == null || this.token ==""){
            this.router.navigate(['/sign-in']);
        }
    
    if(this.offset!=undefined)
    {
      this.isScrollCalled=true;
    this.auth.tradeAcademylistSrvs(this.baseUrl.baseAppUrl+'trade/academy_listing?offset='+this.offset + '&limit='+this.limit+ '&userId='+userId , this.token).subscribe((res: any) =>  {
          if(res){
                this.isApiCalled = true;
              if(res.status==1){
                //this.academyList = res.data;
                if(this.offset=="0")
                {
                  this.academyList = res.data;
                }
                else{
                   this.academyList = this.academyList.concat(res.data);
                  //this.academyList.unshift(res.data[0]);
                }
                //console.log(this.academyList, 'academyList');
                this.imgBaseUrl = this.baseUrl.baseAppUrl+'uploads/trading-academy/';
                this.academyTotalCount = res.total;
                this.offset = res.offset;
                this.scrollTrigger = true;
                this.isScrollCalled=false;
                //this.showSignalList = true;
               }
            }
            else{
             
             }
          });
    }
  }

  startChapter(academy, index){
    if(this.subscription_status=='active')
    {
      this.router.navigate(['/tc-video-series'],{ state: { chapter_id: academy.id, chapterName: academy.title, description: academy.description } });
    }else{
      const dialogRef = this.dialog.open(NoAccessComponent, {
      });
      
      dialogRef.afterClosed().subscribe(result => {
        
      });
    }
    // if(!this.isAccessible)
    // {
    //   const dialogRef = this.dialog.open(NoAccessComponent, {
    //   });
      
    //   dialogRef.afterClosed().subscribe(result => {
        
    //   });
    // }else{
    //   this.router.navigate(['/tc-video-series'],{ state: { chapter_id: academy.id, chapterName: academy.title, description: academy.description } });
    // }
    
  }



}
