import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from "src/app/service/auth.service";
import { AppGlobals } from "src/app/app.global";
import { Router } from "@angular/router";

import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';

export interface PeriodicElement {
  TransactionDate:string;
  PlanName: string;
  PaymentMethod: string;
  InvoiceID: string;
  Amount: string;
  Action: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {TransactionDate: '24-09-2024', PlanName: 'PRO', PaymentMethod: '**** **** **** 3110', InvoiceID: 'bqk-67543', Amount: '$ 80', Action: ''},
  {TransactionDate: '24-09-2024', PlanName: 'ADVANCED', PaymentMethod: '**** **** **** 3110', InvoiceID: 'bqk-67543', Amount: '$ 100', Action: ''},
  {TransactionDate: '24-09-2024', PlanName: 'PRO', PaymentMethod: '**** **** **** 3110', InvoiceID: 'bqk-67543', Amount: '$ 80', Action: ''},
];

@Component({
  selector: 'app-my-subscription-detail',
  templateUrl: './my-subscription-detail.component.html',
  styleUrls: ['./my-subscription-detail.component.scss']
})
export class MySubscriptionDetailComponent implements OnInit {
  displayedColumns: string[] = ['TransactionDate', 'PlanName', 'PaymentMethod', 'InvoiceID', 'Amount', 'Action'];
  //dataSource = ELEMENT_DATA;
  dataSource : any;
  check_sess: string;
  subscriptionlist: any = [];
  currentAmount: number;
  renewDate: string;
  paymentMethod: string;
  planCycle: string;
  planName: string;
  alertMsg = '';
  @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;
  constructor(private baseUrl: AppGlobals,
    private router: Router,
    private authservice: AuthService,) { }

  ngOnInit() {
    this.check_sess = localStorage.getItem('for_token');
    if (this.check_sess == null || this.check_sess == "") {
      this.router.navigate(['/sign-in']);
    }

    var userId = localStorage.getItem('for_userid');
    this.subscriptionlist = [];
    this.authservice.get(this.baseUrl.baseAppUrl + 'web/mysubscription-list?id=' + userId, this.check_sess).subscribe((res: any) => {
      console.log('Result - ',res);
      //this.subscriptionlist = res.data;
      const count = res.data.length;
      this.currentAmount = res.currentplan[0].plan_amount;
      //this.paymentMethod = this.subscriptionlist[count-1].payment_method;
      this.paymentMethod = res.data[0].payment_method;
      this.planName = res.data[0].plan_name;
      this.planCycle = res.currentplan[0].plan_cycle;
      this.renewDate = res.currentplan[0].end_date;
      let j = 1;
      for (let i = 0; i < res.data.length; i++) {
        this.subscriptionlist.push({record_id: j, order_id: res.data[i].id,TransactionDate:res.data[i].created_at, PlanName:res.data[i].plan_name, PaymentMethod:res.data[i].payment_method,InvoiceID:res.data[i].invoice_number, Amount: '$ '+res.data[i].amount,Action:res.data[i].pdf },);
        ['record_id', 'TransactionDate','PlanName', 'PaymentMethod', 'InvoiceID', 'Amount', 'Action'];
        j++;
      }
      
      this.dataSource = new MatTableDataSource<PeriodicElement>(this.subscriptionlist);
      this.dataSource.paginator = this.paginator;

    });
  }

  redirectPage(){
    this.router.navigate(['/subscription-plans']);
  }

  cancelSubscription(){
    var userId = localStorage.getItem('for_userid');
    this.authservice.get(this.baseUrl.baseAppUrl + 'web/cancel-subscription?id=' + userId, this.check_sess).subscribe((res: any) => {
      
      if(res.status == '1'){
        if (res.data.cancellation_details.reason==='cancellation_requested'){
          this.alertMsg = 'Your subscription has been cancelled successfully.';
        }
      }
    });
  }

}
