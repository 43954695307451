import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup,FormGroupDirective, Validators } from '@angular/forms';
import { AppGlobals } from '../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../service/auth.service';

export interface PeriodicElement {
  email     	: string;
  success_msg 	: string;
  error_msg : string;
}

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.scss'],
  providers: [ AppGlobals]
})
export class ForgotComponent implements OnInit {

//@ViewChild(FormGroupDirective) formGroupDirective: FormGroupDirective;

  email = '';
	success_msg = '';
  error_msg ='';
  	forForm: FormGroup;
  	isSubmitted  =  false;
    isDisabled = false;
  	constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) { }

    emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';


  ngOnInit() {

  	this.forForm = this.formBuilder.group({
            email: ['', [Validators.required , Validators.pattern(this.emailPattern)]]

        });

  }
  get forFrm() { return this.forForm.controls; }

  click_message(){
    this.success_msg='';
    this.error_msg='';
  }
  forgot(value: any = undefined): void{
  
  		this.isSubmitted = true;
    if (this.forForm.invalid) {
            return;
        }

      const data = {
      email : this.email,
      base_url_server: this.baseUrl.baseAppUrl
      };
      //console.log(data, 'data');
      //http://localhost:4200/
      this.isDisabled = true;
      this.auth.signupSrvs(this.baseUrl.baseAppUrl+'auth/forgot_password', data).subscribe((res : tokendata) => {
        this.isDisabled = false;
      if(res.status == '1'){
        //this.email='';
        this.error_msg = '';
        this.success_msg = res.message;
        //formDirective.resetForm();
    	 
       this.forForm.reset(value);
        this.isSubmitted = false;
       }
      else{
         this.success_msg = '';
      	 this.error_msg = res.message;
       }
    });
  }

}
