import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppGlobals } from '../../app.global';
import { tokendata } from '../../main/variable';
import { AuthService } from '../../service/auth.service';
import { ConfirmedValidator } from '../../main/confirmed.validator';

@Component({
  selector: 'app-login-dashboard',
  templateUrl: './login-dashboard.component.html',
  styleUrls: ['./login-dashboard.component.scss'],
  providers: [ AppGlobals]
})
export class LoginDashboardComponent implements OnInit {
	username ='';
  password='';
  error_msg='';
  success_msg='';
  hide : any;
  hide2 : any;
  signinForm: FormGroup;
  isSubmitted  =  false;
  check_sess = '';
  capability='';
  userType='';
  editpermission=false;
  deletepermission = false;
  readpermission = false;
  constructor( private formBuilder: FormBuilder, private router: Router,
    private baseUrl: AppGlobals, private auth: AuthService) { }

  ngOnInit() {
  	this.check_sess=localStorage.getItem('access_token');
  	console.log(this.check_sess, 'check_sess');
  	if(this.check_sess != null && this.check_sess !=""){
            this.router.navigate(['/admin/trading-list']);
        }
  	this.signinForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
  }
	get signinFrm() { return this.signinForm.controls; }

  login(){
   localStorage.setItem('s_resetpass', '');
   this.success_msg="";
   this.isSubmitted = true;
    if (this.signinForm.invalid) {
            return;
        }
      const data = {
      username    : this.username,
      password    : this.password
      };

      this.auth.signupSrvs(this.baseUrl.baseAppUrl+'admin/login', data).subscribe((res : tokendata) => {
      if(res.status == '1'){
        console.log(res.data.capability, res.data);
        localStorage.setItem('for_userid', res.data.id);
        localStorage.setItem('for_email', res.data.email);
        localStorage.setItem('for_username', res.data.username);
        localStorage.setItem('access_token', res.token);
        localStorage.setItem('capability', res.data.capability);
        localStorage.setItem('user_type', res.data.user_type);
        this.capability=localStorage.getItem('capability');
        this.userType=localStorage.getItem('user_type');
        if(this.userType!='admin' && this.capability.split(',').indexOf('trading_academy_edit')>-1){
          this.editpermission = true;
        }
        if(this.userType!='admin' && this.capability.split(',').indexOf('trading_academy_delete')>-1){
          this.deletepermission = true;
        }
        if(this.userType!='admin' && this.capability.split(',').indexOf('trading_academy_read')>-1){
          this.readpermission = true;
        }
        
        if(this.userType=='admin'){
          this.editpermission=true;
          this.deletepermission = true;
          this.readpermission = true;
        }
        if(!this.editpermission && !this.deletepermission && !this.readpermission){
          this.router.navigate(['/admin/my-profile']);
        }
        else{
          this.router.navigate(['/admin/trading-list']);
        }
      }
      else{
        this.error_msg = res.message;
       }
    });

  }
 click_message(){
    this.success_msg='';
    this.error_msg='';
  }


}
