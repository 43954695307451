import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder,FormGroup, FormControl, Validators } from '@angular/forms';
import {AdminService} from "../services/admin.service";
import { AppGlobals } from '../../app.global';
import { ConfirmedValidator } from '../confirmed.validator';
import { tokendata } from '../../main/variable';
import { AuthService } from '../../service/auth.service';
import { DateAdapter } from '@angular/material/core';


@Component({
  selector: 'app-add-roles',
  templateUrl: './add-roles.component.html',
  styleUrls: ['./add-roles.component.scss']
})
export class AddRolesComponent implements OnInit {
  minDate:any;
  btn_title = 'Create Role';
  addRoleForm: FormGroup;
  isSubmitted  =  false;

  check_sess : any;
  hide1 : any;hide2 : any;

  email_msg = '';
  user_msg = '';
  country = '101';

  user_id = '';
  userTypes=<any>[];
  role_id='';
  roleData=[];
  error_msg = '';

  constructor(
    private adminService: AdminService,
    private baseUrl: AppGlobals,
    private route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder,
    private auth: AuthService,
    private dateAdapter: DateAdapter<Date>
    ) {
      //this.dateAdapter.setLocale('en-GB'); //dd/MM/yyyy
     }

    emailPattern = '^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$';
    numberOnly = '^[0-9]*$';

    roles = {'Trading Academy':['trading_academy_add','trading_academy_read','trading_academy_edit','trading_academy_delete',''],
      'Video Library':['video_library_add','video_library_read','video_library_edit','video_library_delete',''],
      'Trading Tools':['trade_tool_add','trade_tool_read','trade_tool_edit','',''],
      'Strategies':['strategi_add','strategi_read','strategi_edit','',''],
      'Stream':['stream_add','stream_read','stream_edit','stream_delete','stream_complete'],
      'Members':['members_add','members_read','members_edit','members_delete','members_manage_subscribe'],
      'Contact Enquiry':['','contact_enq_read','','',''],
      'Testimonials':['testimonial_add','testimonial_read','testimonial_edit','testimonial_delete',''],
      'FAQ Website':['faq_website_add','faq_website_read','faq_website_edit','faq_website_delete',''],
      'FAQ Dashboard':['faq_dashboard_add','faq_dashboard_read','faq_dashboard_edit','faq_dashboard_delete',''],
      'Subscription':['subscription_add','subscription_read','subscription_edit','subscription_delete',''],
      'Transaction History':['','tranction_read','','','']
    }

  ngOnInit() {
    this.minDate = new Date();

    this.addRoleForm = this.formBuilder.group({
      role_name: ['', Validators.required]
    });

    this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
      this.router.navigate(['/admin/login-dashboard']);
    }
    
    if(localStorage.getItem('user_type')!='admin'){
      this.router.navigate(['/admin/my-profile']);
    }

    if(this.route.snapshot.paramMap.get('id')!=null)this.role_id = this.route.snapshot.paramMap.get('id');

    if(this.role_id!=''){

        this.btn_title = 'Edit Role';
        const data = {id: this.role_id};
        this.adminService.getRoledetail(this.baseUrl.baseAppUrl+'admin/getRoledetail?id='+this.role_id, this.check_sess).subscribe((res : any) => {
          if(res.status == 1){
            
            this.roleData = (res.data.capability).split(',');
              this.addRoleForm = this.formBuilder.group({
                role_name: [res.data.name, Validators.required]
              });
          }else{

          }
      });

    }else{

      this.btn_title = 'Create Role';
      this.addRoleForm = this.formBuilder.group({
        role_name: ['', Validators.required]
      });

    }
  }

  onSubmit(){
    this.isSubmitted = true;
    
    if (this.addRoleForm.invalid) {
      return;
    }
    
    this.addRoleForm.value.capability =  this.roleData.join();
    let endpoint = this.baseUrl.baseAppUrl+'admin/roleAction';
    if(this.role_id!=''){
      this.addRoleForm.value.role_id = this.role_id;
    }
    
    this.adminService.roleAction(endpoint, this.addRoleForm.value, this.check_sess).subscribe((data: any) => {
      
      if(data.status){
            if(this.role_id!=''){
              this.router.navigate(['admin/roles']);
              localStorage.setItem('succ_user', data.message);
            }else{
              this.router.navigate(['admin/roles']);
              localStorage.setItem('succ_user', data.message);
            }
      }else{
        this.error_msg = data.message;
        setTimeout (() => {
          this.error_msg="";
        }, 3000);
      }
    });

  }

  employeeSelect(event,employeeid,type,base=''){
    
      if( event.target.checked) {
        if(type!=''){
          this.roleData.push(type);
        }
        this.roleData.push(employeeid);
      } else {
        const index = this.roleData.indexOf(employeeid);
        if (index !== -1) {
          this.roleData.splice(index, 1);
        }
      }
      console.log('Roles --- ',this.roleData);
   }

   get rolesFrm() { return this.addRoleForm.controls; }
   asIsOrder(a, b) {
    return 1;
  }

}
