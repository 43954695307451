import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { MainModule } from '../app/main/main.module';
import { AdminModule } from '../app/admin/admin.module';
import { AppGlobals } from './app.global';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
//import { HeaderComponent } from './layout/header/header.component';
//import { FooterComponent } from './layout/footer/footer.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { environment } from 'src/environments/environment';
import { NgxAgoraModule } from 'ngx-agora';
import { ChatService } from './service/chat.service';

//import { DateAgoPipe } from './pipes/date-ago.pipe';
// directive
// import { CreditCardDirective } from './main/directive/credit-card.directive';

@NgModule({
  declarations: [
    // CreditCardDirective,
    AppComponent,
    //HeaderComponent,
    //FooterComponent,
    //DateAgoPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MainModule,
    AdminModule,
    BrowserAnimationsModule,
    MatTableModule,
    NgxAgoraModule.forRoot({ AppID: environment.agora.appId })
  ],
  providers: [AppGlobals,ChatService],
  bootstrap: [AppComponent]
})
export class AppModule {
}
//platformBrowserDynamic().bootstrapModule(AppModule);
