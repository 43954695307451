import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AppGlobals } from "../../app.global";
import { tokendata } from "../variable";
import { AuthService } from "../../service/auth.service";
import { ConfirmedValidator } from "../confirmed.validator";
//import { CometChat } from '@cometchat-pro/chat';
//import { COMETCHAT_CONSTANTS } from '../../Commit-Constants';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";

export interface PeriodicElement {
  username: string;
  password: string;
  error_msg: string;
}

@Component({
  selector: "app-unsubscribe-email",
  templateUrl: "./unsubscribe-email.component.html",
  styleUrls: ["./unsubscribe-email.component.scss"],
  providers: [AppGlobals],
})
export class UnsubscribeEmailComponent implements OnInit {
  userid = "";

  constructor(
    private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private http: HttpClient
  ) {}

  ngOnInit() {
    this.userid = this.route.snapshot.paramMap.get("uid");
    
    if (this.userid != "") {
      const data = {
        user_id: this.userid,
        email_notification: 0,
        trade_email_subscribe: 0,
      };

      this.auth
        .signupSrvs(this.baseUrl.baseAppUrl + "auth/unsubscribe", data)
        .subscribe((res: tokendata) => {
          if (res.status == "1") {
            this.router.navigate(["/unsubscribe"]);
          } else {
          }
        });
    }
    console.log(this.userid);
  }
}
