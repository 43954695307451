import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import {AdminService} from "../services/admin.service";
import { AppGlobals } from '../../app.global';
import * as $ from 'jquery';

@Component({
  selector: 'app-trading-tools-tutorials',
  templateUrl: './trading-tools-tutorials.component.html',
  styleUrls: ['./trading-tools-tutorials.component.scss'],
  providers: [ AppGlobals]
})
export class TradingToolsTutorialsComponent implements OnInit {

  public addVideoAcademyForm: FormGroup;
  submitted: boolean = false;
  btn_title='Tutorials Video';
  
  check_sess;
  errorImage;
  videoDetailError = [];
  invalidVideoUrl:boolean = false;
  urlError =[];
  valid = true;
  embedVideoList = [];
  descriptionError = false;
  mentorsError =  false;
  position;
  deletedVideoList = [];
  success_msg = '';
  error_msg = '';
  tid='';
  capability='';
  userType='';
  editpermission=false;
  addpermission=false;
  deletepermission = false;

  constructor(
      private fb: FormBuilder, 
      private el: ElementRef,
      private adminService: AdminService, 
      private baseUrl: AppGlobals,
      private router: Router
    ) {
  }
  
  ngOnInit() {
      this.check_sess=localStorage.getItem('access_token');
      if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/admin/login-dashboard']);
      }

      this.capability=localStorage.getItem('capability');
      this.userType=localStorage.getItem('user_type');
      if(this.userType!='admin' && this.capability.split(',').indexOf('trade_tool_edit')>-1){
        this.editpermission = true;
      }
      if(this.userType!='admin' && this.capability.split(',').indexOf('trade_tool_add')>-1){
        this.addpermission = true;
      }
      if(this.userType=='admin'){
        this.editpermission=true;
        this.addpermission = true;
      }
      if(!this.editpermission && !this.addpermission){
        this.router.navigate(['/admin/my-profile']);
      }

      this.success_msg=localStorage.getItem('succ_trade1');
      
  	    setTimeout (()=>{
         this.success_msg="";
         localStorage.setItem('succ_trade1','');
         
        }, 2000);

          const data = {  };
          this.adminService.getEditdetail(this.baseUrl.baseAppUrl+'admin/trading_tools_tutorials', data).subscribe((res : any) => {
            if(res.status == '1'){
              //console.log('result',res);
              this.embedVideoList = res.vid_list;
              this.addVideoAcademyForm = this.fb.group({
                videoUrl: this.fb.array(res.vid_list.map(datum => this.generateDatumFormGroup(datum)))
              });
              //this.btn_title='Tutorials Video';
            }
            else{
              }
          });
      
      
      this.addVideoAcademyForm = this.fb.group({
        videoUrl: this.fb.array([this.initSection()])
      });
        
  }

  initSection() {
    return new FormGroup({
      url: new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
      host: new FormControl('', Validators.required),
      duration: new FormControl('', Validators.required),
      views: new FormControl('0'),
      videoId: new FormControl('')
    });
  }

  validVideoUrl(str, prefixes) {
    if(str!='' && str!=null){
      var cleaned = str.replace(/^(https?:)?\/\/(www\.)?/, '');
      for(var i = 0; i < prefixes.length; i++) {
        if (cleaned.indexOf(prefixes[i]) === 0)
          return cleaned.substr(prefixes[i].length)
      }
      return false;
    }
  }

  getControls(frmGrp: FormGroup, key: string) {
    return (<FormArray>frmGrp.controls[key]).controls;
  }

  get signinFrm() { return this.addVideoAcademyForm.controls; }

  private generateDatumFormGroup(datum) {
      return this.fb.group({
        url: this.fb.control({ value: datum.video_url, disabled: false }),
        title: this.fb.control({ value: datum.title, disabled: false }),
        host: this.fb.control({ value: datum.host, disabled: false }),
        duration: this.fb.control({ value: datum.duration, disabled: false }),
        views: this.fb.control({ value: datum.viewCount, disabled: true }),
        videoId: this.fb.control({ value: datum.id, disabled: false })
      });
  }

  addUrl() {
      const add = this.addVideoAcademyForm.get('videoUrl') as FormArray;
      add.push(this.fb.group({
        url: [],
        title: [],
        host: [],
        duration: [],
        views:[],
        videoId: []
      }))
  }

  deleteUrl(index: number) {
    console.log(index,this.embedVideoList[index]);
      if(this.embedVideoList[index]!=undefined && this.embedVideoList[index].id!=undefined)this.deletedVideoList.push(this.embedVideoList[index].id)
      const add = this.addVideoAcademyForm.get('videoUrl') as FormArray;
      add.removeAt(index)
  }

  onSubmit(){
    
    this.submitted = true;
    //console.log(this.addVideoAcademyForm.invalid,this.addVideoAcademyForm.value, 'this.addVideoAcademyForm.invalid');//return false;
      
      //console.log('10');
      for (var i = 0; i <  this.addVideoAcademyForm.value.videoUrl.length; i++){
        
        var isValidUrl = this.validVideoUrl(this.addVideoAcademyForm.value.videoUrl[i].url, ['player.vimeo.com/']);
            
        if(isValidUrl=='' || isValidUrl==undefined){
          this.urlError[i]="Please enter valid url";
          //console.log('9')
          //this.urlError.push({"error": true});
          //console.log(i, 'test', this.urlError);
          this.invalidVideoUrl=true;
          return false;
        }else{
          this.urlError[i]='';
        }
        
        console.log(this.addVideoAcademyForm.value.videoUrl[i].url, 'as', this.addVideoAcademyForm.value.videoUrl[i].title, 'po',this.addVideoAcademyForm.value.videoUrl[i].host)
        
        if(this.addVideoAcademyForm.value.videoUrl[i].url=='' || this.addVideoAcademyForm.value.videoUrl[i].title=='' ||  this.addVideoAcademyForm.value.videoUrl[i].host=='' ||  this.addVideoAcademyForm.value.videoUrl[i].duration==''){
          this.valid = false;
          console.log('8');
          this.videoDetailError[i]="Please add full video detail";
          return false;
        }
      }
      
      //console.log(this.addVideoAcademyForm.value.videoUrl, 'this.addVideoAcademyForm.value.videoUrl', this.embedVideoList);

      const requestData = { videoUrl : JSON.stringify(this.addVideoAcademyForm.value.videoUrl), deleteIdes:this.deletedVideoList };
      
      //console.log(requestData);
      this.adminService.addChapter(this.baseUrl.baseAppUrl+'admin/add_trading_tools_tutorials_video', requestData).subscribe((data : any) => {
        if(data.status == '1'){
          localStorage.setItem('succ_trade1', data.message);
          //this.router.navigate(['admin/trading-tools-tutorials']);
          //this.addVideoAcademyForm.reset();
          this.ngOnInit();
        }
        else{
          
        }
      });
  }

}



