import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../service/auth.service';
import { Select2TemplateFunction, Select2OptionData } from 'ng2-select2';

export interface PeriodicElement {
  username    : string;
  email     : string;
  fullname    : string;
  country   : string;
  mobile    : string;
  password    : string;
  card_no   : string;
  exp_date    : string;
  card_cvv    : string;
  card_name   : string;
  card_error : string;
}

interface Ccode {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-signup-page-two',
  templateUrl: './signup-page-two.component.html',
  styleUrls: ['./signup-page-two.component.scss'],
  providers: [ AppGlobals]
})
export class SignupPageTwoComponent implements OnInit {
    

  /*codes: Ccode[] = [
    {value: '91', viewValue: '+91'},
    {value: '92', viewValue: '+92'},
    {value: '75', viewValue: '+75'}
  ];*/

      fullname ='';
      address = '';
      country = '101';
      mobile ='';
      card_no = '';
      exp_date = '';
      card_cvv = '';
      card_name = '';
      card_error = '';
      exp_error = '';
      cvv_error = '';
  signForm: FormGroup;
  isSubmitted  =  false;
  isDisabled = false;
public options: Select2Options;
public codes: Array<Select2OptionData>;
  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) { }
    numberOnly = '^[0-9]*$';
    //"hello world".replace(/\s/g, "");

  ngOnInit() {

      localStorage.setItem('s_signup', '');
      this.card_no = this.card_no.replace(/\s/g, "");
      this.signForm = this.formBuilder.group({
            fullname: ['', Validators.required],
            //country: ['', Validators.required],
            mobile: ['', [Validators.required, Validators.pattern(this.numberOnly)]],
            card_no: ['', Validators.required],
            exp_date: ['', Validators.required],
            card_cvv: ['', [Validators.required,Validators.pattern(this.numberOnly)]],
            card_name: ['', Validators.required]

        });

      
      this.auth.commonSrvs(this.baseUrl.baseAppUrl+'signup/getcountrylist').subscribe((res : tokendata) => {
      if(res.status == '1'){
        //this.codes = res.data;

        let finalData = <any>[];
        let children = <any>[];
       
        let newData = <any>[];
        res.data.forEach((code, index)=>{
          let c = '+'+code.text;
        let p = {"id":code.id, text:c,additional: {"image":code.country_code.toLowerCase( )+".png"}};
          newData.push(p);
        });
        
        
        this.codes = newData;

      }
      else{
      }
    });

      this.options = {
      templateResult: this.templateResult,
      templateSelection: this.templateSelection
    }

  }

  public templateResult: Select2TemplateFunction = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }

    let image = '<span class="image"></span>';

    if (state.additional.image) {
      image = '<span class="image"><img src="' +'/assets/images/flags/16x16/'+ state.additional.image + '"</span>';
    }

    return jQuery('<span>' + image + ' '  + state.text + '</span>');

  }

  // function for selection template
 /* public templateSelection: Select2TemplateFunction = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {
      return state.text;
    }

    return jQuery('<span><b>' + state.additional.winner + '.</b> ' + state.text + '</span>');
  }*/

  public templateSelection: Select2TemplateFunction = (states: Select2OptionData): JQuery | string => {
    if (!states.id) {
      return states.text;
    }
    let image = '<span class="image"></span>';

    if (states.additional.image) {
      image = '<span class="image"><img src="' +'/assets/images/flags/16x16/'+ states.additional.image + '"</span>';
    }
    return jQuery('<span>' + image + ' '  + states.text + '</span>');
  }

  get signFrm() { return this.signForm.controls; }

  getdate(evt){
   if(this.exp_date.length == 1){
    if (isNaN(parseInt(this.exp_date, 10)))
      this.exp_date ='';
    }
  }
  getcard(evt){
   if(this.card_no.length == 1){
    if (isNaN(parseInt(this.card_no, 10)))
      this.card_no ='';
    }
  }
  blurCodeNo(evt){
    this.card_error = '';
  }
  blurExpDate(evt){
    this.exp_error = '';
  }
  setCode(e){
    this.country = e;
  }
  blurCvv(evt)
  {
    this.cvv_error = ''
  }
  signup_two(){
   this.isSubmitted = true;
    if (this.signForm.invalid) {
            return;
        }

      const data = {
      username    : localStorage.getItem('f_user'),
      email     : localStorage.getItem('f_email'),
      password    : localStorage.getItem('f_password'),
      fullname : this.fullname,
      address : '',
      country : this.country,
      mobile : this.mobile,
      card_number : this.card_no,
      month : '05',
      year : '24',
      exp_date : this.exp_date,
      card_cvv : this.card_cvv,
      card_name : this.card_name
      };
      
      
      this.isDisabled = true;
      this.auth.signupSrvs(this.baseUrl.baseAppUrl+'signup/user_registration', data).subscribe((res : tokendata) => {
        this.isDisabled = false;
      if(res.status == '1'){
        this.card_error = '';
        this.exp_error = '';
        this.cvv_error = '';
        //this.success_msg = res.message;
        localStorage.removeItem('f_password');
        localStorage.removeItem('f_email');
        localStorage.removeItem('f_user');
        localStorage.removeItem('f_confpassword');
        localStorage.removeItem('s_resetpass');
        localStorage.removeItem('s_signup');
        localStorage.removeItem('f_password');

        //localStorage.clear();
        localStorage.setItem('s_signup', res.message);
        this.router.navigate(['/sign-in']);
       }
      else{
        if(res.message=="Your card's expiration month is invalid." || res.message=="Your card's expiration year is invalid." || res.message=="Your card's security code is invalid.")
        {
          this.exp_error = res.message;
          this.card_error = '';
          this.cvv_error = '';
        }else if(res.message=="Your card's security code is invalid."){
          this.cvv_error = res.message;
          this.exp_error = '';
          this.card_error = '';
        }else{
          this.card_error = res.message;
          this.exp_error = '';
          this.cvv_error = '';
        }
      //alert("not");
       //this.error_msg = res.message;
       }
    });

  }

}
