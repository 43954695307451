import { Component, OnInit, Renderer, ElementRef, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import {AdminService} from "../services/admin.service";
import { AppGlobals } from '../../app.global';
import * as $ from 'jquery';

import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';

export interface PeriodicElement {
  id: number;
  video_url: number;
  name: string;
  biography: string;
  status: string;
  action: string;
}

const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-dashboard-faqs',
  templateUrl: './dashboard-faqs.component.html',
  styleUrls: ['./dashboard-faqs.component.scss'],
  providers: [ AppGlobals]
})

export class DashboardFaqsComponent implements OnInit {

  displayedColumns: string[] = ['rowid','id', 'question','answer','order_no','status', 'action'];
  dataSource : any;
  @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;
  all_records=[];
  error_msg='';
  success_msg='';
  check_sess;
  videoId='';
  editpermission=false;
  deletepermission = false;
  completepermission = false;
  readpermission = false;
  capability='';
  userType='';
  addpermission=false;

  constructor(
  	private adminService: AdminService,
  	private baseUrl: AppGlobals,
  	private router: Router,private dialog: MatDialog) { }

  ngOnInit() {
    this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/admin/login-dashboard']);
    }

    this.capability=localStorage.getItem('capability');
    this.userType=localStorage.getItem('user_type');
    if(this.userType!='admin' && this.capability.split(',').indexOf('faq_dashboard_edit')>-1){
      this.editpermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('faq_dashboard_delete')>-1){
      this.deletepermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('faq_dashboard_add')>-1){
      this.addpermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('faq_dashboard_read')>-1){
      this.readpermission = true;
    }
    if(this.userType=='admin'){
      this.editpermission=true;
      this.deletepermission = true;
      this.addpermission=true;
      this.readpermission = true;
    }
    if(!this.editpermission && !this.deletepermission && !this.addpermission && !this.readpermission){
      this.router.navigate(['/admin/my-profile']);
    }

  	 this.success_msg=localStorage.getItem('succ_faqs');
  	 setTimeout (() => {
         this.success_msg="";
         localStorage.setItem('succ_faqs','');
        }, 2000);
  	this.listing();
  }

  listing(){
  	this.all_records=[];
    this.adminService.faqsListing(this.baseUrl.baseAppUrl+'admin/faqs_listing?type=dashboard', []).subscribe((res: any) =>  {

      if(res.status == '1'){
        //console.log(res.data.length);
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
            //console.log(res.data[i].id);
            //alert(res.data[i].id);
            this.all_records.push({rowid: i+1,id: res.data[i].id, question: res.data[i].question, answer : res.data[i].answer,order_no:res.data[i].order_no,status : res.data[i].status, action: ''},);
            ['rowid','id', 'question','answer','order_no','status', 'action'];
            j++;
          }
          //console.log(this.all_records);
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;

      }
      else{
       //this.error_msg = res.message;
      }

      });
	}

	// deleteVideo(id){
	//  const data = {
  //     id: id
  //     };
  //   this.adminService.deletefromListing(this.baseUrl.baseAppUrl+'admin/video_listing_delete', data).subscribe((res : any) => {
  //     if(res.status == '1'){
  //       this.success_msg = res.message;
  //       this.video_listing();
  //       setTimeout (() => {
  //        this.success_msg="";
  //       }, 2000);
  //      }
  //     else{
  //      	this.error_msg = res.message;
  //      	setTimeout (() => {
  //        this.error_msg="";
  //       }, 2000);
  //      }
  //   });
  // }

  edit(id){
  	//console.log(id, 'adad');succ_testimonials
  	localStorage.setItem('for_faqsid', id);
    this.router.navigate(['admin/add-dashboard-faqs']);
	}

  add(){
    localStorage.setItem('for_faqsid', '');
		this.router.navigate(['admin/add-dashboard-faqs']);
	}


  openDialog(id) {
    this.videoId=id;
    const dialogRef = this.dialog.open(ConfirmDialogComponent,{
      data:{
        message: 'Are you sure want to delete?',
        buttonText: {
          ok: 'Yes',
          cancel: 'No'
        }
      }
    });
    //const snack = this.snackBar.open('Snack bar open before dialog');

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
           const data = {
            id: this.videoId
            };
          this.adminService.deletefromListing(this.baseUrl.baseAppUrl+'admin/delete-faqs', data).subscribe((res : any) => {
            if(res.status == '1'){
              this.success_msg = res.message;
              this.listing();
              setTimeout (() => {
               this.success_msg="";
              }, 2000);
             }
            else{
               this.error_msg = res.message;
               setTimeout (() => {
               this.error_msg="";
              }, 2000);
             }
          });

      }
    });
  }
}



