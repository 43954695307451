/*export const COMETCHAT_CONSTANTS = {
    APP_ID: '203207a6d563a72',
    REGION: 'US',
    API_KEY: 'b2797a3bfdd08920c63eaf38043ea3c9df133e73',
    GUID: 'forexgroup',
    REST_API_KEY: 'fb8911a01f49cd18bce070e0b3f64fbce6f4b5d0'
}
*/
export const COMETCHAT_CONSTANTS = {
    APP_ID: '20660e8f6c949b3',
    REGION: 'US',
    API_KEY: 'a1f80b566247fadda62a027241daa5dadb789b9a',
    GUID: 'forexgroup',
    REST_API_KEY: '6832f53aec0c22c6482cd1fdeb3b830d4b218f17'
}

// export const COMETCHAT_CONSTANTS = {
//     APP_ID: '210267bc47ad8a0e',
//     REGION: 'US',
//     API_KEY: '09e14db2365a692bc9f4361d6aac86f696f6e7b0',
//     GUID: 'forexgroup',
//     REST_API_KEY: '8399421171274726d5f4d26b18cc24ebe119eb6c'
// }
