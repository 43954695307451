import { Component, OnInit, ViewChild, HostListener, ChangeDetectorRef, Optional, Inject, ElementRef, OnDestroy } from '@angular/core';

import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
//import {MatDialog} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../service/auth.service';
import { TradeIdeaComponent } from './trade-idea/trade-idea.component';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';
//import { CometChat } from '@cometchat-pro/chat';
//import { COMETCHAT_CONSTANTS } from '../../Commit-Constants';
import * as io from "socket.io-client";
import { environment } from '../../../environments/environment';

import { DatePipe } from '@angular/common';
import { CountdownComponent, CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { DialogPopupComponent } from '../dialog-popup/dialog-popup.component';
import { NoAccessComponent } from '../dashboard-header/no-access/no-access.component';

import {BrowserModule, DomSanitizer} from '@angular/platform-browser'
export interface DialogData {
  otpdata: any;
}
import { formatDate } from '@angular/common';
import { format } from 'date-fns';
const MINIUES = 1000 * 60;
const CountdownTimeUnits: Array<[string, number]> = [
  ['Y', 1000 * 60 * 60 * 24 * 365], // years
  ['M', 1000 * 60 * 60 * 24 * 30], // months
  ['D', 1000 * 60 * 60 * 24], // days
  ['H', 1000 * 60 * 60], // hours
  ['m', 1000 * 60], // minutes
  ['s', 1000], // seconds
  ['S', 1], // million seconds
];

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [ AppGlobals, DatePipe]
})

export class DashboardComponent implements OnInit, OnDestroy {
  private socket = io(environment.socketApiPath);

  check_sess ='';
  remoteCalls: string[] = [];
  dateFnsConfig: CountdownConfig = {};
  moreThan24Hours: CountdownConfig = {};
  isAccessible=false;

  constructor(public dialog: MatDialog,
  private http: HttpClient,
  private router: Router,
  private baseUrl: AppGlobals,
  private auth: AuthService,
  private route: ActivatedRoute,
  private formBuilder: FormBuilder,
  private cdRef: ChangeDetectorRef,
  private datePipe:DatePipe
  ) { }
  token ='';
  imgBaseUrl = '';
  profileBaseUrl = '';
  signalList = [];
  offset: string = "0";
  limit:string = "5";
  type = 'directive';
  signalTotalCount;
  isSocket = 0;
  showSignalList = false;
  comet_token;
  userId;
  subscription_status='';
  subscription_text;
  isSubCalled = false;
  isAlertShow = true;
  liveStreams = [];
  livestreamIndex:number;
  subscribe_streams=<any>{};
  play_id;
  showVideoBlock= false;
  localCallId = 'agora_local';
  recordedStreamsCalled=false;
  recordedStreams = [];
  recordedStreamsOffset: string = "0";
  recordedStreamsLimit:string = "6";
  recordedStreamTotalCount;
  streamImgBaseUrl;
  isRecordedScrollCalled=false;
  pause=false;
  subscriptionID;
  isEventListenerInitialize = 0;
  subscription_end_date;
  compare_sub_end_date;
  compare_end_date_time;
  trial_end_formatted;
  role = 2
  leaveUrl = '/dashboard'
  meeting_id;
  meeting_password;
  stream_access_status;
  isDragging: boolean = false;
  startX: number = 0;
  scrollLeft: number = 0;


  isSignalsCalled=false;
  isSignalScrollCalled=false;

  accessrole:any;
  MarketingStaff = 'Marketing Staff';


  @HostListener('window:scroll', ['$event'])
  onScroll(e) {
    //console.log(this.signalTotalCount,'scroll called', this.offset);
    if(this.signalTotalCount>this.offset)
    {
      this.onListScroll();
    }
    //console.log(this.recordedStreamsCalled, 'this.recordedStreamsCalled', this.recordedStreamTotalCount, '>', this.recordedStreamsOffset);
    if(!this.isRecordedScrollCalled && this.recordedStreamTotalCount>this.recordedStreamsOffset)
    {
      this.getRecordedStreams();
    }
  }

  onListScroll() {
    if(this.signalTotalCount>this.offset && this.recordedStreamsCalled == false && !this.isSignalScrollCalled)
    {
      this.getSignals();
    }
  }

  ngOnDestroy() {



  }

  ngOnInit() {

    this.comet_token = localStorage.getItem('comet_authtoken');
    this.userId=localStorage.getItem('for_userid');
    this.accessrole = localStorage.getItem('role');

    /**********/
    let loginType = localStorage.getItem('for_usertype');
    if(loginType!='members'){ //'member'
      this.router.navigate(['/error404']);
    }
    let user_accessibility = localStorage.getItem('for_accessibility');
    if(user_accessibility!=''){
      let accessibility = JSON.parse(user_accessibility);
        if(!accessibility.members)this.router.navigate(['/error404']);
    }
    /**********/

    this.getSubscriptionDetail();

    this.auth.getStreamSubscribed().subscribe(info => {
      //console.log('getStreamSubscribed',info);
      this.isEventListenerInitialize = info.streamSubscribed;
    });





    this.streamImgBaseUrl = this.baseUrl.baseAppUrl+'uploads/agora_stream/';

    localStorage.setItem('activeTab', 'dashboard');
      this.check_sess=localStorage.getItem('for_token');
        if(this.check_sess == null || this.check_sess ==""){
            this.router.navigate(['/sign-in']);
        }

      /*if(comet_token==null)
      {
        this.cometLogin();
      }*/

  }

  onMouseDown(event: MouseEvent): void {
    this.isDragging = true;
    const element = event.target as HTMLElement;
    element.classList.add('active');
    this.startX = event.pageX - element.offsetLeft;
    this.scrollLeft = element.scrollLeft;
  }

  onMouseMove(event: MouseEvent): void {
    if (!this.isDragging) return;
    event.preventDefault();
    const element = event.target as HTMLElement;
    const x = event.pageX - element.offsetLeft;
    const walk = (x - this.startX) * 2; // Adjust scroll speed
    element.scrollLeft = this.scrollLeft - walk;
  }

  onMouseUp(): void {
    this.isDragging = false;
    const element = document.querySelector('.drag-box-row') as HTMLElement;
    element.classList.remove('active');
  }

  onTimerFinished(e, i)
  {
    //console.log(e, 'completed timer');
    if(e.action=='done')
    {
      this.liveStreams[i].isStreamIsLive = true;
    }
  }

  joinZoomStream(url, stream)
  {
    //console.log(stream, 'stream');
    //console.log(this.compare_end_date_time, 'subscription_end_date', stream.db_live_date+ ' '+ stream.live_time);
    //if(stream.db_live_date<this.compare_sub_end_date)
    var stream_time = stream.db_live_date+ ' '+ stream.live_time;
    if(stream_time<this.compare_end_date_time)
    {
      window.open(url);
    }else{
      const dialogRef = this.dialog.open(NoAccessComponent, {
      });

      dialogRef.afterClosed().subscribe(result => {

      });
    }

  }

  getLiveStreams()
  {
    this.auth.getLiveStreams(this.baseUrl.baseAppUrl+'user/getLiveStreams'+'?sub_status='+this.subscription_status+'&sub_updated_at='+this.subscription_end_date,this.check_sess).subscribe((res: any) =>  {

        if(res){
          if(res.is_socket==undefined)
          {
            let stream = [];
            for (var i = 0; i <  res.data.length; i++) {
              if(res.data[i].meeting_id!='' && res.data[i].status==1)
              {
                this.meeting_id = res.data[i].meeting_id;
                localStorage.setItem('m_id', this.meeting_id);
              }
              if(res.data[i].meeting_password!='' && res.data[i].status==1)
              {
                this.meeting_password = res.data[i].meeting_password;

                localStorage.setItem('m_pwd', this.meeting_password);
              }
              this.meeting_password = res.data.meeting_password;
              //console.log(res.data[i].live_date, 'res.data[i].live_date one');
              // res.data[i].live_date = this.datePipe.transform(res.data[i].live_date, 'yyyy-MM-dd')+' '+res.data[i].live_time+":00";
              // res.data[i].dd = this.datePipe.transform(res.data[i].live_date, 'yyyy-MM-dd')+' '+res.data[i].live_time+":00";
              res.data[i].db_live_date = res.data[i].live_date;
              res.data[i].sort_date = res.data[i].live_date.replaceAll('-','')+res.data[i].live_time.replaceAll(':','');

              res.data[i].live_date = res.data[i].live_date+' '+res.data[i].live_time+":00";
              res.data[i].dd = res.data[i].live_date+' '+res.data[i].live_time+":00";




              var aestTime = new Date().toLocaleString("en-US", {timeZone: "Australia/Sydney"});


              var Sydney = new Date(aestTime).toString();
              var Sydney_num = Date.parse(Sydney);

               var date_new:any = new Date(res.data[i].live_date.replace(/\s/, 'T'));
               var date_num = Date.parse(date_new);
              //console.log(+new Date(res.data[i].live_date), 'diff', Sydney_num);

              //let diffInMilliSeconds:any = ((+new Date(res.data[i].live_date) - (Sydney_num)) / 1000);
              let diffInMilliSeconds:any = ((+date_num - (Sydney_num)) / 1000);
              //diffInMilliSeconds = diffInMilliSeconds.toFixed(0);
              diffInMilliSeconds = parseInt(diffInMilliSeconds);
              //const days = Math.floor(diffInMilliSeconds / 86400);
              var days:any = (diffInMilliSeconds / 86400);
              days = parseInt(days);
              // var days:any = diffInMilliSeconds / 86400;
              // days = days.toFixed(0);
              // console.log(days, 'days before fixed')
              diffInMilliSeconds -= days * 86400;
              //diffInMilliSeconds = diffInMilliSeconds - (days * 86400);

              var hours:any = (diffInMilliSeconds / 3600) % 24;
              hours = parseInt(hours);
              //diffInMilliSeconds -= hours * 3600;
              // var hours:any = (diffInMilliSeconds / 3600) % 24;
              // hours = hours.toFixed(0);
               diffInMilliSeconds = diffInMilliSeconds - (hours * 3600);

              // calculate minutes
              var minutes:any = (diffInMilliSeconds / 60) % 60;
              minutes = parseInt(minutes);
              diffInMilliSeconds -= minutes * 60;
              // var minutes:any = (diffInMilliSeconds / 60) % 60;
              // minutes = minutes.toFixed(0);
              // diffInMilliSeconds = diffInMilliSeconds - (minutes * 60);
              let difference = '';
              let diff = '';
              res.data[i].isStreamIsLive = false;
              if (days > 0) {
                difference += (days === 1) ? `${days} day, ` : `${days} days, `;

              }

              if(days<0)
              {
                res.data[i].isStreamIsLive = true;
              }

              difference += (hours === 0 || hours === 1) ? `${hours} hour, ` : `${hours} hours, `;

              //console.log(days, 'days12', diffInMilliSeconds, 'hours12', hours);

              difference += (minutes === 0 || hours === 1) ? `${minutes} minutes` : `${minutes} minutes`;
              res.data[i].live_date = difference;
              res.data[i].l_d = days+':'+hours+':'+minutes+':'+diffInMilliSeconds;


              console.log('diffInMilliSeconds',res.data[i],diffInMilliSeconds);

              if(days<=0)
              {
                this.moreThan24Hours = {
                  leftTime: 60*60*hours+60*minutes+diffInMilliSeconds,
                  formatDate: ({ date, formatStr }) => {
                    let duration = Number(date || 0);

                    return CountdownTimeUnits.reduce((current, [name, unit]) => {
                      if (current.indexOf(name) !== -1) {
                        const v = Math.floor(duration / unit);
                        duration -= v * unit;
                        return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
                          return v.toString().padStart(match.length, '0');
                        });
                      }
                      return current;
                    }, formatStr);
                  },
                }
                res.data[i].moreThan24Hours = this.moreThan24Hours;
              }



              stream.push(res.data[i]);
              if(res.data[i].status==1)
              {
                this.remoteCalls = [];
                this.livestreamIndex = i;
                this.joinstream(res.data[i].channel_id);
              }
            }
            this.liveStreams = stream;

            //this.liveStreams = this.liveStreams.sort((a, b) => (a.l_d > b.l_d) ? 1 : -1);

            this.liveStreams = this.liveStreams.sort((a, b) => a.sort_date - b.sort_date);



          }else{
            if(res.isAdd==1)
            {
              //console.log(res.data[0], 'res daya');
              //console.log(res.data[0].live_date.indexOf('hours'), 'is hour');
              if(res.data[0].live_date.indexOf('hours') !== -1 || res.data[0].live_date.indexOf('hour') !== -1)
              {
                //console.log('if part');
              }else{
                //console.log('else part');
                res.data[0].sort_date = res.data[0].live_date.replaceAll('-','')+res.data[0].live_time.replaceAll(':','');
                //res.data[0].live_date = this.datePipe.transform(res.data[0].live_date, 'yyyy-MM-dd')+' '+res.data[0].live_time+":00";
                res.data[0].db_live_date = res.data[0].live_date;
                res.data[0].live_date = res.data[0].live_date+' '+res.data[0].live_time+":00";
                var aestTime = new Date().toLocaleString("en-US", {timeZone: "Australia/Sydney"});
              //console.log('AEST time: '+ (new Date(aestTime)).toString())
              //console.log(res.data[0].live_date, 'res.data[i].live_date');

              var Sydney = new Date(aestTime).toString();
              var Sydney_num = Date.parse(Sydney);
              var date_new:any = new Date(res.data[0].live_date.replace(/\s/, 'T'));
               var date_num = Date.parse(date_new);

              //console.log(+new Date(res.data[0].live_date), 'diff', Sydney_num);
              //let diffInMilliSeconds = Math.floor((+new Date(res.data[0].live_date) - +Sydney_num) / 1000);
              let diffInMilliSeconds:any = Number((+date_num - (Sydney_num)) / 1000);
              diffInMilliSeconds = parseInt(diffInMilliSeconds);

              //const days = Math.floor(diffInMilliSeconds / 86400);
              var days:any = Number(diffInMilliSeconds / 86400);
              //console.log(days, 'days before fixed')
              days = parseInt(days);
              diffInMilliSeconds -= days * 86400;

              //const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
              var hours:any = Number(diffInMilliSeconds / 3600) % 24;
              hours = parseInt(hours);
              diffInMilliSeconds -= hours * 3600;
              // calculate minutes
              //const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
              var minutes:any = Number(diffInMilliSeconds / 60) % 60;
              minutes = parseInt(minutes);
              diffInMilliSeconds -= minutes * 60;

                  let difference = '';
                  if (days > 0) {
                    difference += (days === 1) ? `${days} day, ` : `${days} days, `;
                }

                difference += (hours === 0 || hours === 1) ? `${hours} hour, ` : `${hours} hours, `;

                difference += (minutes === 0 || hours === 1) ? `${minutes} minutes` : `${minutes} minutes`;
                res.data[0].live_date = difference;
                res.data[0].l_d = days+':'+hours+':'+minutes+':'+diffInMilliSeconds;
                if(days<=0)
                {
                  this.moreThan24Hours = {
                    leftTime: 60*60*hours+60*minutes+diffInMilliSeconds,
                    formatDate: ({ date, formatStr }) => {
                      let duration = Number(date || 0);

                      return CountdownTimeUnits.reduce((current, [name, unit]) => {
                        if (current.indexOf(name) !== -1) {
                          const v = Math.floor(duration / unit);
                          duration -= v * unit;
                          return current.replace(new RegExp(`${name}+`, 'g'), (match: string) => {
                            return v.toString().padStart(match.length, '0');
                          });
                        }
                        return current;
                      }, formatStr);
                    },
                  }
                  res.data[0].moreThan24Hours = this.moreThan24Hours;
                }

              }

                //console.log(res.data[0], 'socket dtaa');
              /*if(this.liveStreams.length<3)
              {
                this.liveStreams.unshift(res.data[0]);
                this.liveStreams.sort((a, b) => (a.l_d > b.l_d) ? 1 : -1);
              }*/

                this.liveStreams.unshift(res.data[0]);
                //console.log(this.liveStreams, 'liveStreams');

                //this.liveStreams =  this.liveStreams.sort((a, b) => (a.l_d > b.l_d) ? 1 : -1);
                this.liveStreams = this.liveStreams.sort((a, b) => a.sort_date - b.sort_date);

            }
            if(res.isAdd==0)
            {
              //console.log(res.data, 'data of sockket res');
              for (var i = 0; i <  this.liveStreams.length; i++) {
                if(this.liveStreams[i].id==res.data.id)
                {
                  this.remoteCalls = [];
                  this.livestreamIndex = i;
                  //this.liveStreams[i].status = 1;
                  this.liveStreams[i].status = res.data.status;
                  if(res.isScreenShare!=1 && res.data.status!=3)
                  {
                    this.meeting_id = res.data.meeting_id;
                    this.meeting_password = res.data.meeting_password;
                    localStorage.setItem('m_id', this.meeting_id);
                    localStorage.setItem('m_pwd', this.meeting_password);
                    //this.joinMeeting(res.data.meeting_id, res.data.meeting_password)
                  }

                  if(res.data.status==3)
                  {
                    this.remoteCalls = [];
                    this.liveStreams[this.livestreamIndex].status=3;
                    this.showVideoBlock=false;
                    this.liveStreams.splice(this.livestreamIndex, 1);
                  }


                }
              }

            }

          }

        }
      })
  }

  joinMeeting(stream)
  {
    var stream_time = stream.db_live_date+ ' '+ stream.live_time;
    //if(this.stream_access_status=='active')
    if(this.isAccessible)
    {
      this.router.navigate([],{ state: { meetingNumber: this.meeting_id, passWord: this.meeting_password } }).then(result => {  window.open('/join-meeting', '_blank'); });
    }else{
      const dialogRef = this.dialog.open(NoAccessComponent, {
      });

      dialogRef.afterClosed().subscribe(result => {

      });
    }


  }

  // startMeeting(signature) {
  //   console.log(signature, 'signature');
  //   document.getElementById('zmmtg-root').style.display = 'block';
  //   var d = document.getElementById("zmmtg-root");
  //   d.className += " custom-zoom-root";

  //   ZoomMtg.init({
  //     leaveUrl: this.leaveUrl,
  //     isSupportAV: true,
  //     meetingInfo:<any>[ 'topic', 'host', ],
  //     success: (success) => {
  //       ZoomMtg.join({
  //         signature: signature,
  //         meetingNumber: this.meeting_id,
  //         userName: 'Anji',
  //         apiKey: environment.zoomApiKey,
  //         userEmail: '',
  //         passWord: this.meeting_password,
  //         success: (success) => {
  //           console.log(success)
  //         },
  //         error: (error) => {
  //           console.log(error)
  //         }
  //       })

  //     },
  //     error: (error) => {
  //       console.log(error)
  //     }
  //   })
  // }

  screenJoinStream(uid) {



  }

  joinstream(uid) {



  }

  closeAlert()
  {
    this.isAlertShow=false;

  }

  getRecordedStreams()
  {
    //console.log(this.recordedStreamsOffset, 'this.recordedStreamsOffset');

    this.isRecordedScrollCalled=true;
    this.auth.getRecordedStreams(this.baseUrl.baseAppUrl+'user/getRecordedStreams?offset='+this.recordedStreamsOffset+'&limit='+this.recordedStreamsLimit ,this.check_sess).subscribe((res: any) =>  {
        if(res.status==1){
          this.recordedStreamsCalled=true;
          //this.recordedStreamsCalled=false;
          this.isRecordedScrollCalled=false;
          this.recordedStreamsOffset = res.offset;
          this.recordedStreamTotalCount = res.total;
          this.streamImgBaseUrl = this.baseUrl.baseAppUrl+'uploads/agora_stream/';
          this.recordedStreams = this.recordedStreams.concat(res.data);
          this.recordedStreams.sort((a, b) => (a.updated_at < b.updated_at) ? 1 : -1);
          let stream=[];
          // for (var i = 0; i <  res.data.length; i++) {
          //     res.data[i].db_live_date=res.data[i].live_date;
          //     res.data[i].live_date = this.datePipe.transform(res.data[i].live_date, 'yyyy-MM-dd')+' '+res.data[i].live_time+":00";

          //     let diffInMilliSeconds = Math.floor((+new Date(res.data[i].live_date) - +new Date()) / 1000);
          //     const days = Math.floor(diffInMilliSeconds / 86400);
          //     diffInMilliSeconds -= days * 86400;

          //     const hours = Math.floor(diffInMilliSeconds / 3600) % 24;
          //     diffInMilliSeconds -= hours * 3600;
          //     // calculate minutes
          //     const minutes = Math.floor(diffInMilliSeconds / 60) % 60;
          //     diffInMilliSeconds -= minutes * 60;

          //     let difference = '';
          //     if (days > 0) {
          //       difference += (days === 1) ? `${days} day, ` : `${days} days, `;
          //     }

          //     difference += (hours === 0 || hours === 1) ? `${hours} hour, ` : `${hours} hours, `;

          //     difference += (minutes === 0 || hours === 1) ? `${minutes} minutes` : `${minutes} minutes`;
          //     res.data[i].live_date = difference;
          //     this.recordedStreams.push(res.data[i]);
          //     this.recordedStreams.sort((a, b) => (a.updated_at < b.updated_at) ? 1 : -1);
          //     console.log(this.recordedStreams, 'Recorded STream');

          //   }

        }else{
          this.recordedStreamsCalled=true;
        }
      })
  }

  getSubscriptionDetail() {
    this.auth.getSubscriptionDetail(this.baseUrl.baseAppUrl+'user/getSubscriptionDetail?userId='+this.userId,localStorage.getItem('for_token')).subscribe((res: any) =>  {
        if(res){
          console.log(res.data, ' - res');
          this.subscription_end_date=res.data.end_date_time;
          this.compare_sub_end_date = res.data.compare_end_date;
          this.compare_end_date_time = res.data.compare_end_date_time;
          this.trial_end_formatted = res.data.trial_end_formatted;
          if(res.data.tradeAccess!=undefined)
          {
            this.stream_access_status= res.data.tradeAccess;
          }

          if(res.data.is_accessible==1)
          {
            this.subscription_status='active';
            this.isAccessible=true;
          }else{
            this.subscription_status = res.data.subscription_status;
            this.isAccessible=false;
          }

          this.getSignals();
          this.getLiveStreams();


          //console.log(res.data, 'res');
          //localStorage.setItem('subscription_status', this.subscription_status);
          this.isSubCalled = true;
            //console.log(this.subscription_status,'this.subscription_status')
          if((this.subscription_status=='cancel' || this.subscription_status=='expire') && res.data.is_accessible==0)
          {
            //console.log('cancel called');
            this.subscription_text = 'You do not have an active subscription. Upgrade now to gain full access.';
            //this.socket.disconnect();
            /*this.socket.on('disconnect', function(){
                console.log('user disconnected');
            });*/
          }else if(this.subscription_status=='trial')
          {
           this.subscription_text = 'Your Subscription is in trial period, Start your subscription to fully access the website.';
          }
          //this.cdRef.detectChanges();
        }
     });
  }

  openRecordedVimeoStream(streamData){
    //console.log(streamData, 'stream data', streamData.db_live_date, 'qq', streamData.live_time);
    var stream_time = streamData.live_date+ ' '+ streamData.live_time;

    //console.log(stream_time, 'stream_time',this.compare_end_date_time)
    //if(stream_time<this.compare_end_date_time && this.stream_access_status=='active')
    if((stream_time<this.compare_end_date_time || stream_time<this.trial_end_formatted) || this.isAccessible)
    {
      streamData.video_popup_url = "https://player.vimeo.com/video/"+streamData.recorded_stream_id;

      const dialogRef = this.dialog.open(DialogPopupComponent, {
        data: { url: streamData.video_popup_url }
      });
    }else{
      const dialogRef = this.dialog.open(NoAccessComponent, {
      });

      dialogRef.afterClosed().subscribe(result => {

      });
    }
    }

  // cometLogin(UID) {
  //   const appSetting = new CometChat.AppSettingsBuilder().setRegion(COMETCHAT_CONSTANTS.REGION).subscribePresenceForAllUsers().build();

  //     CometChat.init(COMETCHAT_CONSTANTS.APP_ID, appSetting).then(() => {

  //       let httpOptions = { headers: new HttpHeaders({ 'Content-Type': 'application/json', 'apikey': COMETCHAT_CONSTANTS.REST_API_KEY, 'appid': COMETCHAT_CONSTANTS.APP_ID, 'Accept': 'application/json' }) };
  //       var data = {};
  //       this.http.post<any>('https://api-us.cometchat.io/v3.0/users/'+UID+'/auth_tokens', data,httpOptions).subscribe(
  //         (res:any) => {


  //            CometChat.login(res.data.authToken).then(
  //               (User:any) => {
  //                 //console.log('Login token: ', User);
  //                 //this.comet_token = user.getAuthToken();
  //                 localStorage.setItem('comet_authtoken', res.authToken);
  //                 //console.log("Login Successful:", { User });

  //                 // User loged in successfully.
  //               },
  //               error => {
  //                 //console.log("Login failed with exception:", { error });
  //                 // User login failed, check error and take appropriate action.
  //               }
  //            );

  //               //console.log(res, 'auth_tokens');
  //             })


  //     });

  // }

  swtichToSignals()
  {
    this.isSignalsCalled = false;
    this.recordedStreamsCalled = false;
    this.recordedStreamsOffset = '0';
    this.recordedStreamTotalCount = 0;
    this.recordedStreams = [];
    this.offset='0';
    this.signalList=[];
    this.getSignals();
  }

  getSignals() {
    //console.log('called getSignals');
    this.isSignalScrollCalled = true;
    let params = new HttpParams()
                .set('offset', this.offset)
                .set('limit', this.limit);
    this.token=localStorage.getItem('for_token');
        if(this.token == null || this.token ==""){
            this.router.navigate(['/sign-in']);
        }
    //console.log(localStorage.getItem('subscription_status'), 'aa');

    if(this.offset!=undefined)
    {

    this.auth.tradeideaListSrvs(this.baseUrl.baseAppUrl+'trade/signal_listing?offset='+this.offset + '&limit='+this.limit  + '&user_id='+localStorage.getItem('for_userid')+'&sub_status='+this.subscription_status+'&sub_updated_at='+this.subscription_end_date,this.isSocket, this.token).subscribe((res: any) =>  {
          if(res){
            this.isSignalsCalled = true;
            console.log('socekt comming',res);

              if(res.status==1){

                this.offset = res.offset;
                this.imgBaseUrl = this.baseUrl.baseAppUrl+'uploads/trade/';
                this.profileBaseUrl = this.baseUrl.baseAppUrl+'uploads/profile/';
                this.signalTotalCount = res.total;

                  if(this.offset=="0")
                  {
                    this.signalList = [];
                  }
                  console.log(res.data, 'data');

                  if(res.is_socket==0)
                  {
                    this.signalList = this.signalList.concat(res.data);
                    this.isSocket = 1;
                  }
                  else{
                    //console.log(res.data[0]);
                    // if(localStorage.getItem('subscription_status')!='cancel' && res.is_edit!=1)
                    // {
                    //   this.signalList.unshift(res.data[0]);
                    // }

                    if(res.is_edit==1)
                    {
                      for (var i = 0; i <  this.signalList.length; i++) {
                        if(this.signalList[i].id==res.data[0].id)
                        {
                          this.signalList[i]=res.data[0];
                        }
                      }
                      console.log('edit signal id is '+ res.data[0].id);
                    }else this.signalList.unshift(res.data[0]);
                  }

                //console.log(this.offset, 'ass offset');
                this.showSignalList = true;
               }
               this.isSignalScrollCalled=false;
            }
            else{

             }
          });
    }
  }




  tradeDetailpage(id,frm=''){
    localStorage.setItem('for_tradeid', id);
    localStorage.setItem('for_tradeidfrm', frm);
    this.router.navigate(['/signals-detail-view']);
  }

  tradelikeDislike(id,ind, created_by){
    //console.log(ind, 'index');
      //alert(ind);
     this.token=localStorage.getItem('for_token');
        if(this.token == null || this.token ==""){
            this.router.navigate(['/sign-in']);
        }

      const data = {
        user_id : localStorage.getItem('for_userid'),
        post_id : id,
        trade_created_by: created_by
      };
    this.auth.tradeideaLikeSrvs(this.baseUrl.baseAppUrl+'trade/post_like', data , this.token).subscribe((res: any) =>  {
            if(res.status==1){
              this.signalList[ind].totallike = res.total;
              if(res.action =='like')
                this.signalList[ind].userlike = 1;
              else
                this.signalList[ind].userlike = 0;
              //this.signalList[ind].totallike+1;
              //console.log(this.signalList, 'res', this.imgBaseUrl);
             }
            else{

             }
          });

  }

      // Create stream Video modal
      //public dialog: MatDialog
    CreateStreamVideo(status){
      //console.log(status, 'status');
      // if(status=='open')
      // {
      //   var streamIds = Object.keys(this.subscribe_streams);
      //   var randomId = streamIds[Math.floor(Math.random() * streamIds.length)];
      //   this.subscribe_streams.stop();


      // //console.log(this.subscribe_streams, 'stream subscribe');
      // const id = this.getRemoteId(this.subscribe_streams);
      // //console.log(id, 'remote id');
      // this.play_id = id;
      // if (!this.remoteCalls.length) {
      //   if(this.remoteCalls.indexOf(id) === -1) {
      //       this.remoteCalls.push(id);
      //       console.log(this.remoteCalls);
      //   }
      //   //this.remoteCalls.push(id);
      // }
      // //console.log(this.remoteCalls, 'remote calls');
      //   setTimeout(() => this.subscribe_streams.play(id), 1000);
      //   //this.subscribe_streams.play(this.play_id);
      //   this.showVideoBlock = true;
      // }else{
      //   this.pause=true;
      //   this.showVideoBlock = false;
      // }

    }

    pauseStream(){
      this.pause=true;
      this.subscribe_streams.stop();
    }

    playAgain(){
      // this.pause=false;
      // const id = this.getRemoteId(this.subscribe_streams);
      // this.play_id = id;
      // if (!this.remoteCalls.length) {
      //   /*if(this.remoteCalls.indexOf(id) === -1) {
      //       this.remoteCalls.push(id);
      //       console.log(this.remoteCalls);
      //   }*/
      //   this.remoteCalls.push(id);
      // }
      // setTimeout(() => this.subscribe_streams.play(id), 1000);
    }

    openRecordedStream(streams){
      //console.log(streams, 'streams');
      const dialogRef = this.dialog.open(CreateStreamVideoDialog, {
        data: { video_id: streams.recorded_stream_id, filename: streams.filename }
      });
    }

      // Create stream Video modal
      //public dialog: MatDialog
    CreateTradeIdea(){
      const dialogRef = this.dialog.open(TradeIdeaComponent, {
        height: "100vh",
        maxHeight: "640px"
      });

      dialogRef.afterClosed().subscribe(result => {
        //this.ngOnInit();
      });
    }
}


/**  Create confirm logout  **/
@Component({
  selector: 'dialog-create-stream-video',
  templateUrl: './dialog-create-stream-video.html',
  styleUrls: ['./dashboard.component.scss']
  })
  export class CreateStreamVideoDialog {
    rCalls: string[] = [];
    //popup_stream;
    video_id;
    player_id;
     @ViewChild('videoPlayer', {static: false}) videoplayer: ElementRef;
    constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any,private activatedRoute: ActivatedRoute, private baseUrl: AppGlobals, private sanitizer: DomSanitizer) {
    //var url= this.baseUrl.baseAppUrl+'uploads/output/06d43e07-e384-47fe-8083-b623f4bcc0cc/'+'0_20200731151353964.mp4';
    var url = this.baseUrl.baseAppUrl+'output/'+data.video_id+'/'+data.filename;

    this.video_id = sanitizer.bypassSecurityTrustResourceUrl(url);
    //console.log(this.video_id.duration, 'duration');
    //console.log(this.video_id, 'video_id');
     }

     toggleVideo() {
      this.videoplayer.nativeElement.play();
      }

  }


