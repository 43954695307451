import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[credit-card]'
})
export class CreditCardDirective {
  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    let trimmed = input.value.replace(/\s+/g, '');
    if (trimmed.length > 16) {
      trimmed = trimmed.substr(0, 16);
    }


    const nums = trimmed.split('');
    const numbers1 = [];
    nums.forEach(element => {
      if (!isNaN(parseInt(element, 10))) {
        numbers1.push(element);
      }
    });
    trimmed = numbers1.join(''); 
    
    const numbers = [];
    for (let i = 0; i < trimmed.length; i += 4) {
      numbers.push(trimmed.substr(i, 4));
    }

    input.value = numbers.join(' ');

  }
}



