import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appExpdate]'
})
export class ExpdateDirective {

  @HostListener('input', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    const input = event.target as HTMLInputElement;

    let trimmed = input.value.replace('/', '');
    //let trimmed = input.value.replace(/\s/g, "");
    
    if (trimmed.length > 4) {
      trimmed = trimmed.substr(0, 4);
    }

    const nums = trimmed.split('');
    const numbers = [];
    let i = 0;
    nums.forEach(element => {
      if (!isNaN(parseInt(element, 10))) {
        if (i == 2) {
          numbers.push('/');
        }
        numbers.push(element);
        i++;
      }
      else{
        //alert(element);
      }
    });

    input.value = numbers.join('');


  }

}
