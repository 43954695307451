import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AdminService} from "../../services/admin.service";
import { AppGlobals } from '../../../app.global';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';

export interface PeriodicElement {
  row_id: number;
  record_id: number;
  customer_id: string;
  invoice_id: string;
  renews_at: string;
  amount_paid: string;
  invoice_pdf:string;
}
const ELEMENT_DATA: PeriodicElement[] = [];
@Component({
  selector: 'app-invoice-list',
  templateUrl: './invoice-list.component.html',
  styleUrls: ['./invoice-list.component.scss']
})
export class InvoiceListComponent implements OnInit {
	cust_id;
	all_records;
	displayedColumns: string[] = ['record_id','customer_id', 'invoice_id','renews_at','amount_paid', 'invoice_pdf'];
  	dataSource : any;
  	check_sess;
  	error_msg='';
  	success_msg='';
	@ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;
  constructor(private adminService: AdminService,private baseUrl: AppGlobals,private router: Router) {
   //this.user_id = this.router.getCurrentNavigation().extras.state.uid;
	}

  ngOnInit() {
  	this.check_sess=localStorage.getItem('access_token');
  	this.cust_id = localStorage.getItem('customer_id');
  	console.log(this.cust_id, 'cust_id');
  	if(this.cust_id=='' ||this.cust_id==null)
  	{
  		this.router.navigate(['/members']);
  	}
  	this.getInvoices();
  }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  getInvoices()
  {
  	this.all_records=[];
  	this.adminService.userListing(this.baseUrl.baseAppUrl+'admin/getInvoiceDetails/?cusID='+this.cust_id, this.check_sess).subscribe((res: any) =>  {
      	console.log(res, 'res');
      if(res.status == '1'){
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
        	this.all_records.push({record_id: j, order_id: res.data[i].id, customer_id: res.data[i].customer_id, invoice_id : res.data[i].invoice_id,renews_at : res.data[i].renews_at, amount_paid: res.data[i].amount_paid,invoice_pdf: res.data[i].invoice_pdf },);
            ['record_id','customer_id', 'invoice_id','renews_at','amount_paid','invoice_pdf'];
			j++;
          }
          this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
          this.dataSource.paginator = this.paginator;
          localStorage.setItem('customer_id', '');
   
      }
      else{
       this.error_msg = res.message;
      }

      });
  }

}
