import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router , ParamMap} from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../service/auth.service';
import { ConfirmedValidator } from '../confirmed.validator';


export interface PeriodicElement {
  password		: string;
  conf_password	: string;
  error_msg		: string;
  success_msg : string;
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  providers: [ AppGlobals]
})
export class ResetPasswordComponent implements OnInit {
	password='';
	conf_password ='';
	error_msg='';
  success_msg ='';
	resetForm: FormGroup;
   isSubmitted  =  false;
   isDisabled = false;
 	userid ='';
 	code ='';
  hide : any;
  hide2 : any;
  constructor(private router: Router,
    private baseUrl: AppGlobals,
    private auth: AuthService,
    private route: ActivatedRoute,
    private formBuilder: FormBuilder) { }

  ngOnInit() {
  	this.userid = this.route.snapshot.paramMap.get('userid');
  	this.code = this.route.snapshot.paramMap.get('code');
  		
    this.resetForm = this.formBuilder.group({
            password: ['', [Validators.required, Validators.minLength(8)]],
            conf_password: ['', Validators.required]

        }, {
            validator: ConfirmedValidator('password', 'conf_password')
        });   
  }
  get resetFrm() { return this.resetForm.controls; }

  click_message(){
    this.success_msg='';
    this.error_msg='';
  }

  reset_password(){
   this.isSubmitted = true;
    if (this.resetForm.invalid) {
            return;
        }
      const data = {
      	password			: this.password,
      	confirm_password 	: this.conf_password,
      	code 				: this.code,
      	user_id				: this.userid
      };

      //console.log(data);
      this.isDisabled = true;
      this.auth.signupSrvs(this.baseUrl.baseAppUrl+'auth/reset_password', data).subscribe((res : tokendata) => {
        this.isDisabled = false;
      if(res.status == '1'){
        this.success_msg = res.message;
        this.password = '';
        this.conf_password = '';
        localStorage.setItem('s_resetpass', 'Your password has been reset');
        this.router.navigate(['/sign-in']);
      }
      else{
      	 	this.error_msg = res.message;
      	 	localStorage.setItem('s_resetpass', '');
       	}
    });
      /*localStorage.setItem('user', JSON.stringify(data));*/
      
  }


}
