//import { BrowserModule } from '@angular/platform-browser';
//import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule,ReactiveFormsModule, } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Routes, RouterModule } from '@angular/router';
import { FlexLayoutModule } from "@angular/flex-layout";

import { DateAgoPipe } from '../pipes/date-ago.pipe';
import { TruncatePipe } from '../pipes/char-limit.pipe';
import { TitlecasestringPipe } from '../pipes/titlecase-string.pipe';
import { ImageCropperModule } from 'ngx-image-cropper';
import {
  MatButtonModule,
  MatDividerModule,
  MatFormFieldModule,
  MatIconModule,
  MatMenuModule,
  MatSelectModule,
  MatTableModule,
  MatTabsModule,
  MatOptionModule,
  MatListModule,
  MatPaginatorModule,
  MatSortModule,
  MatInputModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatDialogModule,
  MatChipsModule,
  MatRadioModule,
  MatProgressSpinnerModule,
} from '@angular/material';

import { NgxShimmerLoadingModule } from  'ngx-shimmer-loading';
import {AutosizeModule} from 'ngx-autosize';
// import components
import { HomeComponent } from './home/home.component';
import { SignupComponent } from './signup/signup.component';
import { SignupPageTwoComponent } from './signup-page-two/signup-page-two.component';
import { SigninComponent } from './signin/signin.component';
import { ForgotComponent } from './forgot/forgot.component';
import { VerifyComponent } from './verify/verify.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignalsDetailViewComponent } from './signals-detail-view/signals-detail-view.component';
import { CreateImageDialog } from './signals-detail-view/signals-detail-view.component';
import { CreateStreamVideoDialog } from './dashboard/dashboard.component';

import { CreateUpdateCardDialog } from './my-subscriptions/my-subscriptions.component';
import { CreatecancelCardDialog } from './my-subscriptions/my-subscriptions.component';

import { UnsubscribeEmailComponent } from './unsubscribe-email/unsubscribe-email.component';

// directive
import { CreditCardDirective } from './directive/credit-card.directive';
import { ExpdateDirective } from './directive/expdate.directive';
import { AutoFocusDirective } from './directive/auto-focus.directive';
import { MySubscriptionsComponent } from './my-subscriptions/my-subscriptions.component';
import { GlobelNewsComponent } from './globel-news/globel-news.component';
import { MyProfileComponent, OrdinalDatePipe } from './my-profile/my-profile.component';
import { TradingAcademyComponent } from './trading-academy/trading-academy.component';
import { TradeToolsComponent } from './trade-tools/trade-tools.component';
import { TradingAcademy2Component } from './trading-academy2/trading-academy2.component';
import { TradingAcademyVideoLibraryComponent } from './trading-academy-video-library/trading-academy-video-library.component';

import { TradingToolsTutorialsComponent } from './trading-tools-tutorials/trading-tools-tutorials.component';
import { TradingStrategiesComponent } from './trading-strategies/trading-strategies.component';

import { PrizesComponent } from './prizes/prizes.component';
import { PersonalChatComponent } from './personal-chat/personal-chat.component';

import { TradeIdeaComponent } from './dashboard/trade-idea/trade-idea.component';
import { TradeSuccessThankComponent } from './dashboard/trade-idea/trade-success-thank/trade-success-thank.component';
import { ContactThankPopupComponent } from '../layout/contact-thank-popup/contact-thank-popup.component'

import { PerfectScrollbarModule, PerfectScrollbarConfigInterface,
  PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { ChatComponent } from './chat/chat.component';
import { DashboardHeaderComponent } from './dashboard-header/dashboard-header.component';
import { TradingAcademyVideoSeriesComponent, SafePipe } from './trading-academy-video-series/trading-academy-video-series.component';
import { DialogPopupComponent } from './dialog-popup/dialog-popup.component';

import { HeaderComponent } from '../layout/header/header.component';
import { FooterComponent } from '../layout/footer/footer.component';

import { DialogLogoutComponent } from './dashboard-header/dialog-logout/dialog-logout.component';
import { ChangePasswordComponent } from './my-profile/change-password/change-password.component';
import { SubscribeTradeNotificationComponent } from './my-profile/subscribe-trade-notification/subscribe-trade-notification.component';
import { ThankyouComponent } from './thankyou/thankyou.component';
import { ImagePopupComponent } from './my-profile/image-popup/image-popup.component';
//import { PickerModule } from '@ctrl/ngx-emoji-mart';

import { EmojiModule, EmojiService } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { PickerModule, EmojiFrequentlyService, EmojiSearch } from '@ctrl/ngx-emoji-mart';
import { Error404Component } from './error404/error404.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { MemberPageComponent } from './member-page/member-page.component';
import { RecommendedBrokersComponent } from './recommended-brokers/recommended-brokers.component';
import { ProductsServicesComponent } from './products-services/products-services.component';
import { NoAccessComponent } from './dashboard-header/no-access/no-access.component';
  const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {


    minScrollbarLength: 40,       // Minimum size (px) for the scrollbar (Default: null).
    maxScrollbarLength: 50,      // Maximum size (px) for the scrollbar (Default: null).
  };

import { Select2Module } from 'ng2-select2';
import { GlobelNewsFootSliderComponent } from './globel-news-foot-slider/globel-news-foot-slider.component';
import { NgxAgoraModule } from 'ngx-agora';
import { environment } from 'src/environments/environment';
import { CountdownModule, CountdownGlobalConfig, CountdownConfig } from 'ngx-countdown';
import { NgxCaptchaModule } from 'ngx-captcha';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NotificationPageComponent } from './notification-page/notification-page.component';
import { JoinMeetingComponent } from './dashboard/join-meeting/join-meeting.component';

import { AuthGuard } from '../auth/guards/auth.guard';
import { RandomGuard } from '../auth/guards/random.guard';
import { FaqComponent } from './faq/faq.component';
import { MySubscriptions2Component } from './my-subscriptions2/my-subscriptions2.component';
import { PayDialog } from './my-subscriptions2/my-subscriptions2.component';
//import { ChooseToolCheckbox, ChooseToolRadio } from './subscription-plans/subscription-plans.component';

import { AutomationToolsComponent } from './automation-tools/automation-tools.component';
import { AutomationDetailComponent } from './automation-detail/automation-detail.component';
import { SubscriptionPlansComponent } from './subscription-plans/subscription-plans.component';
import { PayPageComponent } from './pay-page/pay-page.component';
import { MySubscriptionDetailComponent } from './my-subscription-detail/my-subscription-detail.component';

// define routes
const routes: Routes = [
  //{ path: '', component: HomeComponent },
  //{path: 'home', component:HomeComponent},
  //{path: '', component:DashboardComponent},
  {
    path: '',
    component: HomeComponent

  },
  { path: 'unsubscribe/:uid', component: UnsubscribeEmailComponent },
  { path: 'unsubscribe', component: UnsubscribeEmailComponent },
  { path: 'sign-up', component: SignupComponent},
  { path: 'sign-up-started', component: SignupPageTwoComponent,canActivate: [AuthGuard] },
  { path: 'sign-in', component: SigninComponent,canActivate: [AuthGuard]  },
  { path: 'sign-in/:type', component: SigninComponent,canActivate: [AuthGuard]  },
  { path: 'forgot', component: ForgotComponent,canActivate: [AuthGuard] },
  { path: 'verify', component: VerifyComponent,canActivate: [AuthGuard] },
  { path: 'dashboard',
    component: DashboardComponent,
    canActivate: [RandomGuard], data: {title: 'Member Access'} ,
    children: [
      {
        path: '',
        component: ChatComponent,
      }
    ]
  },
  { path: 'reset-password', component: ResetPasswordComponent },
  { path: 'reset-password/:userid/:code', component: ResetPasswordComponent },
  { path: 'signals-detail-view', component: SignalsDetailViewComponent,
    children: [
      {
        path: '',
        component: ChatComponent,
      }
    ]  },
  { path: 'signals-detail-view/:tradeid', component: SignalsDetailViewComponent,
    children: [
      {
        path: '',
        component: ChatComponent,
      }
    ]  },
  // { path: 'my-subscriptions', component: MySubscriptionsComponent,canActivate: [RandomGuard] },
  //{ path: 'my-subscriptions2', component: MySubscriptions2Component,canActivate: [RandomGuard] },
  //{ path: 'subscription-plans', component: MySubscriptions2Component,canActivate: [RandomGuard] },
  { path: 'subscription-plans', component: SubscriptionPlansComponent,canActivate: [RandomGuard] },
  { path: 'pay-page', component: PayPageComponent },
  { path: 'my-subscription-detail', component: MySubscriptionDetailComponent, canActivate: [RandomGuard] },
  { path: 'global-news', component: GlobelNewsComponent, canActivate: [RandomGuard] },
  { path: 'my-profile', component: MyProfileComponent, canActivate: [RandomGuard] },
  { path: 'trading-academy', component: TradingAcademyComponent, canActivate: [RandomGuard],
    children: [
      {
        path: '',
        component: ChatComponent,
      }
    ]  },
  { path: 'trading-tools', component: TradingAcademyComponent, canActivate: [RandomGuard],
    data: {title: 'Trading Access'} ,
    children: [
      {
        path: '',
        component: ChatComponent,
      }
    ]  },
  { path: 'trade-tools', component: TradeToolsComponent, canActivate: [RandomGuard],
    children: [
      {
        path: '',
        component: ChatComponent,
      }
    ]  },
  { path: 'automation-tools', component: AutomationToolsComponent, canActivate: [RandomGuard] },
  { path: 'automation-detail', component: AutomationDetailComponent, canActivate: [RandomGuard] },
  { path: 'trading-academy2', component: TradingAcademy2Component, canActivate: [RandomGuard] },
  { path: 'tc-video-library', component: TradingAcademyVideoLibraryComponent, canActivate: [RandomGuard] },
  { path: 'prizes', component: PrizesComponent, canActivate: [RandomGuard] },
  { path: 'personal-chat', component: PersonalChatComponent, canActivate: [RandomGuard] },
  { path: 'tc-video-series', component: TradingAcademyVideoSeriesComponent, canActivate: [RandomGuard] },
  { path: 'thankyou', component: ThankyouComponent },
  { path: 'thankyou/:userid', component: ThankyouComponent, canActivate: [RandomGuard] },
  { path: 'error404', component: Error404Component },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'member-page', component: MemberPageComponent },
  { path: 'recommended-brokers', component: RecommendedBrokersComponent },
  { path: 'products-services', component: ProductsServicesComponent },
  { path: 'notification-page', component: NotificationPageComponent, canActivate: [RandomGuard] },
  { path: 'join-meeting', component: JoinMeetingComponent, canActivate: [RandomGuard] },
  { path: 'trading-tools-tutorials', component: TradingToolsTutorialsComponent, canActivate: [RandomGuard] },
  { path: 'strategies', component: TradingStrategiesComponent, canActivate: [RandomGuard] },
  { path: 'faq', component: FaqComponent },

];


@NgModule({
  // module declaration
  declarations: [
    CreditCardDirective,
    HeaderComponent,
    FooterComponent,
    HomeComponent,
    SignupComponent,
    SignupPageTwoComponent,
    SigninComponent,
    ForgotComponent,
    VerifyComponent,
    DashboardComponent,
    ResetPasswordComponent,
    SignalsDetailViewComponent,
    CreateStreamVideoDialog,
    CreateUpdateCardDialog,
    CreatecancelCardDialog,
    CreateImageDialog,
    UnsubscribeEmailComponent,



    ExpdateDirective,
    MySubscriptionsComponent,
    AutoFocusDirective,
    GlobelNewsComponent,
    MyProfileComponent,
    TradingAcademyComponent,
    TradeToolsComponent,
    TradingAcademy2Component,
    TradingAcademyVideoLibraryComponent,
    TradingToolsTutorialsComponent,
    TradingStrategiesComponent,
    PrizesComponent,
    PersonalChatComponent,
    TradeSuccessThankComponent,
    ContactThankPopupComponent,
    TradeIdeaComponent,

    DateAgoPipe,
    TruncatePipe,
    TitlecasestringPipe,
    ChatComponent,
    DashboardHeaderComponent,
    TradingAcademyVideoSeriesComponent,

    SafePipe,
    OrdinalDatePipe,
    DialogPopupComponent,
    DialogLogoutComponent,
    ChangePasswordComponent,
    SubscribeTradeNotificationComponent,
    ThankyouComponent,
    ImagePopupComponent,
    Error404Component,
    ContactUsComponent,
    MemberPageComponent,
    RecommendedBrokersComponent,
    ProductsServicesComponent,
    NoAccessComponent,
    GlobelNewsFootSliderComponent,
    NotificationPageComponent,
    JoinMeetingComponent,
    FaqComponent,
    MySubscriptions2Component,
    PayDialog,
    AutomationToolsComponent,
    AutomationDetailComponent,
    SubscriptionPlansComponent,
    // ChooseToolRadio,
    // ChooseToolCheckbox,
    PayPageComponent,
    MySubscriptionDetailComponent


  ],
  // module imports
  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    EmojiModule,
    PickerModule,
    FlexLayoutModule,
    MatButtonModule,
    MatDividerModule,
    MatFormFieldModule,
    MatIconModule,
    MatMenuModule,
    MatSelectModule,
    MatTableModule,
    MatTabsModule,
    MatOptionModule,
    MatListModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatChipsModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    MatProgressSpinnerModule,
    RouterModule.forChild(routes),
    ImageCropperModule,
    NgxShimmerLoadingModule,
    AutosizeModule,
    Select2Module,
    CountdownModule,
    NgxCaptchaModule,
    CarouselModule ,
    MatRadioModule,
    NgxAgoraModule.forRoot({ AppID: environment.agora.appId }),
  ],
  entryComponents: [
    CreateStreamVideoDialog,
    CreateUpdateCardDialog,
    CreatecancelCardDialog,
    TradeSuccessThankComponent,
    ContactThankPopupComponent,
    TradeIdeaComponent,
    CreateImageDialog,
    DialogPopupComponent,
    DialogLogoutComponent,
    NoAccessComponent,
    ChangePasswordComponent,
    SubscribeTradeNotificationComponent,
    ImagePopupComponent,
    PayDialog
    // ChooseToolRadio,
    // ChooseToolCheckbox

  ],

  // export routes
  exports: [RouterModule],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    },
    { provide: CountdownGlobalConfig },
    EmojiFrequentlyService, EmojiService, EmojiSearch,AuthGuard,RandomGuard
  ]
})
// export mail module
export class MainModule { }
