import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../app.global';
import { tokendata } from '../variable';
import { AuthService } from '../../service/auth.service';
import { HttpClient,HttpParams,HttpHeaders } from '@angular/common/http';
import { DialogPopupComponent } from '../dialog-popup/dialog-popup.component';
import {MatDialog,MatDialogRef,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { NoAccessComponent } from '../dashboard-header/no-access/no-access.component';

@Component({
  selector: 'app-trading-academy-video-library',
  templateUrl: './trading-academy-video-library.component.html',
  styleUrls: ['./trading-academy-video-library.component.scss'],
  providers: [ AppGlobals]
})
export class TradingAcademyVideoLibraryComponent implements OnInit {

  token ='';
  imgBaseUrl = '';
  videoList = [];
  offset: string = "0";
  limit:string = "9";
  videoTotalCount;
  isApiCalled = false;
  subscription_status=''
  isScrollCalled = false;

  userId = "0";
  isAccessible = false;

  admin_subscription = 0;
  loginType = '';

  constructor(public dialog: MatDialog,private http: HttpClient,private router: Router, private baseUrl: AppGlobals, private auth: AuthService, private route: ActivatedRoute) { }

  ngOnInit() {
    /**********/
    this.loginType = localStorage.getItem('for_usertype');
    // if(this.loginType!=='trading-academy'){ //'member'
    //   this.router.navigate(['/error404']);
    // }
    let user_accessibility = localStorage.getItem('for_accessibility');
     if(user_accessibility!=''){
       let accessibility = JSON.parse(user_accessibility);
        if(!accessibility.video_library || this.loginType!='trading_academy')this.router.navigate(['/error404']);
     }
    /**********/

    this.getVideoLibrary();
    //this.subscription_status = localStorage.getItem('subscription_status');

    this.userId = localStorage.getItem('for_userid');
    this.getSubscriptionDetail();
  }

  getSubscriptionDetail() {
    this.auth.getSubscriptionDetail(this.baseUrl.baseAppUrl+'user/getSubscriptionDetail?userId='+this.userId,this.token).subscribe((res: any) =>  {
        if(res){
          console.log(res);
          if(res.data.length>0){
            // this.subscription_status=res.data.subscription_status;
            // // if(res.data.tradeAccess=='active')
            // // {
            // //   this.subscription_status='active';
            // // }
            // //console.log(this.subscription_status, 'insode');
            // if(res.data.is_accessible==1 && res.data.subscription_status!='trial')
            // {
            //   this.isAccessible=true;
            // }else{
            //   this.isAccessible=false;
            // }
            // this.admin_subscription = res.data.admin_subscription;
            for(var i=0;i<res.data.length;i++){
              if(res.data[i].permission_video_library==1){
                this.isAccessible=true;
                this.subscription_status='active';
              }
              console.log(res.data[i].permission_video_library, ' == ',this.isAccessible ,' == ',this.subscription_status);

            }
          }
          else{
            this.isAccessible=false;
          }

        }
     });
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(e) {
    if(this.videoTotalCount>this.offset && !this.isScrollCalled)
    {
      this.onListScroll();
    }
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.onListScroll, true);
  }

  onListScroll() {
    if(this.videoTotalCount>this.offset && !this.isScrollCalled)
    {
      this.getVideoLibrary();
    }
  }

  getVideoLibrary(){
    let params = new HttpParams()
                .set('offset', this.offset)
                .set('limit', this.limit);
    this.token=localStorage.getItem('for_token');
        if(this.token == null || this.token ==""){
            this.router.navigate(['/sign-in']);
        }

    if(this.offset!=undefined)
    {
      this.isScrollCalled=true;
      this.auth.videoLibrarylistSrvs(this.baseUrl.baseAppUrl+'trade/videoLibrary_listing?offset='+this.offset + '&limit='+this.limit , this.token).subscribe((res: any) =>  {
          if(res){
              this.isApiCalled = true;
              this.imgBaseUrl = this.baseUrl.baseAppUrl+'uploads/video_library/';
                this.videoTotalCount = res.total;
                this.offset = res.offset;
              if(res.status==1){
              	if(this.offset=="0")
                {
                	this.videoList = res.data;
                }
                else{
                	this.videoList = this.videoList.concat(res.data);
                }
                this.isScrollCalled=false;
               }
            }
            else{

             }
          });
    }
  }

  openVideo(videoData, i){
    //if( this.admin_subscription == 1 || ((this.subscription_status=='trial' || this.subscription_status=='cancel' || this.subscription_status=='failed') && this.isAccessible==false ))
    //if( (this.subscription_status=='trial' || this.subscription_status=='cancel' || this.subscription_status=='failed') && this.isAccessible==false )
    if(this.isAccessible==false )
    {
      const dialogRef = this.dialog.open(NoAccessComponent, {
      });

      dialogRef.afterClosed().subscribe(result => {

      });

    }else{

      var image = this.imgBaseUrl+videoData.imageName;
      this.videoViewed(videoData.id, i);
      const dialogRef = this.dialog.open(DialogPopupComponent, {
        data: { url: videoData.video_url, image: image }
      });
    }

    }

    videoViewed(id, index){
    this.token=localStorage.getItem('for_token');
        if(this.token == null || this.token ==""){
            this.router.navigate(['/sign-in']);
        }

      const data = {
	      user_id : localStorage.getItem('for_userid'),
	      video_id : id,
      };
    this.auth.videoView(this.baseUrl.baseAppUrl+'trade/videoLibraryView', data , this.token).subscribe((res: any) =>  {
            if(res.status==1){
            	this.videoList[index].viewCount = this.videoList[index].viewCount+1;
             }
            else{

             }
          });
  	}

}
