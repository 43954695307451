import { Component, OnInit,Inject } from '@angular/core';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import { ValidatorFn,FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-admin-subscription-status-dialog',
  templateUrl: './admin-subscription-status-dialog.component.html',
  styleUrls: ['./admin-subscription-status-dialog.component.scss']
})
export class AdminSubscriptionStatusDialogComponent implements OnInit {

	public updateSubscriptionForm: FormGroup;
	check_sess;
	user_id;
  admin_subscription;
  admin_subscription_on="1";
  admin_subscription_off="0";
	month;
	userID:string='';
	userTypes=<any>[]
	submitted = false;
	userType;
   disableTextbox =  true;
   selectedMonth:any;
   monthErr =false;
   accessibility=false;

  //public updateSubscriptionForm: FormGroup;
  //submitted = false;
  months = [0,1,2,3,4,5,6,7,8,9,10,11,12];
  
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AdminSubscriptionStatusDialogComponent>
    ) {
  		// if(data){
      //       this.message = data.message || this.message;
      //       if (data.buttonText) {
      //         this.confirmButtonText = data.buttonText.ok || this.confirmButtonText;
      //         this.cancelButtonText = data.buttonText.cancel || this.cancelButtonText;
      //       }
      // }
     }

  ngOnInit() {
    console.log(this.data);
    this.accessibility = this.data.accessibility;
        // this.updateSubscriptionForm = this.fb.group({
        //   admin_subscription: [this.data.admin_subscription, Validators.required],
        //   month: ['', Validators.required],
        //   uid:[this.data.uid]
        // });

        this.selectedMonth= this.month = this.data.month;
        //if(this.selectedMonth==NaN || this.selectedMonth=='NaN')this.selectedMonth = 0;
        console.log('this.selectedMonth',this.selectedMonth);
        
        //this.admin_subscription = (this.data.admin_subscription).toString();
        console.log('this.admin_subscription',this.admin_subscription);
        this.updateSubscriptionForm = new FormGroup({
          //admin_subscription: new FormControl(this.admin_subscription, [Validators.required]),
          month: new FormControl(this.data.month, [Validators.required]),
          uid : new FormControl(this.data.id),
        });
  }

  get updateSubscriptionFrm () { return this.updateSubscriptionForm.controls; }


  onSubmit() {

    console.log('dd',this.admin_subscription,this.month,this.updateSubscriptionForm.value);
    this.monthErr = false;
    // if(this.updateSubscriptionForm.value.admin_subscription=='1'){
    //     this.submitted = true;
    //     console.log(this.updateSubscriptionForm);
    //     if (this.updateSubscriptionForm.invalid) {
    //         return;
    //     }
    //     if(this.updateSubscriptionForm.value.month==0){
    //       this.monthErr = true;
    //       return ;
    //     }
    // }

    
    
    var submitdata = this.updateSubscriptionForm.value;
    console.log(submitdata);
    
    this.dialogRef.close(submitdata);
  }

  onConfirmClick(): void {
    this.dialogRef.close(true);
  }

}
