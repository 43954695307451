import { Injectable } from '@angular/core';
import { Router } from "@angular/router";

@Injectable()
export class AppGlobals {

  //local
      // readonly baseAppUrl: string = 'http://localhost:4005/';
      // readonly baseFrontAppUrl: string = 'http://localhost:4005/';
  //staging
      readonly baseAppUrl: string = 'https://dashboard.staging.forexplatinumtrading.com/';
      readonly baseFrontAppUrl: string = 'https://staging.forexplatinumtrading.com/';
  //prod
      // readonly baseAppUrl: string = 'https://dashboard.prod.forexplatinumtrading.com/';
      // readonly baseFrontAppUrl: string = 'https://forexplatinumtrading.com/';
  //live
      // readonly baseAppUrl: string = 'https://dashboard.forexplatinumtrading.com/';
      // readonly baseFrontAppUrl: string = 'https://forexplatinumtrading.com/';


  //server
    //readonly baseAppUrl: string = 'https://newsite.forexplatinumtrading.com/';

  //zoom
    //readonly baseAppUrl: string = 'https://4999.forexplatinumtrading.com/';
  //staging
          //readonly baseAppUrl: string = 'http://78.46.210.25:4004/';
  //QA
            //readonly baseAppUrl: string = 'http://78.46.210.25:5005/';

    public isLogin: boolean = false;
    public auth_token = localStorage.getItem("auth_token");
    constructor(private router:Router) {
    //console.log(this.auth_token, 'auth token');
    if(!this.auth_token)
    {
        //console.log("hellos login");
      //this.router.navigate(['/sign-in']);
    }
  }

}
