import { Component, OnInit, ViewChild } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';
import {AdminService} from "../services/admin.service";
import { AppGlobals } from '../../app.global';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { VERSION, MatDialogRef, MatDialog, MatSnackBar, MAT_DIALOG_DATA } from '@angular/material';
import {ConfirmDialogComponent} from '../confirm-dialog/confirm-dialog.component';
declare var $: any;

export interface PeriodicElement {
  row_id: number;
  subscription_id: number;
  planname: string;
  plan_price: string;
  plan_type: string;
  plan_cycle: string;
  status: string;
  action: string;
}
const ELEMENT_DATA: PeriodicElement[] = [];

@Component({
  selector: 'app-subscription-plan',
  templateUrl: './subscription-plan.component.html',
  styleUrls: ['./subscription-plan.component.scss']
})
export class SubscriptionPlanComponent implements OnInit {
  all_records;
	displayedColumns: string[] = ['record_id','planname', 'plan_price','plan_type','status', 'action'];
  dataSource : any;
  check_sess;
  user_id;
  error_msg='';
  success_msg='';
  editpermission=false;
  deletepermission = false;
  completepermission = false;
  capability='';
  userType='';
  addpermission=false;
  readpermission=false;

  @ViewChild(MatPaginator, { static: true, }) paginator: MatPaginator;

  constructor(
    private baseUrl: AppGlobals,
  	private router: Router,
    private dialog: MatDialog,
    private adminService: AdminService,
  ) { }

  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }

  ngOnInit() {
    this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/admin/login-dashboard']);
    }

    this.capability=localStorage.getItem('capability');
    this.userType=localStorage.getItem('user_type');
    if(this.userType!='admin' && this.capability.split(',').indexOf('subscription_edit')>-1){
      this.editpermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('subscription_delete')>-1){
      this.deletepermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('subscription_add')>-1){
      this.addpermission = true;
    }
    if(this.userType!='admin' && this.capability.split(',').indexOf('subscription_read')>-1){
      this.readpermission = true;
    }
    if(this.userType=='admin'){
      this.editpermission=true;
      this.deletepermission = true;
      this.addpermission = true;
      this.readpermission = true;
    }
    if(!this.editpermission && !this.deletepermission && !this.addpermission && !this.readpermission){
      this.router.navigate(['/admin/my-profile']);
    }

    this.success_msg=localStorage.getItem('succ_user');
  	 setTimeout (() => {
         this.success_msg="";
         localStorage.setItem('succ_user','');
        }, 2000);
    this.planlist();
  }

  planlist(){
  	this.all_records=[];
  	this.adminService.subscriptionplan(this.baseUrl.baseAppUrl+'admin/subscriptionplan', this.check_sess).subscribe((res: any) =>  {

      if(res.status == '1'){

        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
          var planType = '';
          planType += (res.data[i].permission_academy_video==1)?'Trading Academy Videos,':'';
          planType += (res.data[i].permission_video_library==1)?'Trading Video Library,':'';
          planType += (res.data[i].permission_trading_strategies==1)?'Trading Strategies,':'';
          planType += (res.data[i].trading_academy==1)?'Trading Academy,':'';
          planType += (res.data[i].	members==1)?'Members Area,':'';
          planType = planType.slice(0, -1);

          this.all_records.push({record_id: j, subscription_id: res.data[i].id, planname: res.data[i].plan_name, plan_price : res.data[i].plan_amount,plan_type : planType, plan_cycle: res.data[i].plan_cycle, status: res.data[i].status,planrun:res.data[i].planrun},);
          ['record_id','planname', 'plan_price','plan_type', 'plan_cycle', 'status', 'action'];
          j++;
        }
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.all_records);
        this.dataSource.paginator = this.paginator;
        console.log(this.dataSource);
      }
      else{
        this.error_msg = res.message;
      }

    });
  }
  addPlan(){
		this.router.navigate(['admin/subscription-plan-add']);
	}
  editplan(id){
    this.router.navigate(['admin/edit-plan/'+id]);
  }

  openDialog2(id,notdel) {
		if(notdel==true){
      const dialogRef = this.dialog.open(ConfirmDialogComponent,{
        data:{
          message: 'Not able to delete this plan',
          buttonText: { ok: 'OK'}
        }
      });
    }
    else{
      const dialogRef = this.dialog.open(ConfirmDialogComponent,{
        data:{
          message: 'Are you sure want to delete the plan?',
          buttonText: { ok: 'Yes', cancel: 'No' }
        }
      });

        dialogRef.afterClosed().subscribe((confirmed: boolean) => {
          if (confirmed) {
            //this.updateStatus(id);
            console.log('user deleted');
            const data = { id: id };
              this.adminService.updateUserStatus(this.baseUrl.baseAppUrl+'admin/delete_plan', data, this.check_sess).subscribe((res : any) => {
                if(res.status == '1'){
                  this.success_msg = res.message;
                  this.planlist();
                  setTimeout (() => {
                    this.success_msg="";
                  }, 2000);
                }
                else{
                  this.error_msg = res.message;
                  setTimeout (() => {
                    this.error_msg="";
                  }, 2000);
                }
              });
          }
        });
    }


	}

}
