import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import {
  MatDialog,
  MAT_DIALOG_DATA,
  MatDialogRef,
  MatDialogModule,
} from "@angular/material/dialog";
// import {  PayDialog } from "../my-subscriptions2/my-subscriptions2.component";
import { FormBuilder, FormGroup } from "@angular/forms";
import { AuthService } from "src/app/service/auth.service";
import { AppGlobals } from "src/app/app.global";
import { Router } from "@angular/router";

export interface DialogData {
  animal: string;
  name: string;
  id: BigInteger;
  cardelement: any;
  stripeLoad: any;
}

@Component({
  selector: "app-subscription-plans",
  templateUrl: "./subscription-plans.component.html",
  styleUrls: ["./subscription-plans.component.scss"],
})
export class SubscriptionPlansComponent implements OnInit {

  all_records: { subscription_id: any; planname: any; plan_price: any; plan_type: any; plan_cycle: any; status: any; academy_video: any; video_library: any; trading_strategies: any; trading_academy: any; members: any; expiryDt: string; plandesc: any; adminsubscrib: number; traderexpiry: string; currDate: string; automation_tool: any; is_recommend: any; }[];
  check_sess: string;
  animal: string;
  name: string;
  cardelement: any;
  stripeLoad: any;
  planexists: any;
  checklist: any = [];
  constructor(public dialog: MatDialog, private baseUrl: AppGlobals,
    private router: Router,
    private authservice: AuthService,) { }
  // openDialog() {
  //   const dialogRef = this.dialog.open(PayDialog, {
  //     data: {},
  //   });
  //   dialogRef.afterClosed().subscribe((result) => { });
  // }

  /*chooseToolRadioModalOpen() {
    const dialogRefs = this.dialog.open(ChooseToolRadio, {
      data: {},
    });
    dialogRefs.afterClosed().subscribe((result) => { });
  }
  chooseToolCheckboxModalOpen() {
    const dialogRefs = this.dialog.open(ChooseToolCheckbox, {
      data: {},
    });
    dialogRefs.afterClosed().subscribe((result) => { });
  }*/

  chooseOption(item: any) {
    // if(option===1){
    //   //this.chooseToolRadioModalOpen();
    //   localStorage.setItem('option','1');
    // }
    // else if(option===2){
    //   //this.chooseToolCheckboxModalOpen();
    //   localStorage.setItem('option','2');
    // }
    // else{
    //   this.openDialog();
    //   localStorage.setItem('option','all');
    // }
    
    localStorage.setItem('selectedPlan',JSON.stringify(item));
    this.router.navigate(['/pay-page']);
  }

  ngOnInit() {
    this.check_sess = localStorage.getItem('for_token');
    if (this.check_sess == null || this.check_sess == "") {
      this.router.navigate(['/sign-in']);
    }
    var userId = localStorage.getItem('for_userid');
    this.planexists = [];
    this.authservice.getExistPlan(this.baseUrl.baseAppUrl + 'web/getExistPlan?id=' + userId, this.check_sess).subscribe((res: any) => {
      if (res.status == '1') {
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
          var cd = new Date();
          var cmonth = (cd.getMonth() < 10) ? '0' + (cd.getMonth() + 1) : cd.getMonth() + 1;
          var cday = (cd.getDate() < 10) ? '0' + cd.getDate() : cd.getDate();
          var currDate = cd.getFullYear() + '-' + cmonth + '-' + cday;

          var d = new Date(res.data[i].end_date);
          var month = (d.getMonth() < 10) ? '0' + (d.getMonth() + 1) : d.getMonth() + 1;
          var day = (d.getDate() < 10) ? '0' + d.getDate() : d.getDate();
          var endDate = d.getFullYear() + '-' + month + '-' + day;

          var td = new Date(res.data[i].trader_end_date);
          var tmonth = (td.getMonth() < 10) ? '0' + (td.getMonth() + 1) : td.getMonth() + 1;
          var tday = (td.getDate() < 10) ? '0' + td.getDate() : td.getDate();
          var tendDate = td.getFullYear() + '-' + tmonth + '-' + tday;
          this.checklist.push({ planId: res.data[i].plan_id, startDt: res.data[i].start_date, endDt: endDate, id: res.data[i].id, adminSubscribe: res.data[i].admin_subscription, tradstartDt: res.data[i].trader_start_date, tradendDt: tendDate, currDate: currDate });
          j++;
        }
      }
    });

    var __this = this;
    setTimeout(function () {
      __this.planlist();
    }, 2000);

  }

  planlist() {
    this.all_records = [];
    this.authservice.getPlan(this.baseUrl.baseAppUrl + 'web/planlist', this.check_sess).subscribe((res: any) => {

      if (res.status == '1') {
        let j = 1;
        for (let i = 0; i < res.data.length; i++) {
          var checkedPlan = 0;
          var expiryPlan = '';
          var traderexpiryPlan = '';
          var adminsubscrib = 0;
          var currDate = '';
          if (this.checklist.length > 0) {
            for (var k = 0; k < this.checklist.length; k++) {
              if (this.checklist[k].planId == res.data[i].id && this.checklist[k].adminSubscribe == 0) {
                checkedPlan = res.data[i].id;
                expiryPlan = this.checklist[k].endDt;
                traderexpiryPlan = this.checklist[k].tradendDt;
                adminsubscrib = this.checklist[k].adminSubscribe;
                currDate = this.checklist[k].currDate;
              }
              else if (this.checklist[k].adminSubscribe == 1) {
                adminsubscrib = this.checklist[k].adminSubscribe;
                expiryPlan = this.checklist[k].endDt;
                traderexpiryPlan = this.checklist[k].tradendDt;
                currDate = this.checklist[k].currDate;
              }
            }
          }

          this.all_records.push({ subscription_id: res.data[i].id, planname: res.data[i].plan_name, plan_price: res.data[i].plan_amount, plan_type: res.data[i].plan_type, plan_cycle: res.data[i].plan_cycle, status: res.data[i].status, academy_video: res.data[i].permission_academy_video, video_library: res.data[i].permission_video_library, trading_strategies: res.data[i].permission_trading_strategies, trading_academy: res.data[i].trading_academy, members: res.data[i].members, expiryDt: expiryPlan, plandesc: res.data[i].plan_description, adminsubscrib: adminsubscrib, traderexpiry: traderexpiryPlan, currDate: currDate, automation_tool: res.data[i].automation_tool, is_recommend: res.data[i].is_recommend });
          j++;
        }
      }
    });
  }
}

// payment modal
/*@Component({
  selector: 'PayDialog',
  templateUrl: 'PayDialog.html',
  // standalone: true,
  // imports: [MatDialogModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule],
})
export class PayDialog {

  @ViewChild('paymentForm', { static: false }) paymentForm: ElementRef;
  public cardForm: FormGroup;
  cardName: any;
  cardNo: any;
  expDate: any;
  cvv: any;
  isSubmitted = false;
  card_error: any;
  cvv_error: any;
  token: any;
  stripe_id: any;
  isUpdateDisable = false;
  exp_error: any;
  stripeCard: any;
  stripeData: any = [];
  card_id: any;
  plan_id: any;
  cardElement: any;
  stripeLoad: any;
  msg = '';

  constructor(
    public dialogRef: MatDialogRef<PayDialog>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private baseUrl: AppGlobals,
    private auth: AuthService, private formBuilder: FormBuilder,
  ) {
    dialogRef.disableClose = true;
    this.cardName = '';
    this.cardNo = '';
    this.stripeCard = '';
    this.cvv = '***';
    this.expDate = '';
    this.stripe_id = '';
    this.stripeData = '';
    this.card_id = '';
    this.plan_id = data.id;
    this.cardElement = data.cardelement;
    this.stripeLoad = data.stripeLoad;
  }

  closeUpdate() {
    this.dialogRef.close({ data: 'cross_icon' });
  }
  ngAfterViewInit() {

    this.cardElement.mount('#card-element');
  }

}

// radio
@Component({
  selector: 'ChooseToolRadio',
  templateUrl: 'ChooseToolRadio.html',
  // standalone: true,
  // imports: [MatDialogModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule],
})
export class ChooseToolRadio {
  constructor(
    public dialogRef: MatDialogRef<ChooseToolRadio>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private baseUrl: AppGlobals,
    private auth: AuthService, private formBuilder: FormBuilder,
  ) {
    dialogRef.disableClose = true;
  }

  closeUpdate() {
    this.dialogRef.close({ data: 'cross_icon' });
  }
}


// checkbox
@Component({
  selector: 'ChooseToolCheckbox',
  templateUrl: 'ChooseToolCheckbox.html',
  // standalone: true,
  // imports: [MatDialogModule, MatFormFieldModule, MatInputModule, FormsModule, MatButtonModule],
})
export class ChooseToolCheckbox {
  constructor(
    public dialogRef: MatDialogRef<ChooseToolCheckbox>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private baseUrl: AppGlobals,
    private auth: AuthService, private formBuilder: FormBuilder,
    public dialog: MatDialog
  ) {
    dialogRef.disableClose = true;
  }

  openDialog() {
    const dialogRef = this.dialog.open(PayDialog, {
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => { });
  }

  closeUpdate() {
    this.dialogRef.close({ data: 'cross_icon' });
  }
}*/
