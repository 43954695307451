import { Component, OnInit, Renderer, ElementRef, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import {AdminService} from "../services/admin.service";
import { AppGlobals } from '../../app.global';
import * as $ from 'jquery';

@Component({
  selector: 'app-trading-academy',
  templateUrl: './trading-academy.component.html',
  styleUrls: ['./trading-academy.component.scss'],
  providers: [ AppGlobals]
})
export class TradingAcademyComponent implements OnInit {
  public addVideoAcademyForm: FormGroup;
  submitted: boolean = false;
  trade_id='';
  title='';
  mentors='';
  description='';
  tid='';
  btn_title='Add Chapter';
  resImageFile : File = null;
  resImageSrc: any ='';
  invalidresimage='';
  invalidfile = false;
  resimage : any ;
  check_sess;
  errorImage;
  videoDetailError = [];
  invalidVideoUrl:boolean = false;
  urlError =[];
  valid = true;
  embedVideoList = [];
  descriptionError = false;
  mentorsError =  false;
  position;
  capability='';
  userType='';

  constructor(private fb: FormBuilder,private renderer: Renderer, private el: ElementRef,private adminService: AdminService, private baseUrl: AppGlobals,
    private router: Router) {
  }

  ngOnInit() {
    this.check_sess=localStorage.getItem('access_token');
    if(this.check_sess == null || this.check_sess ==""){
        this.router.navigate(['/admin/login-dashboard']);
    }
    this.capability=localStorage.getItem('capability');
    this.userType=localStorage.getItem('user_type');

    // if(this.userType!='admin' && this.capability.split(',').indexOf('trading_academy_edit')<=-1){
    //   return false;
    // }


  	this.trade_id=localStorage.getItem('for_tradeid');
    if(this.trade_id !=''){

         const data = {
            id: this.trade_id
            };
          this.adminService.getEditdetail(this.baseUrl.baseAppUrl+'admin/trade_detail', data).subscribe((res : any) => {
            if(res.status == '1'){

              this.title   = res.data.title;
              this.mentors= res.data.mentors;
              this.description  = res.data.description;
              this.tid     = res.data.id;
              this.position = res.data.position;
              //this.resImageSrc= this.baseUrl.baseAppUrl+'uploads/video_library/r'+res.data.image_name;
              this.resImageSrc= this.baseUrl.baseAppUrl+'uploads/trading-academy/'+this.tid+'/r'+res.data.image_name;
              this.invalidfile=true;

              this.embedVideoList = res.vid_list;

              this.addVideoAcademyForm = this.fb.group({
                videoUrl: this.fb.array(res.vid_list.map(datum => this.generateDatumFormGroup(datum))),
                title: this.title,
                mentors: this.mentors,
                position: this.position,
                description: this.description,
                image: this.resImageSrc,
              });
              this.btn_title='Update Channel';
              }
            else{
              }
          });
        }

      localStorage.setItem('for_tradeid','');

    this.addVideoAcademyForm = this.fb.group({
            title: ['', Validators.required],
            position: ['', Validators.required, , Validators.pattern("^[0-9]*$")],
            mentors: ['', Validators.required],
            description: ['', Validators.required],
            image :  ['', Validators.required],
      		  videoUrl: this.fb.array([
      			this.initSection()])
        });

}

validVideoUrl(str, prefixes) {
  var cleaned = str.replace(/^(https?:)?\/\/(www\.)?/, '');
  for(var i = 0; i < prefixes.length; i++) {
    if (cleaned.indexOf(prefixes[i]) === 0)
      return cleaned.substr(prefixes[i].length)
  }
  return false;
}

getControls(frmGrp: FormGroup, key: string) {
  return (<FormArray>frmGrp.controls[key]).controls;
}

  get signinFrm() { return this.addVideoAcademyForm.controls; }
  //get signinFrm() { return this.addVideoAcademyForm.get('Data'); }


  private generateDatumFormGroup(datum) {
    return this.fb.group({
      url: this.fb.control({ value: datum.video_url, disabled: false }),
      title: this.fb.control({ value: datum.title, disabled: false }),
      host: this.fb.control({ value: datum.host, disabled: false }),
      duration: this.fb.control({ value: datum.duration, disabled: false }),
      views: this.fb.control({ value: datum.viewCount, disabled: true }),
      videoId: this.fb.control({ value: datum.id, disabled: false })
    });
  }

  initSection() {
    return new FormGroup({
      url: new FormControl('', Validators.required),
      title: new FormControl('', Validators.required),
      host: new FormControl('', Validators.required),
      duration: new FormControl('', Validators.required),
      views: new FormControl(''),
      videoId: new FormControl('')
    });
  }

  addUrl() {
    const add = this.addVideoAcademyForm.get('videoUrl') as FormArray;
    add.push(this.fb.group({
      url: [],
      title: [],
      host: [],
      duration: [],
      views:[],
      videoId: []
    }))
  }

  deleteUrl(index: number) {
    const add = this.addVideoAcademyForm.get('videoUrl') as FormArray;
    add.removeAt(index)
  }

  deleteUrlDB(index: number) {
    /*const deleteData = new FormData();
    deleteData.append('videoId', this.addVideoAcademyForm.value.videoUrl[index].id);*/
    console.log(this.embedVideoList[index],'o');
    console.log('videoId', this.addVideoAcademyForm.value.videoUrl[index].id);
    const deleteData = {
      videoId: this.embedVideoList[index].id
      };

    console.log(deleteData,'deleteData')
    this.adminService.deleteChannelVideo(this.baseUrl.baseAppUrl+'admin/deleteChannelVideo', deleteData).subscribe((res : any) => {
      if(res.status == '1'){
        const add = this.addVideoAcademyForm.get('videoUrl') as FormArray;
        add.removeAt(index)
      }
    });
  }

  videoImage(event){
    //console.l(event, 'e');
      const reader = new FileReader();
      const ext = event.target.files[0].name.split('.')[1];
      if (
        ext === 'jpg' ||
        ext === 'JPG' ||
        ext === 'jpeg' ||
        ext === 'JPEG' ||
        ext === 'png' ||
        ext === 'PNG'

        /*ext === 'gif' ||
        ext === 'GIF'*/
      ) {

        this.errorImage='';
        //console.log(event.target.files[0].size/1024/1024 + ' MB');
        if (event.target.files[0].size/1024/1024 > 2) {
         this.invalidresimage = 'The maximum size limit of image is 2 MB.';
          this.invalidfile = false;
          this.resImageSrc = '';
          this.resimage=null;
        }
        else{
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = () => {
              this.resImageSrc = reader.result;
            };
          this.resImageFile = event.target.files[0];
              console.log(this.resImageFile, 'this.resImageSrc');
          this.invalidfile = true;
          this.invalidresimage = '';
          }
        } else {
        this.invalidresimage = 'Invalid file type.';
        //this.resImageFile ='';
        this.invalidfile = false;
        this.resImageSrc = '';
        this.resimage=null;
        }
  }

  removeImage(){
    /*alert();
    console.log('remove image');*/
    this.invalidfile = false;
    this.resImageSrc = '';
    this.resimage=null;
  }



onSubmit(){

  this.submitted = true;
  /*if (this.addVideoAcademyForm.invalid) {
    return;
  }*/
  console.log(this.addVideoAcademyForm.invalid, 'this.addVideoAcademyForm.invalid');

  if (this.tid=='' && this.resimage==null) {
      this.errorImage = 'Please upload image';
      this.invalidresimage='';
      return false;
  }else if(this.tid!='' && this.resImageSrc==''){
    console.log('2');
    this.errorImage = 'Please upload image';
    this.invalidresimage='';
      return false;
  }else if(this.addVideoAcademyForm.value.title=='') {
    this.addVideoAcademyForm.controls.title.errors.required = true;

    return false;
  }else if(this.addVideoAcademyForm.value.description==''){
    this.descriptionError = true;
    return false;
  }else if(this.addVideoAcademyForm.value.mentors==''){
    this.mentorsError = true;
    return false;
  }else{
    if(this.tid=='')
    {
      console.log('10');
      for (var i = 0; i <  this.addVideoAcademyForm.value.videoUrl.length; i++)
      {

          var isValidUrl = this.validVideoUrl(this.addVideoAcademyForm.value.videoUrl[i].url, [
            'player.vimeo.com/video/'
          ]);
          if(isValidUrl=='' || isValidUrl==undefined)
          {
            this.urlError[i]="Please enter valid url";
            console.log('9')
            //this.urlError.push({"error": true});
            //console.log(i, 'test', this.urlError);
            this.invalidVideoUrl=true;
            return false;
          }else{
            this.urlError[i]='';
          }
          console.log(this.addVideoAcademyForm.value.videoUrl[i].url, 'as', this.addVideoAcademyForm.value.videoUrl[i].title, 'po',this.addVideoAcademyForm.value.videoUrl[i].host)
          if(this.addVideoAcademyForm.value.videoUrl[i].url=='' || this.addVideoAcademyForm.value.videoUrl[i].title=='' ||  this.addVideoAcademyForm.value.videoUrl[i].host=='' ||  this.addVideoAcademyForm.value.videoUrl[i].duration=='')
            {
              this.valid = false;
              console.log('8');
              this.videoDetailError[i]="Please add full video detail";
              //this.videoDetailError='Please add full video detail';
              return false;
            }
      }


    }

    console.log(this.addVideoAcademyForm.value.videoUrl, 'this.addVideoAcademyForm.value.videoUrl', this.embedVideoList);


    const academyFormData = new FormData();
    academyFormData.append('title', this.addVideoAcademyForm.value.title);
    academyFormData.append('mentors', this.addVideoAcademyForm.value.mentors);
    academyFormData.append('description', this.addVideoAcademyForm.value.description);
    academyFormData.append('position', this.addVideoAcademyForm.value.position);
    academyFormData.append('id', this.tid);
    academyFormData.append('videoUrl', JSON.stringify(this.addVideoAcademyForm.value.videoUrl));
    academyFormData.append('image', this.resImageFile);
    this.adminService.addChapter(this.baseUrl.baseAppUrl+'admin/add_channel', academyFormData).subscribe((data : any) => {

        if(data.status == '1'){
        localStorage.setItem('succ_trade', data.message);
        this.router.navigate(['admin/trading-list']);
        this.addVideoAcademyForm.reset();
        }
        else{

        }

      });
  }

}



}



