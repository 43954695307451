import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../../app.global';
import { AuthService } from '../../../service/auth.service';
import * as bcrypt from 'bcryptjs';
import { ConfirmedValidator } from '../../confirmed.validator';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
  providers: [ AppGlobals]
})
export class ChangePasswordComponent implements OnInit {
	token ='';
	isSubmitted=false;
	changePasswordForm: FormGroup;
	old_password='';
	new_password='';
	confirm_password='';
  passwordPattern = '/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/';
  oldPassNotMatch = false;
  userPass;
  hide : any;
  hide2 : any;
  hide3 : any;
  
  constructor(private formBuilder: FormBuilder, private router: Router,
      private baseUrl: AppGlobals,
      private auth: AuthService,
      private route: ActivatedRoute
      ) { }

  ngOnInit() {
  	this.token=localStorage.getItem('for_token');
    	if(this.token == null || this.token ==""){
        this.router.navigate(['/sign-in']);
    }

    

    /*this.changePasswordForm = this.formBuilder.group({
        old_password: ['', Validators.required],
        new_password: ['', Validators.required, Validators.pattern(this.passwordPattern)],
        confirm_password: ['', [Validators.required, Validators.pattern(this.passwordPattern)]],
        
    },{
            validator: ConfirmedValidator('new_password', 'confirm_password')
        });*/

        /*this.changePasswordForm = this.formBuilder.group({
            old_password: ['', Validators.required],
            new_password: ['', [Validators.required , Validators.pattern(this.passwordPattern)]],
            conf_password: ['', Validators.required]

        }, {
            validator: ConfirmedValidator('new_password', 'confirm_password')
        });*/
        this.changePasswordForm = this.formBuilder.group({
            old_password: ['', Validators.required],
            new_password: ['', [Validators.required, Validators.minLength(8)]],
            confirm_password: ['', Validators.required]
		},{
            validator: ConfirmedValidator('new_password', 'confirm_password')
        });
    this.getUserDetail();
  }

  get changeFrm() { return this.changePasswordForm.controls; }


  chkOldPassInDB(event) {
  	let userID = localStorage.getItem('for_userid');
	/*this.auth.checkOldPassExist(this.baseUrl.baseAppUrl+'user/getUserDetail?userId='+ userID, this.token).subscribe((res:any) => {
        if(res.status == '1'){
        	console.log(res.data.password, 'password');
        	let isMatch = bcrypt.compareSync(event.target.value, res.data.password, res.data.password);
        	if(!isMatch)
        	{
        		this.oldPassNotMatch=true;
        	}else{

        	}
        }
    });*/

    let isMatch = bcrypt.compareSync(event.target.value, this.userPass, this.userPass);
	if(!isMatch)
	{
		this.oldPassNotMatch=true;
	}else{
		this.oldPassNotMatch=false;
	}

  }

  getUserDetail(){
  	let userID = localStorage.getItem('for_userid');

  	this.auth.checkOldPassExist(this.baseUrl.baseAppUrl+'user/getUserDetail?userId='+ userID, this.token).subscribe((res:any) => {
        if(res.status == '1'){
        	console.log(res.data.password, 'password');
        	this.userPass = res.data.password;
        }
    });
  }

  onkeyupfunc(evt){
     this.oldPassNotMatch=false;
  }

  
  changePassword() {
  	this.isSubmitted = true;
    if (this.changePasswordForm.invalid) {
        return;
    }
    let isMatch = bcrypt.compareSync(this.old_password, this.userPass, this.userPass);
    if(!isMatch)
    {
      this.oldPassNotMatch=true;
      return
    }else{
      this.oldPassNotMatch=false;
    }
    /*if (this.changePasswordForm.invalid || this.oldPassNotMatch) {
        return;
    }*/
    //return;
    const salt = bcrypt.genSaltSync(10);

    let pass = bcrypt.hashSync(this.changePasswordForm.value.new_password, salt);
    console.log(pass)
    const data = {
            user_id: localStorage.getItem('for_userid'),
            password: pass
            };
    
	  
	this.auth.changePassword(this.baseUrl.baseAppUrl+'user/changePassword',data, this.token).subscribe((res:any)=>{
    	if(res.status=='1'){
    		document.getElementById("close_dialog_box_pass").click();
    	}
    });
  }

}
