import * as io from 'socket.io-client';
import { environment } from '../../environments/environment';
import { Observable } from "rxjs/Observable";

export class ChatService {
    private url = environment.socketApiPath;//'http://localhost:3000';
    //private socket = io(environment.socketApiPath);
    private socket;    
    

    constructor() {
        this.socket = io(this.url);
    }
    
    getChatMessages = () => {
        return new Observable<any>((observer) => {
            //console.log('calld');
            this.socket.on('new_chatmessage', (response) => {
                //console.log('message service ',response);
                observer.next(response);
            });
        });
    }
}