import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { CometChat } from '@cometchat-pro/chat';
import { COMETCHAT_CONSTANTS } from './app/Commit-Constants';

if (environment.production) {
  enableProdMode();
}

/*platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
*/

// const appSetting = new CometChat.AppSettingsBuilder().setRegion(COMETCHAT_CONSTANTS.REGION).subscribePresenceForAllUsers().build();
// CometChat.init(COMETCHAT_CONSTANTS.APP_ID, appSetting).then(() => {
// 	console.log('comet chat initialized');
//   platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
// }, (error) => {
//   console.log('Error In Init', error);
// });

const appSetting = new CometChat.AppSettingsBuilder().subscribePresenceForAllUsers().setRegion(COMETCHAT_CONSTANTS.REGION).build();
CometChat.init(COMETCHAT_CONSTANTS.APP_ID, appSetting).then(() => {
    console.log("Initialization completed successfully");
    // You can now call login function.
    platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.error(err));
  },
  (error) => {
    console.log("Initialization failed with error:", error);
    // Check the reason for error and take appropriate action.
  }
);