import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-products-services',
  templateUrl: './products-services.component.html',
  styleUrls: ['./products-services.component.scss']
})
export class ProductsServicesComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }
  autoFocus()
  {
  	var footerRlem = document.getElementById("footer-contact");
       footerRlem.scrollIntoView();
  }

}
