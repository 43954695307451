import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-automation-tools',
  templateUrl: './automation-tools.component.html',
  styleUrls: ['./automation-tools.component.scss']
})
export class AutomationToolsComponent implements OnInit {
  accessPage: any;
  constructor() { }

  ngOnInit() {
    var accessibility = localStorage.getItem('for_accessibility');
    console.log('accessibility -- ',JSON.parse(accessibility));
    this.accessPage = JSON.parse(accessibility);
  }

}
