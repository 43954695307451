import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppGlobals } from '../../app.global';
import { AdminService } from '../services/admin.service';
import * as bcrypt from 'bcryptjs';
import { ConfirmedValidator } from '../confirmed.validator';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {

  isSubmitted=false;
	changePasswordForm: FormGroup;
	new_password='';
	confirm_password='';
  passwordPattern = '/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{6,20}$/';
  oldPassNotMatch = false;
  userPass;
  hide : any;
  hide2 : any;
  hide3 : any;
  success_msg='';
  error_msg='';
  user_id='';
  code='';
  constructor(
    private formBuilder: FormBuilder, private router: Router,
    private baseUrl: AppGlobals,
    private auth: AdminService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    console.log(' ======= ',this.baseUrl.baseAppUrl);
    this.changePasswordForm = this.formBuilder.group({
      new_password: ['', [Validators.required, Validators.minLength(8)]],
      confirm_password: ['', Validators.required]
    },{
          validator: ConfirmedValidator('new_password', 'confirm_password')
    });
  }

  get changeFrm() { return this.changePasswordForm.controls; }

  changePassword() {
  	this.isSubmitted = true;
    console.log(this.changePasswordForm);
    if (this.changePasswordForm.invalid) {
        return;
    }


    this.route.queryParams.subscribe(params => {
      this.user_id = params['id'];
      this.code = params['code'];
  });
    const salt = bcrypt.genSaltSync(10);

    let pass = bcrypt.hashSync(this.changePasswordForm.value.new_password, salt);
    let confirmpass = bcrypt.hashSync(this.changePasswordForm.value.confirm_password, salt);
    
    const data = {
      user_id: this.user_id,
      password: pass,
      code: this.code,
      confirm_password: confirmpass
    };

	  this.auth.resetpassword(this.baseUrl.baseAppUrl+'admin/createpassword',data).subscribe((res:any)=>{
      console.log(' ========== ',res.status);
    	if(res.status=='1' || res.status==1){
    		//document.getElementById("close_dialog_box_pass").click();
        this.success_msg='Password updated successfully';
        var _this=this;
        setTimeout(function(){ _this.success_msg=''; _this.router.navigate(['/admin/login-dashboard']);}, 2000);

    	}
      else{
        this.error_msg='Password not updated';
        setTimeout(function(){ this.error_msg=''; }, 3000);
      }
    });
  }

  click_message(){
    this.success_msg='';
    this.error_msg='';
  }

}
