import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recommended-brokers',
  templateUrl: './recommended-brokers.component.html',
  styleUrls: ['./recommended-brokers.component.scss']
})
export class RecommendedBrokersComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

  onNavigate(){
    window.open("https://www.usgforex.com/RegAcc/RegAccStep1?culture=en-US&IB=2128p8z8x8p8q8Q-01", "_blank");
	}

  onNavigate_next(){
	window.open("https://go.vtmarkets.com/visit/?bta=35347&nci=5440", "_blank");
  }
}
