import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
	token = '';
  check_sess = '';
  constructor(private router: Router) {
    
   }

  ngOnInit() {
  	this.token = localStorage.getItem('access_token');
    
      this.check_sess=localStorage.getItem('for_token');
      var accessroles: any = JSON.parse(localStorage.getItem('for_accessibility'));
      if(accessroles.members===1){
        this.router.navigate(['/dashboard']); return false;
      } 
      if(accessroles.trading_academy===1){
       this.router.navigate(['/trading-academy']); return false;
      } 
      if(this.check_sess != null && this.check_sess !=""){
          this.router.navigate(['/dashboard']);
      }else{
        this.router.navigate(['/sign-in/member']);
      }
  }

}
